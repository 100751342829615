import * as React from "react";
import { Row, Col, Empty } from "antd";
import { ValidateIsTrainer, addDays } from "../../shared/Common";
import "./SchoolTrainingList.scss";
import moment from "moment";
import EqSchoolTrainingCollapse from "../eqSchoolTrainingCollapse/EqSchoolTrainingCollapse";
import { useAuthState } from "../../context/Context";
import { useTranslation, Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { EqEventUserType } from "../../shared/enums";
import { addMonths } from "date-fns";

// This Component is for school training List
// This splits data in today tomorrow and later trainings separately
// on dashboard it will not titles if data not available

// right now its any but data should be school dto
export interface IEqEventSplit {
  today: any;
  tomorrow: any;
  later: any;
}
export interface ISchoolTrainingList {
  trainingList: any;
  trainersOptions: any;
  productOptions: any;
  products: any;
  areas?: any;
  weekDays?: any;
  eventTypes: any;
  population?: any;
  groupPopulation?: any;
  trainerStudents?: any;
  studentHorsesList?: any;
  schoolStudents?: any;
  // if trainer
  trainerSchoolStudents?: any;
  schoolStudentHorsesList?: any;
  SchoolsTrainersOptionsList?: any;

  removeEventFunc?: (trainingData: any,refund?:any) => void;
  editEventFunc?: (data: any) => void;
  removeAttendeeFunc?: (data: any) => void;
  onCreateOrUpdateSchoolTraining?: (data: any, oldData?: any) => void;
}
const SchoolTrainingList = ({
  trainingList,
  trainersOptions,
  productOptions,
  products,
  areas,
  weekDays,
  eventTypes,
  population,
  groupPopulation,
  trainerStudents,
  studentHorsesList,
  schoolStudents,
  trainerSchoolStudents,
  schoolStudentHorsesList,
  SchoolsTrainersOptionsList,
  removeEventFunc,
  editEventFunc,
  removeAttendeeFunc,
  onCreateOrUpdateSchoolTraining,
  ...rest
}: ISchoolTrainingList) => {

  const userDetails: any = useAuthState();
  const location = useLocation();
  const navigate = useNavigate();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const { t, i18n } = useTranslation();
  const [eventData, setEventData] = React.useState({} as IEqEventSplit);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long", // Correctly typed as "long" | "short" | "narrow" | undefined
    day: "2-digit",  // Correctly typed as "numeric" | "2-digit" | undefined
    month: "short",  // Correctly typed as "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
    year: "numeric", // Correctly typed as "numeric" | "2-digit" | undefined
};
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);
  const tomorrowTime = tomorrow.getTime();

  const dayAfterTomorrow = new Date();
  dayAfterTomorrow.setHours(48, 0, 0, 0);
  const dayAfterTomorrowTime = dayAfterTomorrow.getTime();


  const [todayIsCollapseAll, setIsTodayCollapseAll] = React.useState(false);

  const todayCollapseAll = () => {
    setIsTodayCollapseAll(!todayIsCollapseAll);
    eventData?.today.map((item: any) => {
      item.display = !todayIsCollapseAll;
    });
  };

  const [tomorrowIsCollapseAll, tomorrowSetIsCollapseAll] =
    React.useState(false);

  const tomorrowCollapseAll = () => {
    tomorrowSetIsCollapseAll(!tomorrowIsCollapseAll);
    eventData?.tomorrow.map((item: any) => {
      item.display = !tomorrowIsCollapseAll;
    });
  };

  const [laterIsCollapseAll, laterSetIsCollapseAll] = React.useState(false);

  const laterCollapseAll = () => {
    laterSetIsCollapseAll(!laterIsCollapseAll);
    eventData?.later.map((item: any) => {
      item.display = !laterIsCollapseAll;
    });
  };

    React.useEffect(() => {

   
        var formedData = {} as IEqEventSplit;
    //This is Where we are Creating a List Of Riding Course Lessons. This will Now be added in the informatio tab 
    if (trainingList?.length > 0) {
      formedData.today = trainingList?.filter(
        (opt: any) => opt.startDate < tomorrowTime && opt.startDate >= todayTime
      );
      formedData.tomorrow = trainingList?.filter(
        (opt: any) =>
          opt.startDate < dayAfterTomorrowTime && opt.startDate >= tomorrowTime
      );
      formedData.later = trainingList?.filter(
        (opt: any) => opt.startDate > dayAfterTomorrowTime
      );
      setEventData(formedData);
    }
    //formedData.today = trainingList;
    console.log(trainersOptions);
    if (trainerSchoolStudents && schoolStudentHorsesList) {
      console.log(trainerSchoolStudents);
      console.log(schoolStudentHorsesList);
    }
  }, [trainingList, areas, weekDays, trainersOptions]);

  const GetSchoolStudents = (organizers: any): [] => {
    let value = [];
    const ownerOrganizer = organizers.find(
      (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
    );
    if (trainerSchoolStudents) {
      value = trainerSchoolStudents.filter((x: any) => {
        if (x.user.email === ownerOrganizer.user.email) {
          var result = x.schoolStudentsDto;
          return [...result];
        }
      });
    }

    if (value.length > 0) {
      return value[0].schoolStudentsDto;
    } else {
      return value;
    }
  };

  const GetSchoolStudentHorseList = (organizers: any): [] => {
    let value = [];
    const ownerOrganizer = organizers.find(
      (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
    );
    if (schoolStudentHorsesList) {
      value = schoolStudentHorsesList.filter((x: any) => {
        if (x.key === ownerOrganizer.user.email) {
          var result = x.studentHorsesList;
          return [...result];
        }
      });
    }

    if (value.length > 0) {
      return value[0].studentHorsesList;
    } else {
      return value;
    }
  };
  const GetSchoolTrainers = (organizers: any): [] => {
    let value = [];
    const ownerOrganizer = organizers.find(
      (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
    );

    if (SchoolsTrainersOptionsList) {
      value = SchoolsTrainersOptionsList.filter((x: any) => {
        if (x.key === ownerOrganizer.user.email) {
          var result = x.trainersOptions;
          return [...result];
        }
      });
    }

    if (value.length > 0) {
      return value[0].trainersOptions;
    } else {
      return value;
    }
  };


  const GetSchoolProductOption= (item: any): [] => {
    let value = [] as any;
  
    if(item.product){
      let obj = {
        key: item.productId,
        value: item.product.title,
        label: item.product.title,
        limit: item.product.limit,
      };
      value.push(obj)
    }

    return value;
    
  };
  return (
    <>
      {eventData?.later?.length > 0 ||
      eventData?.tomorrow?.length > 0 ||
      eventData?.today?.length > 0 ? (
        <>
          {eventData?.today?.length > 0 ? (
            <>
              <div className="eq-el-container">
                <Row>
                  <Col flex="auto">
                    <h4 className="eq-el-heading">{t("today")}</h4>
                  </Col>
                  <Col className="eq-el-dateHeading">
                    <h5 className="eq-el-date">
                    {new Date().toLocaleDateString(t("dateLang"),options as any)}
                    </h5>
                  </Col>
                </Row>

                {eventData.today
                .sort((a: any, b: any) => a.startDate - b.startDate) // Sort by startTime
                .map((item: any, index: number) => (
                  <>
                    <EqSchoolTrainingCollapse
                      key={index}
                      training={item}
                      trainersOptions={
                        isTrainer
                          ? GetSchoolTrainers(item.organizers)
                          : trainersOptions
                      }
                      productOptions={
                        isTrainer
                        ? GetSchoolProductOption(item)
                        : productOptions
                        }
                      products={products}
                      eventTypes={eventTypes}
                      areas={areas}
                      weekDays={weekDays}
                      population={population}
                      groupPopulation={groupPopulation}
                      schoolStudents={
                        isTrainer
                          ? GetSchoolStudents(item.organizers)
                          : schoolStudents
                      }
                      studentHorsesList={
                        isTrainer
                          ? GetSchoolStudentHorseList(item.organizers)
                          : studentHorsesList
                      }
                      removeEventFunc={removeEventFunc}
                      editEventFunc={editEventFunc}
                      removeAttendeeFunc={removeAttendeeFunc}
                      onCreateOrUpdateSchoolTraining={
                        onCreateOrUpdateSchoolTraining
                      }
                    ></EqSchoolTrainingCollapse>
                  </>
                ))}
              </div>
            </>
          ) : (
            location.pathname !== "/school/home" && (
              <div className="eq-el-container">
                <Row>
                  <Col flex="auto">
                    <h4 className="eq-el-heading">{t("today")}</h4>
                  </Col>
                  <Col className="eq-el-dateHeading">
                    <h5 className="eq-el-date">
                    {new Date().toLocaleDateString(t("dateLang"),options as any)}
                    </h5>
                  </Col>
                </Row>
              </div>
            )
          )}

          {eventData?.tomorrow?.length > 0 ? (
            <>
              <div className="eq-el-container">
                <Row>
                  <Col flex="auto">
                    <h4 className="eq-el-heading">{t("tomorrow")}</h4>
                  </Col>
                  <Col className="eq-el-dateHeading">
                    <h5 className="eq-el-date">
                    {addDays(new Date(), 1).toLocaleDateString(t("dateLang"), options)}
                    </h5>
                  </Col>
                </Row>


                {eventData.tomorrow
                .sort((a: any, b: any) => a.startDate - b.startDate) // Sort by startTime
                .map((item: any, index: number) => (
                  <EqSchoolTrainingCollapse
                    key={index}
                    training={item}
                    trainersOptions={
                      isTrainer
                        ? GetSchoolTrainers(item.organizers)
                        : trainersOptions
                    }
                    productOptions={
                      isTrainer
                      ? GetSchoolProductOption(item)
                      : productOptions
                      }
                    products={products}
                    eventTypes={eventTypes}
                    areas={areas}
                    weekDays={weekDays}
                    population={population}
                    groupPopulation={groupPopulation}
                    schoolStudents={
                      isTrainer
                        ? GetSchoolStudents(item.organizers)
                        : schoolStudents
                    }
                    studentHorsesList={
                      isTrainer
                        ? GetSchoolStudentHorseList(item.organizers)
                        : studentHorsesList
                    }
                    removeEventFunc={removeEventFunc}
                    editEventFunc={editEventFunc}
                    removeAttendeeFunc={removeAttendeeFunc}
                    onCreateOrUpdateSchoolTraining={
                      onCreateOrUpdateSchoolTraining
                    }
                  ></EqSchoolTrainingCollapse>
                ))}
              </div>
            </>
          ) : (
            location.pathname !== "/school/home" && (
              <div className="eq-el-container">
                <Row>
                  <Col flex="auto">
                    <h4 className="eq-el-heading">{t("tomorrow")}</h4>
                  </Col>
                  <Col className="eq-el-dateHeading">
                    <h5 className="eq-el-date">
                    {addDays(new Date(), 1).toLocaleDateString(t("dateLang"), options)}
                    </h5>
                  </Col>
                </Row>
              </div>
            )
          )}

          {eventData?.later?.length > 0 ? (
            <>
              <div className="eq-el-container">
                <h4 className="eq-el-heading">{t("later")}</h4>

                {eventData.later
                .sort((a: any, b: any) => a.startDate - b.startDate) // Sort by startTime
                  .map((item: any, index: number) => (
                  <EqSchoolTrainingCollapse
                    key={index}
                    training={item}
                    trainersOptions={
                      isTrainer
                        ? GetSchoolTrainers(item.organizers)
                        : trainersOptions
                    }
                    productOptions={
                      isTrainer
                      ? GetSchoolProductOption(item)
                      : productOptions
                      }
                    products={products}
                    eventTypes={eventTypes}
                    areas={areas}
                    weekDays={weekDays}
                    population={population}
                    groupPopulation={groupPopulation}
                    schoolStudents={
                      isTrainer
                        ? GetSchoolStudents(item.organizers)
                        : schoolStudents
                    }
                    studentHorsesList={
                      isTrainer
                        ? GetSchoolStudentHorseList(item.organizers)
                        : studentHorsesList
                    }
                    removeEventFunc={removeEventFunc}
                    editEventFunc={editEventFunc}
                    removeAttendeeFunc={removeAttendeeFunc}
                    onCreateOrUpdateSchoolTraining={
                      onCreateOrUpdateSchoolTraining
                    }
                  ></EqSchoolTrainingCollapse>
                ))}
              </div>
            </>
          ):(

            location.pathname !== "/school/home" && (
              <div className="eq-el-container">
                <Row>
                  <Col flex="auto">
                  <h4 className="eq-el-heading">{t("later")}</h4>
                  </Col>
                
                </Row>
              </div>)
          )}
        </>
      ) : (
        <div className="eq-el-container">
          <Empty description={t("noTrainingsAvailable")} />
        </div>
      )}
    </>
  );
};
export default SchoolTrainingList;
