
//according to documentation
export enum EqProfessionType {
    None = 0,
    Administrator,
    Trainer,
    Student,
    Veterinarian,
    Farrier,
    Therapist,
    EventHolder,
    RidingSchool,
    Stable,
    Parent
}

export enum eqHorseActivityStatusEnum {
    planned = 1,
    completed = 2
}
export enum HorseTaskStatusEnum
{
    Planned,
    Completed
}
export enum eqLoadingState {
    initial = 1,
    loading = 2,
    loadingCompleted = 3
}
export enum eqNotificationTypes {
    error = 1,
    success,
    info
}

export enum Professions {
    none = 0,
    administrator,
    trainer,
    student,
    parent = 10,
    school = 8
}

export enum genderType {
    Stallion = 1,
    Gelding = 2,
    Mare = 3
}

export enum RegisterSteps {
    none = 0,
    one,
    two,
    three
}

export enum EmailFilter {
    date = 1,
    day = 2,
    read = 3,
    unread = 4,
    flag = 5
}

export enum paymentStatusType {
    UnpaidButAttending = 2,
    Paid = 3,
    TrainerCancelled = 4,
    StudentCancelled = 5
}
export enum paymentStatus {
    Paid = "Paid and Attending",
    Unpaid = "Unpaid",
    UnpaidButAttending = "Unpaid but Attending",
    TrainerCancelled = "Paid but Cancelled",
    StudentCancelled = "Paid but Cancelled"
}


export enum EqPaymentStatus
{
    Paid = 1,
    Unpaid,
    UnpaidButAttending,
    PaidButCancelled,
    Refunded
}

export enum Countries {
    NO = "Norway",
    SE = "Sweden",
    DK = "Denmark",
    DE = "Germany",
    US = "United States"
}

export enum EqCurrency {
    NOK, // Norwegian Krone
    SEK, // Swedish Krona
    // USD, // United States Dollar
    
    EUR=3, // Euro 
}

export enum EqJoinPermissions {
    AllowLessonJoin = 1, // Students can join individual lessons freely.
    AllowLessonJoinAfterCourseStart = 2, // Students can join individual lessons after the course starts.
    DontAllowLessonJoin = 3, // Individual lesson joins are entirely disabled.
}

export enum CourseOrderType
{
    FullCourse, // for full course
    LessonOnly // for only Lesson

}
export enum BusinessType {
    individual = "individual",
    company = "company"
}

//first 3 are in private access
//all are in public access except "Open"
export enum EqDisciplineType {
    Dressage = 1,
    Jumping = 2,
    Open = 3,
    Western = 4,
    Eventing = 5,
    MountedGames = 6,
    IslandicHorses = 7,
    Racehorse = 8,
    RidingCourse = 9          // change riding course from product
}

export enum EqEventType {
    Dressage = 1,
    Jumping,
    Western,
    Eventing,
    MountedGames,
    IslandicHorses,
    Racehorse,
    RidingSchool,
    PayAndJump,
    Horsemanship,
    Clinic
}


export enum EqHorseTrainingType {
    Dressage = 1,
    Jumping = 2,
    Lunging = 3,
    Hacking = 4,
    PoleWork = 5,
    Other = 6
}

// for private access 
export enum EqTrainingType {
    PrivateLesson = 1,              //Dressage
    GroupLesson = 2,                //Jumping
    AvailableTimeSlots = 3,         //Open
    RidingCourse = 9                 // change riding course from product

}
export enum EqTaskTypes {
    Deworming = 1,
    Vet = 2,
    Shoes = 3,
    Vaccination = 4,
    Other = 5

}


export enum TrainingFilters {
    All = 1,
    Name = 2,
    Type = 3,
    Date = 4,
}
export enum StudentTrainingFilters {
    Available = 1,
    MyTrainings = 2,
    Previous = 3,
    unPaid = 4,
    // MyProducts = 5,

    // All = 1,
    // Available = 2,
    // Joined = 3,
}
export enum StudentProductFilters {
    Available = 1,
    UnPaid = 2,
    Paid = 3,
}

export enum TrainersFilters {
    AllTrainers = 1,
    MyTrainers = 2,
}
export enum SchoolFilters {
    AllSchools = 1,
    MySchools = 2,
}
export enum ProductType {
    Public = 1,
    Private = 2,
}


export enum ProductOrderStatus {
    Pending,
    Accepted,
    Rejected,
    Paid,
    Requested,
    Waiting
}

export enum EqOrderStatus
{
    Pending, //Invite
    Accepted,
    Rejected,
    Paid,
    Requested,
    Waiting,
    OwnerCancelled,
    AttendeeCancelled
}

export enum EventSlotStatus {
    Available = 1,
    Pending,
    Booked,
    TrainerCancelled,
    StudentCancelled
}
function getDisciplineTypeIndex(discipline: string): number {
    if (discipline === 'Dressage') {
        return 1;
    }
    else if (discipline === 'Jumping') {
        return 2;
    }
    else if (discipline === 'Open') {
        return 3;
    }
    else if (discipline === 'Western') {
        return 4;
    }
    else if (discipline === 'Eventing') {
        return 5;
    }
    else if (discipline === 'MountedGames') {
        return 6;
    }
    else if (discipline === 'IslandicHorses') {
        return 7;
    }
    else if (discipline === 'Racehorse') {
        return 8;
    }
    return -1;
}

export enum EqPaymentAccountStatus {
    Pending = 1,
    NotCompleted,
    Complete
}



export enum EqGroupType {
    House = 1,
    Stable = 2,
    People = 3,
    Event = 4,
    Horse = 5
}

export enum EqMessageAction {
    reply = 1,
    flag = 2,
    delete = 3
}

export enum EqMessageType {
    Message = 1,
    Invitation = 2,
    Booking = 3,
    Payment = 4,
    Notification = 5,
    Group = 6,

}

export enum EqPaymentSourceType {
    Card = 1,
    Cash = 2
}

export enum EqEventRepeatType {
    //Daily = 1,
    Weekly = 2,
    Fortnightly = 3,
    //Monthly = 4,
    //Quarterly = 5,
    //Biannually = 6,
    //Annually = 7
}

export enum EqUserType {
    Student = 1,
    Group = 2
}

export enum EqLocationType {
    Home = 1,
    Work
}

export enum EqTrainingLevels {
    Pony = 1,
    YoungHorse = 2,
    Horse = 3
    // Beginner = 1,
    // Advance = 2,
    // Professional = 3
}

export enum EqEventAccessType {
    Private = 1,
    Public = 2,
    //Personal = 3
}

export enum EqEventHorseAvailabilityStatus {
    All = 1,
    With = 2,
    Without = 3
}

export enum EqSlotStatusEnum {
    Available = 1,
    Pending = 2,
    Booked = 3,
    TrainerCancelled = 4,
    StudentCancelled = 5,
    Settled = 6
}
export enum EqEventAttendeeStatus {
    Accept = 1,
    Reject,
    Pending,
    Requested,
    RequestAccepted,
    Waiting,
    Cancelled

}


export enum EqWeekDay {
    Sunday = 1,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Satureday
}

export enum EqEventUserType {
    Owner = 1,
    Attendee = 2,
    Trainer = 3
}

export enum EqGroupUserType {
    Owner = 1,
    Attendee = 2
}


export enum QuestionType {
    Text,
    MultipleChoice,
}

// FOR SUBSCRIPTION
// PRODUCT

export enum EqSubscriptionRoleType {
    Student,
    EventHolder,
    School,
    Trainer,
    // Admin
}
export enum SubscriptionStatusEnum {
    Active,
    Finished,
    Changed,
    Pending,
}
export enum SubscriptionTypeEnum {
    Default = 'Default',
    Custom = 'Custom',
    Free = 'Free',
    ///// <summary>
    ///// This is not a subscription in stripe. As students don't pay anything for it.
    ///// </summary>
    //StudentFree,
    //StudentBasic,
    ///// <summary>
    ///// This is not a subscription in stripe. Event holder doesn't pay any monthly subscription to hold events.
    ///// </summary>
    //EventHolderBasic,
    //SchoolBasic,
    ///// <summary>
    ///// KR 150 for trainer with students under 20.
    ///// </summary>
    //TrainerBasic,
    ///// <summary>
    ///// KR 250 for trainer with students under 30 and above 20.
    ///// </summary>
    //TrainerPlus,
    ///// <summary>
    ///// KR 350 for trainer with students above 30.
    ///// </summary>
    //TrainerPro,
    ///// <summary>
    ///// Customized for trainer.
    ///// </summary>
    //TrainerCustom
}
export enum EqIntegrationsType {
    None,
    TripleTex,
    PowerOfficeGo,
}

// 
export enum EqRefundType {
    NoRefund,
    Refund,
    Credit
  };



  export enum WeekDaysEnum {
    Sunday= "Sunday",
    Monday= "Monday",
    Tuesday= "Tuesday",
    Wednesday= "Wednesday",
    Thursday= "Thursday",
    Friday= "Friday",
    Saturday= "Saturday"
  };

  export const WeekDaysEnums = {
    Sunday: "Sunday",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday"
};