import * as React from "react";
import "./EqCourseInviteSection.scss";
import Api from "../../shared/Api";
import { ApiEndpoints, openNotificationWithIcon } from "../../shared/Common";
import EqCourseInvite from "./EqCourseInvite/EqCourseInvite";
import { useTranslation } from "react-i18next";
import { CourseOrderType, eqNotificationTypes } from "../../shared/enums";
import { CourseLessonOrderEnt } from "../../shared/general/IStudentUnpaidOrders";

export interface IEqTrainerInvitationBoard {
  onloadActivity?: any;
 }
const EqCourseInviteSection = ({onloadActivity}:IEqTrainerInvitationBoard) => {
  const [messageString, setMessageString] = React.useState("");
  const [coursePendingInvites, setCoursePendingInvites] = React.useState(
    [] as any
  );
     const { t } = useTranslation();
 

     const handleApiError = (error: any, defaultMessage: string) => {
      const errorMessage = error?.response?.data || defaultMessage;
      openNotificationWithIcon(t("error"), errorMessage, eqNotificationTypes.error);
      console.error(errorMessage);
    };
  


    const GetCourseOrderCheckoutSession = async (orderId: string) => {
      try {
        const path = `${ApiEndpoints.CoursePayment.GetCourseOrderCheckoutSession}/${orderId}`;
        const response = await Api.get(path);
  
        if (response?.status === 200) {
          window.location.href = response.headers.location;
        }
      } catch (error: any) {
         if (error.response.status === 409) {
               openNotificationWithIcon("Error!", error.response.data, eqNotificationTypes.error);
          } else {
        handleApiError(error, t("generalServerError"));
        }
      }
    };


    const GetCourseLessonOrderCheckoutSession = async (orderId: any) => {
      try {
          let path = `${ApiEndpoints.CoursePayment.GetCourseLessonOrderCheckoutSession}/${orderId}`;
          // Replace with your API endpoint and method
          const response = await Api.get(path);
          if (response && response.status === 200) {
              window.location.href = response.headers.location;
          }
          // Optionally handle the result
      } catch (error: any) {
          if (error.response.status === 409) {
              // already exist
              openNotificationWithIcon("Error!", error.response.data, eqNotificationTypes.error);
            
          } else {
            handleApiError(error, t("generalServerError"));

          }
      }
  };

    const AcceptInvite = async (orderInvite: CourseLessonOrderEnt) => {
      try {
        const response = await Api.put(`${ApiEndpoints.CourseOrderInvite.Accept}/${orderInvite.id}`);
        if (response.status === 200) {
          setCoursePendingInvites((prev:any) => prev.filter((invite:any) => invite.id !== orderInvite.id));
         if(orderInvite.orderType === CourseOrderType.FullCourse ){
           await GetCourseOrderCheckoutSession(orderInvite.id);
         }
         else{
          await GetCourseLessonOrderCheckoutSession(orderInvite.id);

         }
        }
      } catch (error: any) {
        handleApiError(error, t("acceptInviteError"));
      }
    };  const RejectInvite = async (orderInvite: any) => {
      try {
        const response = await Api.put(`${ApiEndpoints.CourseOrderInvite.Reject}/${orderInvite.id}`);
        if (response.status === 200) {
          setCoursePendingInvites((prev:any) => prev.filter((invite:any) => invite.id !== orderInvite.id));
        }
      } catch (error: any) {
        handleApiError(error, t("rejectInviteError"));
      }
    };




    const GetAllPendingStudentCourseOrderInvites = async () => {
      try {
        const response = await Api.get(ApiEndpoints.CourseOrderInvite.GetAll);
        if (response?.status === 200) {
          const data: any = response.data || [];
          setCoursePendingInvites(data);
          onloadActivity?.(data.length > 0);
        }
      } catch (error: any) {
        handleApiError(error, t("fetchInvitesError"));
      }
    };
  // const GetAllPendingStudentCourseOrderInvites = () => {
  //   setMessageString("");
  //   Api.get(ApiEndpoints.CourseOrderInvite.GetAll).then(
  //     (response) => {
  //       if (response && response.status === 200) {
  //         // var pendingInvites = [] as any;
  //         var data = response?.data ? response?.data : ([] as any);
  //         setCoursePendingInvites(data);
  //         if(data.length)
  //         {
  //             onloadActivity(true);
  //         }
  //         else{
  //             onloadActivity(false);

  //         }
       
  //         console.log(data);
  //       } else {
  //         setMessageString("Not found");
  //       }
  //     },
  //     () => {
  //       setMessageString("Not found");
  //     }
  //   );
  // };



  React.useEffect(() => {
    
    GetAllPendingStudentCourseOrderInvites();

  }, []);
  return (
    <>
      <div className="eq-trainerInvitationBoard-container">
 

        
          {coursePendingInvites && coursePendingInvites.length > 0
            ? [...coursePendingInvites].map((item: any, index: number) => (
                <EqCourseInvite 
                acceptClicked={() => AcceptInvite(item)}
                rejectClicked={() => RejectInvite(item)}
                key={index} orderInvite={item}></EqCourseInvite>
              ))
            : ""}
        
      </div>
    </>
  );
};
export default EqCourseInviteSection;
