import React, { useEffect, useState } from "react";
import Api from "../../components/shared/Api";
import { ApiEndpoints } from "../../components/shared/Common";
import { Col, Empty, Row, Spin, Typography } from "antd-v5";
import EqPublicEventCard from "../eqPublicEventCard/EqPublicEventCard";
import { useTranslation } from "react-i18next";
import "./EqPublicEventList.scss"; // Import SASS file
import { ISelectProps } from "../../components/shared/Interfaces";
import EqSelect from "../../components/custom/eqSelect/EqSelect";
import { EqEventType } from "../../components/shared/enums";
import { useNavigate } from "react-router-dom";

const EqPublicEventList = () => {
    const [events, setEvents] = useState<any[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<any[]>([]);

    const [locations, setLocations] = useState<any[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);

    const [loading, setLoading] = useState(true);
    const [selectedEventTypes, setSelectedEventTypes] = useState<number[]>([]);
    const [sortBy, setSortBy] = useState<number | undefined>(undefined);
    const { t } = useTranslation();
    const navigate = useNavigate(); // Initialize useNavigate
    useEffect(() => {
        fetchPublicEvents();
        fetchLocations();
    }, []);

    useEffect(() => {
        applyFilters();
    }, [selectedEventTypes, selectedLocations, sortBy, events]);

    const fetchPublicEvents = async () => {
        try {
            const response = await Api.get(`${ApiEndpoints.EqPublicEvent.GetAll}`);
            
            let data = response.data.filter((ev: any) => !ev.isDraft);
            setEvents(data);
            setFilteredEvents(data); // Initially, show all events
            setLoading(false);
        } catch (error) {
            console.error("eventList", error);
            setLoading(false);
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await Api.get(`${ApiEndpoints.Location.GetLocationOptions}`);
            setLocations(response.data);
        } catch (error) {
            console.error("locations", error);
            setLoading(false);
        }
    };

    const applyFilters = () => {
        let filtered = [...events];

        // Filter by event type
        if (selectedEventTypes.length > 0) {
            filtered = filtered.filter((event) =>
                selectedEventTypes.includes(event.disciplineType)
            );
        }

        // Filter by location
        if (selectedLocations.length > 0) {
            filtered = filtered.filter((event) => selectedLocations.includes(event.locationId));
        }

        // Sort events
        if (sortBy === 0) {
            filtered.sort((a, b) => a.startDate - b.startDate); // Upcoming
        } else if (sortBy === 1) {
            filtered.sort((a, b) => b.createdDate - a.createdDate); // New
        }

        setFilteredEvents(filtered);
    };

    const handleEventTypeChange = (value: number[]) => {
        setSelectedEventTypes(value);
    };

    const sortOptions = [
        { key: 0, value: "Upcoming", label: "Upcoming" },
        { key: 1, value: "New", label: "New" },
    ];

    const sortSelectProps: ISelectProps = {
        classNames: "extra-height",
        placeholder: `${t("select")}`,
        allowClear: true,
        options: sortOptions,
        defaultSelectedValue: sortBy,
        changeFunc: (value: any) => {
            const intVal = value !== undefined ? parseInt(value, 10) : undefined;
            setSortBy(intVal);
        },
    };

    const typeSelectProps: ISelectProps = {
        mode: "multiple",
        showSearch: true,
        optionFilterProp: "label",
        filterOption: true,
        isTag: true,
        allowClear: true,
        classNames: "eq-multiSelect eq-searchSelect extra-height",
        ddClassName: "eq-select-dropdown",
        placeholder: `${t("selectType")}`,
        options: Object.entries(EqEventType)
            .filter((e) => !isNaN(e[0] as any))
            .map((e) => ({
                key: parseInt(e[0]),
                value: `${t(e[1] as any)}`,
                label: `${t(e[1] as any)}`,
            })),
        defaultSelectedValue: selectedEventTypes,
        changeFunc: (stringArray: any) => {
            const intArray = stringArray.map((str: any) => parseInt(str, 10));
            setSelectedEventTypes(intArray);
        },
    };

    const locationSelectProps: ISelectProps = {
        mode: "multiple",
        showSearch: true,
        optionFilterProp: "value",
        filterOption: true,
        allowClear: true,
        classNames: "eq-multiSelect eq-searchSelect extra-height",
        ddClassName: "eq-select-dropdown",
        placeholder: `${t("location")}`,
        options: locations,
        defaultSelectedValue: selectedLocations,
        changeFunc: (stringArray: any) => {
            setSelectedLocations(stringArray);
        },
    };

    const handleCardClick = (eventId: any) => {
        navigate(`/publicEventSingle?id=${eventId}`); // Navigate with query string
    };
    return (
        <div className="eq-public-event-list">
            {/* Top Row with Title and Filter */}
            <Row justify="space-between" align="middle" className="top-row">
                <Col>
                    <Typography.Title level={2} className="title">
                        {t("publicEvents")}
                    </Typography.Title>
                </Col>
                <Col span={18}>
                    <Row gutter={[16, 0]} align="middle">
                        <Col span={8}>
                            <Row align={"middle"}>
                                <span style={{ paddingRight: "8px" }}>{t("sortBy")}</span>
                                <EqSelect {...sortSelectProps} />
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row align={"middle"}>
                                <span style={{ paddingRight: "8px" }}>{t("filterBy")}</span>
                                <EqSelect {...locationSelectProps} />
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row align={"middle"}>
                                <span style={{ paddingRight: "8px" }}>{t("filterBy")}</span>
                                <EqSelect {...typeSelectProps} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* keep this for test  */}
            {/* <Col>
                <span>{t("Filter by ")}</span>
                    <Select
                        mode="multiple"
                        // menuItemSelectedIcon={"ss"}
                        // direction="rtl"
                        showArrow
                        allowClear
                        suffixIcon={"icon"}
                        optionFilterProp={"label"}
                        className="filter-select"
                        placeholder={t("selectType")}
                        onChange={handleEventTypeChange}
                        options={Object.entries(EqEventType)
                            .filter((e) => !isNaN(e[0] as any))
                            .map((e) => ({ key: parseInt(e[0]), value: parseInt(e[0]),  label:`${t(e[1] as any)}` }))}
                    >
                       
                    </Select>
                </Col> */}
            {/* Event Card List */}
            {loading ? (
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <Spin size="large" />
                </div>
            ) : filteredEvents.length > 0 ? (
                <Row gutter={[0, 16]} className="event-card-list">
                    {filteredEvents.map((eventData: any) => (
                        <Col xs={24} sm={12} xl={8} xxl={6} key={eventData.id}>
                            <EqPublicEventCard
                                eventData={eventData}
                                onCardClick={() => handleCardClick(eventData.id)}
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <Empty description={<p>{t("noEventsAvailable")}</p>} />
            )}
        </div>
    );
};

export default EqPublicEventList;
