import React, { useEffect, useState } from "react";
import { Button, Select, Typography, Row, Col, Divider } from "antd-v5";

import "./EqCreateOrEditEvent.scss"; // Make sure to include your custom styles
import { useTranslation } from "react-i18next";
import EqButton from "../../../components/custom/eqButton/EqButton";
import { EqInput } from "../../../components/custom/eqInput/EqInput";
import EqSelect from "../../../components/custom/eqSelect/EqSelect";
import { ISelectProps } from "../../../components/shared/Interfaces";
import { EqCurrency, EqEventType, eqNotificationTypes, QuestionType } from "../../../components/shared/enums";
import EqDatePicker, { IDatePickerOptions } from "../../../components/custom/eqDatePicker/EqDatePicker";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CloseCircleFilled, CloseOutlined, PlusOutlined } from "@ant-design/icons";

import EqEventAlternative from "../eqEventAlternative/EqEventAlternative";
import Api from "../../../components/shared/Api";
import { FrontEndPoints, openNotificationWithIcon } from "../../../components/shared/Common";
import config from "../../../components/shared/Config";
import EqTags from "../../../components/custom/eqTags/EqTags";
import { message, Modal, Space, Switch, Tabs, Tooltip } from "antd-v5";
import { useLocation, useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import EqPublicEventPdfGenerate from "../EventPdfGenerate/EqPublicEventPdfGenerate";
import TabPane from "antd-v5/es/tabs/TabPane";
import EqPublicEventPdfGenerateListView from "../PublicEventPdfGenerateListView/EqPublicEventPdfGenerateListView";
import EqImageSelector from "../../eqImageSelector/EqImageSelector";
import EqUploadTerms from "../../eqUploadTerms/EqUploadTerms";

const { Option } = Select;
const { Title } = Typography;

interface EqImageInfoDto {
    id: string;
    key: number;
    url: string;
    uploadedDate: number;
    imageId: string;
    eqUser: any | null;
}

interface EqCreateOrEditEventProps {
    eventData?: any; // Event data to prefill when editing
    imagesData: EqImageInfoDto[];
    creatorUser?: any;
    stripeAccountInfo?: any;
    areas: any[];
    onCreateEvent?: (data: any) => void;
    onEditEvent?: (data: any) => void;
    onCloseEvent: () => void;
    onImageUploadSuccess: (newImage: any) => void;
    onImageDeleteSuccess: (imageId: any) => void;
    onRemoveClassOrder?: (orderId: any, refund: boolean, refundAmount?: number) => void;
}

const EqCreateOrEditEvent: React.FC<EqCreateOrEditEventProps> = ({
    eventData,
    imagesData,
    creatorUser,
    stripeAccountInfo,
    areas,
    onCreateEvent,
    onEditEvent,
    onCloseEvent,
    onImageUploadSuccess,
    onImageDeleteSuccess,
    onRemoveClassOrder,
}) => {
    const { t } = useTranslation();
    // const [formData, setFormData] = React.useState({}) as any;

    const [formData, setFormData] = useState({
        id: eventData?.id || "",
        title: eventData?.title || "",
        image: eventData?.image || null,
        disciplineTypes: eventData?.disciplineTypes || [],
        locationId: eventData?.locationId || "",
        startDate: eventData?.startDate || null,
        endDate: eventData?.endDate || null,
        registrationDeadline: eventData?.registrationDeadline || null, 
        currency: eventData?.currency || EqCurrency.NOK,
        phoneNumber: eventData?.phoneNumber || "",
        email: eventData?.email || "",
        description: eventData?.description || "",
        eventTermsFile: eventData?.eventTermsFile || null,
        isDraft: eventData?.isDraft || false,
        useHouseTerms: eventData?.useHouseTerms || false,
        houseTerms: eventData?.houseTerms || "",
    }) as any;
    const [fileObj, setFileObj] = React.useState<any | undefined>(undefined);
    const [isEditMode, setIsEditMode] = useState(false); // Track whether we are in edit mode
    const [isEditDraft, setIsEditDraft] = useState(false); // Track whether we are in edit mode
    const [canSaveDraft, setCanSaveDraft] = useState(true); // Track whether we are in edit mode
    const location = useLocation();
    const navigate = useNavigate();
    const [wordCount, setWordCount] = useState(0);
    const [termsWordCount, setTermsWordCount] = useState(0);
    const wordLimit = 1000;
    // State to manage multiple Alternatives
    const [alternatives, setAlternatives] = useState([
        {
            isMultipleClassActive: true,
            customClassName: "",
            classList: [],
            questions: [],
        },
    ]);
    const [copyMessage, contextCopyMessage] = message.useMessage();
    const [pdfVisible, setPdfVisible] = React.useState(false);
    const [pdfListViewVisible, setPdfListViewVisible] = React.useState(false);

    const [activeTabKey, setActiveTabKey] = useState("1"); // Default to the first tab you want

    const handleTabChange = (key:any) => {
        setActiveTabKey(""); // Clear the active key briefly to force a re-render
        setTimeout(() => {
            setActiveTabKey(key); // Re-set the active tab
        }, 0);
    };
    useEffect(() => {
        
        if (eventData && eventData.id !== undefined) {
            setIsEditMode(true); // Set mode to edit
            setIsEditDraft(eventData.isDraft); // Set mode to edit Draft

            setFormData(eventData); // Pre-fill the form data
            setAlternatives(eventData.alternatives || []); // Pre-fill alternatives if available
            if (eventData.eventTermsFile) {
                setFileObj({
                    name: t("eventTerms"),
                    status: "done",
                    url: config.apiUrl + eventData.eventTermsFile,
                });
            }
        } else if (eventData) {
            setIsEditMode(false); // Set mode to edit
            setIsEditDraft(false); // Set mode to edit Draft
            setCanSaveDraft(false);
            setFormData(eventData); // Pre-fill the form data
            setAlternatives(eventData.alternatives || []); // Pre-fill alternatives if available
            if (eventData.eventTermsFile) {
                setFileObj({
                    name: t("eventTerms"),
                    status: "done",
                    url: config.apiUrl + eventData.eventTermsFile,
                });
            }
        } else {
            setIsEditMode(false); // Set mode to create
            setIsEditDraft(false);
            setFormData({}); // Clear form data for create mode
        }
    }, [eventData]);

    const validateData = (data: any): boolean => {
        let isComplete = true;
        const msg = t("incompleteData");
        const desc = t("isEmpty");

        if (data.title === undefined || data.title === "") {
            openNotificationWithIcon(msg, t("title") + desc);
            isComplete = false;
        } else if (!data.image) {
            openNotificationWithIcon(msg, t("image") + desc);
            isComplete = false;
        } else if (data.disciplineTypes === "" || data.disciplineTypes === undefined) {
            openNotificationWithIcon(msg, t("disciplineTypes") + desc);
            isComplete = false;
        } else if (data.locationId === "" || data.locationId === undefined) {
            openNotificationWithIcon(msg, t("location") + desc);
            isComplete = false;
        } else if (!data.startDate) {
            openNotificationWithIcon(msg, t("startDate") + desc);
            isComplete = false;
        } else if (!data.endDate) {
            openNotificationWithIcon(msg, t("endDate") + desc);
            isComplete = false;
        } else if (!data.registrationDeadline) {
            openNotificationWithIcon(msg, t("regDeadline") + desc);
            isComplete = false;
        } else if (data.currency === "" || data.currency === undefined) {
            openNotificationWithIcon(msg, t("currency") + desc);
            isComplete = false;
        }
        // else if (data.phoneNumber === "") {
        //   openNotificationWithIcon(msg, "Phone Number" + desc);
        //   isComplete = false;
        // }
        // else if (data.email === "" || !/\S+@\S+\.\S+/.test(data.email)) {
        //   openNotificationWithIcon(msg, "Valid Email" + desc);
        //   isComplete = false;
        // }
        //  else if (data.description === "") {
        //   openNotificationWithIcon(msg, "Description" + desc);
        //   isComplete = false;
        // }
        //  else if (data.eventTermsFile === "") {
        //   openNotificationWithIcon(msg, "Event Terms File" + desc);
        //   isComplete = false;
        // }
        else if (alternatives && alternatives.length > 0) {
            alternatives.forEach((alt: any, altIdx: number) => {
                // if (alt.customClassName === "") {
                //   openNotificationWithIcon(
                //     msg,
                //     `Class Name at alternative ${altIdx}` + desc
                //   );
                //   isComplete = false;
                // }
                alt.classList.forEach((cls: any, clsIdx: number) => {
                    if (cls.title === "") {
                        openNotificationWithIcon(
                            msg,
                            `${t("altClassTitle")} ${altIdx} ${t("class")} ${clsIdx}` + desc
                        );
                        isComplete = false;
                    }
                    //else if (cls.description === "") {
                    //    openNotificationWithIcon(
                    //        msg,
                    //        `${t("altClassDescription")}  ${altIdx} ${t("class")} ${clsIdx}` + desc
                    //    );
                    //    isComplete = false;
                    //}
                    else if (cls.capacity === "" || isNaN(cls.capacity)) {
                        openNotificationWithIcon(
                            msg,
                            `${t("altClassCapacity")} ${altIdx} ${t("class")} ${clsIdx}` + desc
                        );
                        isComplete = false;
                    } else if (cls.price === "" || isNaN(cls.price)) {
                        openNotificationWithIcon(
                            msg,
                            `${t("altClassPrice")} ${altIdx} ${t("class")} ${clsIdx}` + desc
                        );
                        isComplete = false;
                    }
                });
                alt.questions.forEach((q: any, qIdx: number) => {
                    if (q.questionText === "") {
                        openNotificationWithIcon(
                            msg,
                            `${t("altClassQuestion")} ${altIdx} ${t("question")} ${qIdx}` + desc
                        );
                        isComplete = false;
                    }
                });
            });
        }

        return isComplete;
    };

    const handleOnCreateEvent = async (isDraft: boolean) => {
        // console.log(formData);
        
        const isValid = validateData(formData);
        if (!isValid) {
            return; // Stop execution if validation fails
        }
        // Prepare the data to be sent to the API
        const eventData = {
            ...formData,
            isDraft,
            title: formData.title,
            imageId: formData.image.id,
            disciplineTypes: formData.disciplineTypes,
            locationId: formData.locationId,
            startDate: formData.startDate?.valueOf(),
            endDate: formData.endDate?.valueOf(),
            registrationDeadline: formData.registrationDeadline?.valueOf(),
            currency: formData.currency,
            phoneNumber: formData.phoneNumber,
            email: formData.email,
            description: formData.description,
            eventTermsFile: formData.eventTermsFile,
            alternatives: alternatives,

            // alternatives: alternatives.map((alt: any) => ({
            //   customClassName: alt.customClassName,
            //   classList: alt.classList.map((cls: any) => ({
            //     title: cls.title,
            //     description: cls.description,
            //     capacity: cls.capacity,
            //     price: cls.price,
            //   })),
            //   questions: alt.questions.map((q: any) => ({
            //     questionText: q.questionText,
            //     questionType: q.questionType,
            //     options: q.options,
            //     isMultiOptionAllowed: q.isMultiOptionAllowed,
            //   })),
            // })),
        };

        console.log(eventData);
        onCreateEvent && onCreateEvent(eventData);
    };

    const handleOnUpdateEvent = async (isDraft: boolean) => {
        console.log(formData);

        // Prepare the data to be sent to the API
        const eventData = {
            ...formData,
            isDraft: isDraft,
            imageId: formData.image.id,
            startDate: formData.startDate?.valueOf(),
            endDate: formData.endDate?.valueOf(),
            registrationDeadline: formData.registrationDeadline?.valueOf(),
            alternatives: alternatives,
        };

        console.log(eventData);
        setIsEditDraft(isDraft);
        onEditEvent && onEditEvent(eventData);
    };

    const handleInputChange = (
        alternativeIndex: number,
        classIndex: number,
        field: string,
        value: string
    ) => {
        const updatedAlternatives = [...alternatives] as any;
        updatedAlternatives[alternativeIndex].classList[classIndex][field] = value;
        setAlternatives(updatedAlternatives);
    };

    const handleRemoveRow = (alternativeIndex: number, classIndex: number) => {
        const updatedAlternatives = [...alternatives];
        updatedAlternatives[alternativeIndex].classList = updatedAlternatives[
            alternativeIndex
        ].classList.filter((_, i) => i !== classIndex);
        setAlternatives(updatedAlternatives);
    };

    const handleAddRow = (alternativeIndex: number) => {
        const updatedAlternatives = [...alternatives] as any;

        // if already class exist
        const classList = updatedAlternatives[alternativeIndex].classList;
        if (classList.length > 0) {
            const lastClass = classList[classList.length - 1];

            updatedAlternatives[alternativeIndex].classList.push({
                title: "",
                description: "",
                capacity: lastClass.capacity, // Copy last class's capacity
                price: lastClass.price, // Copy last class's price
                vat: lastClass.vat, // Copy last class's vat
            });
        } else {
            updatedAlternatives[alternativeIndex].classList.push({
                title: "",
                description: "",
                capacity: "",
                price: "",
                vat: "",
            });
        }

        setAlternatives(updatedAlternatives);
    };

    const addQuestion = (alternativeIndex: number) => {
        const updatedAlternatives = [...alternatives] as any;
        updatedAlternatives[alternativeIndex].questions.push({
            questionText: "",
            questionType: QuestionType.Text,
            options: [],
            isMultiOptionAllowed: false,
        });
        setAlternatives(updatedAlternatives);
    };

    const toggleQuestionType = (alternativeIndex: number, questionIndex: number) => {
        const updatedAlternatives = [...alternatives] as any;
        const question = updatedAlternatives[alternativeIndex].questions[questionIndex];
        question.questionType =
            question.questionType === QuestionType.Text
                ? QuestionType.MultipleChoice
                : QuestionType.Text;
        setAlternatives(updatedAlternatives);
    };

    const updateIsMultiOptionAllowed = (
        alternativeIndex: number,
        questionIndex: number,
        value: boolean
    ) => {
        const updatedAlternatives = [...alternatives] as any;
        updatedAlternatives[alternativeIndex].questions[questionIndex].isMultiOptionAllowed = value;
        setAlternatives(updatedAlternatives);
    };

    const updateQuestionText = (alternativeIndex: number, questionIndex: number, value: string) => {
        const updatedAlternatives = [...alternatives] as any;
        updatedAlternatives[alternativeIndex].questions[questionIndex].questionText = value;
        setAlternatives(updatedAlternatives);
    };

    const addOption = (alternativeIndex: number, questionIndex: number) => {
        const updatedAlternatives = [...alternatives] as any;
        updatedAlternatives[alternativeIndex].questions[questionIndex].options.push({
            optionText: "",
        });
        setAlternatives(updatedAlternatives);
    };

    const updateOption = (
        alternativeIndex: number,
        questionIndex: number,
        optionIndex: number,
        value: string
    ) => {
        const updatedAlternatives = [...alternatives] as any;
        updatedAlternatives[alternativeIndex].questions[questionIndex].options[
            optionIndex
        ].optionText = value;

        setAlternatives(updatedAlternatives);
    };

    const removeOption = (alternativeIndex: number, questionIndex: number, optionIndex: number) => {
        const updatedAlternatives = [...alternatives] as any;
        // Remove the option at optionIndex from the question's options array
        updatedAlternatives[alternativeIndex].questions[questionIndex].options =
            updatedAlternatives[alternativeIndex].questions[questionIndex].options.filter(
                (_: any, i: number) => i !== optionIndex
            );
        setAlternatives(updatedAlternatives);
    };

    const removeQuestion = (alternativeIndex: number, questionIndex: number) => {
        const updatedAlternatives = [...alternatives] as any;
        updatedAlternatives[alternativeIndex].questions = updatedAlternatives[
            alternativeIndex
        ].questions.filter((_: any, i: any) => i !== questionIndex);
        setAlternatives(updatedAlternatives);
    };

    const removeAlternative = (alternativeIndex: number) => {
        let updatedAlternatives = [...alternatives] as any;
        updatedAlternatives = updatedAlternatives.filter(
            (_: any, i: any) => i !== alternativeIndex
        );

        setAlternatives(updatedAlternatives);
    };
    // Add new Alternative
    const addAlternative = () => {
        setAlternatives([
            ...alternatives,
            {
                isMultipleClassActive: false,
                customClassName: "",
                classList: [],
                questions: [],
            },
        ]);
    };

    // Image Library part

    const handleImageSelect = (image: EqImageInfoDto) => {
        // Handle the selected image
        console.log("Selected Image:", image);
        setFormData({ ...formData, image: image });
    };


    const typeSelectProps: ISelectProps = {
        label: `${t("type")} *`,
        mode: "multiple",
        showSearch: true,
        optionFilterProp: "label",
        filterOption: true,
        isTag: false,
        allowClear: true,
        classNames: "eq-multiSelect eq-searchSelect extra-height",
        ddClassName: "eq-select-dropdown",
        placeholder: `${t("selectType")}`,

        options: Object.entries(EqEventType)
            .filter((e) => !isNaN(e[0] as any))
            .map((e) => ({ 
                key: parseInt(e[0]), 
                value: `${t(e[1] as any)}`,
                label: `${t(e[1] as any)}`
            })),
        defaultSelectedValue: formData?.disciplineTypes || [],

        changeFunc: (stringArray: any) => {
            
            // let type: number = +value;
            // setFormData({ ...formData, disciplineTypes: type });
            const intArray = stringArray.map((str: any) => parseInt(str, 10));

            setFormData({ ...formData, disciplineTypes: stringArray });

        },
    };

    // const typeSelectProps: ISelectProps = {
    //     label: `${t("type")} *`,
    //     classNames: "extra-height",
    //     placeholder: `${t("selectType")}`,

    //     options: Object.entries(EqEventType)
    //         .filter((e) => !isNaN(e[0] as any))
    //         .map((e) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
    //     defaultSelectedValue: formData?.disciplineTypes,

    //     changeFunc: (value: string) => {
    //         let type: number = +value;
    //         setFormData({ ...formData, disciplineTypes: type });


    //     },
    // };

    const areaSelectProps: ISelectProps = {
        label: `${t("location")} *`,
        classNames: "extra-height",
        placeholder: `${t("selectLocation")}`,
        // isTag: false,

        // for Search
        showSearch: true,
        optionFilterProp: "label",
        filterOption: true,
        //--
        options: areas,

        ddClassName: "eq-select-dropdown",
        defaultSelectedValue: formData?.locationId ? formData.locationId + "" : null,

        changeFunc: (value: string) => {
            setFormData({ ...formData, locationId: value });
        },

        // Custom dropdown rendering with additional space and link
        dropdownRender: (menu: any) => (
            <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                    <Typography.Link
                        onClick={() =>
                            navigate(FrontEndPoints.trainer.editTrainer + "?tab=4", {
                                state: {
                                    prevPath: location.pathname,
                                },
                            })
                        }
                        style={{
                            whiteSpace: "nowrap",
                        }}
                    >
                        <PlusOutlined /> {t("createNew")}
                    </Typography.Link>
                </Space>
            </>
        ),
    };

    const startOnProps: IDatePickerOptions = {
        label: `${t("startDate")} *`,
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: formData?.startDate,
        placeHolder: t("startDate"),
        classNames: "eq-event",
        showTime: true,
        dateFormat: "YYYY-MM-DD HH:mm",
        onChangeFunc: (date: any) => {
            console.log(date);
            if (creatorUser) {
                if (creatorUser.allowLatePayments) {
                    setFormData({
                        ...formData,
                        startDate: moment(date),
                        endDate: moment(date),
                        registrationDeadline: moment(date).add(creatorUser.paymentDays, "days"),
                    });
                } else {
                    setFormData({
                        ...formData,
                        startDate: moment(date),
                        endDate: moment(date),
                        registrationDeadline: moment(date),
                    });
                }
            } else {
                setFormData({
                    ...formData,
                    startDate: moment(date),
                    endDate: moment(date),
                    registrationDeadline: moment(date),
                });
            }
        },
    };

    const endDateProps: IDatePickerOptions = {
        label: `${t("endDate")} *`,
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: formData?.endDate,
        placeHolder: t("endDate"),
        classNames: "eq-event",
        // showTime:true,
        // dateFormat:"YYYY-MM-DD HH:mm",
        onChangeFunc: (date: any) => {
            console.log(date);

            setFormData({
                ...formData,
                endDate: moment(date),
            });
        },
    };

    const regDeadlineProps: IDatePickerOptions = {
        label: `${t("regDeadline")}`,
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: formData?.registrationDeadline,
        placeHolder: t("regDeadline"),
        classNames: "eq-event",
        // showTime:true,
        // dateFormat:"YYYY-MM-DD HH:mm",
        onChangeFunc: (date: any) => {
            console.log(date);

            setFormData({
                ...formData,
                registrationDeadline: moment(date),
            });
        },
    };
    const currencySelectProps: ISelectProps = {
        label: `${t("currency")} *`,
        classNames: "extra-height",
        placeholder: `${t("selectCurrency")}`,
        options: Object.entries(EqCurrency)
            .filter((e) => !isNaN(e[0] as any))
            .map((e) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
        // options: currencyOptions,
        defaultSelectedValue: formData?.currency,

        changeFunc: (value: string) => {
            let type: number = +value;
            setFormData({ ...formData, currency: type });
        },
    };

    const countWords = (htmlString: any) => {
        // Strip HTML tags
        const text = htmlString.replace(/<[^>]*>/g, " ");

        // Split by spaces or newlines and count the words
        const words = text
            .trim()
            .split(/\s+/)
            .filter((word: any) => word.length > 0);
        return words.length;
    };
    const handleDescriptionChange = (value: string) => {
        const currentWordCount = countWords(value);

        if (currentWordCount <= wordLimit) {
            setFormData({ ...formData, description: value });
            setWordCount(currentWordCount);
        } else {
            // If limit is reached, do not allow new input but allow deletion
            const limitedText = formData.description; // Preserve the last valid state
            setFormData({ ...formData, description: limitedText });
        }
    };

    const handleHouseTermsChange = (value: string) => {
        const currentWordCount = countWords(value);

        if (currentWordCount <= wordLimit) {
            setFormData({ ...formData, houseTerms: value });
            setTermsWordCount(currentWordCount);
        } else {
            // If limit is reached, do not allow new input but allow deletion
            const limitedText = formData.houseTerms; // Preserve the last valid state
            setFormData({ ...formData, houseTerms: limitedText });
        }
    };

    const handleSwitchChange = (checked: boolean) => {
        if (checked) {
            // If checked, pre-fill the text with default data
            // const preFilledText = "Default pre-filled house terms text..."; // Add your default text here
            const preFilledText = t("houseTerms");

            setFormData({ ...formData, useHouseTerms: checked, houseTerms: preFilledText });
            setTermsWordCount(countWords(preFilledText)); // Update word count for pre-filled data
        } else {
            // If unchecked, clear the text
            setFormData({ ...formData, useHouseTerms: checked, houseTerms: "" });
            setTermsWordCount(0); // Reset word count
        }
    };

    const confirmSaveAsDraft = () => {
        Modal.confirm({
            title: t("draftConfirm"),
            footer: (
                <Row justify={"end"}>
                    {/* Discard Button */}
                    <EqButton
                        style={{ marginRight: "2px" }}
                        bordered
                        onClick={() => {
                            Modal.destroyAll(); // Close the modal on discard
                            onCloseEvent();
                            console.log("Discarded");
                        }}
                    >
                        {t("discard")}
                    </EqButton>

                    {/* Save as Draft Button */}
                    <EqButton
                        onClick={() => {
                            handleOnCreateEvent(true); // Call your save as draft function
                            Modal.destroyAll(); // Close the modal after saving
                        }}
                    >
                        {t("saveAsDraft")}
                    </EqButton>
                </Row>
            ),
            // Hide the default OK and Cancel buttons
            okButtonProps: { style: { display: "none" } },
            cancelButtonProps: { style: { display: "none" } },
        });
    };

    const getEmailsText = () => {
        const emails = new Set<any>();

        eventData?.alternatives?.forEach((alt: any) => {
            alt?.classList.forEach((classItem: any) => {
                if (classItem.classOrders && classItem.classOrders.length) {
                    classItem.classOrders.forEach((classOrder: any) => {
                        emails.add(classOrder.user.email);
                    });
                }
            });
        });
        // return emails;
        return Array.from(emails).join(",");
    };

    const getNumbersText = () => {
        const numbers = new Set<any>();
        eventData?.alternatives?.forEach((alt: any) => {
            alt?.classList.forEach((classItem: any) => {
                if (classItem.classOrders && classItem.classOrders.length) {
                    classItem.classOrders.forEach((classOrder: any) => {
                        numbers.add(classOrder?.user?.phoneNumber);
                    });
                }
            });
        });
        // return emails;
        return Array.from(numbers).join(",");
    };

    const handleCopyLink = () => {
        copyMessage.open({
            type: "success",
            content: "Copied",
        });
    };

    const onClickPdfVisible = () => {
        if (pdfVisible) {
            setPdfVisible(!pdfVisible);
        } else {
            setPdfListViewVisible(false);
            setPdfVisible(!pdfVisible);
        }
    };
    const onClickPdfListViewVisible = () => {
        if (pdfListViewVisible) {
            setPdfListViewVisible(!pdfListViewVisible);
        } else {
            setPdfVisible(false);
            setPdfListViewVisible(!pdfListViewVisible);
        }
    };

    return (
        <div className="eq-create-or-edit-event">
            <EqButton
                shape="circle"
                style={{ margin: "-16px" }}
                classNames="btn-background-orange"
                onClick={onCloseEvent}
            >
                <CloseOutlined />
            </EqButton>
            {isEditMode && activeTabKey!=="1" &&(
                            <>
                        {/* <Divider className="eq-create-event-divider" /> */}

                            
                            <Row className="eq-create-event-input" justify={"end"}>
                                <Space>
                                    <Col>
                                        {contextCopyMessage}
                                        <CopyToClipboard text={getEmailsText()}>
                                            <EqButton
                                                type="submit"
                                                classNames="btn-background-orange"
                                                onClick={handleCopyLink}
                                            >
                                                {t("copyEmails")}
                                            </EqButton>
                                        </CopyToClipboard>
                                    </Col>
                                    <Col>
                                        <CopyToClipboard text={getNumbersText()}>
                                            <EqButton
                                                type="submit"
                                                classNames="btn-background-orange"
                                                onClick={handleCopyLink}
                                            >
                                                {t("copyNumbers")}
                                            </EqButton>
                                        </CopyToClipboard>
                                    </Col>{" "}
                                </Space>
                            </Row></>
                        )}
            <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                <TabPane  key="1" tab={isEditMode ? t("editEvent") : t("createEvent")}>
                    <div className="eq-create-event-container">
                        {/* Header Row */}
                        <Row
                            justify="space-between"
                            align="middle"
                            className="eq-create-event-header"
                        >
                            <Col>
                                <Title level={3} className="eq-create-event-title">
                                    {isEditMode ? t("editEvent") : t("createEvent")}{" "}
                                    {isEditDraft ? <EqTags text={t("draft")} /> : ""}
                                </Title>
                            </Col>
                            <Col>
                                {isEditMode ? (
                                    isEditDraft ? (
                                        <Row>
                                            <Col>
                                                <Tooltip
                                                    title={
                                                        stripeAccountInfo
                                                            ? ""
                                                            : "Connect Stripe Account"
                                                    }
                                                >
                                                    <EqButton
                                                        style={{ marginRight: "10px" }}
                                                        type="submit"
                                                        disabled={stripeAccountInfo ? false : true}
                                                        classNames={
                                                            stripeAccountInfo
                                                                ? "btn-background-orange"
                                                                : "btn-gray"
                                                        }
                                                        onClick={() => handleOnUpdateEvent(false)} // false for publish
                                                    >
                                                        {t("publish")}
                                                    </EqButton>
                                                </Tooltip>
                                            </Col>
                                            <Col>
                                                <EqButton
                                                    // classNames="btn-light-gray"
                                                    type="button"
                                                    bordered="true"
                                                    onClick={() => handleOnUpdateEvent(true)} // false for publish
                                                >
                                                    {" "}
                                                    {t("updateDraft")}
                                                </EqButton>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row>
                                            <EqButton
                                                classNames="btn-background-orange"
                                                onClick={() => handleOnUpdateEvent(false)}
                                            >
                                                {t("update")}
                                            </EqButton>
                                        </Row>
                                    ) // for create Event
                                ) : (
                                    <Row>
                                        <Col>
                                            <Tooltip
                                                title={
                                                    stripeAccountInfo
                                                        ? ""
                                                        : "Connect Stripe Account"
                                                }
                                            >
                                                <EqButton
                                                    style={{ marginRight: "10px" }}
                                                    type="submit"
                                                    disabled={stripeAccountInfo ? false : true}
                                                    classNames={
                                                        stripeAccountInfo
                                                            ? "btn-background-orange"
                                                            : "btn-gray"
                                                    }
                                                    onClick={() => handleOnCreateEvent(false)} // false for publish
                                                >
                                                    {t("publish")}
                                                </EqButton>
                                            </Tooltip>
                                        </Col>
                                        {canSaveDraft && (
                                            <Col>
                                                <EqButton
                                                    // classNames="btn-light-gray"
                                                    type="button"
                                                    bordered="true"
                                                    onClick={confirmSaveAsDraft} // false for publish
                                                >
                                                    {" "}
                                                    {t("saveAsDraft")}
                                                </EqButton>
                                            </Col>
                                        )}
                                    </Row>
                                )}
                            </Col>
                        </Row>

                        {/* Divider */}
             
                      
                        <Divider className="eq-create-event-divider" />

                        {/* Image Selector */}
                        <EqImageSelector
                            defaultSelectedImage={formData.image}
                            imagesData={imagesData}
                            onImageSelect={handleImageSelect}
                            onImageUploadSuccess={onImageUploadSuccess}
                            onImageDeleteSuccess={onImageDeleteSuccess}
                        />

                        {/* Title Input */}
                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <EqInput
                                    label={
                                        <span>
                                            {t("title")} <span className="required">*</span>
                                        </span>
                                    }
                                    placeHolder={t("enterTitle")}
                                    className="name-input"
                                    value={formData?.title}
                                    // onBlur={(e: any) =>
                                    //   onActivityTitleChange(e.currentTarget.value)
                                    // }
                                    onChange={(event: any) =>
                                        setFormData({
                                            ...formData,
                                            title: event?.target?.value,
                                        })
                                    }
                                />
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <EqSelect {...typeSelectProps} />
                            </div>
                        </Col>


    {/* <Col  xs={24} sm={24} md={14}>
                <span>{t("Test ")}</span>
                    <Select
                    style={{width:"100%"}}
                        mode="multiple"
                        // menuItemSelectedIcon={"ss"}
                        // direction="rtl"
                        showArrow
                        allowClear
                        // suffixIcon={"icon"}
                        optionFilterProp={"label"}
                        className="filter-select"
                        placeholder={t("selectType")}
                        onChange={(data:any)=>{
                            setFormData({ ...formData, disciplineTypes: data });
                        }}
                        defaultValue={formData?.disciplineTypes || []}
                        value={formData?.disciplineTypes}
                        options={Object.entries(EqEventType)
                            .filter((e) => !isNaN(e[0] as any))
                            .map((e) => ({ key: parseInt(e[0]), value: parseInt(e[0]),  label:`${t(e[1] as any)}` }))}
                    >
                       
                    </Select>
                </Col> */}


                        {/* Location Input */}

                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <EqSelect {...areaSelectProps} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <EqDatePicker {...startOnProps} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <EqDatePicker {...endDateProps} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <EqDatePicker {...regDeadlineProps} />
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <EqSelect {...currencySelectProps} />
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <div className="input-label">{t("description")}</div>

                                <ReactQuill
                                    theme="snow"
                                    value={formData.description}
                                    placeholder={t("addDescription")}
                                    onChange={handleDescriptionChange}

                                    // onChange={(e: any) => {
                                    //   setFormData({
                                    //     ...formData,
                                    //     description: e,
                                    //   });
                                    //   // handleChangeTextEditor(e);
                                    // }}
                                />
                                <div className="eq-create-event-input">
                                    {wordCount}/{wordLimit} words
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <EqUploadTerms
                                    disabled={formData.useHouseTerms}
                                    onUploadSuccess={(newImage:any) => {
                                        setFormData({ ...formData, eventTermsFile: newImage });
                                    }}
                                    onRemove={() => {
                                        setFormData({ ...formData, eventTermsFile: null });
                                    }}
                                    defaultFile={fileObj}
                                />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <div className="eq-create-event-input">
                                <Row justify="space-between">
                                    <Col>{t("premadeEventTerms")}</Col>
                                    <Col className="switch-container">
                                        <span>{formData.useHouseTerms ? t("yes") : t("no")}</span>
                                        <Switch
                                            disabled={formData.eventTermsFile != null || undefined}
                                            size="small"
                                            checked={formData.useHouseTerms}
                                            onChange={handleSwitchChange} // Call the new switch handler
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        {formData.useHouseTerms && (
                            <Col xs={24} sm={24} md={14}>
                                <div className="eq-create-event-input">
                                    <ReactQuill
                                        // Adjust the height to fit approximately 4 rows
                                        theme="snow"
                                        value={formData.houseTerms}
                                        onChange={handleHouseTermsChange}
                                    />
                                    <div className="eq-create-event-input">
                                        {termsWordCount}/{wordLimit} words
                                    </div>
                                </div>
                            </Col>
                        )}

                        <Col xs={24} sm={24} md={14}>
                            <div className="eq-create-event-input">
                                <strong>{t("note")}: </strong> {t("uploadTermsNote")}
                            </div>
                        </Col>
                    </div>

                    {alternatives.map((alternative, index) => (
                        <EqEventAlternative
                            key={index}
                            t={t}
                            isEditMode={isEditMode}
                            removeAlternative={() => removeAlternative(index)}
                            customClassName={alternative.customClassName}
                            setCustomClassName={(data) => {
                                const updatedAlternatives = [...alternatives];
                                updatedAlternatives[index].customClassName = data;
                                setAlternatives(updatedAlternatives);
                            }}
                            isMultipleClassActive={alternative.isMultipleClassActive}
                            setIsMultipleClassActive={(data) => {
                                const updatedAlternatives = [...alternatives];
                                updatedAlternatives[index].isMultipleClassActive = data;
                                setAlternatives(updatedAlternatives);
                            }}
                            classList={alternative.classList}
                            handleInputChange={(classIndex, field, value) =>
                                handleInputChange(index, classIndex, field, value)
                            }
                            handleRemoveRow={(classIndex) => handleRemoveRow(index, classIndex)}
                            handleAddRow={() => handleAddRow(index)}
                            questions={alternative.questions}
                            addQuestion={() => addQuestion(index)}
                            togglePredefined={(questionIndex) =>
                                toggleQuestionType(index, questionIndex)
                            }
                            updateQuestionText={(questionIndex, value) =>
                                updateQuestionText(index, questionIndex, value)
                            }
                            updateIsMultiOptionAllowed={(questionIndex, value) =>
                                updateIsMultiOptionAllowed(index, questionIndex, value)
                            }
                            addOption={(questionIndex) => addOption(index, questionIndex)}
                            updateOption={(questionIndex, optionIndex, value) =>
                                updateOption(index, questionIndex, optionIndex, value)
                            }
                            removeOption={(questionIndex, optionIndex) =>
                                removeOption(index, questionIndex, optionIndex)
                            }
                            removeQuestion={(questionIndex) => removeQuestion(index, questionIndex)}
                            onRemoveClassOrder={onRemoveClassOrder}
                        />
                    ))}

                    {/* Button to Add New Alternative */}
                    <Row style={{ marginTop: "20px" }} justify="center">
                        <Col>
                            <Row>
                                <EqButton
                                    shape="circle"
                                    // style={{ margin: "-16px" }}
                                    classNames="btn-background-orange"
                                    onClick={addAlternative}
                                >
                                    <PlusOutlined />
                                </EqButton>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Title level={3} className="eq-create-event-title">
                            {t("createAlternatives")}
                        </Title>
                    </Row>
                    <Divider className="eq-create-event-divider" />


                    <Row
                            justify="space-between"
                            align="middle"
                            className="eq-create-event-header"
                        >
                        
                            <Col span={24}>
                                {isEditMode ? (
                                    isEditDraft ? (
                                        <Row justify={"center"}>
                                            <Col>
                                                <Tooltip
                                                    title={
                                                        stripeAccountInfo
                                                            ? ""
                                                            : "Connect Stripe Account"
                                                    }
                                                >
                                                    <EqButton
                                                        style={{ marginRight: "10px" }}
                                                        type="submit"
                                                        disabled={stripeAccountInfo ? false : true}
                                                        classNames={
                                                            stripeAccountInfo
                                                                ? "btn-background-orange"
                                                                : "btn-gray"
                                                        }
                                                        onClick={() => handleOnUpdateEvent(false)} // false for publish
                                                    >
                                                        {t("publish")}
                                                    </EqButton>
                                                </Tooltip>
                                            </Col>
                                            <Col>
                                                <EqButton
                                                    // classNames="btn-light-gray"
                                                    type="button"
                                                    bordered="true"
                                                    onClick={() => handleOnUpdateEvent(true)} // false for publish
                                                >
                                                    {" "}
                                                    {t("updateDraft")}
                                                </EqButton>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row justify={"center"}>

                                            <EqButton
                                                classNames="btn-background-orange"
                                                onClick={() => handleOnUpdateEvent(false)}
                                            >
                                                {t("update")}
                                            </EqButton>
                                        </Row>
                                    ) // for create Event
                                ) : (
                                    <Row justify={"center"}>

                                        <Col>
                                            <Tooltip
                                                title={
                                                    stripeAccountInfo
                                                        ? ""
                                                        : "Connect Stripe Account"
                                                }
                                            >
                                                <EqButton
                                                    style={{ marginRight: "10px" }}
                                                    type="submit"
                                                    disabled={stripeAccountInfo ? false : true}
                                                    classNames={
                                                        stripeAccountInfo
                                                            ? "btn-background-orange"
                                                            : "btn-gray"
                                                    }
                                                    onClick={() => handleOnCreateEvent(false)} // false for publish
                                                >
                                                    {t("publish")}
                                                </EqButton>
                                            </Tooltip>
                                        </Col>
                                        {canSaveDraft && (
                                            <Col>
                                                <EqButton
                                                    // classNames="btn-light-gray"
                                                    type="button"
                                                    bordered="true"
                                                    onClick={confirmSaveAsDraft} // false for publish
                                                >
                                                    {" "}
                                                    {t("saveAsDraft")}
                                                </EqButton>
                                            </Col>
                                        )}
                                    </Row>
                                )}
                            </Col>
                        </Row>


                </TabPane>
                {isEditMode && eventData &&(<>
                <TabPane  key="2" tab={t("startList")}>


                <EqPublicEventPdfGenerate
                eventData={activeTabKey==="2"?eventData:null}
                selectedDates={""}
                ></EqPublicEventPdfGenerate>
           

                </TabPane>
                <TabPane key="3" tab={t("attendeeList")}>
                <EqPublicEventPdfGenerateListView
                   event={eventData}
                   selectedDates={""}
                >
                    
                </EqPublicEventPdfGenerateListView>
                </TabPane>
                </>
                )}


            </Tabs>
        </div>
    );
};

export default EqCreateOrEditEvent;
