import React, { useState } from "react";
import { Row, Col, Button, Card, Input, Space, Divider, message, Grid } from "antd-v5";
import { CloseOutlined, InfoCircleFilled } from "@ant-design/icons";
import Title from "antd-v5/es/typography/Title";
import { Modal, Switch, Tooltip } from "antd-v5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { EqInput } from "../../../components/custom/eqInput/EqInput";
import EqButton from "../../../components/custom/eqButton/EqButton";
import "./EqEventAlternative.scss";
import { QuestionType } from "../../../components/shared/enums";
import { faCircle as faCircleRegular } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
const { useBreakpoint } = Grid;

interface EqEventAlternativeProps {
    t: any;
    isEditMode:boolean;
    customClassName: any;
    setCustomClassName: (data: any) => void;

    isMultipleClassActive: any;
    setIsMultipleClassActive: (data: any) => void;

    classList: any[];
    handleInputChange: (classIndex: number, field: string, value: string) => void;
    handleRemoveRow: (classIndex: number) => void;
    handleAddRow: () => void;
    questions: any[];
    addQuestion: () => void;
    togglePredefined: (questionIndex: number) => void;
    updateQuestionText: (questionIndex: number, value: string) => void;
    updateIsMultiOptionAllowed: (questionIndex: number, value: boolean) => void;
    addOption: (questionIndex: number) => void;
    updateOption: (questionIndex: number, optionIndex: number, value: string) => void;
    removeQuestion: (questionIndex: number) => void;
    removeOption: (questionIndex: number, optionIndex: number) => void;
    onRemoveClassOrder?: (orderId: any,refund:boolean, refundAmount?: number) => void;
    removeAlternative: () => void;
    
}

const EqEventAlternative: React.FC<EqEventAlternativeProps> = ({
    t,
    isEditMode,
    isMultipleClassActive,
    setIsMultipleClassActive,
    customClassName,
    setCustomClassName,
    classList,
    handleInputChange,
    handleRemoveRow,
    handleAddRow,
    questions,
    addQuestion,
    togglePredefined,
    updateQuestionText,
    updateIsMultiOptionAllowed,
    addOption,
    updateOption,
    removeQuestion,
    removeOption,
    onRemoveClassOrder,
    removeAlternative
}) => {

    const [refundAmount, setRefundAmount] = useState<string>(""); // State to store the refund amount
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderId, setOrderId] = useState("");
    const screens = useBreakpoint();

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        setRefundAmount("");
        setOrderId("");
      };

      const handleRefund = () => {
        
        const amount = Number(refundAmount);
        if (!refundAmount || isNaN(amount) || amount <= 0) {
          message.error(t("Please enter a valid refund amount"));
          return;
        }
        onRemoveClassOrder && onRemoveClassOrder(orderId, true, amount);
        closeModal();
      };
    
      const handlePayLater = () => {
        onRemoveClassOrder && onRemoveClassOrder(orderId, false,0);
        closeModal();
      };
    

    // const confirmRemoveAttendee = ( orderId:any) => {
    //     Modal.confirm({
    //         className:"refund-confirm-modal",
    //         title: t("confirm"),
    //        content: (
    //   <div>
    //     <p>{t("Would you like to Refund Attendee")}</p>
    //     <Input
    //       type="number"
    //       placeholder={t("Enter refund amount")} // Input field for refund amount
    //       value={refundAmount}
    //       onChange={(e) => setRefundAmount(e.target.value)}
    //       style={{ marginBottom: '10px' }} // Add some spacing
    //     />
    //   </div>
    // ),  
    //         footer: (
    //             <Row style={{padding:"2px"}} justify={"end"}>
    //                 {/* Discard Button */}
    //                 <EqButton
    //                     style={{ marginRight: "2px" }}
    //                     bordered
    //                     onClick={() => {
    //                         const amount = Number(refundAmount);
    //                         if (!refundAmount || isNaN(Number(amount))) {
    //                           message.error(t("Please enter a valid refund amount")); // Show error if amount is empty or invalid
    //                           return;
    //                         }
    //                         onRemoveClassOrder && onRemoveClassOrder(orderId, true, amount); // Proceed with refund if amount is valid
    //                         Modal.destroyAll(); // Close the modal
    //                       }}
    //                 >
    //                     {t("refund")}
    //                 </EqButton>

    //                 {/* Save as Draft Button */}
    //                 <EqButton
    //                     onClick={() => {
    //                         onRemoveClassOrder && onRemoveClassOrder(orderId,false);
    //                         Modal.destroyAll(); // Close the modal after saving
    //                     }}
    //                 >
    //                     {t("Pay Later")}
    //                 </EqButton>
    //             </Row>
    //         ),
    //         // Hide the default OK and Cancel buttons
    //         // okButtonProps: { style: { display: "none" } },
    //         // cancelButtonProps: { style: { display: "none" } },
    //         closable: true, // Enable the top-right close (X) button

    //     });
    // };


    return (
        <div className="eq-event-alternative">
            <div className="eq-create-event-container">
               
               <Row justify={"space-between"}>
               <Col>
                    <Title level={3} className="eq-create-event-title">
                        {t("addAlternatives")}
                        <Tooltip className="evt-tooltip" title={t("addAlternativesNote")}>
                            <span className="quick-links">
                                {/* <FontAwesomeIcon icon={faQuestion} color="#FF8838" /> */}
                                <InfoCircleFilled style={{ fontSize: "16px", marginLeft: "2px" }} />
                            </span>
                        </Tooltip>
                    </Title>
                </Col>
                <Col>
                {isEditMode?
                classList.length>0? <Tooltip title={t("Remove Added Classes First")} ><Button type="text" disabled shape="circle" icon={<CloseOutlined />} /></Tooltip>
               : <CloseOutlined disabled
               onClick={() => {
                   removeAlternative();
               }}
               style={{ cursor: "pointer" }}
               />
                 :<CloseOutlined
                 onClick={() => {
                     removeAlternative();
                 }}
                 style={{ cursor: "pointer" }}
             />}
                
                
                </Col>
               </Row>
              

                {/* Title Input */}
                <Col xs={24} sm={24} md={14}>
                    <div className="eq-create-event-input">
                        <EqInput
                            label={<span>{t("signingFor")}</span>}
                            placeHolder={t("classes")}
                            className="name-input"
                            value={customClassName}
                            onChange={(event: any) => setCustomClassName(event?.target?.value)}
                        />
                    </div>
                </Col>
                {/* 
      <Row>
        <Title level={3} className="eq-create-event-title">
          {t("Classes")}
        </Title>
      </Row>
      <Divider className="eq-create-event-divider" /> */}
                {/* <EqCollapsePanel
                    defaultOpen={true}
                    header={t("classes")}
                    children={ */}
                        <div className="alternative-classes" style={{ padding: "16px" }}>
                            {/* Dynamic Rows for Class Details */}
                     
                            {screens.sm &&       <Row>
                                <Col span={4}>{t("title")}</Col>
                                <Col span={4}>{t("description")}</Col>
                                <Col span={4}>{t("capacity")}</Col>
                                <Col span={4}>{t("price")}</Col>
                                <Col span={4}>{t("vat") + "%"}</Col>
                            </Row>
                        } 
                            {/* Mapping through classList to create input fields */}
                            {classList.map((classItem, index) => (
                                <>
                                    <Row key={index} gutter={[8, 8]} style={{ marginTop: "10px" , alignItems:"center"}}>
                                        <Col xs={24} sm={4}>
                                            <EqInput
                                     label={screens.xs && t("title")}
                                                value={classItem.title}
                                                onChange={(e: any) =>
                                                    handleInputChange(
                                                        index,
                                                        "title",
                                                        e.target.value
                                                    )
                                                }
                                                placeholder={t("title")}
                                            />
                                        </Col>
                                        <Col xs={24} sm={4}>
                                            <EqInput
                                     label={screens.xs && t("description")}
                                                value={classItem.description}
                                                onChange={(e: any) =>
                                                    handleInputChange(
                                                        index,
                                                        "description",
                                                        e.target.value
                                                    )
                                                }
                                                placeholder={t("description")}
                                            />
                                        </Col>
                                        <Col xs={24} sm={4}>
                                            <EqInput
                                     label={screens.xs && t("capacity")}
                                                value={classItem.capacity}
                                                type="number"
                                                min={0}
                                                onChange={(e: any) =>
                                                    handleInputChange(
                                                        index,
                                                        "capacity",
                                                        e.target.value
                                                    )
                                                }
                                                placeholder={t("capacity")}
                                            />
                                        </Col>
                                        <Col xs={24} sm={4}>
                                            <EqInput
                                     label={screens.xs && t("price")}
                                                value={classItem.price}
                                                type="number"
                                                min={100}
                                                onChange={(e: any) =>
                                                    handleInputChange(
                                                        index,
                                                        "price",
                                                        e.target.value
                                                    )
                                                }
                                                placeholder={t("price")}
                                            />
                                        </Col>
                                        <Col xs={24} sm={4}>
                                            <EqInput
                                     label={screens.xs && t("vat")}
                                                value={classItem.vat}
                                                type="number"
                                                min={0}
                                                max={100}
                                                onChange={(e: any) =>
                                                    handleInputChange(index, "vat", e.target.value)
                                                }
                                                placeholder={t("vat")}
                                            />
                                        </Col>
                                        <Col span={4}>
                                        {isEditMode?
                                        classItem.classOrders && classItem.classOrders.length > 0?<Tooltip title={t("Remove Class Attendees First")} >
                                        <span>
                                        <img
                                            alt=""
                                            src="/trash-alt.svg"
                                           
                                        />
                                    </span>
                                        </Tooltip>
                                        : <span>
                                        <img
                                            alt=""
                                            src="/trash-alt-red.svg"
                                            onClick={() => handleRemoveRow(index)}
                                        />
                                    </span>
                                        : <span>
                                        <img
                                            alt=""
                                            src="/trash-alt-red.svg"
                                            onClick={() => handleRemoveRow(index)}
                                        />
                                    </span>
                                    }
                                       
                                        </Col>
                                    </Row>

                                    {classItem.classOrders && classItem.classOrders.length > 0 &&  <>
                                 
                                    <Divider style={{margin:"10px 0"}} >   {t("attendees")}</Divider>
                                    {classItem.classOrders.filter((x:any)=>x.status===1).map((order: any) => (
                                        <>
                                        <Row gutter={[8, 8]} align={"middle"}> 
                                            <Col
                                                className="eq-std-td"
                                                xs={8} sm={8} md={8}
                                                // xs={19}
                                                // sm={20}
                                                // md={21}
                                            >
                                                <div className="eq-up-name">
                                                    {order.attendUsingName
                                                        ? order.attendUsingName
                                                        : (order?.user?.firstName +" "+ order.user.lastName ) ||
                                                          order?.user?.userName}
                                                </div>
                                                {/* <div>{item?.group?.name}</div> */}
                                            </Col>
                                          
                                            <Col  xs={12} sm={12} md={12}>
                                            {order?.answers?.map((answer: any, qIdx: number) => (
                                            <div key={qIdx}>
                                                {/* <div >
                                                    {answer.question.questionText}
                                                </div>
                                                <br /> */}
                                                {answer.question.questionType ===
                                                QuestionType.Text ? (
                                                    <div>
                                                        {answer.answerText}
                                                    </div>
                                                ) : (
                                                    answer.selectedOptions.map(
                                                        (option: any, oIdx: number) => (
                                                            <div key={oIdx}>
                                                                {option.option.optionText}
                                                            </div>
                                                        )
                                                    )
                                                )}
                                            </div>
                                        ))}
                                            
                                            
                                            </Col>
                                            <Col xs={3} sm={3} md={3}>
                                                <div className="action-col">
                                                    <Row className="actions-btn">
                                                        <Col className="eq-event-actions-trash">
                                                            <span>
                                                                <img
                                                                    alt=""
                                                                    // src="/trash-alt.svg"
                                                                    src="/trash-alt-red.svg"
                                                                    onClick={() =>{

                                                                        // confirmRemoveAttendee(
                                                                        //     order.id
                                                                        // )
                                                                        setOrderId(order.id);
                                                                        openModal();
                                                                    }
                                                                    }
                                                                />
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    <Divider style={{margin:"10px 0"}}></Divider></>

                                        
                                    ))}
                                    </> 
                                        }
                                 
                                </>
                            ))}

                            {/* Button to add new row */}
                            <Row >
                                <Col span={22} style={{textAlign:"center"}}>
                               

                            <Button
                                className="eq-button-link"
                                type="link"
                                onClick={handleAddRow}
                                style={{ marginTop: "20px" }}
                            >
                                + {t("add")}
                            </Button>
                            </Col>
                                </Row>

                            <Row gutter={[0,8]} className="multiple-class-Row">
                                <Space>
                                <Col>{t("multipleChoice")}</Col>
                                <Col className="switch-container">
                                    <span>{isMultipleClassActive ? t("yes") : t("no")}</span>
                                    <Switch
                                        size="small"
                                        checked={isMultipleClassActive}
                                        onChange={(checked) => setIsMultipleClassActive(checked)}
                                    />
                                </Col>
                                </Space>
                            </Row>
                        </div>
                    {/* }
                /> */}

                {/* Questions Section */}
                <Row justify="space-between" style={{ marginTop: "20px" }}>
      
                    <Col>
                        {" "}
                        <Title level={3} className="eq-create-event-title">
                            {t("addQuestion")}
                        </Title>
                    </Col>
                </Row>

                <Space direction="vertical" style={{ width: "100%", marginTop: "20px" }}>
                    {questions.map((question, index) => (
                        <Card
                            className="question-card"
                            key={index}
                            // title={`Question ${index + 1}`}
                            // extra={
                            //   <div className="switch-container">
                            //   <span>{question.predefined ? t("Predefined Answers") : t("Free Text")}</span>
                            //   <Switch
                            //     checked={question.predefined}
                            //     onChange={() => togglePredefined(index)}

                            //   />
                            //   </div>
                            // }
                            extra={
                                <CloseOutlined
                                                            onClick={() => {
                                                                removeQuestion(index);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                        
                            }
                        >
                            <Row justify="space-between">
                                <Col span={16}>
                                    <EqInput
                                        classNames="eq-card-input"
                                        placeholder={t("addYourQuestion")}
                                        value={question.questionText}
                                        onChange={(e: any) =>
                                            updateQuestionText(index, e.target.value)
                                        }
                                    />
                                </Col>
                                <Col className="switch-container">
                                    <span>
                                        {question.questionType ? t("predefined") : t("freeText")}
                                    </span>
                                    <Switch
                                        size="small"
                                        checked={question.questionType}
                                        onChange={() => togglePredefined(index)}
                                    />
                                </Col>
                            </Row>

                            {question.questionType === QuestionType.MultipleChoice && (
                                <div>
                                    {question.options.map((option: any, optionIndex: any) => (
                                        <Row key={optionIndex} style={{ marginTop: "10px" }}>
                                            <Col span={24}>
                                                <EqInput
                                                    classNames="eq-card-input"
                                                    placeholder={`Option ${optionIndex + 1}`}
                                                    value={option.optionText}
                                                    onChange={(e: any) =>
                                                        updateOption(
                                                            index,
                                                            optionIndex,
                                                            e.target.value
                                                        )
                                                    }
                                                    prefix={
                                                        question.isMultiOptionAllowed ? (
                                                            <FontAwesomeIcon
                                                                icon={faSquareCheck}
                                                                color="#FF8838"
                                                            />
                                                        ) : (
                                                                <FontAwesomeIcon icon={faCircleRegular as IconProp} />
                                                        )
                                                    }
                                                    suffix={
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                removeOption(index, optionIndex);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    }
                                                />
                                            </Col>
               
                                        </Row>
                                    ))}

                                    <Row
                                        style={{ marginTop: "10px" }}
                                        justify="space-between"
                                        align="middle"
                                    >
                                        <Divider className="eq-create-event-divider" />
                                        <Col>
                                            <Button
                                                className="eq-button-link"
                                                type="link"
                                                onClick={() => addOption(index)}
                                                // icon={<PlusOutlined />}
                                            >
                                                + {t("addOption")}
                                            </Button>
                                        </Col>
                                        <Col className="switch-container bordered-col">
                                            <span>{t("multipleChoice")}</span>
                                            <Switch
                                                size="small"
                                                checked={question.isMultiOptionAllowed}
                                                onChange={(e: any) =>
                                                    updateIsMultiOptionAllowed(index, e)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}

              
                        </Card>
                    ))}
                </Space>

                <Row>
                    <Button
                        className="eq-button-link"
                        type="link"
                        onClick={addQuestion}
                        style={{ marginTop: "20px" }}
                    >
                        + {t("addQuestion")}
                    </Button>
                </Row>
            </div>

            <Modal
        title={t("confirm")}
        className="refund-confirm-modal"
        open={isModalOpen}
        onCancel={closeModal}
        footer={null} // Custom footer
        closable={true}
        // closeIcon={<span style={{ fontSize: '16px', color: '#000' }}>X</span>}
      >
        <div>
          <p>{t("refundAttendee")}</p>
          <p>{t("refundAttendeeNote")}</p>
          <Input
            type="number"
            placeholder={t("amount")}
            min={0}
            value={refundAmount}
            onChange={(e) => setRefundAmount(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
        </div>

        <Row style={{ padding: "2px" }} justify={"end"}>
          {/* Refund Button */}
          <EqButton
            style={{ marginRight: "2px" }}
            bordered
            onClick={handleRefund}
          >
            {t("refund")}
          </EqButton>

          {/* Pay Later Button */}
          <EqButton onClick={handlePayLater}>
            {t("payLater")}
          </EqButton>
        </Row>
      </Modal>

        </div>
    );
};

export default EqEventAlternative;
