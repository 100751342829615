import { t } from "i18next";
import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Popconfirm, Input , Tabs, Badge , Empty } from "antd";
import EqButton from "../../../custom/eqButton/EqButton";
import EqCollapsePanel from "../../../custom/eqCollapsePanel/EqCollapsePanel";
import { ApiEndpoints, openNotificationWithIcon, ValidateIsSchool } from "../../../shared/Common";
import { useAuthState } from "../../../context/Context";
import { eqNotificationTypes } from "../../../shared/enums";
import Api from "../../../shared/Api";
import ProductRequestNew from "./ProductRequestNew";
import "./RidingCourseInformation.scss";
import EqProductInvitations from "../../../custom/EqProductInvitations/EqProductInvitations";
import { EqInput } from "../../../custom/eqInput/EqInput";
import CourseOrderdetails from "./CourseOrderDetails/CourseOrderdetails";
import { CourseUI, EqOrderStatus, ISchoolStudents } from "../../../shared/interfaces/ridingCourse/IRidingCourse";
import { useTranslation } from "react-i18next";


interface IRidingCourseInformation {
    editCourse: CourseUI | null
    schoolStudentsData: ISchoolStudents[];
    onAcceptCourseOrderRequest: (requestId: string) => void;
    onRejectCourseOrderRequest: (requestId: string) => void;
    onDeleteCourseInvite: (inviteId: string) => void;
    onRemoveCourseOrder?: (order: any, refund: boolean, refundAmount?: number) => void;
    onAcceptCourseLessonOrderRequest: (requestId: string) => void;
    onRejectCourseLessonOrderRequest: (requestId: string) => void;
}

const RidingCourseInformation : React.FC<IRidingCourseInformation> =({
    editCourse,
    schoolStudentsData,
    onAcceptCourseOrderRequest,
    onRejectCourseOrderRequest,
    onAcceptCourseLessonOrderRequest,
    onRejectCourseLessonOrderRequest,
    onDeleteCourseInvite,
    onRemoveCourseOrder
}) => {
    const [courseorderList, setcourseorderList] = React.useState(editCourse?.courseLessonOrders || []);
    const [filterStudents, setFilterStudents] = React.useState([]) as any;
    const [searchQuery, setSearchQuery] = React.useState() as any;
    const [noResultFound, SetnoResultFound] = React.useState(false);
    const userDetails: any = useAuthState();
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const { t, i18n } = useTranslation();

    
    const [productInvites, setProductInvites] = useState<any>(null);
    const [SelectedStudent, setSelectedStudents] = useState<number[]>([]);;
    const isCourse = true;
    useEffect(() => {
        
        setcourseorderList(editCourse?.courseLessonOrders || []);
        setFilterStudents(editCourse?.courseLessonOrders || []);

    }, [editCourse]);

    const handleSearchInputChange = (e : any) => {
        setSearchQuery(e.target.value);
    };

    const handleSearch = () => {
        if (typeof searchQuery === "string") {
            const filteredList = courseorderList.filter((value: any) => {
                const fullName = `${value.student?.firstName} ${value.student?.lastName}`.toLowerCase();
                return (
                    fullName.includes(searchQuery.toLowerCase()) ||
                    (value.student?.email || "").toLowerCase().includes(searchQuery.toLowerCase())
                );
            });

            setFilterStudents(filteredList);

            if (filteredList.length === 0) {
                SetnoResultFound(true);
            } else {
                SetnoResultFound(false);
            }
        }
    };
    const handleRejectProductRequest = async (requestId: string) => {
        try {
            const response = await Api.put(
                `${ApiEndpoints.UserProductRequest.Reject}/${requestId}`
            );

            //if (response.status === 200) {
            //    setProductRequests((prevRequests: any) =>
            //        prevRequests.filter((invite: any) => invite.id !== requestId)
            //    );

            //    setStartedCourseRequests((prevRequests: any) =>
            //        prevRequests.filter((invite: any) => invite.id !== requestId)
            //    );
            //    openNotificationWithIcon(
            //        "Rejected!",
            //        "Successfully Rejected Request",
            //        eqNotificationTypes.success
            //    );
            //} else {
            //    console.error("Unexpected response status:", response.status);
            //}
        } catch (error) {
            console.error("Error Reject invite:", error);
            openNotificationWithIcon("Error!", "Unable to Reject", eqNotificationTypes.error);
        }
    };
    const handlestudentselect = (selectedValues: number[]) => {
        if (selectedValues.length < SelectedStudent.length) {
            return;

        }
        //const array1 = [...selectedValues] 
        setSelectedStudents([...selectedValues]);
    };

    const handleDeleteProductInvites = async (inviteIds: any) => {

        try {
            const response = await Api.delete(
                ApiEndpoints.UserProductInvite.DeleteMultipleInvites,
                {
                    data: [...inviteIds],
                }
            );

            if (response.status === 200) {
                // Remove deleted inviteIds from the local state
                setProductInvites((prevInvites: any) =>
                    prevInvites.filter((invite: any) => !inviteIds.includes(invite.id))
                );

                openNotificationWithIcon(
                    "Deleted!",
                    "SucessFully Removed Invites",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error deleting invites:", error);

            openNotificationWithIcon(
                "Error!",
                "Unable to Delete",
                eqNotificationTypes.error
            );
        }
    };


    const handleOpenModal = (requestId: string) => {
        // let selectedRequest = productRequests.find(
        // 	(r: any) => r.id === requestId
        // );

        //setSelectedRequestId(requestId);
        //setIsModalVisible(true);
        // if (selectedRequest.product.isUsed) {
        // 	setSelectedRequestId(requestId);
        // 	setIsModalVisible(true);
        // } else {
        // 	// normal flow just accept
        // 	handleAcceptProductRequest(requestId, null);
        // }
    };

    
    return (
        <>

            <div className="Course-order-details">
                <Col className= "search-Col-info">
                <Row>
                    <Col span={24}>
                        <Row className="filter-selector">
                            <Col span={24} className="search-input">
                                <EqInput
                                    placeholder={t("searchNameMail")}
                                    classNames="EqInput-Course"
                                    onChange={handleSearchInputChange}
                                />
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className="search">
                        <EqButton
                            classNames="eq-input-info-button"
                            type="primary"
                            htmlType="button"
                            onClick={handleSearch}
                        >
                            {t("search")}
                        </EqButton>
                    </Col>
                </Row>

            </Col>


            <Col span={24}>
                <div className="Course-Invitation">
                    <Row>
                        <h2>{t("courseInvitations")}</h2>
                    </Row>
                        {noResultFound ? (
                                                        <Empty description={t("noSuchStudentExists")} />
                        ) : (
                    <Col span={24}>
                        <EqProductInvitations
                            isCourse={isCourse}
                            editCourse={editCourse}
                            data={productInvites}
                            filteredStudent={[...filterStudents]}
                            onDelete={onDeleteCourseInvite}
                            onDeleteMultiple={handleDeleteProductInvites}
                        />
                    </Col>
                        )}
                </div>
                </Col>


                

                <Col span={24}>
                    <Row className="Course-order-heading"><h2>{t("requests")}</h2></Row>
                    <div className="Request-class">
                        {noResultFound ? (
                            <Empty description={t("noSuchStudentExists")} />
                        ): (
                        isSchool && (
                            <ProductRequestNew
                                editCourse={editCourse}
                                searchQuery={searchQuery}
                                onAccept={onAcceptCourseOrderRequest}
                                OnReject={onRejectCourseOrderRequest}
                                onAcceptCourseLessonOrderRequest={onAcceptCourseLessonOrderRequest}
                                onRejectCourseLessonOrderRequest={onRejectCourseLessonOrderRequest}
                            />
                            )
                        )}
                    </div>
                </Col>

                <Row className="Course-order-heading"> <h2> {t("courseOrderDetails")} </h2></Row>
                {noResultFound ? (
                                                <Empty description={t("noSuchStudentExists")} />
                ): (
                <CourseOrderdetails
                    editCourse={editCourse}
                    filteredStudent={[...filterStudents]}
                    onRemoveCourseOrder={onRemoveCourseOrder}
                 />
                )}
            
            </div>
    </>
    );
}

export default RidingCourseInformation;