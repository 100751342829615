import { EqDisciplineType, EqCurrency, EqEventRepeatType, EqTrainingLevels, EqEventHorseAvailabilityStatus, EqJoinPermissions, CourseOrderType } from "../../enums";
import { ILocationDto } from "../ILocationDto";

export enum CourseStatus {
    Drafted,
    Active,
    Paused,
    Completed,
    Deleted,
    Requested
}
export enum EqRefundType {
    NoRefund,
    Refund,
    Credit,
}
export enum EqUserProfessionType {
    None = 0,
    Administrator,
    Trainer,
    Student,
    Veterinarian,
    Farrier,
    Therapist,
    EventHolder,
    RidingSchool,
    Stable,
    Parent
}

export enum EqUserType {
    Student = 1,
    Group = 2
}
export enum EqPaymentStatus {
    Paid = 1,
    Unpaid,
    UnpaidButAttending,
    PaidButCancelled,
    Refunded
}

export enum EqPaymentSourceType {
    Card = 1,
    Cash = 2
}

export interface EqUiPayment {
    key: number;
    paymentDate: number;
    status: EqPaymentStatus;
    paymentReminder: boolean;
    note: string;
    paymentSourceType: EqPaymentSourceType;
    user: ICourseStudent;
    userEmails: string[] | null;
}
//export interface 
export interface ISchoolStudents {
    key: string;
    userType: EqUserType;
    user: IEqUserInfoDto; 
    school: IEqUserInfoDto;
}

export interface IStudentHorseList {
    key: string;
    horseList: any[];
}

export enum EqOrderStatus {
    Pending,
    Accepted,
    Rejected,
    Paid,
    Requested,
    Waiting,
    OwnerCancelled,
    AttendeeCancelled
}

export interface IEqTrainingCourseBoard {
    updateCount?: (count: number) => void;
    activeCourse?: any;
}

export enum EqGroupType {
    House = 1,
    Stable = 2,
    People = 3,
    Event = 4,
    Horse = 5
}
export interface IEqGroupDto {
    id: string;
    Key: number; 
    Name: string;
    type: EqGroupType;
    Message: string;
    Color: string;
}

export interface ICourseStudent {
    email: string;
    key: number;
    id: string;
    firstName: string;
    lastName: string;
}
export interface ICourseUser {
    id: string;
    key: number;
    email: string;
    firstName: string;
    lastName: string;
}


export interface ICourseLessonOrderUI {
    id: string;

    key: number;
    orderStatus: EqOrderStatus;
    createDate: number;
    updateDate: number;
    studentId:string;
    student: ICourseStudent;
    payment: EqUiPayment;
    paymentMethod: EqPaymentSourceType;
    course: CourseUI;
    orderType:CourseOrderType;
}

export interface ICourseLessonUI {
    id: string;
    key: number;
    disciplineType: EqDisciplineType;
    currency: EqCurrency | null;
    startDate: number;
    endDate: number;
    startTime: number;
    endTime: number | null;
    durationMinutes: number;
    capacity: number;
    price: number;
    isRepeat: boolean;
    repeatType: EqEventRepeatType;
    trainingLevel: EqTrainingLevels;
    horseAvailabilityStatus: EqEventHorseAvailabilityStatus;
    createdDate: number;
    locationId: string;
    location: ILocationDto;
    isDeleted: boolean;
    courseLessonOrders: Array<ICourseLessonOrderUI>;
    course: CourseUI;
    
}

export interface CourseUI {
    id:string;
    key: number;
    title: string;
    description: string;
    currency: EqCurrency | null;
    joinPermission: EqJoinPermissions | null;
    startDate: number;
    endDate: number;
    coursePrice: number;
    vat: number;
    repeatType: EqEventRepeatType;
    weekDayIds: Array<string>;
    lessonStartTime: string;
    lessonDurationMinutes: number;
    capacity: number;
    lessonPrice: number;
    lessons: number;
    imageId: string | null;
    image: EqImageInfoDto;
    createdDate: number;
    schoolId: string;
    school: ICourseUser;
    courseTrainerId: string;
    courseTrainer: ICourseUser;
    status: CourseStatus;

    courseLessonOrders: Array<ICourseLessonOrderUI>;
    courseLessons: Array<ICourseLessonUI>;

    createdById: string;
    createdBy: ICourseUser;
}

export interface EqImageInfoDto {
    id: string;
    key: number;
    url: string;
    uploadedDate: number;
    imageId: string;
    eqUser: any | null;
}
export enum HorseTypeEnum {
    Stallion = 1,
    Gelding,
    Mare
}

export interface IEqEntityHorseInfoDto {
    id:string
    key: number;
    name : string; 
    About: string;
    Type :HorseTypeEnum;
    ImageURL: string;
    }

export interface IEqUserInfoDto {
    id: number;
    key: number;
    firstName: string;
    LastName: string;
    CompanyName: string;
    SchoolName: string;
    Email: string;
    UserName: string;
    PhoneNumber: string;
    ProfessionType: EqUserProfessionType;
    UserImage: EqImageInfoDto;
    AllowLatePayments: boolean;
    //CancellationDeadline: long;
    RefundType: EqRefundType;
    AllowProductTrainingCancel: boolean;
    ProductRefundType: EqRefundType;
    horses: IEqEntityHorseInfoDto[];
    PaymentStatus: number;
    Groups: IEqGroupDto;
}

export interface ITrainerOption {
    key: string | number;
    value: string;
    label: string;
    icon: React.ReactNode;
}




export interface TrainingCourseCreateProp {
    imagesData: EqImageInfoDto[];
    onClose: () => void;
    onImageUploadSuccess: (newImage: any) => void;
    onImageDeleteSuccess: (imageId: any) => void;
    creatorUser?: any;
    trainersOptions: any;
    schoolStudents: any;
    studentHorsesList?: IStudentHorseList[];

    onCreateCourse?: (data: any,isPublishDraft:boolean,draftCourseId?: string) => void;
    onEditCourseDetails?: (data: any) => void;
    onEditCourseLesson?: (data: any) => void;
    editCourse?: CourseUI; 
    onRemoveLessonOrder?: (lessonId:string,order: any, refund: boolean, refundAmount?: number) => void;
    
    onRemoveCourseOrder?: (order: any, refund: boolean, refundAmount?: number) => void;
    isStudent?: boolean;
    onAcceptCourseOrderRequest: (requestId: string) => void;
    onRejectCourseOrderRequest: (requestId: string) => void;
    onAcceptCourseLessonOrderRequest: (requestId: string) => void;
    onRejectCourseLessonOrderRequest: (requestId: string) => void;
    onDeleteCourseInvite: (inviteId: string) => void;
    onBuy?: () => void;
    onPayNow?: (orderId:string) => void;
    onPayNowLessonOrder?: (orderId:string) => void;

    onCourseLessonJoinRequest?: (lessonId:string) => void;

}

export interface TrainingCourseViewProp {    
    course?: CourseUI;     
    isStudent?: boolean;
    statusChanged?: (param: CourseStatus) => void;
    onBuy?: () => void;
    onPayNow?: (orderId:string) => void;
}



export interface ISelectOption {
    isDisabled?: string;
    key?: string;
    value?: string;
    title?: string;
    calssName?: string;
}

export interface ICourseStudentList {
    student: ISelectOption;
    horse: ISelectOption;
}
export interface ICourseCreateForm {
    imageData: EqImageInfoDto;
    trainer: ISelectOption;
    title: string;
    description: string;
    startDate: any;
    endDate: any;
    from: any;
    lesonWeeks: number;
    totalPrice: number;
    individualPrice: number;
    studentList: Array<ICourseStudentList>
};

//export interface I
export interface CourseCreateForm {
    id?:string;
    image: EqImageInfoDto| null;
    imageId: string | null;
    title: string;
    description: string;
    startDate: any;
    endDate: any;
    currency: EqCurrency | null;
    joinPermission: EqJoinPermissions | null;
    coursePrice: number;
    vat: number;
    repeatType: EqEventRepeatType| null;
    weekDayIds?: Array<string>,
    lessonStartTime:string;
    lessonDurationMinutes: number;
    capacity: number;
    lessonPrice: number;
    lessons: number;
    schoolId?: string;
    courseTrainerId: string;
    selectedStudents?: Array<ICourseStudent>
   
    status: CourseStatus;

}

export interface ISelectedStudentDTO {
    StudentKey: string;
    HorseId?: string;
}


export interface EqEntityCredit {
    id: string; // Corresponds to Guid in C#
    userId: string; // Corresponds to Guid in C#
    eventId: string; // Corresponds to Guid in C#
    dateIssued: number; // Corresponds to long in C#
    isUsed: boolean; // Corresponds to bool in C#
    fromUserId: string; // Corresponds to Guid in C#
    creditType: CreditType; // Assumes a matching TypeScript enum or type for CreditType
}

export enum CreditType {
  
    Course, // for full course
    TrainingLesson // for only Lesson

}