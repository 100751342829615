import React, { useState } from "react";
import { Select, Row, Col, Typography } from "antd-v5";
import EqSelect from "../../../custom/eqSelect/EqSelect";
import "./EqCourseStudentsSelect.scss";
import { useTranslation } from "react-i18next";
import { ISchoolStudents } from "../../../shared/interfaces/ridingCourse/IRidingCourse";

const { Title } = Typography;

export interface IEqCourseStudentsSelect {
    schoolStudentsData?: ISchoolStudents[];
    onSelectionChange?: (data: any) => void;
    noBorder?: boolean
}

const EqCourseStudentsSelect = ({ schoolStudentsData, onSelectionChange, noBorder }: IEqCourseStudentsSelect) => {
    const [selectedStudents, setSelectedStudents] = useState([]) as any;
    const [selectedPairs, setSelectedPairs] = useState([]) as any;
    const { t } = useTranslation();

    const handleStudentSelect = (selectedKeys: any) => {
        setSelectedStudents(selectedKeys);
        

        // Retain existing pairs for already selected students, and add new students with horseId as null
        const updatedPairs = selectedKeys.map((studentKey: any) => {
            const existingPair = selectedPairs.find((pair: any) => pair.studentKey === studentKey);
            return existingPair || { studentKey, horseId: null };
        });
        setSelectedPairs(updatedPairs);
        onSelectionChange && onSelectionChange(updatedPairs);
    };

    const handleHorseSelect = (studentKey: any, horseId: any) => {
        // Update the horse selection for the given student in selectedPairs
        const updatedPairs = selectedPairs.map((pair: any) =>
            pair.studentKey === studentKey ? { ...pair, horseId } : pair
        );
        setSelectedPairs(updatedPairs);
        onSelectionChange && onSelectionChange(updatedPairs);
    };

    const getHorseOptions = (studentKey: any) => {
        
        const studentData = schoolStudentsData?.find((item: any) => item.key === studentKey);
        if (!studentData) return [];
        
        console.log("The School Student data in the Eq Course Student Select is", studentData);
        const studentHorses = studentData.user.horses.map((horse: any) => ({
            key: horse.id,
            value: horse.id,
            label: horse.name,
        }));

       

        const schoolHorses = studentData?.school.horses.map((horse: any) => ({
            key: horse.id,
            value: horse.id,
            label: horse.name,
        }));

        return [...studentHorses, ...schoolHorses];
    };

    return (
        <div className={noBorder ? "student-horse-selector no-border" : "student-horse-selector"}>
            <Title level={3} className="eq-create-event-title">
                {t("students")}
            </Title>
            <EqSelect
                mode="multiple"
                showSearch
                placeholder={t("selectStudents")}
                classNames="eq-multiSelect eq-searchSelect extra-height"
                optionFilterProp="value"
                filterOption={true}
                defaultSelectedValue={selectedStudents}
                changeFunc={handleStudentSelect}
                options={schoolStudentsData?.map((student: any) => ({
                    key: student.key,
                    value: student.user.email,
                    label: `${student.user.firstName} ${student.user.lastName}`,
                }))}
            />

            <div className="selection-container">
                <Row className="selection-header">
                    <Col span={12} className="header-title">{t("student")}</Col>
                    <Col span={12} className="header-title">{t("horse")}</Col>
                </Row>

                {selectedStudents.map((studentKey: any) => (
                    <Row key={studentKey} className="selection-row">
                        <Col span={12} className="student-name">
                            {schoolStudentsData?.find((s: any) => s.key === studentKey)?.user.firstName}
                        </Col>
                        <Col span={12}>
                            <Select
                                placeholder="Select Horse"
                                options={getHorseOptions(studentKey)}
                                className="horse-select"
                                value={selectedPairs.find((pair: any) => pair.studentKey === studentKey)?.horseId || undefined}
                                onChange={(value) => handleHorseSelect(studentKey, value)}
                            />
                        </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
};

export default EqCourseStudentsSelect;
