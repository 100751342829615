import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd-v5';
import TrainingCourseCreate from './trainingCourseCreate/TrainingCourseCreate';
import {
    ValidateIsTrainer,
} from '../../shared/Common';
import { EqEventUserType } from '../../shared/enums';
import { useAuthState } from '../../context/Context';
import { CourseUI, EqImageInfoDto, ISchoolStudents, IStudentHorseList, ITrainerOption } from '../../shared/interfaces/ridingCourse/IRidingCourse';

interface TrainingCourseDrawerProps {
    onClose: () => void;
    trainersOptions: ITrainerOption[];
    SchoolsTrainersOptionsList?: any;
    schoolStudents: ISchoolStudents[];
    studentHorsesList?: IStudentHorseList[];
    imagesData: EqImageInfoDto[];
    onImageUploadSuccess: (newImage: any) => void;
    onImageDeleteSuccess: (imageId: any) => void;
    onCreateCourse?: (data: any,isPublishDraft:boolean,draftCourseId?: string) => void;
    onEditCourseDetails?: (data: any) => void;
    onEditCourseLesson?: (data: any) => void;
    onEditEvent?: (data: any) => void;
    onRemoveLessonOrder?: (lessonId:string,order: any, refund: boolean, refundAmount?: number) => void;
    onRemoveCourseOrder?: (order: any, refund: boolean, refundAmount?: number) => void;
    isStudent?: boolean;
    isEventLoading?: boolean;
    editCourse?: CourseUI; // New prop for editing
    onAcceptCourseOrderRequest: (requestId: string) => void;
    onRejectCourseOrderRequest: (requestId: string) => void;
    onAcceptCourseLessonOrderRequest: (requestId: string) => void;
    onRejectCourseLessonOrderRequest: (requestId: string) => void;
    onDeleteCourseInvite: (inviteId: string) => void;
    onBuy?: () => void;
    onPayNow?: (orderId:string) => void;
    onPayNowLessonOrder?: (orderId:string) => void;
    onCourseLessonJoinRequest?: (lessonId:string) => void;
}

const TrainingCourseDrawer: React.FC<TrainingCourseDrawerProps> = ({
    isEventLoading,
    onClose,
    onBuy,
    onPayNow,
    onPayNowLessonOrder,
    trainersOptions,
    schoolStudents,
    studentHorsesList,
    SchoolsTrainersOptionsList,
    imagesData,
    onImageUploadSuccess,
    onImageDeleteSuccess,
    onCreateCourse,
    onEditCourseDetails,
    onEditCourseLesson,
    editCourse,
    onRemoveLessonOrder,
    onRemoveCourseOrder,
    isStudent,
    onAcceptCourseOrderRequest,
    onRejectCourseOrderRequest,
    onAcceptCourseLessonOrderRequest,
    onRejectCourseLessonOrderRequest,
    onDeleteCourseInvite,
    onCourseLessonJoinRequest
}) => {
    const userDetails: any = useAuthState();
    const isTrainer = ValidateIsTrainer(userDetails);

    const getSchoolTrainers = (): any[] => {
        const ownerOrganizer = organizers.find(
            (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
        );
        if (ownerOrganizer && SchoolsTrainersOptionsList) {
            const schoolTrainerData = SchoolsTrainersOptionsList.find(
                (school: any) => school.key === ownerOrganizer.user.email
            );
            return schoolTrainerData ? schoolTrainerData.trainersOptions : [];
        }
        return [];
    };


    console.log("The Trainer Options are", trainersOptions);
    const [organizers, setOrganizers] = useState<any[]>([]);
    const [creatorUser, setCreatorUser] = useState<any[]>([]);

    useEffect(() => {
        // Fetch organizers or any other data if required
        
        console.log("drawer", editCourse);
    }, [editCourse]);
    
    console.log("The Trainer Api Data is", trainersOptions);
    return (
        <Drawer
            closable={false}
            width={1000}
            onClose={onClose}
            open={true}
            loading={isEventLoading}
        >
            <TrainingCourseCreate
                isStudent={isStudent}
                imagesData={imagesData}
                onImageUploadSuccess={onImageUploadSuccess}
                onImageDeleteSuccess={onImageDeleteSuccess}
                creatorUser={creatorUser}
                trainersOptions={isTrainer ? getSchoolTrainers() : trainersOptions}
                onClose={onClose}
                schoolStudents={schoolStudents}
                studentHorsesList={studentHorsesList}
                onCreateCourse={onCreateCourse}
                editCourse={editCourse} 

                onEditCourseDetails={onEditCourseDetails} 
                onEditCourseLesson={onEditCourseLesson} 
                onRemoveLessonOrder={onRemoveLessonOrder}
                onRemoveCourseOrder={onRemoveCourseOrder}
                onAcceptCourseOrderRequest={onAcceptCourseOrderRequest}
                onRejectCourseOrderRequest={onRejectCourseOrderRequest}
                onAcceptCourseLessonOrderRequest={onAcceptCourseLessonOrderRequest}
                onRejectCourseLessonOrderRequest={onRejectCourseLessonOrderRequest}
                onDeleteCourseInvite={onDeleteCourseInvite}
                onBuy={onBuy}
                onPayNow={onPayNow}
                onPayNowLessonOrder={onPayNowLessonOrder}
                onCourseLessonJoinRequest={onCourseLessonJoinRequest}
                      />
        </Drawer>
    );
};

export default TrainingCourseDrawer;
