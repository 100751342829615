import * as React from "react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuthState } from "../context/Context";
import {
  FrontEndPoints,
  ValidateIsAdmin,
  ValidateIsTrainer,
  ValidateIsStudent,
  ValidateIsUser,
  ValidateIsSchool,
  ValidateIsEventHolder,
  ApiEndpoints,
} from "./Common";
import { Layout, Menu } from "antd";
import "./RoleBasedRoute.scss";
import { useTranslation } from "react-i18next";
import { EqDashboardLayout } from "../shared/EqDashboardLayout";
import MenuItem from "antd/lib/menu/MenuItem";
import EqButton from "../custom/eqButton/EqButton";
import EqNotificationSideBar from "../eqStudent/notificationSideBar/EqNotificationSideBar";
import { Badge } from "antd-v5";
import Api from "./Api";

const RoleBasedRoute = (props: any, state: any) => {
  const { component: Component, ...rest } = props;

  const { t, i18n } = useTranslation();
  const userDetails: any = useAuthState();
  const { Sider, Content } = Layout;
  const [collapsed, setCollapsed] = React.useState(false);
  const [smallScreen, setSmallScreen] = React.useState(false);
  const [unReadCount, setUnReadCount] = React.useState(0);
  const [lang, setLang] = React.useState(false);

  const handleSetCollapse = (e: any) => {
    if (smallScreen) {
      setCollapsed(e);
    }
  };
  const admin_sideBarRouterLinks = [
    {
      icon: "images/icons/dashboard.svg",
      text: t("dashboard"),
      route: FrontEndPoints.admin.dashboard,
    },
    {
      icon: "images/icons/dashboard.svg",
      text: t("approveProfessionals"),
      route: FrontEndPoints.admin.approveProfessionals,
    },
    {
      icon: "images/icons/messages.svg",
      text: t("messages"),
      route: FrontEndPoints.admin.messageBoard,
    },
    {
      icon: "images/icons/financials.svg",
      text: t("financials"),
      route: FrontEndPoints.trainer.paymentBoard,
    },
    {
      icon: "images/icons/search.svg",
      text: t("search"),
      route: FrontEndPoints.trainer.search,
    },
    {
      icon: "images/icons/financials.svg",
      text: t("subscriptionProduct"),
      route: FrontEndPoints.admin.subscriptionProduct,
    },
    {
      icon: "images/icons/financials.svg",
      text: t("subscriptionSetting"),
      route: FrontEndPoints.admin.subscriptionSetting,
    },
    {
      icon: "images/icons/dashboard.svg",
      text: t("subscriptionSetting"),
      route: FrontEndPoints.admin.subscription,
    },
    {
      icon: "images/icons/events.svg",
      text: t("EmailReport"),
      route: FrontEndPoints.admin.emailReport,
    },
    // {
    //   icon: "images/icons/financials.svg",
    //   text: t("subscriptionSettings"),
    //   route: FrontEndPoints.admin.subscriptionSettings,
    // },
    // {
    //   icon: "images/icons/financials.svg",
    //   text: t("createSubscription"),
    //   route: FrontEndPoints.admin.createSubscription,
    // },
  ];
  const trainer_sideBarRouterLinks = [
    {
      icon: "images/icons/dashboard.svg",
      text: t("dashboard"),
      route: FrontEndPoints.trainer.dashboard,
    },
    {
      icon: "images/icons/events.svg",
      text: t("activities"),
      route: FrontEndPoints.trainer.activityBoard,
    },
    {
      icon: "images/icons/student.svg",
      text: t("students"),
      route: FrontEndPoints.trainer.studentBoard,
      //}, {
      //    icon: "images/icons/student.svg",
      //    text: t("edit") + " " + t("Student"),
      //    route: FrontEndPoints.trainer.editStudentBoard
    },
    {
      icon: "images/icons/groups.svg",
      text: t("groups"),
      route: FrontEndPoints.trainer.groupsBoard,
    },
    {
      icon: "images/icons/financials.svg",
      text: t("financials"),
      route: FrontEndPoints.trainer.paymentBoard,
    },
    {
      icon: "images/icons/events.svg",
      text: t("products"),
      route: FrontEndPoints.trainer.productBoard,
    },
    {
      icon: "images/icons/events.svg",
      text: t("findPublicEvents"),
      route: FrontEndPoints.allPublicEvents,
      },
      {
          icon: "images/icons/professionals.svg",
          text: t("horses"),
          route: FrontEndPoints.trainer.taskList,
      },
    //{
    //  icon: "images/icons/professionals.svg",
    //  text: t("horses"),
    //  route: FrontEndPoints.trainer.horses,
    //},
    {
      icon: "images/icons/send-right.svg",
      text: t("invitations"),
      route: FrontEndPoints.trainer.invitations,
    },
    {
      icon: "images/icons/send-right.svg",
      text: t("requests"),
      route: FrontEndPoints.trainer.requests,
    },
    //  {
    //     icon: "images/icons/events.svg",
    //     text: "Trainings",
    //     route: FrontEndPoints.trainer.eventBoard
    // },
    // {
    //   icon: "images/icons/events.svg",
    //   text: "Activity Invitations",
    //   route: FrontEndPoints.trainer.trainerEventInvitationsBoard,
    // },
    {
      icon: "images/icons/messages.svg",
      text: t("messages"),
      route: FrontEndPoints.trainer.messageBoard,
    }, 
    //{
    //    icon: "images/icons/notifications.svg",
    //    text: "Notifications",
    //    route: ""
    // },

    // {
    //   icon: "images/icons/search.svg",
    //   text: t("search"),
    //   route: FrontEndPoints.trainer.search,
    // },
  ];
  const eventHolder_sideBarRouterLinks = [
    {
      icon: "images/icons/dashboard.svg",
      text: t("dashboard"),
      route: FrontEndPoints.eventHolder.dashboard,
    },
    {
      icon: "images/icons/events.svg",
      text: t("activities"),
      route: FrontEndPoints.eventHolder.activityBoard,
    },

    // {
    //   icon: "images/icons/groups.svg",
    //   text: t("Groups"),
    //   route: FrontEndPoints.eventHolder.groupsBoard,
    // },
    //{
    //  icon: "images/icons/financials.svg",
    //  text: t("Financials"),
    //  route: FrontEndPoints.eventHolder.paymentBoard,
    //},
  ];
  const school_sideBarRouterLinks = [
    {
      icon: "images/icons/dashboard.svg",
      text: t("dashboard"),
      route: FrontEndPoints.school.dashboard,
    },
    {
      icon: "images/icons/events.svg",
      text: t("activities"),
      route: FrontEndPoints.school.activityBoard,
    },
    {
      icon: "images/icons/events.svg",
      text: t("products"),
      route: FrontEndPoints.school.productBoard,
    }, {
      icon: "images/icons/events.svg",
      text: t("ridingCourses"),
        route: FrontEndPoints.school.trainingCourse,
    },
    {
      icon: "images/icons/events.svg",
      text: t("findPublicEvents"),
      route: FrontEndPoints.allPublicEvents,
      },
    {
      icon: "images/icons/student.svg",
      text: t("students"),
      route: FrontEndPoints.school.studentBoard,
    },
    {
      icon: "images/icons/student.svg",
      text: t("trainers"),
      route: FrontEndPoints.school.trainerBoard,
    },
    {
      icon: "images/icons/groups.svg",
      text: t("groups"),
      route: FrontEndPoints.school.groupsBoard,
    },
    {
      icon: "images/icons/financials.svg",
      text: t("financials"),
      route: FrontEndPoints.school.paymentBoard,
    },
    {
      icon: "images/icons/professionals.svg",
      text: t("horses"),
        route: FrontEndPoints.trainer.taskList,
      },
    {
      icon: "images/icons/send-right.svg",
      text: t("invitations"),
      route: FrontEndPoints.school.invitations,
    },
    {
      icon: "images/icons/send-right.svg",
      text: t("requests"),
      route: FrontEndPoints.school.requests,
    },
    {
      icon: "images/icons/messages.svg",
      text: t("messages"),
      route: FrontEndPoints.school.messageBoard,
    }, 
  ];
  const student_sideBarRouterLinks = [
    {
      icon: "images/icons/dashboard.svg",
      text: t("dashboard"),
      route: FrontEndPoints.student.dashboard,
    },
    {
      icon: "images/icons/events.svg",
      text: t("myTrainings"),
      route: FrontEndPoints.student.allTrainings,
    },
    {
      icon: "images/icons/events.svg",
      text: t("products"),
      route: FrontEndPoints.student.productBoard,
      },
      {
          icon: "images/icons/events.svg",
          text: t("ridingCourses"),
          route: FrontEndPoints.student.trainingCourse,
      },
    {
      icon: "images/icons/events.svg",
      text: t("findPublicEvents"),
      route: FrontEndPoints.student.publicEvents,
    },
    // {
    //   icon: "images/icons/events.svg",
    //   text: t("publicEventSingle"),
    //   route: FrontEndPoints.student.publicEventSingle,
    // },

    // {
    //   icon: "images/icons/events.svg",
    //   text: t("School Trainings"),
    //   route: FrontEndPoints.student.schoolTrainings,
    // },
    // {
    //   icon: "images/icons/student.svg",
    //   text: t("Students"),
    //   route: FrontEndPoints.student.studentBoard,
    // },
    {
      icon: "images/icons/groups.svg",
      text: t("groups"),
      route: FrontEndPoints.student.groupBoard,
    },
    {
      icon: "images/icons/financials.svg",
      text: t("financials"),
      route: FrontEndPoints.student.paymentBoard,
    },
    // {
    //   icon: "images/icons/events.svg",
    //   text: t("findPublicEvents"),
    //   route: FrontEndPoints.allPublicEvents,
    // },
    {
      icon: "images/icons/student.svg",
      text: t("professionals"),
      route: FrontEndPoints.student.connection,
    },
    {
      icon: "images/icons/professionals.svg",
      text: t("horses"),
        route: FrontEndPoints.trainer.taskList,
    },
    {
      icon: "images/icons/messages.svg",
      text: t("messages"),
      route: FrontEndPoints.student.messageBoard,
      }, 
      // {
      //     icon: "images/icons/events.svg",
      //     text: t("Horse Task"),
      //     route: FrontEndPoints.student.taskList,
      // },
    // {
    //   icon: "images/icons/events.svg",
    //   text: "Trainings",
    //   route: FrontEndPoints.student.trainingBoard,
    // },

    // {
    //   icon: "images/icons/events.svg",
    //   text: "Activity Invitations",
    //   route: FrontEndPoints.student.eventInvitationsBoard,
    // },
    // {
    //   icon: "images/icons/events.svg",
    //   text: "Trainer Invitations",
    //   route: FrontEndPoints.student.trainerInvitationsBoard,
    // },
    // {
    //   icon: "images/icons/messages.svg",
    //   text: t("Messages"),
    //   route: FrontEndPoints.student.messageBoard,
    // },

    // {
    //   icon: "images/icons/search.svg",
    //   text: t("Find professional"),
    //   route: FrontEndPoints.trainer.search,
    // },
    // {
    //   icon: "images/icons/professionals.svg",
    //   text: t("Professionals"),
    //   route: FrontEndPoints.trainer.professionalsBoard,
    // },
  ];




  const navigate = useNavigate();
  function changeLanguage() {
    setLang(!lang);
    i18n.changeLanguage(lang ? "en" : "no");
  }
  const goToTrainerProfileBoard = (): void => {
    if (userDetails && userDetails.key) {
      navigate(`${FrontEndPoints.trainer.profileBoard}?key=${userDetails.key}`);
    }
  };
  const goToEventHolderProfileBoard = (): void => {
    if (userDetails && userDetails.key) {
      navigate(
        `${FrontEndPoints.eventHolder.profileBoard}?key=${userDetails.key}`
      );
    }
  };
  const goToSProfileBoard = (): void => {
    if (userDetails && userDetails.key) {
      navigate(`${FrontEndPoints.school.profileBoard}?key=${userDetails.key}`);
    }
  };
  const goToStudentProfileboard = (): void => {
    if (userDetails && userDetails.key) {
      // navigate(`${FrontEndPoints.student.profileBoard}?key=${userDetails.key}`);
    }
    };
    const [messagesCount, setMessagesCount] = React.useState(0);

    const getMessageCount = async () => {
        Api.get(ApiEndpoints.UserMessage.getUserMessagesCount).then((response: any) => {
            if (response.data) {
                setMessagesCount(response.data);
                console.log("user message count", response.data);
            }
        }, (error: any) => console.log(error))
    }
    React.useEffect(() => { getMessageCount() }, [])




    // const trainerItems = trainer_sideBarRouterLinks.map((d, index) => ({
    //   key: index,
    //   label: (
    //     <Link to={d.route}>
    //       <img src={d.icon} className="link-icon" alt={d.text} />
    //       {d.route === FrontEndPoints.trainer.messageBoard ? (
    //         <Badge count={messagesCount} offset={[15, -5]} color='#FF8838' size="small">
    //           <span className="link-text">{d.text}</span>
    //         </Badge>
    //       ) : (
    //         <span className="link-text">{d.text}</span>
    //       )}
    //     </Link>
    //   ),
    //   onClick: () => {
    //     handleSetCollapse(true);
    //   },
    // }));


  if (ValidateIsAdmin(userDetails)) {
    return (
      <EqDashboardLayout>
        <Layout className="trainer-layout">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              setSmallScreen(broken);
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
            }}
            collapsed={collapsed}
          >
            <div className="eq-sider-inner">
              <img
                src={
                  userDetails.imageUrl?.length
                    ? userDetails.imageUrl
                    : "images/icons/student.svg"
                }
                className="user"
                alt="user"
              />
              <div className="name">{userDetails.name || t("name")}</div>
              <div className="expertize">Admin</div>
              <div
                className="edit"
                onClick={() => {
                  handleSetCollapse(true);
                }}
              >
                <img
                  src="images/icons/edit.svg"
                  className="link-icon"
                  alt="edit"
                />
                <span className="link-text">{t("settings")}</span>
              </div>
              {ValidateIsUser(userDetails) ? (
                <>
                  <EqButton
                    size="small"
                    onClick={() => navigate(FrontEndPoints.logout)}
                    type="button"
                    classNames="round-bordered"
                  >
                    {t("logOut")}
                  </EqButton>
                </>
              ) : (
                <EqButton type="button" classNames="round-bordered">
                  Log In
                </EqButton>
              )}
              <div className="border-line"></div>
              <Menu className="route-links">
                {admin_sideBarRouterLinks.map((d: any, index: any) => (
                  <MenuItem
                    className="route-item"
                    onClick={() => {
                      handleSetCollapse(true);
                    }}
                    key={index}
                  >
                    <Link to={d.route} state={props.history?.location?.state}>
                            <img src={d.icon} className="link-icon" alt={d.text} />
                            {d.route == FrontEndPoints.admin.messageBoard ?
                                <Badge count={messagesCount} offset={[15, -5]} color='#FF8838' size="small">
                                    <span className="link-text">{d.text}</span>
                                </Badge> : <span className="link-text">{d.text}</span>}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Sider>
          <Content>
            <Outlet {...props} />
          </Content>
        </Layout>
      </EqDashboardLayout>
    );
  } else if (ValidateIsTrainer(userDetails)) {
    return (
      <EqDashboardLayout>
        <Layout className="trainer-layout">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              setSmallScreen(broken);
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
            }}
            collapsed={collapsed}
          >
            <div className="eq-sidebar-notification">
              <EqNotificationSideBar />
            </div>
            <div className="eq-sider-inner">
              {/* <Row className="bell-icon">
              <EqNotificationBell/>
              </Row> */}
              <div onClick={goToTrainerProfileBoard}>
                <div className="profile">
                  <img
                    src={
                      userDetails.imageUrl?.length
                        ? userDetails.imageUrl
                        : "images/icons/student.svg"
                    }
                    className="user"
                    alt="user"
                  />

                  <div className="name">{userDetails.name || t("name")}</div>
                </div>
              </div>

              <div className="expertize">{t("certifiedTrainer")}</div>
              {/* 
             <Row justify="center">
             
              <Col><EqButton
            type="button"
            onClick={changeLanguage}
            value={lang ? "en" : "no"}
            classNames="lang-toggle"
          >
            {lang ? "EN" : "NO"}
          </EqButton></Col>
          <Col><EqNotificationBell/></Col>
              </Row>  */}

              <div
                className="edit"
                onClick={() => {
                  handleSetCollapse(true);
                }}
              >
                <Link to={FrontEndPoints.trainer.editTrainer}>
                  <img
                    src="images/icons/edit.svg"
                    className="link-icon"
                    alt="edit"
                  />
                  <span className="link-text">{t("settings")}</span>
                </Link>
              </div>

              {ValidateIsUser(userDetails) ? (
                <>
                  <EqButton
                    size="small"
                    onClick={() => navigate(FrontEndPoints.logout)}
                    type="button"
                    classNames="round-bordered"
                  >
                    {t("logOut")}
                  </EqButton>
                </>
              ) : (
                <EqButton type="button" classNames="round-bordered">
                  {t("logIn")}
                </EqButton>
              )}
              <div className="border-line"></div>

              <Menu className="route-links">
                {trainer_sideBarRouterLinks.map((d: any, index: any) => (
                  <MenuItem
                    className="route-item"
                    key={index}
                    onClick={() => {
                      handleSetCollapse(true);
                    }}
                  >
                    <Link to={d.route}>
                      <img src={d.icon} className="link-icon" alt={d.text} />
                                {d.route == FrontEndPoints.trainer.messageBoard ? 
                                <Badge count={messagesCount} offset={[15, -5]} color='#FF8838' size="small">
                                    <span className="link-text">{d.text}</span>
                                </Badge> : <span className="link-text">{d.text}</span>}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Sider>
          <Content>
            <Outlet {...props} />
          </Content>
        </Layout>
      </EqDashboardLayout>
    );
  } else if (ValidateIsEventHolder(userDetails)) {
    return (
      <EqDashboardLayout>
        <Layout className="trainer-layout">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              setSmallScreen(broken);
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
            }}
            collapsed={collapsed}
          >
            <div className="eq-sidebar-notification">
              <EqNotificationSideBar />
            </div>
            <div className="eq-sider-inner">
              {/* <Row className="bell-icon">
              <EqNotificationBell/>
              </Row> */}
              <div onClick={goToEventHolderProfileBoard}>
                <div className="profile">
                  <img
                    src={
                      userDetails.imageUrl?.length
                        ? userDetails.imageUrl
                        : "images/icons/student.svg"
                    }
                    className="user"
                    alt="user"
                  />

                  <div className="name">{userDetails.name || t("name")}</div>
                </div>
              </div>

              <div className="expertize">Event Holder</div>
              {/* 
             <Row justify="center">
             
              <Col><EqButton
            type="button"
            onClick={changeLanguage}
            value={lang ? "en" : "no"}
            classNames="lang-toggle"
          >
            {lang ? "EN" : "NO"}
          </EqButton></Col>
          <Col><EqNotificationBell/></Col>
              </Row>  */}

              <div
                className="edit"
                onClick={() => {
                  handleSetCollapse(true);
                }}
              >
                <Link to={FrontEndPoints.eventHolder.editEventHolder}>
                  <img
                    src="images/icons/edit.svg"
                    className="link-icon"
                    alt="edit"
                  />
                  <span className="link-text">{t("settings")}</span>
                </Link>
              </div>

              {ValidateIsUser(userDetails) ? (
                <>
                  <EqButton
                    size="small"
                    onClick={() => navigate(FrontEndPoints.logout)}
                    type="button"
                    classNames="round-bordered"
                  >
                    {t("logOut")}
                  </EqButton>
                </>
              ) : (
                <EqButton type="button" classNames="round-bordered">
                  {t("logIn")}
                </EqButton>
              )}
              <div className="border-line"></div>

              <Menu className="route-links">
                {eventHolder_sideBarRouterLinks.map((d: any, index: any) => (
                  <MenuItem
                    className="route-item"
                    key={index}
                    onClick={() => {
                      handleSetCollapse(true);
                    }}
                  >
                    <Link to={d.route}>
                            <img src={d.icon} className="link-icon" alt={d.text} />
                            {d.route == FrontEndPoints.trainer.messageBoard ?
                                <Badge count={messagesCount} offset={[15, -5]} color='#FF8838' size="small">
                                    <span className="link-text">{d.text}</span>
                                </Badge> : <span className="link-text">{d.text}</span>}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Sider>
          <Content>
            <Outlet {...props} />
          </Content>
        </Layout>
      </EqDashboardLayout>
    );
  } else if (ValidateIsSchool(userDetails)) {
    return (
      <EqDashboardLayout>
        <Layout className="trainer-layout">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              setSmallScreen(broken);
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
            }}
            collapsed={collapsed}
          >
            <div className="eq-sidebar-notification">
              <EqNotificationSideBar />
            </div>
            <div className="eq-sider-inner">
              {/* <Row className="bell-icon">
              <EqNotificationBell/>
              </Row> */}
              <div onClick={goToSProfileBoard}>
                <div className="profile">
                  <img
                    src={
                      userDetails.imageUrl?.length
                        ? userDetails.imageUrl
                        : "images/icons/student.svg"
                    }
                    className="user"
                    alt="user"
                  />

                  <div className="name">{userDetails.name || t("name")}</div>
                </div>
              </div>

              <div className="expertize">{t("school")}</div>
              {/* 
             <Row justify="center">
             
              <Col><EqButton
            type="button"
            onClick={changeLanguage}
            value={lang ? "en" : "no"}
            classNames="lang-toggle"
          >
            {lang ? "EN" : "NO"}
          </EqButton></Col>
          <Col><EqNotificationBell/></Col>
              </Row>  */}

              <div
                className="edit"
                onClick={() => {
                  handleSetCollapse(true);
                }}
              >
                <Link to={FrontEndPoints.school.editSchool}>
                  <img
                    src="images/icons/edit.svg"
                    className="link-icon"
                    alt="edit"
                  />
                  <span className="link-text">{t("settings")}</span>
                </Link>
              </div>

              {ValidateIsUser(userDetails) ? (
                <>
                  <EqButton
                    size="small"
                    onClick={() => navigate(FrontEndPoints.logout)}
                    type="button"
                    classNames="round-bordered"
                  >
                    {t("logOut")}
                  </EqButton>
                </>
              ) : (
                <EqButton type="button" classNames="round-bordered">
                  {t("logIn")}
                </EqButton>
              )}
              <div className="border-line"></div>

              <Menu className="route-links">
                {school_sideBarRouterLinks.map((d: any, index: any) => (
                  <MenuItem
                    className="route-item"
                    key={index}
                    onClick={() => {
                      handleSetCollapse(true);
                    }}
                  >
                    <Link to={d.route}>
                            <img src={d.icon} className="link-icon" alt={d.text} />
                            {d.route == FrontEndPoints.school.messageBoard ?
                                <Badge count={messagesCount} offset={[15, -5]} color='#FF8838' size="small">
                                    <span className="link-text">{d.text}</span>
                                </Badge> : <span className="link-text">{d.text}</span>}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Sider>
          <Content>
            <Outlet {...props} />
          </Content>
        </Layout>
      </EqDashboardLayout>
    );
  } else if (ValidateIsStudent(userDetails)) {
    return (
      <EqDashboardLayout>
        <Layout className="trainer-layout">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              setSmallScreen(broken);
            }}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
            }}
            collapsed={collapsed}
          >
            <div className="eq-sidebar-notification">
              <EqNotificationSideBar />
            </div>
            <div className="eq-sider-inner">
              {/* <Row className="bell-icon">
              <EqNotificationBell/>
              
              </Row> */}
              <div onClick={goToStudentProfileboard} className="profile">
                <img
                  src={
                    userDetails.imageUrl?.length
                      ? userDetails.imageUrl
                      : "images/icons/student.svg"
                  }
                  className="user"
                  alt="user"
                />
                <div className="name">{userDetails.name || t("name")}</div>
              </div>
              <div className="expertize">
                {userDetails.userType == 10 ? "Parent" : t("student")}
              </div>
              <div
                className="edit"
                onClick={() => {
                  handleSetCollapse(true);
                }}
              >
                <Link to={FrontEndPoints.student.editProfile}>
                  <img
                    src="images/icons/edit.svg"
                    className="link-icon"
                    alt="edit"
                  />
                  <span className="link-text">{t("settings")}</span>
                </Link>
              </div>

              {ValidateIsUser(userDetails) ? (
                <>
                  <EqButton
                    size="small"
                    onClick={() => navigate(FrontEndPoints.logout)}
                    type="button"
                    classNames="round-bordered"
                  >
                    {t("logOut")}
                  </EqButton>
                </>
              ) : (
                <EqButton type="button" classNames="round-bordered">
                  {t("logIn")}
                </EqButton>
              )}
              <div className="border-line"></div>

              {/* <Menu className="route-links" items={trainerItems} /> */}
              <Menu className="route-links">
                {student_sideBarRouterLinks.map((d: any, index: any) => (
                  <MenuItem
                    className="route-item"
                    onClick={() => {
                      handleSetCollapse(true);
                    }}
                    key={index}
                  >
                    <Link to={d.route} state={props.history?.location?.state}>
                            <img src={d.icon} className="link-icon" alt={d.text} />
                            {d.route == FrontEndPoints.student.messageBoard ?
                                <Badge count={messagesCount} offset={[15, -5]} color='#FF8838' size="small">
                                    <span className="link-text">{d.text}</span>
                                </Badge> : <span className="link-text">{d.text}</span>}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Sider>
          <Content>
            <Outlet {...props} />
          </Content>
        </Layout>
      </EqDashboardLayout>
    );
  } else {
    console.log("navigating to /login from role based routing");

    return <Navigate to={FrontEndPoints.login} state={props.location} />;
  }
};

export default RoleBasedRoute;
