import { Col, Row } from "antd";
import { Empty, Input, message, Modal } from "antd-v5";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EqButton from "../../../../custom/eqButton/EqButton";
import EqCollapsePanel from "../../../../custom/eqCollapsePanel/EqCollapsePanel";
import {EqInput} from "../../../../custom/eqInput/EqInput";
import "./CourseOrderdetails.scss";
import { CourseUI, EqOrderStatus } from '../../../../shared/interfaces/ridingCourse/IRidingCourse';



interface ICourseOrderDetails {
    editCourse?: CourseUI | null
    filteredStudent : any[];
    onRemoveCourseOrder?: (order: any, refund: boolean, refundAmount?: number) => void;

}

const CourseOrderDetails: React.FC<ICourseOrderDetails> = (
    {
        editCourse,
        filteredStudent,
        onRemoveCourseOrder
    }
) => {
    const { t, i18n } = useTranslation();
    const StatusLabel = {
        0: "Pending", 
        1: "Accepted", 
        2: "Rejected",
        3: "Paid",
        4: "Requested",
        5: "Waiting"
    }
    const [courseorderList, setcourseorderList] = React.useState(editCourse?.courseLessonOrders || []);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [order, setOrder] = useState<string>("");
        const [refundAmount, setRefundAmount] = useState<string>("");
    
    useEffect(() => {
        setcourseorderList(editCourse?.courseLessonOrders || []);
        
    }, [editCourse]);
    const closeModal = () => {
        setIsModalOpen(false);
        setRefundAmount("");
        setOrder("");
    };

    const openModal = () => {
        setIsModalOpen(true);
    };
    const handlePayLater = () => {
        if (onRemoveCourseOrder) onRemoveCourseOrder(order, false, 0);
        closeModal();
    };
    const handleRefund = () => {
        const amount = Number(refundAmount);
        if (!refundAmount || isNaN(amount) || amount <= 0) {
            message.error(t("Please enter a valid refund amount"));
            return;
        }
        if (onRemoveCourseOrder) onRemoveCourseOrder(order, true, amount);
        closeModal();
    };

    const handleOnclickRemoveAttendee = (order: any) => {
        
        if (order.id !== undefined) {
            if (order.orderStatus === EqOrderStatus.Paid) {
                setOrder(order);
                openModal();
            } else {
                onRemoveCourseOrder && onRemoveCourseOrder(order, false, 0);
            }

            // handle remove attendee
        }
        else{
          return;
        }
    
    };

    return (
        <>
        <Col xs = { 24 } sm = { 24 } md = { 24 } lg = {24} className="Main-course-information">
              
                   
                    <Row>
                            {editCourse?.courseLessonOrders != null ? (
                                <>
                                        <div className="eq-pbo-container">
                                            <Row className="eq-pbo-h-row">
                                                <Col className="eq-sl-th" span={5} >
                                                    {t("student")}
                                                </Col>
                                                <Col className="eq-sl-th" span={6} >
                                                    {t("email")}
                                                </Col>
                                                <Col className="eq-sl-th" span={5} >
                                                    {t("title")}
                                                </Col>
                                                <Col className="eq-sl-th eq-sl-th-status" span={5} >
                                                    {t("status")}
                                                </Col>
                                                <Col className="eq-sl-th eq-sl-th-status" span={3} >
                                                    {t("action")}
                                                </Col>
                                            </Row>                      


                                    {(filteredStudent.length > 0 ? filteredStudent :editCourse?.courseLessonOrders).filter(fo=>(fo.orderStatus===EqOrderStatus.Accepted || fo.orderStatus=== EqOrderStatus.Paid))

                                    // .filter((ol: any) => ol.orderStatus === 0)
                                                .map((o: any | object) => (

                                                    <Row className="eq-pbo-row" key={o.id}>
                                                        <Col className="eq-sl-th" span={5}>
                                                            {o.student?.firstName + " " + o.student?.lastName}
                                                        </Col>
                                                        <Col className="eq-sl-th" span={6}>
                                                            {o.student?.email}
                                                        </Col>
                                                        <Col className="eq-sl-th" span={5}>
                                                            {editCourse?.title}
                                                        </Col>
                                                        <Col className="eq-sl-th eq-sl-th-status" span={5}>
                                                            {typeof o.orderStatus === 'number' && o.orderStatus in StatusLabel
                                                                ? StatusLabel[o.orderStatus as keyof typeof StatusLabel]
                                                                : 'Unknown Status'}
                                                        </Col>
                                                              <Col md={3} className="Delete-Icon">
                                                                                                <span>
                                                                                                    <img
                                                                                                        alt=""
                                                                                                        // src="/trash-alt.svg"
                                                                                                        src="/trash-alt-red.svg"
                                                                                                        onClick={() => handleOnclickRemoveAttendee(o)}
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        />
                                                                                                </span>
                                                                                            </Col>
                                                    </Row>
                                                ))

                                            }
                                        </div>
                                </>
                            ) : (
                                <Empty description={String(t("noDataAvailable"))} />)
                            }
                    </Row>
                
            </Col>

            
            <Modal
                title={t("confirm")}
                className="refund-confirm-modal"
                open={isModalOpen}
                onCancel={closeModal}
                footer={null} // Custom footer
                closable={true}
                // closeIcon={<span style={{ fontSize: '16px', color: '#000' }}>X</span>}
            >
                <div>
                    <p>{t("refundAttendee")}</p>
                    <Input
                        type="number"
                        placeholder={t("amount")}
                        min={0}
                        value={refundAmount}
                        onChange={(e) => setRefundAmount(e.target.value)}
                        style={{ marginBottom: "10px" }}
                    />
                </div>

                <Row style={{ padding: "2px" }} justify={"end"}>
                    {/* Refund Button */}
                    <EqButton style={{ marginRight: "2px" }} bordered onClick={handleRefund}>
                        {t("refund")}
                    </EqButton>

                    {/* Pay Later Button */}
                    <EqButton onClick={handlePayLater}>{t("payLater")}</EqButton>
                </Row>
            </Modal>

                            </>
      
    )
}

export default CourseOrderDetails;