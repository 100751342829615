import * as React from "react";
import "./EqPublicEventPreviousBoard.scss";
import { Row, Col, Skeleton, Empty } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsTrainer,
  ValidateIsSchool,
  openNotificationWithIcon,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { useTranslation } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import { Drawer, Spin } from "antd-v5";
import EqCreateOrEditEvent from "../../../eqComponents/publicEvents/eqCreateorEditEvent/EqCreateOrEditEvent";

import { eqNotificationTypes } from "../../shared/enums";
import EqPublicEventCard from "../../../eqComponents/eqPublicEventCard/EqPublicEventCard";

import { useNavigate } from "react-router-dom";
export interface IEqPublicEventPreviousBoard {
  updateCount?: (count: number) => void;
  activeEvent?: any;
}
const EqPublicEventPreviousBoard = ({ updateCount, activeEvent }: IEqPublicEventPreviousBoard) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [creatorUser, setCreatorUser] = React.useState([]) as any;
  const [stripeAccountInfo, setStripeAccountInfo] = React.useState() as any;

  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [population, setPopulation] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;


  const [imagesData, setImagesData] = React.useState([]) as any;

  const [CreatePublicEventShow, setCreatePublicEventShow] =
    React.useState(false);

  const [eventsList, setEventsList] = React.useState(null) as any;
  const [editEvent, setEditEvent] = React.useState<any | null>(null); // State to track selected event ID for editing
  const [showEditDrawer, setShowEditDrawer] = React.useState(false); // State to manage drawer visibility


  const [loading, setLoading] = React.useState(false);



  React.useEffect(() => {
    getImages();
    getAreas();
    GetUserPublicEventsPrevious();
    GetStripeAccount();

  }, []);

  const GetStripeAccount = () => {
    Api.get(ApiEndpoints.StripeConnectAccount.Get).then(
        (response) => {
            if (response.status === 200) {
                setStripeAccountInfo(response.data);
            }
        },
        (error) => {
            console.log(error);
        }
    );
};

  const GetUserPublicEventsPrevious = async () => {
  

    let path = ApiEndpoints.EqOwnerPublicEvent.GetAllPrevious;
 
    Api.get(path).then(
      (response) => {
        if (response && response.status === 200) {
          var data = response.data ? [...response.data] : new Array<any>();

        
                // Sort events: First, those with isDraft = true, then by createdDate
        const sortedEvents = data.sort((a, b) => {
          // Check if either event has isDraft as true
          if (a.isDraft && !b.isDraft) return -1; // a should come first
          if (!a.isDraft && b.isDraft) return 1;  // b should come first

          // If neither or both have isDraft as true, sort by createdDate
     
          return b.createdDate - a.createdDate;  // Sort by descending order (most recent first)
        });

          setEventsList(sortedEvents);
          console.log("EventListObject", data)


        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };


  // const handleCreateClick = () => {

  //   setCreatePublicEventShow(true);

  //   // Check if there's an existing draft event
  //   // const draftEvent = eventsList?.find((event: any) => event.isDraft);

  //   // if (draftEvent) {
  //   //   // Open the drawer with the draft event for editing
  //   //   setEditEvent(draftEvent); // Save the event to state for editing
  //   //   setShowEditDrawer(true);  // Open the drawer
  //   // } else {
  //   //   // No draft exists, proceed to create a new event
  //   //   setCreatePublicEventShow(true);
  //   // }
  // };


 



  const removeItem = (eventId: any) => {
    if (eventsList !== undefined) {
      var updatedList =
        eventsList !== undefined
          ? eventsList.filter((item: any) => item.id !== eventId)
          : new Array<any>();
      setEventsList(updatedList);


    }
  };
  const handleOnCreateEvent = async (data: any) => {
    try {
      // Call the API to create the event
      const response = await Api.post(ApiEndpoints.EqOwnerPublicEvent.Create, data);
  
      if (response && response.status === 200) {
        const newEvent = response.data;
  
   
        // Clone the events list
        const events = [...eventsList];
  
        // If the new event is a draft, replace any existing draft in the list
        if (newEvent.isDraft) {

               // Display notification based on creation
          openNotificationWithIcon(
            t('saved'),
            t('draftSaved'),
            eqNotificationTypes.success
          );
  
          const draftIndex = events.findIndex(event => event.isDraft);
  
          if (draftIndex !== -1) {
            // Replace existing draft
            events[draftIndex] = newEvent;
          } else {
            // If no draft exists, just add the new event
            events.push(newEvent);
          }
        } else {
          // If it's not a draft, simply add the new event to the list
          events.push(newEvent);

               // Display notification based on creation
        openNotificationWithIcon(
          t('created'),
          t('eventCreated'),
          eqNotificationTypes.success
        );
  
        }

           // Update the event list state
           setEventsList(events);

        if (!newEvent.isDraft) {
            // Navigate to /eventSuccess with event ID and title as query params
            navigate(`/eventSuccess`, {
                state: {
                    eventId: newEvent.id,
                    eventTitle: newEvent.title,
                },
            });
        }


  
     
  
        // Optionally update the event count
        if (updateCount) {
          updateCount(events.length);
        }
  
        // Close the modal or form for creating a public event
        setCreatePublicEventShow(false);
      } else {
        // Show error notification if response is not successful
        openNotificationWithIcon(
          t('serverError'),
          t('generalServerError')
        );
      }
    } catch (error) {
      // Handle errors
      openNotificationWithIcon(
        t('serverError'),
        t('generalServerError')
      );
      console.error('Error creating or updating event:', error);
    }
  };
  

  const handleOnUpdateEvent = async (data: any) => {
    try {
      // Replace with your API endpoint and method
      const response = await Api.put(ApiEndpoints.EqOwnerPublicEvent.Update, data);
      if (response && response.status === 200) {
        const updatedEvent = response.data;
  
        // Find the event in the list
        const events = [...eventsList];
        const foundIndex = events.findIndex((x) => x.id === updatedEvent.id);
        
        if (foundIndex !== -1) {
          const prevEvent = events[foundIndex];
  
          // Check draft status before and after update to show appropriate notification
          if (prevEvent.isDraft && !updatedEvent.isDraft) {
            openNotificationWithIcon(t('created'), t('eventCreated'), eqNotificationTypes.success);
          } else if (updatedEvent.isDraft) {
            openNotificationWithIcon(t("updated"), t("draftUpdated"), eqNotificationTypes.success);
          } else {
            openNotificationWithIcon(t('updated'), t('eventUpdated'), eqNotificationTypes.success);
          }
  
          // Update the event in the list
          UpdateEventList(updatedEvent);


          if (prevEvent.isDraft && !updatedEvent.isDraft) {
            // Navigate to /eventSuccess with event ID and title as query params
            navigate(`/eventSuccess`, {
                state: {
                    eventId: updatedEvent.id,
                    eventTitle: updatedEvent.title,
                },
            });
        }
        }
      } else {
        openNotificationWithIcon(t('serverError'), t('generalServerError'), eqNotificationTypes.error);
      }
    } catch (error) {
      console.error('Error creating or updating event:', error);
      openNotificationWithIcon(t('serverError'), t('generalServerError'), eqNotificationTypes.error);
    }
  };
  
  const UpdateEventList = (updatedEvent: any) => {
    // Only update if eventsList is defined
    if (eventsList) {
      const updatedEvents = eventsList.map((event:any) => 
        event.id === updatedEvent.id ? updatedEvent : event
      );
      setEventsList(updatedEvents);
    }
  
    // If editing the event, update the edit state
    if (editEvent?.id === updatedEvent.id) {
      setEditEvent(updatedEvent);
    }
  
    // Update count if the function is provided
    if (updateCount) {
      updateCount(eventsList.length);
    }
  };
  

  const DeleteEvent = (eventId: any) => {
    
    Api.delete(`${ApiEndpoints.EqOwnerPublicEvent.Delete}/${eventId}`).then(
      (response) => {
        if (response && response.status === 200) {
          openNotificationWithIcon(
            t('deleted'),
            t('eventDeleted'),
            eqNotificationTypes.success
          );
          removeItem(eventId);
        } else {
          setIsError(true);
          // setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        if(error?.response?.status===412){
          openNotificationWithIcon(
            t('Conflict'),
            t('Cannot delete the event as it has associated class orders.')
        );
        }else{
          openNotificationWithIcon(
            t('serverError'),
            t('generalServerError')
        );
        }
        setIsError(true);
        // setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

 

  const RemoveAttendeeClassOrderWithAmount = (orderId: any, refund: boolean, refundAmount?: number) => {
    Api.delete(`${ApiEndpoints.EqOwnerPublicEvent.RemoveAttendeeClassOrderWithAmount}/${orderId}?refund=${refund}&refundAmount=${refundAmount}`)
      .then((response) => {
        

        if (response && response.status === 200) {
          openNotificationWithIcon(
            t('removed'),
            t('attendeeRemoved'),
            eqNotificationTypes.success
          );

          UpdateEventList(response.data)

        } else {
          setIsError(true);
          // Optionally handle error messages here
          // setErrorMessage(GetEventMessage(response.status));
        }
      })
      .catch((error) => {
        setIsError(true);
        // Optionally handle error messages here
        openNotificationWithIcon(
          t('serverError'),
          t('generalServerError'));
      });
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.Location.GetUserLocationsOptions).then(
      (response) => {
        if (response && response.status === 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const updateImageLibrary = (newImage: any) => {
    setImagesData((prevImages: any) => [...prevImages, newImage]);
  };

  const removeFromImageLibrary = (imageId: any) => {
    setImagesData((prevImages: any) => prevImages.filter((image: any) => image.id !== imageId));
  };

  const getImages = () => {
    Api.get(ApiEndpoints.Image.GetUserImages).then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setMessage("Error Occurred while fetching images from server");
      }
    );
  };





 
  





  // Function to handle the editing of an event
  const handleEditEvent = (eventId: string) => {
    const selectedEvent = eventsList.find((event: any) => event.id === eventId);
    if (selectedEvent) {
      setEditEvent(selectedEvent); // Set the selected event ID
      setShowEditDrawer(true); // Open the drawer
    } else {
      console.error("Event not found");
    }

  };


  // Handle duplicate event action
  // const handleDuplicateEvent = (event: any) => {
  //   const duplicatedEvent = { ...event, id: undefined, title: `${event.title} (Duplicate)` };
  //   setEditEvent(duplicatedEvent);
  //   setShowEditDrawer(true);
  // };


  const handleDuplicateEvent = (event: any) => {
    const duplicatedEvent = {
      ...event,
      id: undefined,
      title: `${event.title}`,
      alternatives: event.alternatives.map((alternative: any) => ({
        ...alternative,
        classList: alternative.classList.map((classItem: any) => ({
          ...classItem,
          classOrders: [], // Clear the class orders
        })),
      })),
    };
    setEditEvent(duplicatedEvent);
    setShowEditDrawer(true);
  };
  

  // Function to close the edit drawer
  const handleCloseEditDrawer = () => {
    setEditEvent(null);
    setShowEditDrawer(false);
  };

  return (
    <div className={`eq-public-event-board`}>
      <div className="">
        <Spin spinning={loading} size="large">

         

          <Row className="eq-event-heading-line"></Row>


          <Row>
            {[...Array(5)].map((_, index) => (
              <Skeleton key={index} loading={!eventsList} paragraph={{ rows: 2 }} active />
            ))}
          </Row>
          {eventsList && eventsList.length > 0 && (

            <Row gutter={[16, 16]} className="event-card-list">
              {eventsList.map((eventData: any) => (
                <Col xs={24} sm={12} xl={8} xxl={6} key={eventData.id}>
                  
                  <EqPublicEventCard
                    eventData={eventData}
                    // onEdit={() => handleEditEvent(eventData.id)}
                    // onDelete={() => DeleteEvent(eventData.id)}
                    onDuplicate={() => handleDuplicateEvent(eventData)}
                    onCardClick={() => navigate(`/publicEventSingle?id=${eventData.id}`)}

                  />
                </Col>
              ))}
            </Row>

     
          )
          }





          {/* <Drawer
            closable={false}
            width={1000}
            open={showEditDrawer}
            children={
              <EqCreateOrEditEvent
                imagesData={imagesData}
                creatorUser={creatorUser}
                stripeAccountInfo={stripeAccountInfo}
                areas={areas}
                eventData={editEvent}
                // onCreateEvent={handleOnCreateEvent}
                onEditEvent={handleOnUpdateEvent}
                onRemoveClassOrder={RemoveAttendeeClassOrderWithAmount}
          
                onCloseEvent={handleCloseEditDrawer}
                onImageUploadSuccess={updateImageLibrary}
                onImageDeleteSuccess={removeFromImageLibrary}

              />
            }
          /> */}

          {/* Edit event */}
          <Drawer
            closable={false}
            width={1000}
            open={showEditDrawer}
            children={
              <EqCreateOrEditEvent
                imagesData={imagesData}
                creatorUser={creatorUser}
                stripeAccountInfo={stripeAccountInfo}
                areas={areas}
                eventData={editEvent}
                onCreateEvent={handleOnCreateEvent}
                onEditEvent={handleOnUpdateEvent}
                onRemoveClassOrder={RemoveAttendeeClassOrderWithAmount}
          
                onCloseEvent={handleCloseEditDrawer}
                onImageUploadSuccess={updateImageLibrary}
                onImageDeleteSuccess={removeFromImageLibrary}

              />
            }
          />




        </Spin>
      </div>

    </div>
  );
};
export default EqPublicEventPreviousBoard;
