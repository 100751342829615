//import React from "react"
//import EqSchoolTrainingCollapse from "../../eqSchoolTrainingCollapse/EqSchoolTrainingCollapse";
//import { ValidateIsTrainer, addDays } from "../../../shared/Common";
//import { useAuthState } from "../../../context/Context";
//import { EqEventUserType } from "../../../shared/enums";
//export interface ISchoolTrainingList {
//    trainingList: any;
//    trainersOptions: any;
//    productOptions: any;
//    products: any;
//    areas?: any;
//    weekDays?: any;
//    eventTypes: any;
//    population?: any;
//    groupPopulation?: any;
//    trainerStudents?: any;
//    studentHorsesList?: any;
//    schoolStudents?: any;
//    // if trainer
//    trainerSchoolStudents?: any;
//    schoolStudentHorsesList?: any;
//    SchoolsTrainersOptionsList?: any;

//    removeEventFunc?: (trainingData: any, refund?: any) => void;
//    editEventFunc?: (data: any) => void;
//    removeAttendeeFunc?: (data: any) => void;
//    onCreateOrUpdateSchoolTraining?: (data: any, oldData?: any) => void;
//}
//const InformationRidingCourse: React.FC<ISchoolTrainingList> = ({
//    trainingList,
//    trainersOptions,
//    productOptions,
//    products,
//    areas,
//    weekDays,
//    eventTypes,
//    population,
//    groupPopulation,
//    trainerStudents,
//    studentHorsesList,
//    schoolStudents,
//    trainerSchoolStudents,
//    schoolStudentHorsesList,
//    SchoolsTrainersOptionsList,
//    removeEventFunc,
//    editEventFunc,
//    removeAttendeeFunc,
//    onCreateOrUpdateSchoolTraining,
//    ...rest
//}: ISchoolTrainingList) => {
//    const userDetails: any = useAuthState();
//    const isTrainer: boolean = ValidateIsTrainer(userDetails);
//    const GetSchoolTrainers = (organizers: any): [] => {
//        let value = [];
//        const ownerOrganizer = organizers.find(
//            (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
//        );

//        if (SchoolsTrainersOptionsList) {
//            value = SchoolsTrainersOptionsList.filter((x: any) => {
//                if (x.key === ownerOrganizer.user.email) {
//                    var result = x.trainersOptions;
//                    return [...result];
//                }
//            });
//        }

//        if (value.length > 0) {
//            return value[0].trainersOptions;
//        } else {
//            return null;
//        }
//    };
//    const GetSchoolProductOption = (item: any): [] => {
//        let value = [] as any;

//        if (item.product) {
//            let obj = {
//                key: item.productId,
//                value: item.product.title,
//                label: item.product.title,
//                limit: item.product.limit,
//            };
//            value.push(obj)
//        }

//        return value;

//    };
//  return (
//    <>
//      {/* Render all training courses without categorization */}
//      {trainingList?.map((item: any, index: number) => (
//        <EqSchoolTrainingCollapse
//          key={index}
//          training={item}
//          trainersOptions={isTrainer ? GetSchoolTrainers(item.organizers) : trainersOptions}
//          productOptions={isTrainer ? GetSchoolProductOption(item) : productOptions}
//        />
//      ))}
//    </>
//  );
//};
//export default InformationRidingCourse;















import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISchoolStudents, IStudentHorseList, ITrainerOption } from '../../../../shared/interfaces/ridingCourse/IRidingCourse';
import SchoolCourseLessonList from '../CourseLessonList/SchoolCourseLessonLisr';

export interface ILessonRidingCourse {
    onEditLesson?: (data: any) => void;
    onRemoveLessonOrder?: (lessonId:string,order: any, refund: boolean, refundAmount?: number) => void;

    trainings: Array<Object>;
    weekDays: Object;
    schoolStudentsData?: ISchoolStudents[];
    studentHorsesList?: IStudentHorseList[];

    trainersOptions: ITrainerOption;
    editCourse: any;
    isStudent?: boolean;
    onCourseLessonJoinRequest?: (lessonId:string) => void;
    onPayNowLessonOrder?: (orderId:string) => void;

}


const LessonRidingCourse: React.FC<ILessonRidingCourse> = ({
    onEditLesson,
    trainings,
    weekDays,
    schoolStudentsData,
    studentHorsesList,
    trainersOptions, 
    editCourse,
    onRemoveLessonOrder, 
    isStudent,
    onCourseLessonJoinRequest,
    onPayNowLessonOrder
}) => {


    const { t } = useTranslation();

    return (
        <div>
            <h2>{t("listOfLessons")}</h2>
            <SchoolCourseLessonList
            isStudent={isStudent}
                trainings={trainings}
                editCourse={editCourse}
                schoolStudentsData={schoolStudentsData}
                studentHorsesList={studentHorsesList}
                trainersOptions={trainersOptions}
                weekdays={weekDays}
                onEditLesson={onEditLesson}
                onRemoveLessonOrder={onRemoveLessonOrder}
                onCourseLessonJoinRequest={onCourseLessonJoinRequest}
                onPayNowLessonOrder={onPayNowLessonOrder}
            />
        </div>
    );
};

export default LessonRidingCourse;

