import React from "react";
import { useEffect, useState } from "react";
import "./EqStudentDashboard.scss";
import { Modal, Spin, Tabs } from "antd-v5";
import { Row, Col, Skeleton, DatePicker } from "antd";
import { useTranslation } from "react-i18next";

import Api from "../../shared/Api";
import {
    CourseOrderType,
    EqDisciplineType,
    EqEventUserType,
    eqNotificationTypes,
    EqTrainingType,
} from "../../shared/enums";
import { ApiEndpoints, GetEventMessage, openNotificationWithIcon } from "../../shared/Common";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import EqEventInvitationBoard from "../eventInvitationBoard/EqEventInvitationBoard";
import EqTrainerInvitationBoard from "../trainerInvitationBoard/EqTrainerInvitationBoard";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqProductInvitationBoard from "../productInvitationBoard/EqProductInvitationBoard";
import EqSchoolInvitationBoard from "../../schoolInvitation/EqSchoolInvitationBoard";
import StudentOverViewProfile from "../studentOverViewProfile/StudentOverViewProfile";
import EqSelect from "../../custom/eqSelect/EqSelect";
import EqButton from "../../custom/eqButton/EqButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { ISelectProps } from "../../shared/Interfaces";
import EqActivityTrainingStudentDashboard from "../eqActivityTrainingStudentDashboard/EqActivityTrainingStudentDashboard";
import moment from "moment";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import EqAcceptedTrainingRequests from "../EqAcceptedTrainingRequests/EqAcceptedTrainingRequests";
import EqCourseInviteSection from "../CourseInviteSection/EqCourseInviteSection";

import {
    CourseLessonOrderEnt,
    EqEntityEventUserGroup,
    StudentUnpaidOrdersInfoDto,
} from "../../shared/general/IStudentUnpaidOrders";
import { EqIconCalendar, EqIconPrice } from "../../custom/eqIcon/EqIcons";
import EtTag from "../../../eqComponents/eqCustom/EtTag/EtTag";

const EqStudentDashboard = (props: any) => {
    const [unpaidOrders, setUnpaidOrders] = React.useState<StudentUnpaidOrdersInfoDto>();

    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const userDetails: any = useAuthState();
    const { t, i18n } = useTranslation();
    const [messageString, setMessageString] = useState("");
    const [isError, setIsError] = React.useState(false);
    const [activities, setActivities] = React.useState([] as Array<any>);

    const [trainings, setTrainings] = React.useState(null) as any;
    const [events, setEvents] = React.useState([] as Array<any>); //Array<IEqEventData>
    const [studentEventClasses, setStudentEventClasses] = React.useState(null) as any; //Array<IEqEventData>
    const [studentProductOrders, setStudentProductsOrders] = React.useState([] as Array<any>); //Array<IEqEventData>
    const [activityInvitation, setActivityInvitation] = useState(true);
    const [acceptedRequests, setAcceptedRequests] = useState(true);
    const [productOrderInvitation, setProductOrderInvitation] = useState(true);
    const [trainerInvitation, setTrainerInvitation] = useState(true);
    const [schoolInvitation, setSchoolInvitation] = useState(true);
    const [subscribedTrainersList, setSubscribedTrainersList] = useState([]);
    const [subscribedSchoolsList, setSubscribedSchoolsList] = useState([]);
    const [courseInvitation, setCourseInvitation] = useState(true);

    const [joinedTrainings, setJoinedTrainings] = React.useState(null) as any;
    const [stateView, setStateView] = useState({
        totalProfessionals: [],
        unpaidTraining: [],
        unpaidProduct: [],
        upcomingTraining: [],
    });
    //filter
    const [selectedTraining, setSelectedTraining] = React.useState([] as any);
    const [trainingOptions, setTrainingOptions] = React.useState([] as any);
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();

    const GetCourseOrderCheckoutSession = async (orderId: any) => {
        try {
            let path = "api/CoursePayment/GetCourseOrderCheckoutSession" + `/${orderId}`;
            // Replace with your API endpoint and method
            const response = await Api.get(path);
            if (response && response.status === 200) {
                window.location.href = response.headers.location;
            }
            // Optionally handle the result
        } catch (error: any) {
            if (error.response.status === 409) {
                // already exist
                openNotificationWithIcon("Error!", error.response.data, eqNotificationTypes.error);
                // setIsEventSubscribed(false);
            } else {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                console.error("Error Buy Course:", error);
            }
        }
    };

    
    const GetCourseLessonOrderCheckoutSession = async (orderId: any) => {
        try {
            let path = "api/CoursePayment/GetCourseLessonOrderCheckoutSession" + `/${orderId}`;
            // Replace with your API endpoint and method
            const response = await Api.get(path);
            if (response && response.status === 200) {
                window.location.href = response.headers.location;
            }
            // Optionally handle the result
        } catch (error: any) {
            if (error.response.status === 409) {
                // already exist
                openNotificationWithIcon("Error!", error.response.data, eqNotificationTypes.error);
                // setIsEventSubscribed(false);
            } else {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                console.error("Error Buy Course:", error);
            }
        }
    };

    const CreateTrainingLessonOrderCheckoutSession = (event: any) => {
        var eventKey = event?.key;
        Api.post(ApiEndpoints.StudentTrainingStripeCheckout.Create, {
            key: eventKey,
        })
            .then((response) => {
                console.log(response);
                window.location.href = response.data.sessionUrl;
            })
            .catch((error) => {
                openNotificationWithIcon(
                    "Error!",
                    "Unable to Create checkout",
                    eqNotificationTypes.error
                );
            });
    };

    const GetAllStudentTrainers = () => {
        setMessageString("");

        Api.get(ApiEndpoints.GetStudentTrainers).then(
            (response) => {
                if (response && response.status == 200) {
                    // var pendingInvites = [] as any;
                    var data = response?.data ? response?.data : ([] as any);
                    setSubscribedTrainersList(data);
                    // if(data.length)
                    // {
                    //     onloadActivity(true);
                    // }
                    // else{
                    //     onloadActivity(false);

                    // }
                    //data.map((d: any, index: number) => {
                    //    pendingInvites.push(d);
                    //});
                } else {
                    setMessageString("Not found");
                }
            },
            (err) => {
                setMessageString("Not found");
            }
        );
    };
    const GetAllStudentSchools = () => {
        setMessageString("");

        Api.get(ApiEndpoints.GetStudentSchools).then(
            (response) => {
                if (response && response.status == 200) {
                    // var pendingInvites = [] as any;
                    var data = response?.data ? response?.data : ([] as any);
                    setSubscribedSchoolsList(data);
                } else {
                    setMessageString("Not found");
                }
            },
            (err) => {
                setMessageString("Not found");
            }
        );
    };
    const GetUserAcceptedEvents = () => {
        Api.get(ApiEndpoints.GetUserAcceptedEvents).then(
            (response) => {
                if (response && response.status == 200) {
                    var data = response.data ? [...response.data] : new Array<IEqEventData>();
                    data.map((f: any, index: number) => {
                        f.display = index == 0 ? true : false;
                    });
                    var event = data?.filter((opt: any) => opt.accessType == 2);
                    var trainings = data?.filter((opt: any) => opt.accessType == 1);

                    setActivities(data);
                    setEvents(event);
                    setTrainings(trainings);
                } else {
                    setIsError(true);
                }
            },
            (error) => {
                setIsError(true);
            }
        );
    };

    const GetStudentJoinedEventClasses = () => {
        Api.get(ApiEndpoints.GetStudentJoinedClasses).then(
            (response) => {
                if (response && response.status == 200) {
                    var data = response.data ? [...response.data] : new Array<any>();
                    data.map((f: any, index: number) => {
                        f.display = index == 0 ? true : false;
                    });
                    setStudentEventClasses(data);
                } else {
                    setIsError(true);
                }
            },
            (error) => {
                setIsError(true);
            }
        );
    };

    const GetStudentProductOrders = () => {
        Api.get(ApiEndpoints.ProductOrder).then(
            (response) => {
                if (response && response.status == 200) {
                    var data = response.data ? [...response.data] : new Array<any>();

                    if (data.length > 0) {
                        var joinedProducts = data?.filter(
                            (opt: any) => opt.status == 1 || opt.status == 3
                        );

                        setStudentProductsOrders(joinedProducts);
                    }
                } else {
                    setIsError(true);
                }
            },
            (error) => {
                setIsError(true);
            }
        );
    };

    const dashboardState = () => {
        Api.get(ApiEndpoints.GetStudentDashboardState)
            .then((response) => {
                if (response && response.status === 200) {
                    // var result = response.data;
                    const {
                        totalProfessionals,
                        unPaidProductOrders,
                        unPaidTrainingOrders,
                        upcomingTrainings,
                    } = response.data;
                    setStateView({
                        totalProfessionals: totalProfessionals,
                        unpaidTraining: unPaidTrainingOrders,
                        unpaidProduct: unPaidProductOrders,
                        upcomingTraining: upcomingTrainings,
                    });
                } else {
                    setIsError(true);
                    GetEventMessage(response.status);
                }
            })
            .catch((error) => {
                GetEventMessage(error.response.status);
            });
    };

    const studentJoinedLessons = () => {
        Api.get(ApiEndpoints.StudentAllJoinedLessons)
            .then((response) => {
                if (response && response.status === 200) {
                    var data = response.data;
                    setJoinedTrainings(data);
                    console.log("training", data);
                } else {
                    setIsError(true);
                    GetEventMessage(response.status);
                }
            })
            .catch((error) => {
                GetEventMessage(error.response.status);
            });
    };

    const GetAllStudentProductOrders = () => {
        setMessageString("");

        Api.get(ApiEndpoints.ProductOrderStudentInvites).then(
            (response) => {
                if (response && response.status == 200) {
                    var pendingInvites = [] as any;
                    var data = response?.data ? response?.data : ([] as any);

                    if (data.length > 0) {
                        var grouped = data.reduce((acc: any, obj: any) => {
                            const key = obj.product.user.email;
                            if (!acc[key]) {
                                acc[key] = [];
                            }
                            acc[key].push(obj);
                            return acc;
                        }, {});

                        pendingInvites = Object.keys(grouped).map((key) => ({
                            trainer: grouped[key][0].product.user,
                            invites: grouped[key],
                        }));
                    }
                    // setProductOrderInvites(data);
                    // setPendingInvites(pendingInvites);
                    if (data.length) {
                        // onloadActivity(true);
                    } else {
                        // onloadActivity(false);
                    }
                    //data.map((d: any, index: number) => {
                    //    pendingInvites.push(d);
                    //});
                } else {
                    setMessageString("Not found");
                }
            },
            (err) => {
                setMessageString("Not found");
            }
        );
    };

    const filterTrainings = (trainingList: any[]) => {
        let filteredList = trainingList;
        if (selectedTraining && selectedTraining.length > 0) {
            filteredList = filteredList.filter((item) => {
                return selectedTraining.some(
                    (selectedType: any) => item?.event.disciplineType === selectedType
                );
            });
        }

        if (fromDate) {
            const FromDate = moment(fromDate).startOf("day").valueOf();
            filteredList = filteredList.filter((item) => {
                const startDate = moment(item?.event.startDate).startOf("day").valueOf();
                return startDate === FromDate;
            });
        }

        return filteredList;
    };

    const trainingTypeProps: ISelectProps = {
        classNames: `eq-multiSelect eq-searchSelect`,
        placeholder: t("training"),
        extraHeight: true,
        mode: "multiple",
        isTag: false,
        defaultSelectedValue: selectedTraining ? selectedTraining : [],
        options: trainingOptions,
        changeFunc: (value: any) => {
            setSelectedTraining(value);
        },
    };
    const createTrainingList = () => {
        var eventTypes = [];
        eventTypes.push({
            key: EqDisciplineType.Dressage,
            value: EqTrainingType[EqTrainingType.PrivateLesson],
        });
        eventTypes.push({
            key: EqDisciplineType.Jumping,
            value: EqTrainingType[EqTrainingType.GroupLesson],
        });
        eventTypes.push({
            key: EqDisciplineType.Open,
            value: EqTrainingType[EqTrainingType.AvailableTimeSlots],
        });
        eventTypes.push({
            key: EqDisciplineType.RidingCourse,
            value: EqDisciplineType[EqDisciplineType.RidingCourse],
        });
        setTrainingOptions(eventTypes);
    };

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response = await Api.get(`${ApiEndpoints.Student.GetUnpaidOrders}`);
            let data = response.data as StudentUnpaidOrdersInfoDto;
            debugger;
            setUnpaidOrders(data);
            if (data.unPaidTrainingOrders.length > 0 || data.unPaidCourseOrders.length > 0 || data.unPaidCourseLessonOrders.length > 0) {
                showModal();
            }
        } catch (error) {
            console.error("fetchOrders", error);
        } finally {
            setLoading(false);
        }
    };
    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);

    const checkClosable = (): boolean => {
     
      const today = moment().startOf("day");
      if(unpaidOrders?.unPaidCourseOrders && unpaidOrders.unPaidCourseOrders.length>0){
        return false;
      }
     else if(unpaidOrders?.unPaidTrainingOrders){
      return unpaidOrders.unPaidTrainingOrders.every((order:EqEntityEventUserGroup) => {
        const organizer = order.event.organizers.find((org:any) => org.eventUserType===EqEventUserType.Owner).user;
        // const organizer = order.event.organizers.find((org:any) => org.allowLatePayments);
        if (!organizer) return true; // Closable remains true if no organizer allows late payments
        if(!organizer.allowLatePayments) return false;

        const paymentDeadline = moment(order.event.startTime).add(
          organizer.paymentDays??0,
          "days"
        );
        return paymentDeadline.isSameOrAfter(today); // Closable if within the payment window
      });
     }
     else{
      return true
     }
     
    };

    useEffect(() => {
        
        createTrainingList();
        GetUserAcceptedEvents();
        GetStudentProductOrders();
        GetStudentJoinedEventClasses();
        GetAllStudentTrainers();
        GetAllStudentSchools();
        dashboardState();
        studentJoinedLessons();
        // fetchOrders();
    }, []);
    return (
        <Spin size="large" spinning={loading} fullscreen={loading}>
            <AnimationDiv>
                <div className="eq-stb-container">
                    <Row className="eq-stb-heading-row">
                        <Col className="eq-stb-col" flex="auto" md={5} xs={24} sm={24}>
                            <h3 className="eq-stb-heading">{t("myDashboard")}</h3>
                        </Col>
                    </Row>
                    {/* <div className="eq-section">
          <Row className="eq-db-row" align="stretch">
            <Col flex="auto" className="eq-db-icons">
              <Row>
                <h5 className="eq-db-h2">{t("quickLinks")}</h5>
              </Row>
              <Row align="bottom" justify="start" className="eq-db-icon-row">
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <Link to={FrontEndPoints.student.allTrainings}>
                    <span>
                      <img alt="" src="/training.svg" />
                    </span>
                    <div className="eq-db-icon-text">{t("myTrainings")}</div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div> */}
                    <div className="eq-section">
                        <StudentOverViewProfile stat={stateView} joinedTraining={joinedTrainings} />
                    </div>

                    {/* {subscribedTrainersList && subscribedTrainersList.length > 0 ? (
          <>
            <div className="eq-section-divider"></div>
            <div className="eq-section">
              <EqCollapsePanel
                defaultOpen={true}
                header={t("subscribedTrainers")}
                children={[...subscribedTrainersList].map(
                  (item: any, index: number) => (
                    <EqSubscribedTrainer
                      key={index}
                      trainer={item}
                    ></EqSubscribedTrainer>
                  )
                )}
              />
            </div>
          </>
        ) : (
          ""
        )} */}

                    {/* {subscribedSchoolsList && subscribedSchoolsList.length > 0 ? (
          <>
            <div className="eq-section-divider"></div>
            <div className="eq-section">
              <EqCollapsePanel
                defaultOpen={true}
                header={t("subscribedSchools")}
                children={[...subscribedSchoolsList].map(
                  (school: any, index: number) => (
                    <EqSubscribedUser
                      key={index}
                      user={school}
                    ></EqSubscribedUser>
                  )
                )}
              />
            </div>
          </>
        ) : (
          ""
        )} */}

                    {courseInvitation ? (
                        <>
                            <div className="eq-section-divider"></div>
                            <div className="eq-section">
                                <EqCollapsePanel
                                    defaultOpen={true}
                                    header={t("Course Invitations")}
                                    children={
                                        <EqCourseInviteSection
                                            onloadActivity={(e: any) => {
                                                setCourseInvitation(e);
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    {productOrderInvitation ? (
                        <>
                            <div className="eq-section-divider"></div>
                            <div className="eq-section">
                                <EqCollapsePanel
                                    defaultOpen={true}
                                    header={t("productOrderInvitations")}
                                    children={
                                        <EqProductInvitationBoard
                                            onloadActivity={(e: any) => {
                                                setProductOrderInvitation(e);
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    {activityInvitation ? (
                        <>
                            <div className="eq-section-divider"></div>
                            <div className="eq-section">
                                <EqCollapsePanel
                                    defaultOpen={true}
                                    header={t("trainingInvitations")}
                                    children={
                                        <EqEventInvitationBoard
                                            onloadActivity={(e: any) => {
                                                setActivityInvitation(e);
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    {acceptedRequests ? (
                        <>
                            <div className="eq-section-divider"></div>
                            <div className="eq-section">
                                <EqCollapsePanel
                                    defaultOpen={true}
                                    header={t("acceptedRequest")}
                                    children={
                                        <EqAcceptedTrainingRequests
                                            onloadActivity={(e: any) => {
                                                setAcceptedRequests(e);
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                    {/* <div className="eq-section">
          <Row className="eq-msg-header" align="bottom">
            <Col flex="auto">
              <div className="eq-db-h2"> Activity Invitations</div>
            </Col>
          </Row>

          <EqEventInvitationBoard />
        </div> */}

                    {trainerInvitation ? (
                        <>
                            <div className="eq-section-divider"></div>
                            <div className="eq-section">
                                <EqCollapsePanel
                                    defaultOpen={true}
                                    header={t("trainerInvitations")}
                                    children={
                                        <EqTrainerInvitationBoard
                                            onloadActivity={(e: any) => {
                                                setTrainerInvitation(e);
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    {schoolInvitation ? (
                        <>
                            <div className="eq-section-divider"></div>
                            <div className="eq-section">
                                <EqCollapsePanel
                                    defaultOpen={true}
                                    header={t("schoolInvitations")}
                                    children={
                                        <EqSchoolInvitationBoard
                                            onloadActivity={(e: any) => {
                                                setSchoolInvitation(e);
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    <div className="eq-section-divider"></div>
                    <div className="eq-section eq-section-training">
                        <Skeleton loading={!joinedTrainings} active></Skeleton>
                        <Row>
                            {joinedTrainings && (
                                <Col span={24}>
                                    <EqCollapsePanel
                                        defaultOpen
                                        header={t("trainings")}
                                        children={
                                            <div>
                                                <Row
                                                    gutter={[16, 16]}
                                                    className="eq-section-training-filter"
                                                >
                                                    <Col xs={24} md={8}>
                                                        <EqSelect {...trainingTypeProps}></EqSelect>
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <DatePicker
                                                            placeholder={t("selectDate")}
                                                            className="eq-filter-date eq-input-info"
                                                            onChange={(date) => setFromDate(date)}
                                                            value={
                                                                fromDate && fromDate.isValid()
                                                                    ? fromDate
                                                                    : null
                                                            }
                                                        />
                                                    </Col>
                                                    <Col xs={24} md={8}>
                                                        <EqButton classNames="btn-background-white btn-border-text-orange eq-filter-btn">
                                                            <FontAwesomeIcon
                                                                icon={faFilter}
                                                                className="eq-filter-btn-icon"
                                                            />
                                                            {t("filter")}
                                                        </EqButton>
                                                    </Col>
                                                </Row>
                                                <EqActivityTrainingStudentDashboard
                                                    trainingList={filterTrainings(joinedTrainings)}
                                                ></EqActivityTrainingStudentDashboard>
                                            </div>
                                        }
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>

                    {/* student Schedule */}
                    {/* <EqStudentSchedule
          isOwner={false}
          isStudent={true}
          eventList={studentEventClasses}
          trainings={trainings}
          studentProductOrders={studentProductOrders}
        // onCreateOrUpdateTraining: handleOnUpdateTraining,
        // onCreateOrUpdateEvent: handleOnUpdateEvent,
        // onStudentJoin: GetAllTrainerTrainingsLessonsByTrainerKey,
        // onRemoveTraining: handleRemoveTraining,
        // onRemoveEvent: handleRemoveEvent,
        // areas: areas,
        // imagesData: imagesData,
        // weekDays: weekDays,
        // population: population,
        // groupPopulation: groupPopulation,
        // trainerStudents: trainerStudents,
        // studentHorsesList: studentHorsesList,
        // profileData: profileData,
        // activitiesList: activitiesList,
        // publicProducts: [...productsList?.public],
        // isSubscribed: subscribeButtonState.buttonDisabled,
        // subscribeTrainer: SubscribeTrainer,
        ></EqStudentSchedule> */}
                </div>
            </AnimationDiv>

            <Modal
                width={1000}
                title={t("UnpaidOrders")}
                className="unpaid-Orders-modal"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null} // Custom footer
                closable={checkClosable()}
                maskClosable={true}
                // closeIcon={<span style={{ fontSize: '16px', color: '#000' }}>X</span>}
            >
                <>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab={"Course Orders"} key={"1"}>
                            <>
                            
                            {unpaidOrders?.unPaidCourseOrders.map(
                                (item: CourseLessonOrderEnt, index: number) => (
                                    <div className="eq-activity-row">
                                        <Row className="eq-status-row-border" key={index}>
                                            <Col className="content-col">
                                                <Row>
                                                    <Col>
                                                        <div className="date-icon">
                                                            <EqIconCalendar
                                                                eqWidth={34}
                                                                eqHeight={34}
                                                                eqClass="eq-cal-icon"
                                                                eqFill="#083b49"
                                                            />
                                                            <div className="day">
                                                                {moment(
                                                                    item?.course?.startDate
                                                                ).format("DD")}
                                                            </div>
                                                            <div className="month">
                                                                {moment(
                                                                    item?.course?.startDate
                                                                ).format("MMM")}
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col className="header-details">
                                                        <Row>
                                                            <Col></Col>
                                                            <Col>
                                                                {" "}
                                                                <span className="time">
                                                                    {moment(
                                                                        item?.course?.startDate
                                                                    ).format("hh:mm A")}{" "}
                                                                </span>
                                                            </Col>
                                                            <Col className="price">
                                                                <EqIconPrice
                                                                    eqWidth="15"
                                                                    eqHeight="13"
                                                                    eqFill="#b5b5b5"
                                                                />{" "}
                                                                {item.customPrice
                                                                    ? item.customPrice
                                                                    : item.course?.coursePrice}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="eq-f-th">
                                                                {item?.course?.title}
                                                            </Col>
                                                           
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className="action-col">
                                                <EqButton
                                                    onClick={() =>
                                                        GetCourseOrderCheckoutSession(item.id)
                                                    }
                                                >
                                                    {t("payNow")}
                                                </EqButton>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            )}
                             {unpaidOrders?.unPaidCourseLessonOrders.map(
                                (item: CourseLessonOrderEnt, index: number) => (
                                    <div className="eq-activity-row">
                                        <Row className="eq-status-row-border" key={index}>
                                            <Col className="content-col">
                                                <Row>
                                                    <Col>
                                                        <div className="date-icon">
                                                            <EqIconCalendar
                                                                eqWidth={34}
                                                                eqHeight={34}
                                                                eqClass="eq-cal-icon"
                                                                eqFill="#083b49"
                                                            />
                                                            <div className="day">
                                                                {moment(
                                                                    item?.courseLesson?.startTime
                                                                ).format("DD")}
                                                            </div>
                                                            <div className="month">
                                                                {moment(
                                                                    item?.courseLesson?.startTime
                                                                ).format("MMM")}
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col className="header-details">
                                                        <Row>
                                                            <Col></Col>
                                                            <Col>
                                                                {" "}
                                                                <span className="time">
                                                                    {moment(
                                                                        item?.courseLesson?.startTime
                                                                    ).format("hh:mm A")}{" "}
                                                                </span>
                                                            </Col>
                                                            <Col>
                                                            <EtTag>
                                                                {CourseOrderType[item?.orderType]}
                                                            </EtTag>
                                                            </Col>
                                                            <Col className="price">
                                                                <EqIconPrice
                                                                    eqWidth="15"
                                                                    eqHeight="13"
                                                                    eqFill="#b5b5b5"
                                                                />{" "}
                                                                {item.customPrice
                                                                    ? item.customPrice
                                                                    : item.courseLesson?.price}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="eq-f-th">
                                                                {item?.courseLesson?.course?.title}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className="action-col">
                                                <EqButton
                                                    onClick={() =>
                                                        GetCourseLessonOrderCheckoutSession(item.id)
                                                    }
                                                >
                                                    {t("payNow")}
                                                </EqButton>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            )}
                            
                            </>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={"Training Orders"} key={"2"}>
                            {unpaidOrders?.unPaidTrainingOrders.map(
                                (item: EqEntityEventUserGroup, index: number) => (
                                    <div className="eq-activity-row">
                                        <Row className="eq-status-row-border" key={index}>
                                            <Col className="content-col">
                                                <Row>
                                                    <Col>
                                                        <div className="date-icon">
                                                            <EqIconCalendar
                                                                eqWidth={34}
                                                                eqHeight={34}
                                                                eqClass="eq-cal-icon"
                                                                eqFill="#083b49"
                                                            />
                                                            <div className="day">
                                                                {moment(
                                                                    item.event.startDate
                                                                ).format("DD")}
                                                            </div>
                                                            <div className="month">
                                                                {moment(
                                                                    item.event.startDate
                                                                ).format("MMM")}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="header-details">
                                                        <Row>
                                                            <Col></Col>
                                                            <Col>
                                                                {" "}
                                                                <span className="time">
                                                                    {moment(
                                                                        item.event.startTime
                                                                    ).format("hh:mm A")}{" "}
                                                                </span>
                                                            </Col>
                                                            <Col className="price">
                                                                <EqIconPrice
                                                                    eqWidth="15"
                                                                    eqHeight="13"
                                                                    eqFill="#b5b5b5"
                                                                />{" "}
                                                                {item.event?.price}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="title">
                                                                {item?.event?.title}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className="action-col">
                                                <EqButton
                                                    onClick={() =>
                                                        CreateTrainingLessonOrderCheckoutSession(
                                                            item.event
                                                        )
                                                    }
                                                >
                                                    {t("payNow")}
                                                </EqButton>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            )}
                        </Tabs.TabPane>
                    </Tabs>
                </>
            </Modal>
        </Spin>
    );
};
export default EqStudentDashboard;
