import { Col, Row, FloatButton, Checkbox } from "antd-v5";
import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EqButton from "../eqButton/EqButton";
import RevealList from "../RevealList/RevealList";
import Search from "antd-v5/es/input/Search";
import EqSelect from "../eqSelect/EqSelect";
import { ISelectProps } from "../../shared/Interfaces";
import "./EqProductInvitations.scss";
import EqCollapse from "../eqCollapse/EqCollapse";
import { EqCurrency, EqOrderStatus } from "../../shared/enums";
import moment from "moment";
import { EqInput } from "../eqInput/EqInput";
import { CourseUI } from "../../shared/interfaces/ridingCourse/IRidingCourse";

interface EqProductInvitationsProps {
    data: any[]; // Replace 'any' with the actual type of your product invite
    editCourse?: CourseUI | null
    onDelete: (inviteId: string) => void;
    onDeleteMultiple: (inviteIds: string[]) => void;
    isCourse?: boolean;
    filteredStudent?: any;
}


const EqProductInvitations: React.FC<EqProductInvitationsProps> = ({
  data,
  editCourse,
  onDelete,
  onDeleteMultiple,
  isCourse,
  filteredStudent
}) => {
  const { t, i18n } = useTranslation();

  //data
  const [productInvitations, setProductInvitations] = useState() as any;

    const [courseorderList, setcourseorderList] = React.useState(editCourse?.courseLessonOrders || []);
    //const [filterStudents, setFilterStudents] = React.useState(courseorderList);
    //const [searchQuery, setSearchQuery] = React.useState([] as any);
    const [filteredProductInvitations, setFilteredProductInvitations] =
    useState() as any;

  // selection
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([] as any);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [searchName, setSearchName] = React.useState("");
  const [selectedProducts, setSelectedProducts] = React.useState([] as any);

  const [productOptions, setProductOptions] = React.useState([] as any);

  const filterProductOrderList = (productOrderList: any[]) => {
    let filteredList = productOrderList;

    if (searchName !== "") {
      filteredList = filteredList?.filter((item) => {
        let firstName = item?.student?.firstName;
        let lastName = item?.student?.lastName;
        let username = item?.student?.email;

        return (
          (firstName &&
            (firstName + " " + lastName)
              .toLowerCase()
              .includes(searchName.toLowerCase())) ||
          (username &&
            username.toLowerCase().includes(searchName.toLowerCase()))
        );
      });
    }

    if (selectedProducts && selectedProducts.length > 0) {
      filteredList = filteredList?.filter((item) => {
        return selectedProducts.includes(item?.product?.title + "");
      });
    }
    console.log("filteredList:", filteredList);
    return filteredList;
  };

  useEffect(() => {
    if (data ) {
      CreateAvailableProductList(data);
      setProductInvitations(data);
      setFilteredProductInvitations(data);
    }
  }, [data]);
    useEffect(() => {
        setcourseorderList(editCourse?.courseLessonOrders || []);
        //setFilterStudents(editCourse?.courseLessonOrders || []);
    }, [editCourse])


    //const handleSearchInputChange = (e) => {
    //    setSearchQuery(e.target.value);
    //};

    //const handleSearch = () => {
    //    if (typeof searchQuery === "string") {
    //        const filteredList = courseorderList.filter((value: any) => {
    //            const fullName = `${value.student?.firstName} ${value.student?.lastName}`.toLowerCase();
    //            return (
    //                fullName.includes(searchQuery.toLowerCase()) ||
    //                (value.student?.email || "").toLowerCase().includes(searchQuery.toLowerCase())

    //            );
    //        });
    //        setFilterStudents(filteredList);
    //    }
    //};
  const CreateAvailableProductList = (items: any[]) => {
    const productTitlesMap = new Map();

    items.forEach((item) => {
      const productTitle = item?.product.title;

      if (productTitle) {
        productTitlesMap.set(productTitle, {
          key: productTitle,
          value: productTitle,
          label: productTitle,
        });
      }
    });
    const uniqueProductTitles = Array.from(productTitlesMap.values());
    setProductOptions(uniqueProductTitles);
    console.log("titles", uniqueProductTitles);
  };
  const checkAll = () => {
    const isChecked = !isCheckAll;

    setFilteredProductInvitations((prevOrders: any) => {
      const updatedList = prevOrders.map((order: any) => ({
        ...order,
        selected: isChecked,
      }));

      setIsCheckAll(isChecked);
      const updatedSelectedCheckboxes = updatedList.filter(
        (x: any) => x.selected
      );
      setSelectedCheckboxes(updatedSelectedCheckboxes);

      return updatedList;
      
    });
  };

  const searchNameClick = (searchStr: string) => {
    setSearchName(searchStr);
  };

  const productProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("product"),
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedProducts ? selectedProducts : [],
    options: productOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedProducts(value);
    },
  };

  const handleOnCheck = (order: any) => {
    
    setFilteredProductInvitations((prevOrders: any) => {
      const list = [...prevOrders];
      const updatedList = list.map((item) =>
        item.id === order.id ? { ...item, selected: !item.selected } : item
      );
      const updatedSelectedCheckboxes = updatedList.filter((x) => x.selected);
      setSelectedCheckboxes(updatedSelectedCheckboxes);
      
      return updatedList;
    });
  };

    //const handleOnDelete = (studentId: any) => {
    //    const choosestudent = courseorderList.filter(ol => ol.student.Id !== studentId); 
    //    const RemStudentList = choosestudent.map(student => student.Id);
    //    setcourseorderList(RemStudentList);
    //}

    const handleOnDelete = (studentId: any) => {

        //const updatedCourseList = courseorderList.map(course => {
        //    if (Array.isArray(course.student)) {

        //    const updatedStudents = course.student.filter(student => student.Id !== studentId);
        //    return {
        //        ...course,
        //        student: updatedStudents,
        //    };
        //    }
        //});
        //setcourseorderList(updatedCourseList);
    }

  const handleMultipleDelete = () => {
    const selectedInviteIds = selectedCheckboxes.map(
      (invite: any) => invite.id
    );
    
 onDeleteMultiple(selectedInviteIds)

    };
    
  return (
    <div className="eqProductInvitation">
         
          {/*{Array.isArray(productInvitations) && productInvitations.length > 0 ?(*/}
              <>

      <div>
       <Row>
                {isCourse ? (
                    <>
                     
                    </>
                ) : (
        <Col span={24} className="filters">
            <Row gutter={[16, 16]} className="filter-type">
                {/* Check if isCourse is true */}
                    <>
                        {/* Regular layout when isCourse is false */}
                        <Col className="filter-col" xs={24} sm={24} md={12} lg={11}>
                            <Row className="filter-selector">
                                <Col xs={6} sm={5} md={6} lg={5}>
                                    <label className="picker-label">{t("student")}</label>
                                </Col>
                                <Col className="search-input" flex="auto">
                                    <Search
                                        placeholder={t("searchNameMail")}
                                        onChange={(e: any) => searchNameClick(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col className="filter-col" xs={24} sm={24} md={12} lg={11}>
                            <Row className="filter-selector">
                                <Col xs={6} sm={5} md={6} lg={5}>
                                    <label className="picker-label">{t("title")}</label>
                                </Col>
                                <Col className="search-input" flex="auto">
                                    <EqSelect {...productProps}></EqSelect>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                            <div className="search">
                                <EqButton
                                    classNames="eq-input-info"
                                    type="primary"
                                    htmlType="button"
                                >
                                    {t("search")}
                                </EqButton>
                            </div>
                        </Col>
                    </>
            </Row>
        </Col>
                )}
                  </Row>

      </div>
      <Row>
        <Col span={24}>
          <Row justify="end" className="action-all-btn hidden-sm">
            {selectedCheckboxes.length > 1 && (
              <>
                <FloatButton
                        description={t("Delete All")}
                        shape="square"
                        onClick={() => handleMultipleDelete()}
                      />    
              </>
            )}
          </Row>
        </Col>

        <Col span={24}>
          <Row className="eq-fl-row hidden-sm">
            <Col className="eq-fl-th " span={2}>
                              <Checkbox
                                  //onChange={checkAll}
                                  checked={isCheckAll}></Checkbox>
            </Col>
            <Col className="eq-fl-th" span={6}>
              {t("student")}
            </Col>
            <Col className="eq-fl-th" span={6}>
              {t("title")}
            </Col>
            
            {/* <Col className="eq-fl-th" span={4}>
              {t("startDate")}
            </Col> */}
            <Col className="eq-fl-th" span={5}>
              {t("amount")}
            </Col>
            <Col className="eq-fl-th" span={4}>
              {t("actions")}
            </Col>
          </Row>
          <Row justify="space-between" className="eq-fl-row hidden-md">
            <Col className="eq-fl-th">
              <Checkbox onChange={checkAll} checked={isCheckAll}>
                {" "}
                Check all
              </Checkbox>
            </Col>
            <Col>
              <Row justify="end" className="action-delete-all">
                {selectedCheckboxes.length > 1 && (
                  <>
                    <EqButton onClick={() => handleMultipleDelete()}>
                      {t("Delete All")}
                    </EqButton>
                  </>
                )}
              </Row>
            </Col>
          </Row>
                <RevealList>
                    {isCourse ? (
                              (filteredStudent.length > 0 ?
                                  filteredStudent : editCourse?.courseLessonOrders)?.filter((ol: any) => ol.orderStatus === EqOrderStatus.Pending) 
                                  .map((ol: any) => (
                            <div className="product-invite">
                                <Row className="eq-f-row hidden-sm">

                            <Col className="eq-f-td" span={2}>
                                <Checkbox
                                    onChange={() => {
                                        // handleOnCheck(ol);
                                    }}
                                    checked={ol.selected}
                                ></Checkbox>
                            </Col>
                            <Col className="eq-fl-th" span={6}>
                                {`${ol.student.firstName}  ${ol.student.lastName}`}
                            </Col>
                            <Col className="eq-f-td" span={6}>
                                {editCourse?.title}
                                    </Col> 

                                    <Col className="eq-fl-th" span={5}>
                                        {editCourse?.coursePrice + " "}
                                        
                            </Col>
                            <Col className="eq-fl-th product-action" span={4}>
                                <div
                                    className="action"
                                    onClick={() =>
                                        onDelete(ol.id)
                                    }
                                >
                                    <span>
                                        <img alt="" src="/trash-alt-red.svg" />
                                    </span>
                                </div>
                                {/* {ReceiptModal()} */}
                            </Col>
                            </Row>

                            </div>
                        ))
                    ) : (
                        <Row className="">
                        {filterProductOrderList(filteredProductInvitations)?.map(
        (productOrder: any, index: number) => (
                    <div className="product-invite">
                        <Row className="eq-f-row hidden-sm">
                            <Col className="eq-f-td" span={2}>
                                <Checkbox
                                    onChange={() => {
                                        handleOnCheck(productOrder);
                                    }}
                                    checked={productOrder.selected}
                                ></Checkbox>
                            </Col>
                            <Col className="eq-fl-th" span={6}>
                                {`${productOrder.student.firstName}  ${productOrder.student.lastName}`}
                            </Col>
                            <Col className="eq-f-td" span={6}>
                                {productOrder.product.title}
                            </Col>
                            {/* <Col className="eq-fl-th" span={4}>
                {moment(productOrder.product.startTime).format(
                    "DD MMMM, YYYY HH:mm"
                )}
                </Col> */}
                            <Col className="eq-fl-th" span={5}>
                                {productOrder.product.price + " "}
                                {EqCurrency[productOrder.product.currency]}
                            </Col>
                            <Col className="eq-fl-th product-action" span={4}>
                                <div
                                    className="action"
                                    onClick={() =>
                                        onDelete(productOrder.id)
                                    }
                                >
                                    <span>
                                        <img alt="" src="/trash-alt-red.svg" />
                                    </span>
                                </div>
                                {/* {ReceiptModal()} */}
                            </Col>
                        </Row>

                        <div className="hidden-md">
                            <EqCollapse
                                header={
                                    <Row justify="start" align="middle">
                                        <Col className="" span={2}>
                                            <Checkbox
                                                onChange={() => {
                                                    handleOnCheck(productOrder);
                                                }}
                                                checked={productOrder.selected}
                                            ></Checkbox>
                                        </Col>
                                        <Col className="" flex="auto">
                                            {productOrder?.product.title}
                                        </Col>
                                    </Row>
                                }
                            >
                                <>
                                    <Row className="eq-card-row">
                                        <Col className="eq-card-col-heading" span={24}>
                                            {t("student")}
                                        </Col>
                                        <Col className="" span={24}>
                                            {`${productOrder.student.firstName}  ${productOrder.student.lastName}`}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col flex="auto" className="eq-card-divider"></Col>
                                    </Row>
                                    <Row className="eq-card-row">
                                        <Col className="eq-card-col-heading" span={24}>
                                            {t("title")}
                                        </Col>
                                        <Col className="" span={24}>
                                            {productOrder.product.title}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col flex="auto" className="eq-card-divider"></Col>
                                    </Row>

                                    <Row className="eq-card-row">
                                        <Col className="eq-card-col-heading" span={24}>
                                            {t("startTime")}
                                        </Col>
                                        <Col className="product-status" span={24}>
                                            {moment(productOrder.product.startTime).format(
                                                "DD MMMM, YYYY HH:mm"
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col flex="auto" className="eq-card-divider"></Col>
                                    </Row>
                                    <Row className="eq-card-row">
                                        <Col className="eq-card-col-heading" span={24}>
                                            {t("amount")}
                                        </Col>
                                        <Col className="" span={24}>
                                            {productOrder.product.price}{" "}
                                            {EqCurrency[productOrder.product.currency]}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col flex="auto" className="eq-card-divider"></Col>
                                    </Row>
                                    <Row className="eq-card-row">
                                        <Col className="eq-card-col-heading" span={24}>
                                            {t("action")}
                                        </Col>
                                        <Col className="product-action" span={24}>
                                            <div
                                                className="action"
                                                onClick={() =>
                                                    onDelete(productOrder.id)
                                                }
                                            >
                                                <span>
                                                    <img alt="" src="/trash-alt-red.svg" />
                                                </span>
                                            </div>


                                        </Col>
                                    </Row>
                                </>
                            </EqCollapse>
                        </div>
                    </div>
                    )
        )}


                      </Row>
                              )}
           
          </RevealList>
        </Col>
                  </Row>

             </>
          {/*) : (*/}
          {/*  <Empty description={String(t("noDataAvailable"))} />)*/}
          {/*}*/}
    </div>
  );
};

export default EqProductInvitations;
