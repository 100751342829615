import React from "react";
import { Document, PDFViewer, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

// Define props for the generic component
export interface IPdfTableRow {
    [key: string]: any; // Generic row structure
}

export interface IPdfProps {
    title: string;
    bookingId: string;
    bookingDate: string;
    purchaserName: string;
    totalExcludingVAT?: number;
    totalCost: number;
    headers: string[];
    data: IPdfTableRow[];
    columns: { key: string; width: string }[];
    showVAT?: boolean; // Add flag for VAT visibility
}

const EqPdfFinancialReceipt = ({
    title,
    bookingId,
    bookingDate,
    purchaserName,
    totalExcludingVAT,
    totalCost,
    headers,
    data,
    columns,
    showVAT = true, // Default to true
}: IPdfProps) => {
    const logoSrc1 = "logoEquinet.png";
    const { t } = useTranslation();

    const styles = StyleSheet.create({
        page: {
            padding: 40,
            fontSize: 12,
            fontFamily: "Helvetica",
            color: "#333",
        },
        header: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 30,
        },
        companyDetails: {
            textAlign: "left",
        },
        companyLogo: {
            textAlign: "right",
            maxWidth: "auto",
            height: 50,
            justifyContent: "center",
            flexDirection: "row",

            alignItems: "center",
            display: "flex",
            padding: 5,
            border: "1px solid #FF8838",
        },
        logo: {
            // width: 40, // Adjust as needed
            height: 40,
            marginRight: 10,
        },
        customerName: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 1,
            marginBottom: 6,
            fontWeight: "bold",
            fontSize: 14,
        },
        customerContent: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 1,
            fontSize: 10,
        },

        title: {
            fontSize: 24,
            marginBottom: 4,
            color: "#FF8838",
            textAlign: "right",
            fontWeight: "bold",
        },
        infoContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20,
            width: "100%",
        },

        customerInfo: {
            display: "flex",
            marginTop: 20,
            fontSize: 10,
        },
        customerContentHeading: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 1,
            fontSize: 10,
            color: "#FF8838",
        },

        invoiceInfo: {
            display: "flex",
            // flexDirection: 'row',
            justifyContent: "flex-end",
            marginTop: 20,
            fontSize: 10,
        },
        invoiceContent: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 4,
        },
        invoiceDecHeading: {
            fontSize: 12,
            fontWeight: "bold",
            color: "#FF8838",
            textAlign: "right",
        },
        invoiceDecText: {
            fontSize: 12,
        },

        table: {
            marginVertical: 15,
            width: "100%",
            // borderTop: '2px solid #4CAF50',
            borderBottom: "2px solid #FF8838",
        },
        tableHeader: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#FF8838",
            color: "white",
            padding: 8,
            fontSize: 10,
        },
        tableRow: { flexDirection: "row", padding: 8, borderBottom: "1px solid #e0e0e0" },
        tableCell: { padding: 4, fontSize: 10 },
        // totalRow: {
        //     marginTop: 10,
        //     flexDirection: "row",
        //     justifyContent: "flex-end",
        //     padding: 8,

        //     backgroundColor: "#ffe0c7",
        //     borderBottom: "2px solid #FF8838",
        //     borderTop: "2px solid #FF8838",
        // },
        totalSection: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 20,
            fontSize: 10,
        },
        totalRow: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            // marginBottom: 5,
            padding: 8,
            backgroundColor: "#ffe0c7",
            borderBottom: "2px solid #FF8838",
            borderTop: "2px solid #FF8838",
        },
        // totalText: { fontSize: 10, fontWeight: "bold", marginRight: 5 },
        totalAmount: {
            color: "#FF8838",
            fontSize: 12,
            fontWeight: "bold",
        },
        notes: {
            marginTop: 40,
            fontSize: 10,
            color: "#666",
        },
    });

    return (
        <PDFViewer className="pdf-viewer" width={1000} height={1000}>
            <Document>
                <Page size="A4" style={styles.page}>
                    {/* Header Section */}
                    <View style={styles.header}>
                        <View style={styles.companyDetails}>
                            <Text style={styles.customerName}>Equinet AS</Text>
                            <Text style={styles.customerContent}>Gamle Kongsvingerveg 4,</Text>
                            <Text style={styles.customerContent}>2040 Kløfta Norway.</Text>
                        </View>
                        <View style={styles.companyLogo}>
                            <Image src={logoSrc1} style={styles.logo} />
                        </View>
                    </View>

                    {/* Title */}
                    <Text style={styles.title}>{t("receipt")}</Text>

                    {/* Info Section (Billed to and Receipt details) */}
                    <View style={styles.infoContainer}>
                        <View style={styles.customerInfo}>
                            <Text style={styles.customerContentHeading}>{t("billedTo")}</Text>
                            <Text style={styles.customerName}>{purchaserName}</Text>
                        </View>
                        <View style={styles.invoiceInfo}>
                            <View style={styles.invoiceContent}>
                                <Text style={styles.invoiceDecHeading}>{t("receipt")} #:</Text>
                                <Text style={styles.invoiceDecText}>{bookingId}</Text>
                            </View>
                            <View style={styles.invoiceContent}>
                                <Text style={styles.invoiceDecHeading}>
                                    {t("receipt")} {t("date")}:
                                </Text>
                                <Text style={styles.invoiceDecText}>{bookingDate}</Text>
                            </View>
                            {/* <Text>Receipt #: 0000457</Text>
                            <Text>Receipt date: 11-04-2023</Text> */}
                        </View>
                    </View>

                    {/* Table Section */}
                    <View style={styles.table}>
                        {/* Table Headers */}
                        <View style={styles.tableHeader}>
                            {headers.map((header, index) => (
                                <Text
                                    key={index}
                                    style={[styles.tableCell, { width: columns[index].width }]}
                                >
                                    {header}
                                </Text>
                            ))}
                        </View>
                        {/* Table Rows */}
                        {data.map((row, rowIndex) => (
                            <View style={styles.tableRow} key={rowIndex}>
                                {columns.map((col, colIndex) => (
                                    <Text
                                        key={colIndex}
                                        style={[styles.tableCell, { width: col.width }]}
                                    >
                                        {row[col.key]}
                                        {/* {row[col.key] || "N/A"} */}
                                    </Text>
                                ))}
                            </View>
                        ))}
                    </View>

                    {/* Total Section */}
                    <View style={styles.totalSection}>
                        <View style={{ width: "50%" }}>
                            {showVAT && totalExcludingVAT !== undefined && (
                                <View style={styles.totalRow}>
                                    <>
                                        <Text style={styles.totalAmount}>
                                            {t("totalAmountExcludingVat")}:
                                        </Text>
                                        <Text style={styles.totalAmount}>
                                            {totalExcludingVAT.toFixed(2)}
                                        </Text>
                                    </>
                                </View>
                            )}
                            <View style={styles.totalRow}>
                                <Text style={styles.totalAmount}>{t("totalAmount")}:</Text>
                                <Text style={styles.totalAmount}>{totalCost.toFixed(2)}</Text>
                            </View>
                        </View>
                    </View>
                       {/* Footer */}
                                    <Text style={styles.notes}>{t("thankMsgInvoice")}</Text>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default EqPdfFinancialReceipt;
