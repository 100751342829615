
import { useEffect, useState } from "react";
import * as React from "react";
import { Button, Col, Row } from "antd";
import "./Home.scss";
import EqCategory, { IEqCategory } from "../eqCategory/EqCategory";
import EqCard, { IEqCard } from "../custom/eqCard/EqCard";
import EqButton from "../custom/eqButton/EqButton";
import { Select } from "antd";
import { DatePicker, Radio, Slider, Switch, Typography } from "antd";
import { Carousel } from "antd";
import { EqInput } from "../custom/eqInput/EqInput";
import { useTranslation, Trans } from "react-i18next";
import {
  ApiEndpoints,
  dateFormat,
  FrontEndPoints,
  FrontendRoles,
  getDefaultImage,
  GetHomeMessage,
  imageType,
} from "../shared/Common";
import Api from "../shared/Api";
import moment from "moment";
import {
  EqDisciplineType,
  EqProfessionType,
  EqEventHorseAvailabilityStatus,
  EqEventType,
} from "../shared/enums";
import { Link, useNavigate } from "react-router-dom";
import EqMessageModal from "../custom/eqMessageModal/EqMessageModal";
import { IEqEventData } from "../shared/interfaces/IEqEventDto";
import { loadState, saveEvent } from "../shared/LocalStorage";
import { Professions } from "../shared/enums";
import { loginUser } from "../context/Actions";
import { useAuthDispatch } from "../context/Context";
import AsyncImage from "../custom/asyncImage/AsyncImage";
import { RightCircleFilled, RightCircleTwoTone } from "@ant-design/icons";
import EqCardEvent from "../custom/eqCardEvent/EqCardEvent";
import EqTags from "../custom/eqTags/EqTags";
import { Popover } from "antd-v5";
import { motion } from 'framer-motion';
import TransitionLoad from "../custom/TransitionLoad/TransitionLoad";
import Reveal from "../custom/Reveal/Reveal";
import EqPublicEventCard from "../../eqComponents/eqPublicEventCard/EqPublicEventCard";
import EqSelect from "../custom/eqSelect/EqSelect";
import { ISelectProps } from "../shared/Interfaces";
interface Location {
  key: number;
  value: string;
}
export const Home = () => {
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [events, setEvents] = useState<any[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState<number[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  
  const [sortBy, setSortBy] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  // const [disciplines, setDisciplines] = React.useState({
  //   allDisciplines: [],
  //   selectedDiscipline: "Dressage",
  // } as any);
  // const [locations, setLocations] = React.useState({
  //   allLocations: [] as Location[],
  //   selectedLocations: [] as number[],
  // });
  const [trainers, setTrainers] = React.useState([] as any);
  const [datesToggle, setDatesToggle] = React.useState([false, false]) as any;
  const [dates, setDates] = React.useState([]) as any;
  const [value, setValue] = React.useState() as any;
  const [isRangeDate, setIsRangeDate] = React.useState(true);
  const [reachOutMsg, setReachOutMsg] = React.useState("");
  const [startDate, setStartDate] = useState(null) as any;
  const [endDate, setEndDate] = useState(null) as any;
  //const [selectedEventCategory, setSelectedEventCategory] = React.useState("");
  const [contactingName, setContactingName] = React.useState("");
  const [contactingEmail, setContactingEmail] = React.useState("");
  const [horseAvailbility, setHorseAvailbility] = React.useState({
    allAvailability: [],
    selectedAvailability: EqEventHorseAvailabilityStatus.All,
  }) as any;
  const mapStyle: any = {
    border: 0,
    allowfullscreen: "",
    loading: "lazy",
  };
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const marks = [
    { key: 0, value: "Near" },
    { key: 1, value: "20KM" },
    { key: 2, value: "40KM" },
    { key: 3, value: "60KM" },
    { key: 3, value: "All" },
  ];
  const [radiusValues, setRadiusValues] = useState<string | null>(null);
  const handleRadius = (value: string) => {
    setRadiusValues(value)
  }
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [disciplines, setDisciplines] = React.useState({
    allDisciplines: [],
    selectedDiscipline: [],
  } as any);





  useEffect(() => {
    applyFilters();
}, [selectedEventTypes, selectedLocations, sortBy, events]);

const applyFilters = () => {
  let filtered = [...events];

  // Filter by event type
  if (selectedEventTypes.length > 0) {
      filtered = filtered.filter((event) =>
          selectedEventTypes.includes(event.disciplineType)
      );
  }

  // Filter by location
  if (selectedLocations.length > 0) {
      filtered = filtered.filter((event) => selectedLocations.includes(event.locationId));
  }

  // Sort events
  if (sortBy === 0) {
      filtered.sort((a, b) => a.startDate - b.startDate); // Upcoming
  } else if (sortBy === 1) {
      filtered.sort((a, b) => b.createdDate - a.createdDate); // New
  }

  setFilteredEvents(filtered);
};


  const disciplineClicked = (value: any) => {
    if (value.includes("All")) {
      setSelectedDiscipline(["All"] as any);
      setIsAllSelected(true);
    } else {
      setSelectedDiscipline(value);
      setIsAllSelected(false);
    }
    updateEventsFilter(
      value.includes("All") ? [] : value.filter((item: any) => item !== "All"),
      selectedLocations,
      dates,
      isRangeDate,
      horseAvailbility.selectedAvailability
    );
  };
  const [userMessageProps, setUserMessageProps] = React.useState({
    title: "Message",
    visible: false,
    message: "Coming soon",
    cancelClicked: (param: any) => {
      setUserMessageProps({ ...userMessageProps, visible: false });
    },
    okClicked: (param: any) => {
      setUserMessageProps({ ...userMessageProps, visible: false });
    },
  });
  const categoryClicked = (profession: string): void => {
    switch (Number(profession)) {
      case EqProfessionType.Trainer: {
        navigate(FrontEndPoints.trainer.allProfessionalsBoard);
        break;
      }
      case EqProfessionType.Stable: {
        setUserMessageProps({ ...userMessageProps, visible: true });
        break;
      }
      case EqProfessionType.RidingSchool: {
        navigate(FrontEndPoints.RidingSchools);
        break;
      }
      case EqProfessionType.Farrier: {
        setUserMessageProps({ ...userMessageProps, visible: true });
        break;
      }
      case EqProfessionType.Veterinarian: {
        setUserMessageProps({ ...userMessageProps, visible: true });
        break;
      }
      case EqProfessionType.Therapist: {
        setUserMessageProps({ ...userMessageProps, visible: true });
        break;
      }
      default: {
        break;
      }
    }
  };
  const categories: Array<IEqCategory> = [
    {
      caption: t("trainers"),
      categoryClicked: categoryClicked,
      iconName: "eqTrainer-icon.svg",
      imageName: "home/eqTrainer.png",
      name: EqProfessionType.Trainer + "",
    },
    // {
    //   caption: t("stables"),
    //   categoryClicked: categoryClicked,
    //   iconName: "eqStable-icon.svg",
    //   imageName: "home/eqStable.webp",
    //   name: EqProfessionType.Stable + "",
    // },
    {
      caption: t("ridingschools"),
      categoryClicked: categoryClicked,
      iconName: "eqRiding-icon.svg",
      imageName: "home/eqRidingSchool.png",
      name: EqProfessionType.RidingSchool + "",
    },
    // {
    //   caption: t("farriers"),
    //   categoryClicked: categoryClicked,
    //   iconName: "eqFarrier-icon.svg",
    //   imageName: "home/eqFarrier.webp",
    //   name: EqProfessionType.Farrier + "",
    // },
    // {
    //   caption: t("veterinarians"),
    //   categoryClicked: categoryClicked,
    //   iconName: "eqVeterinarian-icon.svg",
    //   imageName: "home/eqVeterinarian.webp",
    //   name: EqProfessionType.Veterinarian + "",
    // },
    // {
    //   caption: t("therapists"),
    //   categoryClicked: categoryClicked,
    //   iconName: "eqTherapist-icon.svg",
    //   imageName: "home/eqTherapist.webp",
    //   name: EqProfessionType.Therapist + "",
    // },
  ];
  const handleChange = (value: any) => {
    setSelectedLocations(value);
    updateEventsFilter(
      disciplines.selectedDiscipline,
      value,
      dates,
      isRangeDate,
      horseAvailbility.selectedAvailability
    );
  };
  const createHorseAvailability = () => {
    var accessTypes = [];
    for (var x in EqEventHorseAvailabilityStatus) {
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: EqEventHorseAvailabilityStatus[x] });
      }
    }
    setHorseAvailbility({ ...horseAvailbility, allAvailability: accessTypes });
  };
  const handleSizeChange = (e: any) => {
    console.log(e.target.value);
    let availability: number = e.target.value;
    setHorseAvailbility({
      ...horseAvailbility,
      selectedAvailability: availability,
    });
    updateEventsFilter(
      disciplines.selectedDiscipline,
      selectedLocations,
      dates,
      isRangeDate,
      availability
    );
  };
  const onCarouselChange: any = (a: any, b: any, c: any) => { };
  const nameChanged = (e: any) => {
    setContactingName(e.target.value);
  };
  const emailChanged = (e: any) => {
    setContactingEmail(e.target.value);
  };
  const onRangeCalendarChange = (event: any) => {
    setDates(event);
    updateEventsFilter(
      disciplines.selectedDiscipline,
      selectedLocations,
      event,
      isRangeDate,
      horseAvailbility.selectedAvailability
    );
  };

  // Handler for start date change
  const onStartDateChange = (date: any | null) => {
    setStartDate(date);
    updateEventsFilter(
      disciplines.selectedDiscipline,
      selectedLocations,
      [date, endDate],
      true, // Always in range date mode
      horseAvailbility.selectedAvailability
    );
  };
  // Handler for end date change
  const onEndDateChange = (date: any | null) => {
    setEndDate(date);
    updateEventsFilter(
      disciplines.selectedDiscipline,
      selectedLocations,
      [startDate, date],
      true, // Always in range date mode
      horseAvailbility.selectedAvailability
    );
  };



  const onRangeChange = (event: any) => {
    setValue(event);
    //updateEventsFilter(disciplines.selectedDiscipline, value);
  };
  const rangeSwitchChange = (checked: any) => {
    if (checked) {
      setDatesToggle([false, false]);
    } else {
      setDatesToggle([false, true]);
    }
    updateEventsFilter(
      disciplines.selectedDiscipline,
      selectedLocations,
      dates,
      checked,
      horseAvailbility.selectedAvailability
    );
  };


  const getEventDisciplines = () => {
    Api.get(ApiEndpoints.homeDisciplines).then(
      (response) => {
        if (response && response.status === 200) {
          setIsError(false);
          setMessage("");
          const data = response.data?.map((item: any) => ({
            caption: item.value,
            categoryClicked: disciplineClicked,
            iconName: getDefaultImage(imageType.trainerIcon),
            imageName: getDefaultImage(imageType.trainer),
            name: item.value,
          }));
          setDisciplines({
            allDisciplines: data,
          });
        } else {
          setIsError(true);
          setMessage(GetHomeMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetHomeMessage(error.response.status));
      }
    );
  };

  const fetchLocations = async () => {
    try {
        const response = await Api.get(`${ApiEndpoints.Location.GetLocationOptions}`);
        setLocations(response.data);
    } catch (error) {
        console.error("locations", error);
        setLoading(false);
    }
};
  // const getLocations = () => {
  //   Api.get(ApiEndpoints.homeLocations).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setMessage("");
  //         setLocations({ ...locations, allLocations: response.data });
  //       } else {
  //         setIsError(true);
  //         setMessage(GetHomeMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetHomeMessage(error.response?.status));
  //     }
  //   );
  // };
  // const GetAllPublicEvents = () => {
  //   let path =
  //     ApiEndpoints.GetAllEvents +
  //     `?resultCount=${0}&monthCount=${1}`;
  //   // Api.get(ApiEndpoints.GetAllEvents).then(
  //   Api.get(path).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setMessage("");
  //         var eventsData = generateEventData(response.data);
  //         setEvents({
  //           allEvents: [...response.data],
  //           filteredEvents: [...eventsData],
  //         });
  //       } else {
  //         setIsError(true);
  //         setMessage(GetHomeMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetHomeMessage(error.response.status));
  //     }
  //   );
  // };


  const fetchPublicEvents = async () => {
    try {
        const response = await Api.get(`${ApiEndpoints.EqPublicEvent.GetAll}`);
        
        let data = response.data.filter((ev: any) => !ev.isDraft);
        setEvents(data);
        setFilteredEvents(data); // Initially, show all events
        setLoading(false);
    } catch (error) {
        console.error("eventList", error);
        setLoading(false);
    }
};


  const getTrainers = () => {
    Api.get(ApiEndpoints.homeTrainers).then(
      (response) => {
        //console.log(response);
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setTrainers(response.data);
        } else {
          setIsError(true);
          setMessage(GetHomeMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetHomeMessage(error.response.status));
      }
    );
  };
  const generateEventData = (source: Array<any>): Array<any> => {
    var data = source?.map((item: IEqEventData) => {
      return {
        data: item,
        alt: item.title,
        type: item.disciplineType,
        price: item.price,
        trainer: item.organizers,
        stutents: item.attendees,
        currency: item.currency,
        title: item.title,
        description: item.description,
        date: moment(item.startDate).format(dateFormat),
        startTime: item.startTime,
        endTime: item.endTime,
        duration: item.duration,
        imgSrc: item.eqImage
          ? item.eqImage.url
          : getDefaultImage(imageType.events),
      };
    });
    return data;
  };
  const updateEventsFilter = (
    disciplineFilter: string[],
    locationFilter: any,
    dates: any,
    isRange: boolean,
    horseAvailability: number
  ) => {
    var filterResult = [] as any;
    if (Array.isArray(disciplineFilter) && disciplineFilter.length > 0) {
      var result = [] as any;
      if (filterResult?.length > 0) {
        filterResult?.filter((item: any) => {
          if (disciplineFilter.includes(EqDisciplineType[item.disciplineType])) {
            result.push(item);
          }
        });
      } else {
        events?.filter((item: any) => {
          if (disciplineFilter.includes(EqDisciplineType[item.disciplineType])) {
            result.push(item);
          }
        });
      }
      filterResult = result;
    } else {
      filterResult = events;
    }

    // if (horseAvailability != null || undefined) {
    //   var result = [] as any;
    //   if (filterResult?.length > 0) {
    //     result = filterResult?.filter((item: any) => {
    //       if (item.horseAvailabilityStatus == 0) {
    //         return true;
    //       } else {
    //         return item.horseAvailabilityStatus == horseAvailability;
    //       }
    //     });
    //   } else {
    //     result = events.allEvents?.filter((item: any) => {
    //       if (item.horseAvailabilityStatus == 0) {
    //         return true;
    //       } else {
    //         return item.horseAvailabilityStatus == horseAvailability;
    //       }
    //     });
    //   }
    //   filterResult = result;
    // }
    if (locationFilter != undefined && locationFilter.length > 0) {
      var result = [] as any;
      if (filterResult?.length > 0) {
        filterResult?.filter((item: any) => {
          if (
            locationFilter?.some((loc: any) => {
              return loc == item.locationKey;
            })
          ) {
            result.push(item);
          }
        });
      } else {
        events?.filter((item: any) => {
          if (
            locationFilter?.some((loc: any) => {
              return loc == item.locationKey;
            })
          ) {
            result.push(item);
          }
        });
      }
      filterResult = result;
    }
    var dateResult = [] as any;
    if (isRange && dates && dates[0] != null && dates[1] != null) {
      dates[0].startOf("day");
      var startTime = dates[0].valueOf();
      dates[1].endOf("day");
      var endTime = dates[1].valueOf();
      filterResult?.filter((item: any) => {
        if (item.startDate > startTime && item.endDate < endTime) {
          dateResult.push(item);
        }
      });
      filterResult = dateResult;
    } else if (isRange == false && dates[0] != null) {
      dates[0].startOf("day");
      var startTime = dates[0].valueOf();
      filterResult?.filter((item: any) => {
        if (item.startDate > startTime) {
          dateResult.push(item);
        }
      });
      filterResult = dateResult;
    }
    setFilteredEvents(filterResult);
  };
  const callRegister = () => {
    navigate(FrontEndPoints.register);
  };
  const callRegisterasTrainer = () => {
    navigate(`${FrontEndPoints.register}?profession=${Professions.trainer}`);
  };
  const callRegisterasRider = () => {
    navigate(`${FrontEndPoints.register}?profession=${Professions.student}`);
  };
  const handleCardClick = (eventId: any) => {
    navigate(`/publicEventSingle?id=${eventId}`); // Navigate with query string
};
  
  // const eventClick = (data: IEqEventData) => {

  //   //console.log(data);
  //   // saveEvent(data);
  //   navigate(`${FrontEndPoints.student.viewEvent}?key=${data.key}`, {
  //     state: { event: data },
  //   });
  // };
  const reachOutClicked = () => {
    if (contactingName == "" || contactingEmail == "") {
      setReachOutMsg("Enter complete information.");
      document.getElementById("reachOutMsg")?.classList.remove("hide");
      document.getElementById("reachOutMsg")?.classList.add("show", "red");
    } else {
      var data = { firstName: contactingName, email: contactingEmail };
      Api.post(ApiEndpoints.homeReachOut, data).then(
        (response) => {
          if (response && response.status == 200) {
            setIsError(false);
            setContactingName("");
            setContactingEmail("");
            setReachOutMsg("Email sent. We will contact to you soon.");
            document.getElementById("reachOutMsg")?.classList.remove("hide");
            document
              .getElementById("reachOutMsg")
              ?.classList.add("show", "green");
          } else {
            setIsError(true);
            setReachOutMsg("Something bad happen.Please try again.");
            document.getElementById("reachOutMsg")?.classList.remove("hide");
            document
              .getElementById("reachOutMsg")
              ?.classList.add("show", "red");
          }
        },
        (error) => {
          setIsError(true);
          setReachOutMsg("Something bad happen.Please try again.");
          document.getElementById("reachOutMsg")?.classList.remove("hide");
          document.getElementById("reachOutMsg")?.classList.add("show", "red");
        }
      );
    }
  };

  React.useEffect(() => {
    //
    // GetAllPublicEvents();
    fetchPublicEvents()
    getEventDisciplines();
    fetchLocations();
    getTrainers();
    createHorseAvailability();
    //
    // if (events?.allEvents?.length > 0) {
    //     updateEventsFilter(events.selectedDiscipline, locations.selectedLocations, dates, isRangeDate, horseAvailbility.selectedAvailability);
    // }
    // updateEventsFilter(disciplines.selectedDiscipline, locations.selectedLocations, dates, isRangeDate, horseAvailbility.selectedAvailability);
  }, []);

  const sortOptions = [
    { key: 0, value: "Upcoming", label: "Upcoming" },
    { key: 1, value: "New", label: "New" },
];


  const sortSelectProps: ISelectProps = {
    classNames: "extra-height",
    placeholder: `${t("select")}`,
    allowClear: true,
    options: sortOptions,
    defaultSelectedValue: sortBy,
    changeFunc: (value: any) => {
        const intVal = value !== undefined ? parseInt(value, 10) : undefined;
        setSortBy(intVal);
    },
};

const typeSelectProps: ISelectProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    isTag: true,
    allowClear: true,
    classNames: "eq-multiSelect eq-searchSelect extra-height",
    ddClassName: "eq-select-dropdown",
    placeholder: `${t("selectType")}`,
    options: Object.entries(EqEventType)
        .filter((e) => !isNaN(e[0] as any))
        .map((e) => ({
            key: parseInt(e[0]),
            value: `${t(e[1] as any)}`,
            label: `${t(e[1] as any)}`,
        })),
    defaultSelectedValue: selectedEventTypes,
    changeFunc: (stringArray: any) => {
        const intArray = stringArray.map((str: any) => parseInt(str, 10));
        setSelectedEventTypes(intArray);
    },
};

const locationSelectProps: ISelectProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "value",
    filterOption: true,
    allowClear: true,
    classNames: "eq-multiSelect eq-searchSelect extra-height",
    ddClassName: "eq-select-dropdown",
    placeholder: `${t("location")}`,
    options: locations,
    defaultSelectedValue: selectedLocations,
    changeFunc: (stringArray: any) => {
        setSelectedLocations(stringArray);
    },
};











  return (
    <TransitionLoad>

      <>
        <EqMessageModal {...userMessageProps}></EqMessageModal>
        <div className="home">
          <div className="page-banner">
            <AsyncImage className="hero-image" src="home/eqFrontPageBanner.webp" />
            <div className="overlay">
              <h1 className="welcome-heading">
                {t("fasterSmootherBooking")}
                <br />
                {t("andAdministration")}
              </h1>
              <h3 className="welcome-text">
                {t("connectsPeopleWthHorses")}
              </h3>
              <div className="create-profile-btn">
                <EqButton
                  type="button"
                  centered="true"
                  onClick={callRegister}
                >
                  {t("getStarted")}
                </EqButton>
              </div>
            </div>
          </div>
          {/* about */}

          <div className="about">
            <Reveal>
              <Row className="about-container" >
                <Col className="about-info" sm={24} md={12}>
                  <h1 className="about-heading">{t("softwareDevelopedForHorseLovers")}</h1>
                  <p className="about-text">{t("spendTimeInOfficePerfectSolution")}</p>
                  <div className="about-sub">
                    <h5 className="about-sub-text">{t("findMoreInformation")}</h5>
                    <AsyncImage className="sub-image" src="home/arrow-about.png" />
                  </div>
                </Col>
                <Col className="about-img" sm={24} md={12}>
                  <Row className="image-container">
                    <Col span={11} className="image-col">
                      <Link to={FrontEndPoints.ridingSchoolFeatures} className="more-info"
                      >
                        <AsyncImage className="image" src="home/school.png" />
                        <div className="overlay overlay-school"></div>
                        <Button className="btn-school btn-type">{t("ridingSchool")}</Button>
                      </Link>
                    </Col>
                    <Col span={11} className="image-col">
                      <Link to={FrontEndPoints.trainerFeatures} className="more-info"
                      >
                        <AsyncImage className="image image-trainer" src="home/trainer.png" />
                        <div className="overlay overlay-trainer"></div>
                        <Button className="btn-trainer btn-type">{t("trainer")}</Button>
                      </Link>
                    </Col>
                    <Col span={11} className="image-col">
                      <Link to={FrontEndPoints.eventHolderFeatures} className="more-info">
                        <AsyncImage className="image image-holder" src="home/holder.png" />
                        <div className="overlay overlay-holder"></div>
                        <Button className=" btn-holder btn-type">{t("eventHolder")}</Button>
                      </Link>
                    </Col>
                    <Col span={11} className="image-col">
                      <Popover placement="bottom" content={"Coming Soon"}>
                        <AsyncImage className="image" src="home/student.png" />
                        <div className="overlay overlay-student"></div>
                        <Button className="btn-student btn-type">{t("horseEnthusiasts")}</Button>
                      </Popover>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Reveal>

          </div>
          {/* contact */}

          <div className="contact">
            <Reveal>
              <Row className="contact-container">
                <Col className="contact-info" sm={24} md={12}>
                  <h1 className="contact-heading">{t("contactUsToSupportYourBusiness")}</h1>
                  <EqButton classNames="contact-btn">{t("contactUs")}</EqButton>
                </Col>
                <Col className="contact-img" sm={24} md={12}>
                  <AsyncImage className="image" src="home/contactUs.png" />
                </Col>
              </Row>
            </Reveal>

          </div>
          {/*categories*/}

          <div className="categories">
            <Reveal>
              <Row justify="center" align="middle">
                <Col sm={24}>
                  <div className="categories-inner">
                    <div className="categories-text">
                      <h3 className="categories-heading">
                        {t("forTheEnthusiast")}
                      </h3>
                      <h2 className="category-description">
                        {t("findAndConnectWithSchoolsAndTrainers")}
                      </h2>
                    </div>

                    <Row className="row category-liks">
                      {categories?.map((cat: any) => (
                        <Col
                          md={8}
                          className="col-md-4 link-category"
                          key={cat.name}
                        >
                          <EqCategory {...cat} />
                        </Col>
                      ))}
                      {/*{disciplines?.allDisciplines?.map((cat: any) =>*/}
                      {/*    <div className="col-md-4 link-category" key={cat.name}>*/}
                      {/*        <EqCategory {...cat} />*/}
                      {/*    </div>*/}
                      {/*)}*/}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Reveal>
          </div>

          {/*events*/}
          <div className="events">
            <Reveal>
              <div className="events-container">
                <Row>
                  <Col span={24} className="event-heading">
                    <h3 className="event-title">{t("findEventsNearYou")}</h3>
                    <p className="event-text">{t("findEventsInYourArea")}</p>
                  </Col>
                </Row>
                {/* filter */}
                {/* <Row>
                  <Col span={24} className="filters">
                   
                    <Row gutter={[16, 16]} className="filter-type">
                      <Col className="location-col" xs={24} sm={24} md={12} lg={11}>
                        <Row className="location-selector">
                          <Col xs={6} sm={5} md={6} lg={5}>
                            <label className="picker-label">{t("location")}</label>
                          </Col>
                          <Col className="location-input" flex="auto">
                            <Select
                              className="eq-filter-radius eq-input-info"
                              mode="multiple"
                              allowClear
                              placeholder="Select location"
                              onChange={handleChange}
                              value={selectedLocations}
                            >
                              {locations.map((location) => (
                                <Select.Option
                                  label={location.value}
                                  value={location.key}
                                  key={location.key}
                                >
                                  {location.value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="radius-col" xs={24} sm={24} md={12} lg={11}>
                        <Row className="radius-selector">
                          <Col xs={6} sm={5} md={6} lg={5}>
                            <label className="picker-label">{t("radius")}</label>
                          </Col>
                          <Col className="radius-input" flex="auto">
                            <Select
                              className="eq-filter-radius eq-input-info"
                              placeholder="Select Radius"
                              onChange={handleRadius}
                              value={radiusValues}
                            >
                              {marks.map((mark) => (
                                <Select.Option value={mark.value} key={mark.key}>
                                  {mark.value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="date-col " xs={24} sm={24} md={12} lg={11}>
                        <Row className="date-selector  start-date-selector">
                          <Col xs={6} sm={5} md={6} lg={5} >
                            <label className="picker-label">{t("startDate")}</label>
                          </Col>
                          <Col className="date-input" flex="auto">
                            <DatePicker
                            placeholder={t("selectDate")}
                              className="eq-filter-radius eq-input-info"
                              value={startDate}
                              onChange={onStartDateChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="date-col" xs={24} sm={24} md={12} lg={11}>
                        <Row className="date-selector">
                          <Col xs={6} sm={5} md={6} lg={5}>
                            <label className="picker-label">{t("endDate")}</label>
                          </Col>
                          <Col className="date-input" flex="auto">
                            <DatePicker
                            placeholder={t("selectDate")}
                              className="eq-filter-radius eq-input-info"
                              value={endDate}
                              onChange={onEndDateChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="categories-col " xs={24} sm={24} md={12} lg={11}>
                        <Row className="categories-selector">
                          <Col xs={6} sm={5} md={6} lg={5}>
                            <label className="picker-label">{t("discipline")}</label>
                          </Col>
                          {disciplines && disciplines?.allDisciplines?.length > 0 && (
                            <Col className="categories-input" flex="auto">
                              <Select
                                mode="multiple"
                                allowClear
                                placeholder={t("selectDicipline")}
                                className="eq-filter-radius eq-input-info"
                                value={isAllSelected ? ["All"] : selectedDiscipline}
                                onChange={disciplineClicked}
                              >
                                <Select.Option key="All" value="All">
                                  {t("all")}
                                </Select.Option>
                                {disciplines?.allDisciplines
                                  ?.filter((item: any) => item.name !== "Product" && item.name !== "Open")
                                  .map((item: any) => (
                                    <Select.Option key={item.name} value={item.name}>
                                      {t(item.name)}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                        <div className="search">
                          <EqButton classNames="eq-input-info" type="primary" htmlType="button">
                            {t("search")}
                          </EqButton>
                        </div>
                      </Col>
                    </Row>
          
                  </Col>
                </Row> */}

                <Row justify="space-between" align="middle" className="filters">
                <Col>
                    <Typography.Title level={2} className="title">
                        {t("publicEvents")}
                    </Typography.Title>
                </Col>
                <Col span={18}>
                    <Row gutter={[16, 0]} align="middle">
                        <Col span={8}>
                            <Row align={"middle"}>
                                <span style={{ paddingRight: "8px" }}>{t("sortBy")}</span>
                                <EqSelect {...sortSelectProps} />
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row align={"middle"}>
                                <span style={{ paddingRight: "8px" }}>{t("filterBy")}</span>
                                <EqSelect {...locationSelectProps} />
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row align={"middle"}>
                                <span style={{ paddingRight: "8px" }}>{t("filterBy")}</span>
                                <EqSelect {...typeSelectProps} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
                {/* end filter */}
                <Carousel afterChange={onCarouselChange}>
                  <div>
                    <Row>
                      {filteredEvents &&
                        filteredEvents?.length > 0 &&
                        filteredEvents.length < 12
                        ?filteredEvents
                          .slice(0, 8)
                          .map((evt: any, index: number) => (
                            <Col
                              xs={24}
                              sm={12}
                              md={8}
                              lg={8}
                              xl={6}
                              className="event-card-col"
                              // onClick={() => eventClick(evt.data)}
                              key={index}
                            >
                              {/* <EqCardEvent key={index} {...evt} /> */}
                              <EqPublicEventCard
                                eventData={evt}
                                onCardClick={() => handleCardClick(evt.id)}
                            />
                            </Col>
                          ))
                        : filteredEvents
                          .slice(0, 12)
                          .map((evt: any, index: number) => (
                            <Col
                              xs={24}
                              sm={12}
                              md={8}
                              lg={8}
                              xl={6}
                              className="event-card-col"
                              // onClick={() => eventClick(evt.data)}
                              key={index}
                            >
                              <EqPublicEventCard
                                eventData={evt}
                                onCardClick={() => handleCardClick(evt.id)}
                            />
                              {/* <EqCardEvent key={index} {...evt} /> */}
                            </Col>
                          ))}
                    </Row>
                  </div>
                </Carousel>
                <Row>
                  <Col span={24}>
                    <Link to={FrontEndPoints.allPublicEvents} className="more-events">
                      <EqButton className="more-event-btn" >{t("moreEvents")}</EqButton>
                    </Link>
                  </Col>
                  {/* <EqCardEvent/> */}
                </Row>
              </div>
            </Reveal>
          </div>
          {/*reachout*/}
          {/* <div className="reach-out">
          <div className="react-out-overlay">
            <div className="container">
              <Row className="about-us">
                <Col className="column roles" xs={24} sm={24}>
                  <div className="desc trainer">
                    <h4 className="heading">
                      {t("Equinet provides service professionals with ...")}
                    </h4>
                    <h5 className="sub-heading">
                      {t(
                        "a full overview of their customers bookings and payments."
                      )}
                    </h5>
                    <p className="explanation">
                      {t(
                        "a full overview of their customers bookings and payments."
                      )}
                    </p>
                    <a className="more">Learn More</a>
                    <EqButton
                      type="primary"
                      className="register-btn"
                      htmlType="button"
                      onClick={callRegisterasTrainer}
                    >
                      {t("Register as a trainer ")}
                    </EqButton>
                  </div>
                  <div className="desc rider">
                    <h4 className="heading">
                      {t("Equinet makes it easier for riders and horse owners")}
                    </h4>
                    <h5 className="sub-heading">
                      {t("to find and book services for their horse.")}
                    </h5>
                    <p className="explanation">
                      {t(
                        "It has never been easier to find your nearest veterinary, try a new trainer or find other stables near you. Scroll through the offers that exists for you and your horse, the next booking is only a click away."
                      )}
                    </p>
                    <a className="more">Learn More</a>
                    <EqButton
                      type="primary"
                      className="register-btn"
                      htmlType="button"
                      onClick={callRegisterasRider}
                    >
                      {t("Register as a rider ")}
                    </EqButton>
                  </div>
                </Col>
                <Col className="column contacts" xs={24} sm={24}>
                  <div className="desc map">
                    <h4 className="heading">{t("findEquinet")}</h4>
                    <p className="explanation">Find us near you.</p>
                    <div className="map-frame">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1999.666220329814!2d10.731134715987489!3d59.92108667047658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e7913e2a529%3A0xd56463578e04ce80!2sOsloMet%20%E2%80%93%20storbyuniversitetet!5e0!3m2!1sen!2sno!4v1653375222734!5m2!1sen!2sno" width="100%" height="300" style={mapStyle} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </div>
                  <div className="desc form">
                    <h4 className="heading">{t("Reach Out:")}</h4>
                    <p className="explanation">
                      {t(
                        "Our team is happy to answer all you questions, fill out the form below and we’ll be in touch with you."
                      )}
                    </p>
                    <form className="react-out">
                      <EqInput
                        className="name-input"
                        value={contactingName}
                        placeholder="Name"
                        onChange={nameChanged}
                      />
                      <EqInput
                        className="email-input"
                        value={contactingEmail}
                        placeholder="emailaddress@domain.com"
                        onChange={emailChanged}
                      />
                      <EqButton
                        type="primary"
                        className="light-bg"
                        htmlType="submit"
                      >
                        React out
                      </EqButton>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div> */}
          <div className="partners">
            <div className="overlay">
              <div className="container">
                <Row>
                  <Col span={24}>
                    <h3 className="heading">Our Partners</h3>
                    <p className="description">
                      Easy service platform where you can find everything you need
                      a few clicks away
                    </p>
                  </Col>
                </Row>
                <Row>
                  {trainers?.length < 12
                    ? trainers.slice(0, 8).map((d: any, idx: any) => (
                      <Col
                        xs={24}
                        sm={12}
                        md={6}
                        className="partner-item mt-12"
                        key={idx}
                      >
                        <AsyncImage
                          alt="Partner"
                          src={
                            d?.userImage?.url ||
                            getDefaultImage(imageType.partner)
                          }
                          className="eq-img"
                        />
                        <div className="eq-caption">{d.firstName}</div>
                        <a
                          className="eq-link"
                          href={`${FrontEndPoints.trainer.profileBoard}?key=${d.key}`}
                        >
                          More Description
                        </a>
                      </Col>
                    ))
                    : trainers.slice(0, 12).map((d: any, idx: any) => (
                      <Col
                        xs={24}
                        sm={12}
                        md={6}
                        className="partner-item mt-12"
                        key={idx}
                      >
                        <AsyncImage
                          alt="Partner"
                          src={
                            d?.userImage?.url ||
                            getDefaultImage(imageType.partner)
                          }
                          className="eq-img"
                        />
                        <div className="eq-caption">{d.firstName}</div>
                        <a
                          className="eq-link"
                          href={`${FrontEndPoints.trainer.profileBoard}?key=${d.key}`}
                        >
                          More Description
                        </a>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </>
    </TransitionLoad>

  );
}