// useValidation.ts
import { useTranslation } from "react-i18next";
import { openNotificationWithIcon } from "../../../shared/Common";

const useCourseValidation = () => {
    const { t } = useTranslation();

    const validateData = (data: any): boolean => {
        let isComplete = true;
        const msg = t("incompleteData");
        const desc = t("isEmpty");

        if (!data.title) {
            openNotificationWithIcon(msg, `${t("title")} ${desc}`);
            isComplete = false;
        } else if (!data.image) {
            openNotificationWithIcon(msg, `${t("image")} ${desc}`);
            isComplete = false;
        } else if (!data.courseTrainerId) {
            openNotificationWithIcon(msg, `${t("trainer")} ${desc}`);
            isComplete = false;
        } else if (!data.lessons) {
            openNotificationWithIcon(msg, `${t("lessons")} ${desc}`);
            isComplete = false;
        } else if (!data.lessonCapacity) {
            openNotificationWithIcon(msg, `${t("lessonCapacity")} ${desc}`);
            isComplete = false;
        } else if (!data.coursePrice || isNaN(data.coursePrice)) {
            openNotificationWithIcon(msg, `${t("coursePrice")} ${desc}`);
            isComplete = false;
        } else if (!data.lessonPrice || isNaN(data.lessonPrice)) {
            openNotificationWithIcon(msg, `${t("lessonPrice")} ${desc}`);
            isComplete = false;
        }else if (!data.startDate) {
            openNotificationWithIcon(msg, t("startDate") + desc);
            isComplete = false;
        } else if (data.repeatType===undefined) {
            openNotificationWithIcon(msg, t("repeatType") + desc);
            isComplete = false;
        } else if (!data.endDate) {
            openNotificationWithIcon(msg, t("endDate") + desc);
            isComplete = false;
        }
        
        
        else if (!data.lessonDurationMinutes || isNaN(data.lessonDurationMinutes)) {
            openNotificationWithIcon(msg, `${t("lessonDurationMinutes")} ${desc}`);
            isComplete = false;
        // } else if (!data.vat || isNaN(data.vat)) {
        //     openNotificationWithIcon(msg, `VAT ${desc}`);
        //     isComplete = false;
        } else if (!data.lessonStartTime) {
            openNotificationWithIcon(msg, `${t("time")} ${desc}`);
            isComplete = false;
        } else if (data.currency === undefined) {
            openNotificationWithIcon(msg, `Currency ${desc}`);
            isComplete = false;
        }

        return isComplete;
    };

    return validateData;
};

export default useCourseValidation;
