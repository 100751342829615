import React, { useState, useEffect } from "react";
import "./EqTrainerProfileBoard.scss";
import { Row, Col, message } from "antd";
import Api from "../../shared/Api";
import {
  GetEventMessage,
  ApiEndpoints,
  ValidateIsTrainer,
  getEmailFromToken,
  getDefaultImage,
  imageType,
  checkDuePayment,
  ValidateIsStudent,
  ValidateStudentCanBook,
  ValidateStudentCanPay,
  openNotificationWithIcon,
} from "../../shared/Common";
import {
  EqDisciplineType,
  EqEventUserType,
  EqLocationType,
  EqSlotStatusEnum,
  ProductType,
  eqNotificationTypes,
} from "../../shared/enums";
import { IProfileDto } from "../../shared/interfaces/IProfileDto";
import EqButton from "../../custom/eqButton/EqButton";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import EqBookSession from "../eqBookSession/EqBookSession";
import history from "../../shared/history";
import { useAuthState } from "../../context/Context";
import { FrontEndPoints, ValidateIsUser } from "../../shared/Common";
import { notification } from "antd";
import { saveEvent } from "../../shared/LocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckCircleTwoTone,
  CopyFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons/lib/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import config from "../../shared/Config";
import { EqInput } from "../../custom/eqInput/EqInput";
import { useTranslation } from "react-i18next";
import { ISubscribeEventDto } from "../../shared/interfaces/ISubscribeEventDto";
import EqTags from "../../custom/eqTags/EqTags";
import { IGetTrainingDto } from "../../shared/interfaces/trainingDto/IGetTrainingDto";
import modal from "antd-v5/es/modal";
import { motion } from "framer-motion";
import TransitionLoad from "../../custom/TransitionLoad/TransitionLoad";
import EqTrainerSchedule from "./eqTrainerSchedule/EqTrainerSchedule";
import EqSendMessageModal from "../eqMessageBoard/eqSendMessageModal/EqSendMessageModal";

const EqTrainerProfileBoard = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = useAuthState();
  const isSignedIn: boolean = ValidateIsUser(userDetails);
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const studentCanBook: boolean = ValidateStudentCanBook(userDetails);
  const studentCanPay: boolean = ValidateStudentCanPay(userDetails);
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const [isOwner, setIsOwner] = React.useState(false);
  const currentUserKey = getEmailFromToken();
  const [messageApi, contextHolder] = message.useMessage();
  const { t, i18n } = useTranslation();

  const [messageString, setMessageString] = useState("");
  const [schedules, setSchedules] = useState([] as any);
  const [isError, setIsError] = React.useState(false);
  const [messageText, setMessageText] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");
  // const [message, setMessage] = React.useState("");

  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [imagesData, setImagesData] = React.useState([]) as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [eventsList, setEventsList] = React.useState([] as Array<any>);
  const [publicEventsList, setPublicEventsList] = React.useState([] as Array<any>);

  const [trainings, setTrainings] = React.useState([] as Array<any>);
  const [trainerHorsesList, setTrainerHorsesList] = React.useState({});
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);

  const [profileData, setProfile] = useState<IProfileDto>();
  const [stopMultipleRequest, setStopMultipleRequest] = useState(false);
  const [bookingButtonState, setBookingButtonState] = React.useState({
    buttonDisabled: false,
    buttonText: t("bookASession"),
  } as any);
  const [subscribeButtonState, setSubscribeButtonState] = React.useState({
    buttonDisabled: false,
    buttonText: "Subscribe",
    // buttonTextSubscribe: "Subscribe",
    // buttonTextSubscribed: "Subscribed",
  } as any);

  const [productsList, setProductsList] = React.useState({
    all: [],
    private: [],
    public: [],
  }) as any;

  const [activitiesList, setActivitiesList] = React.useState({
    all: [],
    events: [],
    trainings: [],
  }) as any;
  const [userEmail, setUserEmail] = React.useState("");

  const [openEventList, setOpenEventList] = React.useState(
    [] as Array<IEqEventData>
  );
  const [selectedUserKey, setSelectedUserKey] = useState() as any;

  schedules.map((f: IEqEventData, index: number) => {
    f.display = index == 0 ? true : false;
  });


  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState<string>('');

  const showMessageModal = (email: string) => {
    
    setSelectedEmail(email);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };




  const handleSendMessage = async (data: any) => {
    try {
        const response = await Api.post(ApiEndpoints.UserMessage.SendMessageToEmail, data);

        if (response.status === 200) {
        
            openNotificationWithIcon(
                t("sent"),
                t("successfullySentMessage"),
                eqNotificationTypes.success
            );
        } else {
            console.error("Unexpected response status:", response.status);
        }
    setIsModalVisible(false);

    } catch (error) {
        openNotificationWithIcon(
            t("error"),
            t("errorOccurredSending"),
            eqNotificationTypes.error
        );
    setIsModalVisible(false);

    }
};


  const messageClicked = () => {
    if (ValidateIsUser(userDetails)) {
      var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${userEmail}`;
      navigate(out1);
    } else {
      var out1 = FrontEndPoints.login;
      navigate(out1);
    }
  };

  const formatProductListData = (data: any) => {
    var privateList = data?.filter(
      (opt: any) => opt.type == ProductType.Private
    );
    var publicList = data?.filter((opt: any) => opt.type == ProductType.Public);

    var productList = {
      all: [...data],
      private: [...privateList],
      public: [...publicList],
    };
    setProductsList({ ...productList });
  };
  
  const getOpenEvents = () => {
    let list = [] as any;
    eventsList?.map((item: IEqEventData) => {
      if (item.disciplineType == EqDisciplineType.Open) {
        list.push(item);
      }
    });
    setOpenEventList(list);
  };

  const GetProfileDetails = async (key?: any) => {
    setMessageString("");
    
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;

    try {
      const response = await Api.post(ApiEndpoints.GetTrainerProfileByKey, {
        Key: trainerKey,
      });

      if (response && response.status === 200) {
        const data = response.data ? { ...response.data } : null;

        if (data != null) {
          console.log("trainer-profile");
          console.log(data.key);
          console.log(data.email);

          setSelectedUserKey(data.key);
          setUserEmail(data.email);



          if (data.isMyTrainer) {
            setSubscribeButtonState({
              ...subscribeButtonState,
              buttonDisabled: true,
              buttonText: "Subscribed",
            });
          } else {
            if (
              location.state?.prevPath === FrontEndPoints.login &&
              isStudent
            ) {
              confirmUserTermsAndSubscribe(data);
            }
          }

          const profList = data.disciplines?.map(
            (item: any) => EqDisciplineType[Number(item.discipline)]
          );

          const profile: any = {
            email: data.email,
            about: data.aboutMe,
            name:
              (data.firstName ? data.firstName : "") +
              " " +
              (data.lastName ? data.lastName : ""),
            rating: 4.5,
            experience: "10 Years",
            area: data.locations?.find(
              (opt: any) => opt.location?.type === EqLocationType.Home
            ),
            radius: "20Km",
            disciplineList: data.disciplines,
            imagePath: data.imagePath,
            display: false,
            professionsList: profList,
            userImage: data.userImage,
            trainingLevels: data.trainingLevels,
            serviceAreaList: data.locations?.filter(
              (opt: any) => opt.location?.type === EqLocationType.Work
            ),
            trainerTerms: data.trainerTerms,
          };

          setProfile(profile);
        }
      } else {
        setMessageString("Not found");
      }
    } catch (err) {
      setMessageString("Not found");
    }
  };

  // const GetTrainerPublicEventsByKey = async (key?: any) => {
  //   setMessageString("");
  //   const params = new URLSearchParams(window.location.search);
  //   let trainerKey = params.get("key") || key;

  //   let path = ApiEndpoints.UserPublicEvent.GetPublicEventsByKeyForMonths;
  //   Api.get(path + `?userKey=${trainerKey}`).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         var data = response.data ? [...response.data] : new Array<any>();
  //         setEventsList(data);
  //       } else {
  //         setIsError(true);
  //         setErrorMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setErrorMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };


  const GetTrainerPublicEventsByKey = async (key?: any) => {
    setMessageString("");
    const params = new URLSearchParams(window.location.search);
    let userKey = params.get("key") || key;

    let path = ApiEndpoints.EqOwnerPublicEvent.GetByUserKey+`/${userKey}`
    Api.get(path ).then(
      (response) => {
        if (response && response.status === 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          
          const sortedEvents=data.filter(d=>!d.isDraft).sort((a, b) => {
          
            return b.createdDate - a.createdDate; // Sort by descending order (most recent first)
        });
          setPublicEventsList(sortedEvents);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerProductsByKey = async (key?: any) => {
    setMessageString("");
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;

    let path = ApiEndpoints.GetUserProductByUserKey;
    Api.get(path + `?userKey=${trainerKey}`).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          if (data.length > 0) {
            formatProductListData(data);
          }
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  useEffect(() => {
    // console.log("--Trainer-Profile---");

    const params = new URLSearchParams(window.location.search);
    var key = params.get("key");
    var userKey;
    if (key) {
      userKey = Number(key);
      GetProfileDetails(userKey);
      GetTrainerPublicEventsByKey(userKey);
      GetTrainerProductsByKey(userKey);
      setSelectedUserKey(userKey);

      if (userKey === userDetails.key) {
        setIsOwner(true);
        GetTrainerTrainingsByKey(userKey);
        // GetTrainerPublicEvents();

        getImages();
        getAreas();
        getWeekDays();
        getPopulation();
        getGroupPopulation();

        GetTrainerStudents();
        GetAllTrainerHorses();
      } else {
        setIsOwner(false);
        GetAllTrainerTrainingsLessonsByTrainerKey(userKey);
      }
    } else {
      userKey = 0;
      setSelectedUserKey(0);
    }

    // join training
  }, []);
  // }, [props,profileData]);

  // 2nd use Effect Calls when profile and trainings update
  useEffect(() => {
    if (isStudent) {
      if (location.state?.joinTrainingKey) {
        // join training
        if (profileData && trainings) {
          // get Training
          var training = trainings.find(
            (t: any) => (t.key = location.state.joinTrainingKey)
          );

          if (training) {
            if (!stopMultipleRequest) {
              setStopMultipleRequest(true);
              StudentJoinTrainingRequest(training);
            }
          }
        }
      }
    }
  }, [profileData, trainings, stopMultipleRequest]); // This effect runs whenever 'data' changes

  const StudentJoinTrainingRequest = (event: any) => {
    let data: ISubscribeEventDto = {
      ownerKey:
        event?.organizers != undefined ? event?.organizers[0].email : "",
      subscriberKey: currentUserKey,
      eventKey: event.key,
      slotStartTime: 0,
      slotStatus: EqSlotStatusEnum.Booked,
      eventUserType: EqEventUserType.Attendee,
    };

    Api.post(ApiEndpoints.StudentJoinTraining, data).then(
      (response) => {
        if (response && response.data != null) {
          if (response.data.slotStatus != 3) {
            if (studentCanPay && studentCanPay) {
              openNotificationCheckOutNotification(
                "Pay for the accepted Activity",
                "",
                event
              );
            }
          } else {
            openNotificationWithIcon(
              "Joined And Paid! ",
              "Training Joined and Paid",
              eqNotificationTypes.success
            );
          }
          //console.log(response);
          GetAllTrainerTrainingsLessonsByTrainerKey(selectedUserKey);
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const CreateCheckoutSession = (event: any) => {
    var eventKey = event?.key;
    Api.post(ApiEndpoints.StudentTrainingStripeCheckout.Create, {
      key: eventKey,
    })
      .then((response) => {
        console.log(response);
        window.location.href = response.data.sessionUrl;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "Error!",
          "Unable to Create checkout",
          eqNotificationTypes.error
        );
      });
  };

  const openNotificationCheckOutNotification = (
    msg: any,
    desc: any,
    event: any
  ) => {
    const btn = (
      <div className="right">
        <EqButton onClick={() => CreateCheckoutSession(event)}>
          Pay Now
        </EqButton>
      </div>
    );
    notification["info"]({
      message: msg,
      btn,
      description: desc,
    });
  };

  const getImages = () => {
    Api.get("image/getEventImages").then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setErrorMessage("Error Occurred while fetching images from server");
      }
    );
  };
  const GetAllTrainerHorses = () => {
    Api.get(ApiEndpoints.GetAllHorses).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainerHorsesList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerStudents = () => {
    Api.get(ApiEndpoints.GetTrainerStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var trainerStudents = response.data;
          setIsError(false);
          // console.log(userDetails);
          setErrorMessage("");
          setTrainerStudents(response.data);
          // console.log(trainerHorsesList);
          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.Location.GetUserAreaOptions).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setErrorMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerTrainingsByKey = (key?: any) => {
    setErrorMessage("");
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;

    Api.get(
      ApiEndpoints.TrainerTraining.GetTrainerTrainingsByTrainerKeyOfMonths +
        `?trainerKey=${trainerKey}`
    ).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data
            ? [...response.data]
            : new Array<any>();
          data.map((f: any, index: number) => {
            f.display = index == 0 ? true : false;
          });
          setTrainings(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetAllTrainerTrainingsLessonsByTrainerKey = (key?: any) => {
    setErrorMessage("");
    const params = new URLSearchParams(window.location.search);
    let trainerKey = params.get("key") || key;
    Api.get(
      ApiEndpoints.TrainerTraining.GetAllTrainerTrainingsLessonsForStudents +
        `?trainerKey=${trainerKey}`
    ).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data
            ? [...response.data]
            : new Array<any>();
          data.map((f: any, index: number) => {
            f.display = index == 0 ? true : false;
          });
          setTrainings(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainings != undefined) {
      const newTrainings = [...trainings];
      var foundIndex = newTrainings.findIndex((x) => x.key == oldData.key);
      newTrainings[foundIndex] = newData;
      setTrainings(newTrainings);
    }
  };

  const handleOnUpdateEvent = (newData: any, oldData: any) => {
    if (eventsList != undefined) {
      const events = [...eventsList];
      var foundIndex = events.findIndex(
        (x) => x.referenceKey == oldData.referenceKey
      );
      events[foundIndex] = newData;
      setEventsList(events);
    }
  };

  const handleRemoveTraining = (
    trainingData: IGetTrainingDto,
    refund?: boolean
  ) => {
    let path =
      ApiEndpoints.TrainerTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;

    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeTrainingItem(trainingData);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleRemoveEvent = (data: IEqEventData, refund?: boolean) => {
    let path =
      ApiEndpoints.DeleteTrainerEvent + `?key=${data.key}&refund=${refund}`;

    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeEventItem(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeEventItem = (data: any) => {
    if (eventsList != undefined) {
      var updatedList =
        eventsList != undefined
          ? eventsList.filter((item) => item.key !== data.key)
          : new Array<any>();
      setEventsList(updatedList);

      eventsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  const removeTrainingItem = (data: any) => {
    if (trainings != undefined) {
      var updatedList =
        trainings != undefined
          ? trainings.filter((item) => item.key !== data.key)
          : new Array<any>();
      setTrainings(updatedList);

      trainings.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  const [sessionBookingProps, setSessionBookingProps] = React.useState({
    title: t("bookASession"),
    visible: false,
    email: userEmail,
    trainerKey: selectedUserKey,
  });

  const openNotificationWithIconX = (msg: any, desc: any, event: any) => {
    const key = `open${Date.now()}`;
    const btn = (
      <EqButton type="button" onClick={() => viewEvent(event)}>
        Pay Now
      </EqButton>
    );
    notification["error"]({
      message: msg,
      btn,
      key,
      description: desc,
    });
  };
  const viewEvent = (event: IEqEventData) => {
    saveEvent(event);
    const key = `open${Date.now()}`;
    notification.close(key);
    history.push(`${FrontEndPoints.student.viewEvent}?key=${event.key}`, {
      event: event,
    });
  };

  const destroyAllModals = () => {
    modal.destroyAll();
  };

  const confirmUserTermsAndSubscribe = (userData?: any) => {
    let terms = profileData?.trainerTerms || userData?.trainerTerms;
    destroyAllModals();
    modal.confirm({
      width: 1000,
      title: "Terms And Conditions",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p style={{ whiteSpace: "pre-wrap" }}>
          {" "}
          <div dangerouslySetInnerHTML={{ __html: terms }} />
        </p>
      ),
      okText: "Accept",
      onOk: () => SubscribeTrainer(userData),
      onCancel: () => {
        destroyAllModals();
      },
    });
  };

  const SubscribeTrainer = (userData?: any) => {
    const data = {
      trainerKey: userEmail || userData.email,
      subscriberKey: currentUserKey,
    };

    Api.post(ApiEndpoints.SubscribeTrainer, data).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessageText("");

          if (response.data) {
            setMessageText("User Subscribed Trainer");
            console.log("User Subscribed Trainer");
            GetProfileDetails(selectedUserKey);
          } else {
            setMessageText("User is unable Subscribed Trainer");
            console.log("User is unable Subscribed Trainer");
          }
        } else {
          GetProfileDetails(selectedUserKey);

          setIsError(true);
          // setMessageText(GetBookSessionMessage(response.status));
        }
      },
      (error) => {
        GetProfileDetails(selectedUserKey);

        setIsError(true);
        console.log(error);

        // setMessageText(GetBookSessionMessage(error.response.status));
      }
    );
  };
  const handleCopyLink = () => {
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };
  const changeBookSessionView = () => {
    if (ValidateIsUser(userDetails)) {
      checkDuePayment(openNotificationWithIconX).then(
        (success: any) => {
          console.log("success", success);
          if (success == true) {
          } else {
            setSessionBookingProps({
              ...sessionBookingProps,
              visible: true,
              email: userEmail,
              trainerKey: selectedUserKey,
            });
          }
        },
        (err: any) => {
          console.log("error", err);
        }
      );
    } else {
      navigate(FrontEndPoints.login);
    }
  };

  const onChange = (key: any) => {
    console.log(key);
  };

  return (
    <>
      <TransitionLoad>
        <>
          <EqBookSession {...sessionBookingProps} />
          <div className="eq-trainer-profile-container">
            <Row>
              <Col md={6} xs={24} sm={24} className="trainer-info">
                <Row className="profile-img">
                  <Col>
                    <img
                      src={
                        profileData?.userImage?.url ||
                        getDefaultImage(imageType.partner)
                      }
                      className="user"
                      alt="user"
                    />
                  </Col>
                </Row>
                <Row className="profile-name">
                  <Col>{profileData?.name}</Col>
                </Row>
                <Row className="profile-certified">
                  <Col>{t("certifiedTrainer")}</Col>
                </Row>
                <Row className="contact">
                  <div 
                      onClick={(e:any) => { 
                        e.stopPropagation()
                        showMessageModal(userEmail);
                      }}
                  
                  // onClick={messageClicked} 
                  className="about-message">
                    <Row align="middle">
                      <Col flex="auto" className="msg-text">
                        {t("SendAMessage")}
                      </Col>
                    </Row>
                  </div>
                </Row>

                {/* Subscription Button */}
                {!isSignedIn && (
                  <Row className="eq-subscribe-button">
                    <Col>
                      <EqButton
                        type="button"
                        onClick={() => {
                          navigate(FrontEndPoints.login, {
                            state: {
                              prevPath: location.pathname,
                              trainerKey: selectedUserKey,
                            },
                          });
                        }}
                        disabled={subscribeButtonState.buttonDisabled}
                      >
                        {subscribeButtonState.buttonText}
                        {subscribeButtonState.buttonText == t("subscribed") && (
                          <CheckCircleTwoTone style={{ fontSize: "16px" }} />
                        )}
                      </EqButton>
                    </Col>
                  </Row>
                )}
                {isStudent && (
                  <Row className="eq-subscribe-button">
                    <Col>
                      <EqButton
                        type="button"
                        onClick={() => {
                          confirmUserTermsAndSubscribe();
                        }}
                        disabled={subscribeButtonState.buttonDisabled}
                      >
                        {subscribeButtonState.buttonText}
                        {subscribeButtonState.buttonText == t("subscribed") && (
                          <CheckCircleTwoTone style={{ fontSize: "16px" }} />
                        )}
                      </EqButton>
                    </Col>
                  </Row>
                )}
                <Row className="eq-board-line"></Row>
                <Row>
                  <Col span={10} className="profile-info-h">
                    {t("experience")}:
                  </Col>
                  <Col span={10} className="profile-info-i">
                    {profileData?.experience}
                  </Col>
                </Row>
                <Row>
                  <Col span={10} className="profile-info-h">
                    {t("area")}:
                  </Col>
                  <Col span={10} className="profile-info-i">
                    {profileData?.area?.location?.city}
                  </Col>
                </Row>
                <Row>
                  <Col span={10} className="profile-info-h">
                    {t("radius")}:
                  </Col>
                  <Col span={10} className="profile-info-i">
                    {profileData?.radius}
                  </Col>
                </Row>

                <Row>
                  <Col className="section-heading">{t("studentInvite")}</Col>
                </Row>

                <Row className="eq-invite-link">
                  {contextHolder}

                  <Col span={20}>
                    {" "}
                    <CopyToClipboard
                      text={
                        config.apiUrl +
                        `newUser?trainerKey=${selectedUserKey}&profession=3`
                      }
                      onCopy={handleCopyLink}
                    >
                      <EqInput
                        classNames="eq-input-copy"
                        readOnly
                        //  disabled
                        value={
                          config.apiUrl +
                          `newUser?trainerKey=${selectedUserKey}&profession=3`
                        }
                        suffix={
                          <CopyToClipboard
                            text={
                              config.apiUrl +
                              `newUser?trainerKey=${selectedUserKey}&profession=3`
                            }
                            onCopy={handleCopyLink}
                          >
                            <CopyFilled />
                          </CopyToClipboard>
                        }
                      />
                    </CopyToClipboard>
                  </Col>
                </Row>

                <Row>
                  <Col className="section-heading">{t("areaOfService")}</Col>
                </Row>

                {profileData &&
                  profileData.serviceAreaList &&
                  profileData?.serviceAreaList?.length > 0 && (
                    <>
                      <div className="eq-list">
                        <div className="list">
                          {profileData.serviceAreaList.map(
                            (item: any, index: number) => (
                              <EqTags
                                borderColor
                                className=""
                                text={item.location?.city}
                              ></EqTags>
                            )
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </Col>
              <Col md={18} xs={24} sm={24} className="col-right">
                <Row>
                  <Col md={18} xs={24} sm={24}>
                    <div className="profile-about">
                      {/* <Row className="eq-board-line"></Row> */}

                      <Row className="about-info info">
                        <Col xs={24} sm={24} md={18} className="about-left-col">
                          <Row>
                            <h3 className="about-heading heading">
                              {t("aboutTrainer")}
                            </h3>
                          </Row>
                          <Row className="about-text">{profileData?.about}</Row>
                        </Col>
                        <Col></Col>
                      </Row>

                      <Row className="eq-profile-button">
                        <Col flex="1">
                          <EqButton
                            type="button"
                            block={false}
                            onClick={changeBookSessionView}
                            disabled={bookingButtonState.buttonDisabled}
                          >
                            <span className="btn-session">
                              {t("bookASession")}
                            </span>
                          </EqButton>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6} xs={24} sm={24}>
                    <div className="profile-about">
                      <Row className="about-info info">
                        <Col xs={24} sm={24} md={18} className="about-left-col">
                          <Row>
                            <h3 className="about-heading heading">
                              {t("discipline")}
                            </h3>
                          </Row>

                          {profileData &&
                            profileData.disciplineList &&
                            profileData?.disciplineList?.length > 0 && (
                              <>
                                {profileData?.disciplineList?.map(
                                  (item: any, index: number) => (
                                    <Row>
                                      <Col>
                                        <EqTags
                                          key={index}
                                          borderColor={true}
                                          text={t(
                                            EqDisciplineType[
                                              item.discipline?.type
                                            ]
                                          )}
                                        />
                                      </Col>
                                    </Row>
                                  )
                                )}
                              </>
                            )}
                        </Col>
                      </Row>

                      <Row>
                        {profileData?.area?.location?.city ? (
                          <Col flex="end" className="location">
                            <div>
                              <Col className="loc-info">
                                <Col className="loc-icon">
                                  <span className="">
                                    <img alt="" src="/chart-pie.svg" />
                                  </span>
                                </Col>
                                <Col className="my-loc">
                                  {profileData?.area?.location?.city}
                                </Col>
                              </Col>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                 
                  <EqTrainerSchedule
                    isOwner={isOwner}
                    eventList={[...eventsList]}
                    publicEventList={[...publicEventsList]}
                    trainings={[...trainings]}
                    onCreateOrUpdateTraining={handleOnUpdateTraining}
                    onCreateOrUpdateEvent={handleOnUpdateEvent}
                    onStudentJoin={GetAllTrainerTrainingsLessonsByTrainerKey}
                    onRemoveTraining={handleRemoveTraining}
                    onRemoveEvent={handleRemoveEvent}
                    areas={areas}
                    imagesData={imagesData}
                    weekDays={weekDays}
                    population={population}
                    groupPopulation={groupPopulation}
                    trainerStudents={trainerStudents}
                    studentHorsesList={studentHorsesList}
                    profileData={profileData}
                    activitiesList={activitiesList}
                    publicProducts={[...productsList?.public]}
                    isStudent={isStudent}
                    isSubscribed={subscribeButtonState.buttonDisabled}
                    // subscribeTrainer={SubscribeTrainer}
                  />
                </Row>
              </Col>
            </Row>
          </div>
        </>
      </TransitionLoad>

      <EqSendMessageModal
        visible={isModalVisible}
        recipientEmail={selectedEmail}
        onCreate={handleSendMessage}
        onCancel={handleCancel}
      />

    </>
  );
};
export default EqTrainerProfileBoard;
