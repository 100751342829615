import React from 'react'
import './EqSchoolTrainingListDashbord.scss'

import { Row, Col, Empty, Tabs, Badge } from "antd";
import { FrontEndPoints, ValidateIsTrainer, addDays } from "../../../shared/Common";
import ".././SchoolTrainingList.scss";
import moment from "moment";
import EqSchoolTrainingCollapse from "../../eqSchoolTrainingCollapse/EqSchoolTrainingCollapse";
import { useAuthState } from "../../../context/Context";
import { useTranslation, Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IEqEventSplit } from '../SchoolTrainingList';
import TabPane from "antd/lib/tabs/TabPane";
import { Link } from 'react-router-dom';
import EqButton from '../../../custom/eqButton/EqButton';
import { addMonths } from "date-fns";
import EqSchoolTrainingCollapseDashBoard from '../../EqSchoolTrainingCollapseDashBoard/EqSchoolTrainingCollapseDashBoard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

export interface ISchoolTrainingList {
    trainingList: any;
    trainersOptions: any;
    productOptions: any;
    products: any;
    areas?: any;
    weekDays?: any;
    eventTypes: any;
    population?: any;
    groupPopulation?: any;
    trainerStudents?: any;
    studentHorsesList?: any;
    schoolStudents?: any;
    // if trainer
    trainerSchoolStudents?: any;
    schoolStudentHorsesList?: any;
    SchoolsTrainersOptionsList?: any;

    removeEventFunc?: (trainingData: any, refund?: any) => void;
    editEventFunc?: (data: any) => void;
    removeAttendeeFunc?: (data: any) => void;
    onCreateOrUpdateSchoolTraining?: (data: any, oldData?: any) => void;
}
const EqSchoolTrainingListDashbord = ({
    trainingList,
    trainersOptions,
    productOptions,
    products,
    areas,
    weekDays,
    eventTypes,
    population,
    groupPopulation,
    trainerStudents,
    studentHorsesList,
    schoolStudents,
    trainerSchoolStudents,
    schoolStudentHorsesList,
    SchoolsTrainersOptionsList,
    removeEventFunc,
    editEventFunc,
    removeAttendeeFunc,
    onCreateOrUpdateSchoolTraining,
    ...rest
}: ISchoolTrainingList) => {

    const userDetails: any = useAuthState();
    const location = useLocation();
    const navigate = useNavigate();
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const { t, i18n } = useTranslation();
    const [eventData, setEventData] = React.useState({} as IEqEventSplit);

    const options: Intl.DateTimeFormatOptions = {
        weekday: "long", // Correctly typed as "long" | "short" | "narrow" | undefined
        day: "2-digit",  // Correctly typed as "numeric" | "2-digit" | undefined
        month: "short",  // Correctly typed as "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
        year: "numeric", // Correctly typed as "numeric" | "2-digit" | undefined
    };
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTime = today.getTime();

    const tomorrow = new Date();
    tomorrow.setHours(24, 0, 0, 0);
    const tomorrowTime = tomorrow.getTime();

    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setHours(48, 0, 0, 0);
    const dayAfterTomorrowTime = dayAfterTomorrow.getTime();
    const currentDate = addDays(new Date(), 2).toLocaleDateString(t("dateLang"), options)
    const futureDate = addMonths(new Date(), 1).toLocaleDateString(t("dateLang"), options);

    const dateRange = `${currentDate} to ${futureDate}`;

    const [todayIsCollapseAll, setIsTodayCollapseAll] = React.useState(false);

    const todayCollapseAll = () => {
        setIsTodayCollapseAll(!todayIsCollapseAll);
        eventData?.today.map((item: any) => {
            item.display = !todayIsCollapseAll;
        });
    };

    const [tomorrowIsCollapseAll, tomorrowSetIsCollapseAll] =
        React.useState(false);

    const tomorrowCollapseAll = () => {
        tomorrowSetIsCollapseAll(!tomorrowIsCollapseAll);
        eventData?.tomorrow.map((item: any) => {
            item.display = !tomorrowIsCollapseAll;
        });
    };

    const [laterIsCollapseAll, laterSetIsCollapseAll] = React.useState(false);
    const [tabCounts, setTabCounts] = React.useState({
        today: 0,
        tomorrow: 0,
        later: 0,
    });
    const laterCollapseAll = () => {
        laterSetIsCollapseAll(!laterIsCollapseAll);
        eventData?.later.map((item: any) => {
            item.display = !laterIsCollapseAll;
        });
    };

    React.useEffect(() => {

        var formedData = {} as IEqEventSplit;
        if (trainingList?.length > 0) {
            formedData.today = trainingList?.filter(
                (opt: any) => opt.startDate < tomorrowTime && opt.startDate >= todayTime
            );
            formedData.tomorrow = trainingList?.filter(
                (opt: any) =>
                    opt.startDate < dayAfterTomorrowTime && opt.startDate >= tomorrowTime
            );
            formedData.later = trainingList?.filter(
                (opt: any) => opt.startDate > dayAfterTomorrowTime
            );
            setEventData(formedData);
            setTabCounts({
                today: formedData.today.length,
                tomorrow: formedData.tomorrow.length,
                later: formedData.later.length,
            });
        }
        //formedData.today = trainingList;
        console.log(trainersOptions);
        if (trainerSchoolStudents && schoolStudentHorsesList) {
            console.log(trainerSchoolStudents);
            console.log(schoolStudentHorsesList);
        }
    }, [trainingList, areas, weekDays, trainersOptions]);

    const GetSchoolStudents = (organizer: any): [] => {
        let value = [];

        if (trainerSchoolStudents) {
            value = trainerSchoolStudents.filter((x: any) => {
                if (x.user.email === organizer.email) {
                    var result = x.schoolStudentsDto;
                    return [...result];
                }
            });
        }

        if (value.length > 0) {
            return value[0].schoolStudentsDto;
        } else {
            return value;
        }
    };

    const GetSchoolStudentHorseList = (organizer: any): [] => {
        let value = [];

        if (schoolStudentHorsesList) {
            value = schoolStudentHorsesList.filter((x: any) => {
                if (x.key === organizer.email) {
                    var result = x.studentHorsesList;
                    return [...result];
                }
            });
        }

        if (value.length > 0) {
            return value[0].studentHorsesList;
        } else {
            return value;
        }
    };
    const GetSchoolTrainers = (organizer: any): [] => {
        let value = [];

        if (SchoolsTrainersOptionsList) {
            value = SchoolsTrainersOptionsList.filter((x: any) => {
                if (x.key === organizer.email) {
                    var result = x.trainersOptions;
                    return [...result];
                }
            });
        }

        if (value.length > 0) {
            return value[0].trainersOptions;
        } else {
            return value;
        }
    };
    
    return (
        <>
            <div className="eq-dashboard-list">
                {eventData?.later?.length > 0 ||
                    eventData?.tomorrow?.length > 0 ||
                    eventData?.today?.length > 0 ? (
                    <>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={
                                <span>{t("today")}{" "}
                                    <Badge
                                        count={tabCounts.today}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>}

                                key="1">
                                {eventData?.today?.length > 0 ? (
                                    <>
                                        <div className="eq-el-container">
                                            <Row justify='end'>

                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                        {/* {moment(new Date()).format("dddd, DD MMM YYYY")} */}
                                                        {new Date().toLocaleDateString(t("dateLang"),options as any)}
                                                    </h5>
                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.today.map((item: any, index: number) => (
                                                <>
                                                    <EqSchoolTrainingCollapseDashBoard
                                                        key={index}
                                                        training={item}
                                                        trainersOptions={
                                                            isTrainer
                                                                ? GetSchoolTrainers(item.organizers[0])
                                                                : trainersOptions
                                                        }
                                                        productOptions={productOptions}
                                                        products={products}
                                                        eventTypes={eventTypes}
                                                        areas={areas}
                                                        weekDays={weekDays}
                                                        population={population}
                                                        groupPopulation={groupPopulation}
                                                        schoolStudents={
                                                            isTrainer
                                                                ? GetSchoolStudents(item.organizers[0])
                                                                : schoolStudents
                                                        }
                                                        studentHorsesList={
                                                            isTrainer
                                                                ? GetSchoolStudentHorseList(item.organizers[0])
                                                                : studentHorsesList
                                                        }
                                                        removeEventFunc={removeEventFunc}
                                                        editEventFunc={editEventFunc}
                                                        removeAttendeeFunc={removeAttendeeFunc}
                                                        onCreateOrUpdateSchoolTraining={
                                                            onCreateOrUpdateSchoolTraining
                                                        }
                                                    ></EqSchoolTrainingCollapseDashBoard>
                                                </>
                                            ))}

                                        </div>
                                    </>
                                ) : (
                                    <Empty description={t("noTrainingsAvailable")} />

                                )}
                            </TabPane>
                            <TabPane tab={
                                <span>{t("tomorrow")}{" "}
                                    <Badge
                                        count={tabCounts.tomorrow}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>}

                                key="2">
                                {eventData?.tomorrow?.length > 0 ? (
                                    <>
                                        <div className="eq-el-container">
                                            <Row justify='end'>
                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                    {addDays(new Date(), 1).toLocaleDateString(t("dateLang"), options)}
                                                        {/* {moment(addDays(new Date(), 1)).format(
                                                            ` ${t("dddd")}, DD MMM YYYY`
                                                        )} */}
                                                    </h5>
                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.tomorrow.map((item: any, index: number) => (
                                                <EqSchoolTrainingCollapseDashBoard
                                                    key={index}
                                                    training={item}
                                                    trainersOptions={
                                                        isTrainer
                                                            ? GetSchoolTrainers(item.organizers[0])
                                                            : trainersOptions
                                                    }
                                                    productOptions={productOptions}
                                                    products={products}
                                                    eventTypes={eventTypes}
                                                    areas={areas}
                                                    weekDays={weekDays}
                                                    population={population}
                                                    groupPopulation={groupPopulation}
                                                    schoolStudents={
                                                        isTrainer
                                                            ? GetSchoolStudents(item.organizers[0])
                                                            : schoolStudents
                                                    }
                                                    studentHorsesList={
                                                        isTrainer
                                                            ? GetSchoolStudentHorseList(item.organizers[0])
                                                            : studentHorsesList
                                                    }
                                                    removeEventFunc={removeEventFunc}
                                                    editEventFunc={editEventFunc}
                                                    removeAttendeeFunc={removeAttendeeFunc}
                                                    onCreateOrUpdateSchoolTraining={
                                                        onCreateOrUpdateSchoolTraining
                                                    }
                                                ></EqSchoolTrainingCollapseDashBoard>
                                            ))}

                                        </div>
                                    </>
                                ) : (
                                    <Empty description={t("noTrainingsAvailable")} />
                                )}
                            </TabPane>
                            <TabPane tab={
                                <span>{t("later")}{" "}
                                    <Badge
                                        count={tabCounts.later}
                                        offset={[0, -15]}
                                        className="tab-badge"
                                        size="default"
                                    >
                                    </Badge>
                                </span>}
                                key="3">
                                {eventData?.later?.length > 0 ? (
                                    <>
                                        <div className="eq-el-container">
                                            <Row justify='end'>
                                                <Col className="eq-el-dateHeading">
                                                    <h5 className="eq-el-date">
                                                        {dateRange}
                                                    </h5>
                                                    <div className="eq-el-date-icon hidden-sm">
                                                        <span>
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {eventData.later.map((item: any, index: number) => (
                                                <EqSchoolTrainingCollapseDashBoard
                                                    key={index}
                                                    training={item}
                                                    trainersOptions={
                                                        isTrainer
                                                            ? GetSchoolTrainers(item.organizers[0])
                                                            : trainersOptions
                                                    }
                                                    productOptions={productOptions}
                                                    products={products}
                                                    eventTypes={eventTypes}
                                                    areas={areas}
                                                    weekDays={weekDays}
                                                    population={population}
                                                    groupPopulation={groupPopulation}
                                                    schoolStudents={
                                                        isTrainer
                                                            ? GetSchoolStudents(item.organizers[0])
                                                            : schoolStudents
                                                    }
                                                    studentHorsesList={
                                                        isTrainer
                                                            ? GetSchoolStudentHorseList(item.organizers[0])
                                                            : studentHorsesList
                                                    }
                                                    removeEventFunc={removeEventFunc}
                                                    editEventFunc={editEventFunc}
                                                    removeAttendeeFunc={removeAttendeeFunc}
                                                    onCreateOrUpdateSchoolTraining={
                                                        onCreateOrUpdateSchoolTraining
                                                    }
                                                ></EqSchoolTrainingCollapseDashBoard>
                                            ))}

                                        </div>
                                    </>
                                ) : (

                                    <Empty description={t("noTrainingsAvailable")} />

                                )}

                            </TabPane>
                        </Tabs>





                    </>
                ) : (
                    <div className="eq-el-container">
                        <Empty description={t("noTrainingsAvailable")} />
                    </div>
                )}
                <>
                    <Link to={FrontEndPoints.school.activityBoard}>
                        <EqButton classNames="eq-el-btn">
                            {t("showMore")}
                        </EqButton>
                    </Link>
                </>
            </div>
        </>
    );
}

export default EqSchoolTrainingListDashbord