import React, { useEffect, useState } from "react";
import "./EqFinancialPublicEvent.scss";
import { Row, Col } from "antd";
import { Checkbox } from "antd";
import EqCollapse, { IEqCollapse } from "../../custom/eqCollapse/EqCollapse";
import { paymentStatusType, eqNotificationTypes, EqPaymentStatus } from "../../shared/enums";
import moment from "moment";
import { ApiEndpoints, FrontEndPoints, openNotificationWithIcon } from "../../shared/Common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Api from "../../shared/Api";
import {
    EventClassOrderStatusEnum,
    IEqPublicEventFinancialDto,
} from "../../shared/interfaces/Financials/IEqPublicEventFinancialDto";
import { Modal } from "antd-v5";
import EqPdfFinancialReceipt from "../../../eqComponents/PdfView/eqPdfFinancialReceipt/EqPdfFinancialReceipt";
import { ProfileOutlined } from "@ant-design/icons";

export interface IEqFinancialDetail extends IEqPublicEventFinancialDto {
    selected: boolean;
    display: boolean;
}
export interface IEqFinancial {
    financial: IEqFinancialDetail;
}
const EqFinancialPublicEvent = ({ financial }: IEqFinancial) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [check, setCheck] = useState(financial.selected);
    useEffect(() => {
        checkChange(financial.selected);
        console.log(financial);
    }, [financial]);
    function checkChange(selected: boolean) {
        setCheck(selected);
    }
    function onChange() {
        setCheck(!check);
    }
    const messageClicked = () => {
        var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${financial.user.email}`;
        navigate(out1);
    };
    const invoiceClicked = (id: any) => {
        // setLoading(false);
        Api.post(ApiEndpoints.InvoiceRequest.TrainerInvoice, { id: id }).then(
            (response) => {
                if (response && response?.status === 200) {
                    let result = response.data;
                    // setFormData(result);
                    openNotificationWithIcon(
                        "Success",
                        "Invoice generated.",
                        eqNotificationTypes.success
                    );
                } else {
                    openNotificationWithIcon(
                        "Error",
                        "Unable to Create Invoice, Contact Administrator",
                        // error?.response?.data,
                        eqNotificationTypes.error
                    );
                }
            },
            (error: any) => {
                // setLoading(false);
                openNotificationWithIcon(
                    "Error",
                    "Unable to Create Invoice, Contact Administrator",
                    // error?.response?.data,
                    eqNotificationTypes.error
                );
            }
        );
    };

    const getClass = (param: number) => {
        switch (param) {
            case 0:
                return "purple";
            case 1:
                return "green";
            case 2:
                return "red";
            case 3:
                return "orange";
            default:
                return "orange";
        }
    };
    const CollapseProps: IEqCollapse = {
        display: financial.display,
        header: [
            <Row justify="start" align="middle">
                <Col className="" span={2}>
                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                </Col>
                <Col className="" flex="auto">
                    {financial.user?.firstName} {financial.user?.lastName}
                </Col>
            </Row>,
        ],
    };
    return (
        <div className="eq-fin-pub-event">
            <Row className="eq-f-row hidden-sm">
                <Col className="eq-f-td" span={1}>
                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                </Col>
                <Col className="eq-f-td" span={3}>
                    {financial.user?.firstName} {financial.user?.lastName}
                </Col>
                <Col className="eq-f-td" span={3}>
                    {financial.event.title}
                </Col>
                <Col className="eq-f-td" span={4}>
                    {moment(financial.paymentDate).format("DD MMMM, YYYY HH:mm")}
                </Col>
                <Col className="eq-f-td" span={3}>
                    {financial.eventClass.price}
                </Col>
                
                <Col className="eq-f-td" span={3}>
                    {financial.eventClass?.vat}
                </Col>
                <Col className="eq-f-td" span={3}>
                    <span className={`eq-status-${getClass(financial.slotStatus)}`}>
                        {EqPaymentStatus[financial.payment.status]}
                    </span>
                </Col>

                <Col className="eq-f-td" span={2}>
                    <div className="">
                        {/* <span className="eq-action-invite" onClick={messageClicked}>
              <img alt="" src="/Invite.svg" /> 
            </span> */}
                        {/* <span
                            className=""
                            // onClick={() => invoiceClicked(financial.id)}
                        >
                            <img alt="" src="/Envelop.svg" /> Invoice
                        </span> */}
                        {/*<span className="eq-action-delete"><img alt="" src="/Delete.svg" /></span>*/}
                   
                        <span className="eq-action-invite" onClick={() => setIsModalVisible(true)}>
                            <ProfileOutlined style={{ color: "#FF8838" }} /> {t("invoice")}
                        </span>
                    </div>
                    {/* <div className="action" onClick={() => setIsModalVisible(true)}>
                        Receipt
                    </div> */}
                </Col>
            </Row>

            <div className="hidden-md">
                <EqCollapse {...CollapseProps}>
                    <>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("event")}
                            </Col>
                            <Col className="" span={24}>
                                {/* {EqDisciplineType[financial.event.disciplineType]} */}
                                {financial.event.title}
                                {/* {financial.event} */}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("paymentDate")}
                            </Col>
                            <Col className="" span={24}>
                                {moment(financial.paymentDate).format("DD MMMM, YYYY HH:mm")}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
         
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("amount")}
                            </Col>
                            <Col className="" span={24}>
                                Kr.{" "}
                                <span className="eq-f-currency">{financial.eventClass.price}</span>
                            </Col>
                        </Row>
                
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("status")}
                            </Col>
                            <Col className="" span={24}>
                                <span className={`eq-status-${getClass(financial.slotStatus)}`}>
                                    {EqPaymentStatus[financial.slotStatus]}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("actions")}
                            </Col>
                            <Col className="" span={24}>
                                <div className="">
                                    <span className="eq-action-message" onClick={messageClicked}>
                                        <img alt="" src="/Invite.svg" />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </>
                </EqCollapse>

                <Modal
                    title="Receipt"
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null} // Hide the footer if you don't need it
                    width={1050}
                >
                    <EqPdfFinancialReceipt
                        title="Receipt"
                        bookingId={financial.id}
                        bookingDate={moment(financial.paymentDate).format("DD MMMM, YYYY HH:mm")}
                        purchaserName={`${financial.user.firstName} ${financial.user.lastName}`}
                        totalExcludingVAT={financial.eventClass.price-financial.eventClass.price*(financial.eventClass.vat/100)}
                        totalCost={financial.eventClass.price}
                        headers={["Item", "Price", "VAT"]}
                        data={[financial].map((order) => ({
                            item: order.event.title,
                            // owner: `${order.user.firstName} ${order.user.lastName}`,
                            price: financial.eventClass.price,
                            vat: 5,
                        }))}
                        columns={[
                            { key: "item", width: "50%" },
                            { key: "price", width: "25%" },
                            { key: "vat", width: "25%" },
                        ]}
                        showVAT={true}
                    />
                </Modal>
            </div>
        </div>
    );
};
export default EqFinancialPublicEvent;
