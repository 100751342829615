import React, { useState } from "react";
import { Row, Col, Card, Collapse, Checkbox, Radio, Modal, Divider, Button, Grid } from "antd-v5";
import Title from "antd-v5/es/typography/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCopy, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { EqCurrency, EqEventType } from "../../../components/shared/enums";
import EtTag from "../../eqCustom/EtTag/EtTag";
import EqButton from "../../../components/custom/eqButton/EqButton";
import "./EqEventSingleDetails.scss";
import { EqInput } from "../../../components/custom/eqInput/EqInput";
import { getEmailFromToken, ValidateIsStudent, ValidateIsUser } from "../../../components/shared/Common";
import { useAuthState } from "../../../components/context/Context";
import { Link } from "react-router-dom";
import config from "../../../components/shared/Config";
import AsyncImage from "../../../components/custom/asyncImage/AsyncImage";

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

interface EqEventSingleDetailsProps {
    eventData: any;
    handleAttendUsingName: (name: any) => void;
    attendUsingName: any;
    selectedClasses: { [key: string]: string | string[] };
    handleRadioChange: (alternativeId: string, classId: string) => void;
    handleCheckboxChange: (alternativeId: string, classId: string, checked: boolean) => void;
    calculateTotalPrice: () => number;
    isModalOpen: boolean;
    showModal: () => void;
    handleModalClose: () => void;
    handleCopyLink: () => void;
    handleNextClick: () => void;

    linkCopied: boolean;
    t: (key: string) => string; // Translation function
}

const EqEventSingleDetails: React.FC<EqEventSingleDetailsProps> = ({
    eventData,
    handleAttendUsingName,
    attendUsingName,
    selectedClasses,
    handleRadioChange,
    handleCheckboxChange,
    calculateTotalPrice,
    isModalOpen,
    showModal,
    handleModalClose,
    handleCopyLink,
    handleNextClick,
    linkCopied,
    t,
}) => {
    const dateToday = new Date();
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const userDetails: any = useAuthState();
    // const isLoggedInUser: boolean = ValidateIsUser(userDetails);
    // const isStudent: boolean = ValidateIsStudent(userDetails);

    const currentUserEmail = getEmailFromToken();
    const isOwner: boolean = currentUserEmail === eventData?.user?.email;


    const screens = useBreakpoint(); // Detect screen size

    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isRulesChecked, setIsRulesChecked] = useState(false);

    const [isHouseTermsModalVisible, setIsHouseTermsModalVisible] = useState(false);
    const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);

    const handleHouseTermsClick = () => {
        // if (eventData?.useHouseTerms) {
        setIsHouseTermsModalVisible(true);
        // }
    };

    // Checkbox change handlers
    const handleTermsChange = (e: any) => {
        setIsTermsChecked(e.target.checked);
    };

    const handleRulesChange = (e: any) => {
        setIsRulesChecked(e.target.checked);
    };

    // Disable the "Next" button until both checkboxes are checked
    const isNextButtonDisabled =
        !isTermsChecked || !isRulesChecked || !attendUsingName || attendUsingName.trim() === "";
    // const isNextButtonEnabled = termsAccepted && houseRulesAccepted && attendUsingName.trim() !== '';

    if (!eventData) {
        return <div>Loading...</div>;
    }
    return (
        <div className="eq-single-event-details">
   { !screens.md &&         <Row>
                <AsyncImage
                    style={{ width: "100%",paddingBottom:"25px" }}
                    src={eventData.image.url}
                />
            </Row>}

            <Row gutter={[16, 16]}>
                {/* Left Column for Event Info */}
                <Col className="" xs={24} md={16}>
                    <Row>
                        <Title level={3} className="eq-create-event-title">
                            {eventData?.title}
                        </Title>
                    </Row>
                    <Row className="discipline-row">
                        {eventData?.disciplineTypes?.length > 0 &&
                            eventData?.disciplineTypes.map((type: any) => (
                                <EtTag border>{EqEventType[type]}</EtTag>
                            ))}
                    </Row>
                    <Row className="event-time">
                        <FontAwesomeIcon className="icon" icon={faCircle} />
                        <span className="time">
                            {new Date(eventData?.startDate).toLocaleDateString(
                                t("dateLang"),
                                options as any
                            )}
                        </span>
                        ,
                        <span className="time">
                            {moment(eventData?.startDate).format("hh:mm A")}
                        </span>
                        {eventData?.location
                            ? `${eventData?.location?.street} ${eventData?.location?.city ?? ""} ${
                                  eventData?.location?.countryName ?? ""
                              } `.trim()
                            : ""}
                    </Row>
                    <Row className="discipline-row">
                        <FontAwesomeIcon className="icon" icon={faCircle} />
                        {t("regDeadline")} :{" "}
                        {new Date(eventData?.registrationDeadline).toLocaleDateString(
                            t("dateLang"),
                            options as any
                        )}
                    </Row>
                    <Row className="discipline-row">
                        <FontAwesomeIcon className="icon" icon={faCircle} /> {t("createdBy")} :{" "}
                        {eventData?.user?.firstName} {eventData?.user?.lastName}
                    </Row>
                    <Row>
                        <div
                            className="description-text"
                            dangerouslySetInnerHTML={{
                                __html: eventData?.description || "",
                            }}
                        />
                    </Row>

                    {/* Alternatives with Collapse */}
                    {eventData.alternatives.map((alternative: any) => (
                        <div key={alternative.id}>
                            <div className="alternative-header">{alternative.customClassName}</div>
                            <Divider style={{ margin: "10px 0" }} />
                            {alternative.classList.map((classItem: any, clIdx: number) => (
                                <Collapse
                                    bordered={false}
                                    expandIconPosition="end"
                                    expandIcon={() => null} // Hides the default collapse icon
                                    key={classItem.id}
                                    className="class-panel"
                                >
                                    <Panel
                                        key={classItem.id}
                                        collapsible="icon"
                                        header={
                                            <>
                                                <Row className="class-header">
                                                    {/* Column 1: Checkbox or Radio */}
                                                    <Col>
                                                        {alternative.isMultipleClassActive ? (
                                                            <Checkbox
                                                                disabled={
                                                                    classItem.capacity -
                                                                        classItem.classOrders
                                                                            .length ===
                                                                    0
                                                                }
                                                                style={{ marginRight: "8px" }}
                                                                checked={
                                                                    Array.isArray(
                                                                        selectedClasses[
                                                                            alternative.id
                                                                        ]
                                                                    ) &&
                                                                    selectedClasses[
                                                                        alternative.id
                                                                    ].includes(classItem.id)
                                                                } // Set checked based on selectedClasses state
                                                                onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                        alternative.id,
                                                                        classItem.id,
                                                                        e.target.checked
                                                                    )
                                                                } // Handle checkbox change
                                                            />
                                                        ) : (
                                                            <Radio
                                                                style={{ marginRight: "8px" }}
                                                                disabled={
                                                                    classItem.capacity -
                                                                        classItem.classOrders
                                                                            .length ===
                                                                    0
                                                                }
                                                                checked={
                                                                    selectedClasses[
                                                                        alternative.id
                                                                    ] === classItem.id
                                                                }
                                                                onClick={() => {
                                                                    

                                                                    handleRadioChange(
                                                                        alternative.id,
                                                                        classItem.id
                                                                    );
                                                                }} // Handle radio change
                                                                // Set checked based on selectedClasses state
                                                                // onChange={() =>{
                                                                //     

                                                                //     handleRadioChange(
                                                                //         alternative.id,
                                                                //         classItem.id
                                                                //     )
                                                                // }
                                                                // }
                                                            />
                                                        )}
                                                    </Col>

                                                    {/* Column 2: Title in Two Rows */}
                                                    <Col>
                                                        <Row>
                                                            <strong>
                                                                {/* {t("class")} {clIdx + 1} */}
                                                                {classItem.title}
                                                            </strong>
                                                        </Row>
                                                        <Row justify={"center"}>
                                                            <span>
                                                                {classItem.price}{" "}
                                                                {EqCurrency[eventData.currency]}
                                                            </span>

                                                            <span>
                                                                <FontAwesomeIcon
                                                                    className="icon"
                                                                    icon={faCircle}
                                                                />
                                                                {t("availableSpots") + ": "}{" "}
                                                                {classItem.capacity -
                                                                    classItem.classOrders.length +
                                                                    "/" +
                                                                    classItem.capacity}
                                                            </span>
                                                            {/* <span>
                                                                <FontAwesomeIcon
                                                                    className="icon"
                                                                    icon={faCircle}
                                                                />
                                                                {t("description") + ": "}{" "}
                                                                {classItem.description}
                                                            </span> */}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                            <Col>
                                                <p>{classItem.description}</p>
                                            </Col>
                                        </Row> */}
                                            </>
                                        }
                                    ></Panel>
                                </Collapse>
                            ))}
                        </div>
                    ))}
                </Col>

                {/* Right Column for Event Image and Contact Info */}
                <Col xs={24} md={8}>
                    <Divider />

                    <Row justify={"space-between"} align={"middle"}>
                        <Col>
                            <Title style={{ margin: "0" }} level={3}>
                                {t("ShareWithFriends")}
                            </Title>
                        </Col>
                        <Col>
                            <FontAwesomeIcon
                                size="xl"
                                icon={faShareNodes}
                                onClick={showModal}
                                style={{ cursor: "pointer" }}
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <Card
                        className="event-card"
                        cover={screens.md && <img alt="Event" src={eventData.image.url} />}
                    >
                        <Card.Meta
                            title={<h2>{t("contact")}</h2>}
                            description={
                                <Row gutter={[25, 0]}>
                                    <Col>{eventData.user?.email || "N/A"}</Col>
                                    <Col>{eventData.user?.phoneNumber || "N/A"}</Col>
                                </Row>
                                //   <div className="contact-info">
                                //     <p>Phone: {eventData.user?.phoneNumber || "N/A"}</p>
                                //     <p>Email: {eventData.user?.phoneNumber || "N/A"}</p>
                                //   </div>
                            }
                        />
                        <Divider />
                        <Row justify={"space-between"}>
                            <Col>
                                {" "}
                                <div className="price">
                                    <span>
                                        {calculateTotalPrice()} {EqCurrency[eventData.currency]}
                                    </span>
                                    <p>{t("totalClasses")}</p>
                                </div>
                            </Col>
                            <Col>
                                <EqInput
                                    placeHolder={t("nameOfAttendee")}
                                    value={attendUsingName}
                                    onChange={(e: any) => handleAttendUsingName(e.target.value)}
                                />
                            </Col>
                        </Row>

                        {/* <Divider />
                        <Row justify={"space-between"}>
                            <Col>
                                <Title level={3}>{t("ShareWithFriends")}</Title>
                            </Col>
                            <Col>
                                <FontAwesomeIcon
                                    icon={faShareNodes}
                                    onClick={showModal}
                                    style={{ cursor: "pointer" }}
                                />
                            </Col>
                        </Row> */}
                        <Divider />
                        <Row>
                            <Col span={1}>
                                <Checkbox
                                    checked={isTermsChecked}
                                    onChange={handleTermsChange}
                                ></Checkbox>
                            </Col>
                            <Col offset={1} span={22}>
                                {t("confirmEventTerms1")}

                                <Link
                                    color="primary"
                                    type="link"
                                    to=""
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setIsTermsModalVisible(true);
                                    }}
                                >
                                    {t("confirmEventTerms2")}
                                </Link>
                            </Col>

                            <Col offset={1} span={22}></Col>
                        </Row>
                        <Row>
                            <Col span={1}>
                                <Checkbox
                                    checked={isRulesChecked}
                                    onChange={handleRulesChange}
                                ></Checkbox>
                            </Col>

                            <Col offset={1} span={22}>
                                {t("confirmEventRules1")}

                                <Link
                                    color="primary"
                                    type="link"
                                    to=""
                                    onClick={(e: any) => {
                                        e.preventDefault();

                                        handleHouseTermsClick();
                                    }}
                                >
                                    {t("confirmEventRules2")}
                                </Link>
                            </Col>
                        </Row>
                        {/* Add Checkboxes for terms and rules */}
                        {
                        // (!isOwner) &&
                            eventData.startDate > dateToday.getTime() && (
                                <>
                                    <div style={{ marginTop: "10px" }}>
                                        <EqButton
                                            onClick={handleNextClick}
                                            disabled={isNextButtonDisabled}
                                            type="primary"
                                        >
                                            {t("next")}
                                        </EqButton>
                                    </div>
                                </>
                            )}
                    </Card>
                </Col>

                {/* Modal for sharing the invitation link */}
                <Modal
                    title={t("shareEvent")}
                    open={isModalOpen}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    <p>{t("inviteFriends")}</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p>{`${window.location.origin}/publicEventSingle?id=${eventData.id}`}</p>
                        <FontAwesomeIcon
                            icon={faCopy}
                            onClick={handleCopyLink}
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                        />
                    </div>
                    {linkCopied && <p style={{ color: "green" }}>{t("Link copied!")}</p>}
                </Modal>

                <Modal
                    // width={"60%"}
                    width={screens.md ? "60%" : "95%"} // Set modal width based on screen size
                    title={t("Event Holder Rules")}
                    open={isHouseTermsModalVisible}
                    onCancel={() => setIsHouseTermsModalVisible(false)}
                    footer={null}
                >
                    {eventData.useHouseTerms ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: eventData?.houseTerms || t("No terms available"),
                            }}
                        />
                    ) : eventData?.eventTermsFile ? (
                        <iframe
                            title="Terms"
                            src={config.baseUrl + "/" + eventData.eventTermsFile}
                            width="100%"
                            style={{ border: "none", height: "80vh" }}
                        ></iframe>
                    ) : (
                        <div>{t("No terms file available")}</div> // Fallback message if no file is available
                    )}
                </Modal>

                <Modal
                    // width={"60%"}
                    width={screens.md ? "60%" : "95%"} // Set modal width based on screen size
                    title={t("Terms and Conditions")}
                    open={isTermsModalVisible}
                    onCancel={() => setIsTermsModalVisible(false)}
                    footer={null}
                >
                    <iframe
                        title="Terms"
                        //   src={ config.baseUrl   + "/"+eventData?.eventTermsFile}
                        src={"Files/PoliciesTermsAndConditions.pdf"}
                        width="100%"
                        //   height="80vh"
                        style={{ border: "none", height: "80vh" }}
                    ></iframe>
                </Modal>
            </Row>
        </div>
    );
};

export default EqEventSingleDetails;
