import { Checkbox, Col, Image, Row, Upload } from "antd";
import React, { useState } from "react";
import "./EditorCreateProduct.scss";

import EqToggle, { IEqToggle } from "../eqToggle/EqToggle";
import { EqInput } from "../eqInput/EqInput";
import TextArea from "antd/lib/input/TextArea";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import EqButton from "../eqButton/EqButton";
import { eqNotificationTypes, ProductType } from "../../shared/enums";
import Api from "../../shared/AApi";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsSchool,
  openNotificationWithIcon,
} from "../../shared/Common";
import { ISelectProps } from "../../shared/Interfaces";
import Compressor from "compressorjs"; //A library to compress image files before uploading
import { useAuthState } from "../../context/Context";
import { useTranslation } from "react-i18next";
import EqSelect from "../eqSelect/EqSelect";
import { Spin } from "antd-v5";

const EditorCreateProduct = ({
  onCancel,
  onCreate,
  isEdit,
  product,
  onEdit,
}: any) => {
  const productInitial = {
    type: ProductType.Public,
    limit: null,
    price: 0,
    title: "",
    productNumber: "",
    description: "",
    vat: "",
    imageUrl: "",
    // userId:0
  };
  const userDetails: any = useAuthState();
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const { t, i18n } = useTranslation();
  const [spinning, setSpinning] = React.useState<boolean>(false);

  const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };
  const [productFormData, setProductFormData] = React.useState({
    ...productInitial,
  } as any);

  const [isError, setIsError] = React.useState(false);

  const [message, setMessage] = React.useState("");

  const [addNew, setAddNew] = useState(false);
  const [show, setShow] = useState(true);
  const [ImageUrl, setImageUrl] = React.useState("") as any;
  const [loading, setLoading] = React.useState(false);
  // const [productList, setProductList] = React.useState([] as any);
  const [productTypeOptions, setProductTypeOptions] = React.useState([]);//store product type options
  const [productListToggle, setProductListToggle] = React.useState(
    ProductType.Public
  ); //toggel between public and private 
  const [productsList, setProductsList] = React.useState({
    all: [],
    private: [],
    public: [],
  }) as any;
//stores lists of product types(i.e public and private )



  const vatOptions = [
    { key: "0", value: "0" ,description:"%"},
    { key: "5", value: "5",description:"%"},
    { key: "12", value: "12" , description:"%"},
    { key: "25", value: "25" , description:"%"},
  ];

  const vatSelectProps: ISelectProps = {
    label:"VAT *",
    // label: (
    //   <span>
    //     VAT <span className="required">*</span>
    //   </span>
    // ),
    options: vatOptions,
    defaultSelectedValue: productFormData.vat,
    classNames: "extra-height",
    changeFunc: (value: string) => {
      
      setProductFormData({ ...productFormData, vat: value });
    },
  };

  const removeProduct = (data: any) => {
    Api.delete(ApiEndpoints.UserProduct + `/${data.id}`).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(data);
        } else {
          setIsError(true);
          // setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        // setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeItem = (data: any) => {
    if (productsList.all != undefined) {
      var updatedList =
        productsList.all != undefined
          ? productsList.all.filter((item: any) => item.id !== data.id)
          : new Array<any>();
      // setProductList(updatedList);
      formatListData(updatedList);
      // horsesList.map((item: any, index: number) => {
      //   item.display = index == 0 ? true : false;
      // });
    }
  };

  const formatListData = (data: any) => {
    var privateList = data?.filter(
      (opt: any) => opt.type == ProductType.Private
    );
    var publicList = data?.filter((opt: any) => opt.type == ProductType.Public);

    var productList = {
      all: [...data],
      private: [...privateList],
      public: [...publicList],
    };
    setProductsList({ ...productList });
  };

  const showAddNew = () => {
    setAddNew(true);
  };
  const cancelFunc = (e: any) => {
    setProductFormData({ ...productInitial });
    onCancel();
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    // if (data.number == "") {
    //   openNotificationWithIcon(msg, "Number" + desc);
    //   isComplete = false;
    //} else
    
    if (data.price === 0) {
      openNotificationWithIcon(msg, "Price" + desc);
      isComplete = false;
    } else if (data.title == "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    } else if (data.description === "") {
      openNotificationWithIcon(msg, "Description " + desc);
      isComplete = false;
    } else if (data.vat === "") {
      openNotificationWithIcon(msg, "VAT" + desc);
      isComplete = false;
    } else if (data.type === "") {
      openNotificationWithIcon(msg, "Type" + desc);
      isComplete = false;
    } else if (ImageUrl === "") {
      openNotificationWithIcon(msg, "Image" + desc);
      isComplete = false;
    } else if (
      isSchool &&
      data.type == ProductType.Public &&
      !data.limit &&
      data.limit <= 0
    ) {
      openNotificationWithIcon(msg, `${t("capacity")}` + desc);
      isComplete = false;
    }

    return isComplete;
  };

  const CreateFunc = (e: any) => {
    if(!userDetails.isSubscribed){
      openNotificationWithIcon(
        "Error!",
        "Kindly Subscribe to Create",
        eqNotificationTypes.error
      );
      return;
    }
    let validate = validateData(productFormData);

    if (validate == true) {
      setSpinning(true);

      Api.post(ApiEndpoints.UserProduct, productFormData).then(
        (response) => {
          if (response && response.status == 200) {
            openNotificationWithIcon(
              "Created!",
              "Product have been Added",
              eqNotificationTypes.success
            );
            setIsError(false);

            setProductFormData({ ...productInitial });
            setImageUrl("");
            onCreate(response.data);
            // const list = [...productsList.all];
            // list.push(response.data);

            // setProductList(list);
            // formatListData(list);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating Product. Please try again or contact administrator."
            );
            setIsError(true);
          }
        setSpinning(false);

        },
        (error) => {
        setSpinning(false);

          if (error?.response?.status === 403) {
            openNotificationWithIcon(
              "Server error",
              "Not Authorized Kindly contact administrator."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the Product. Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    }
  };

  const EditFunc = (e: any) => {
    let validate = validateData(productFormData);

    if (validate == true) {
      setSpinning(true);

      Api.put(ApiEndpoints.UserProduct, productFormData).then(
        (response) => {
          if (response && response.status == 200) {
            openNotificationWithIcon(
              "Update!",
              "Product have been Updated",
              eqNotificationTypes.success
            );
            setIsError(false);

            setProductFormData({ ...productInitial });
            setImageUrl("");

            onEdit(response.data);
            // const list = [...productsList.all];
            // list.push(response.data);

            // setProductList(list);
            // formatListData(list);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while Updating Product. Please try again or contact administrator."
            );
            setIsError(true);
          }
        setSpinning(false);

        },
        (error) => {
        setSpinning(false);

          if (error?.response?.status === 403) {
            openNotificationWithIcon(
              "Server error",
              "Not Authorized Kindly contact administrator."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while Updating the Product. Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    }
  };

  const handleChangeProductUpload = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.originFileObj) {
      getBase64Product(info.file.originFileObj);
    } else {
      getBase64Product(info.file);
    }
  };

  const getBase64Product = (img: any) => {
    setLoading(true);
    new Compressor(img, {
      quality: 0.7,
      success: (compressedResult) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          var srcStr = reader.result as any;
          var apiImgSrc = srcStr.substr(srcStr.indexOf(",") + 1);

          var form = new FormData();
          form.append("imageFile", img);
          form.append("imageSrc", apiImgSrc);
          form.append("imageName", img.name);

          setIsError(false);

          Api.post("image/uploadImage", form).then(
            (response) => {
              setImageUrl(response?.data?.url);
              setProductFormData({
                ...productFormData,
                imageUrl: response?.data?.url,
              });
              setLoading(false);
            },
            (error) => {
              setLoading(false);
              setIsError(true);
            }
          );

          //updateImage(dispatch, reader.result);
        });
        reader.readAsDataURL(compressedResult);
      },
    });
  };

  const createTypeOptions = () => {
    var types = [] as any;
    // if (isNaN(Number(x)) == false) {
    //   accessTypes.push({ key: x, value: label });

    //   if (Number(x) == EqEventAccessType.Private) {
    //     pAccess.push({ key: Number(x), value: label });
    //   }
    // }
    for (var x in ProductType) {
      if (isNaN(Number(x)) == false) {
        types.push({ key: Number(x), value: ProductType[x] });
      }
    }
    
    setProductTypeOptions(types);
  };
  //
  const productTypeToggleProps: IEqToggle = {
    items: productTypeOptions.map((filter: any) => ({
      key: filter.key,
      value: t(filter.value), // Translate the filter label
    })),
    defaultSelectedValue: productFormData.type,
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
      let type: number = +value;
      // setSelectedToggle(filter);
      setProductFormData({ ...productFormData, type: type });
    },
  };
  //
  const productListToggleProps: IEqToggle = {
    items: productTypeOptions,
    defaultSelectedValue: productListToggle,
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
      let type: number = +value;
      // setSelectedToggle(filter);
      setProductListToggle(type);
    },
  };

  const typeProps: ISelectProps = {
    isTag: false,
    placeholder: t("selectType"),
    defaultSelectedValue: productFormData.type,
    classNames: "extra-height",
    label:  t("selectType"),

    options: productTypeOptions,
    changeFunc: (value: string) => {
      let type: number = +value;
      setProductFormData({ ...productFormData, type: type });
    },
  };

  React.useEffect(() => {
    createTypeOptions();
    if (isEdit && product) {
      setProductFormData(product);
      setImageUrl(product.imageUrl);
    }
    // getProductList();
  }, [product]);

  return (
    <div className="edit-create-product">
      <Spin spinning={spinning} size="large">

      {!isEdit && (
        <Row gutter={gutter}>
          <Col className="gutter-row" span={24}>
            <div className="product-type">
              <div className="label">{t("selectProductType")}</div>
              <div className="toggler">
                <EqToggle {...productTypeToggleProps} />
              </div>
              <span className="dis">
                {productFormData.type == ProductType.Private
                  ? t("privateProductNote")
                  : t("publicProductNote")}
              </span>
              <Row className="dis"> 
              {productFormData.type == ProductType.Private
                  ? ""
                  : isSchool ?
                  (
                    t("schoolRidingCourseNote")

                  ):(
                    ""

                  ) 
                }               
              </Row>
            </div>
          </Col>
        </Row>
      )}
      {/* {isEdit && (
        <>
        </>
      )} */}

      <Row className="input-row">
        {/* <Col md={12} xs={24} sm={24} className="mb-16">
                      <EqSelect extraHeight={true} {...typeProps} />
                    </Col> */}

        <Col md={12} xs={24} sm={24} className="col-left">
          <EqInput
            label={
              <span>
                {t("title")}
                <span className="required">*</span>
              </span>
            }
            className="profile-input"
            placeholder=""
            required="required"
            value={productFormData.title}
            onChange={(event: any) =>
              setProductFormData({
                ...productFormData,
                title: event?.target?.value,
              })
            }
          ></EqInput>
        </Col>

        {/* <Col md={12} xs={24} sm={24} className="col-left mb-16">
          <EqInput
            label={<span>Product Number </span>}
            className="profile-input"
            placeholder=""
            required="required"
            type="number"
            min={0}
            value={productFormData.productNumber}
            onChange={(event: any) =>
              setProductFormData({
                ...productFormData,
                productNumber: event?.target?.value,
              })
            }
          ></EqInput>
        </Col> */}

        {isSchool && productFormData.type === ProductType.Public && (
          <>
            <Col md={12} xs={24} sm={24} className="col-left mb-16">
              <EqInput
                label={
                  <span>
                    {t("numberOfCourses")} <span className="required">*</span>
                  </span>
                }
                className="profile-input"
                placeholder=""
                type="number"
                value={productFormData.lessons}
                min={0}
                onChange={(event: any) =>
                  setProductFormData({
                    ...productFormData,
                    lessons: event?.target?.value,
                  })
                }
              ></EqInput>
            </Col>

            <Col md={12} xs={24} sm={24} className="col-left ">
              <EqInput
                label={
                  <span>
                    {t("courseLimit")} <span className="required">*</span>
                  </span>
                }
                className="profile-input"
                placeholder=""
                type="number"
                value={productFormData.limit}
                min={0}
                onChange={(event: any) =>
                  setProductFormData({
                    ...productFormData,
                    limit: event?.target?.value,
                  })
                }
              ></EqInput>
            </Col>
          </>
        )}
        {productFormData.lessons > 1 && (
          <Col md={12} xs={24} sm={24} className="col-left ">
            <EqInput
              label={
                <span>
                  {t("individualPrice")} <span className="required">*</span>
                </span>
              }
              className="profile-input"
              placeholder=""
              required="required"
              type="number"
              min={0}
              value={productFormData.individualPrice}
              onChange={(event: any) =>
                setProductFormData({
                  ...productFormData,
                  individualPrice: event?.target?.value,
                })
              }
            ></EqInput>
          </Col>
        )}

        <Col md={12} xs={24} sm={24} className="col-left mb-16 ">
          <EqInput
            label={
              <span>
                {productFormData.type === ProductType.Private
                  ? t("price")
                  : t("totalPrice")} <span className="required">*</span>
              </span>
            }
            className="profile-input"
            placeholder=""
            required="required"
            type="number"
            min={0}
            value={productFormData.price}
            onChange={(event: any) =>
              setProductFormData({
                ...productFormData,
                price: event?.target?.value,
              })
            }
          ></EqInput>
        </Col>

        <Col span={24} className="">
          <div className="about-my-self-heading">
            {t("description")}
            <span className="required">*</span>
          </div>
        </Col>
        <Col span={24} className="mb-16">
          <TextArea
            rows={4}
            className="profile-input text-area"
            value={productFormData.description}
            onChange={(event: any) =>
              setProductFormData({
                ...productFormData,
                description: event?.target?.value,
              })
            }
          ></TextArea>
        </Col>
        <Col md={12} xs={24} sm={24} className="mb-16 col-left">
          <EqSelect {...vatSelectProps} />

        </Col>
        {isSchool && productFormData.type === ProductType.Public && 
        <Col md={24} xs={24} sm={24} className="mb-16 col-left">

        <Checkbox checked={productFormData.isRequestRequired}  
            onChange={(e: any) => {
              setProductFormData({
                ...productFormData,
                isRequestRequired: e.target.checked,
              });
            }}
            >
          {t("requestRequiredToJoin")}
        </Checkbox>

        </Col>
        }
        <Col span={24}>
          <div className="image-section">
            <div className="label">
              {t("picture")}
              <span className="required">*</span>
            </div>
            <Row>
              <Col>
                {ImageUrl != "" && (
                  <Image className="eq-image" preview={true} src={ImageUrl} />
                )}
              </Col>
              <Col>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleChangeProductUpload}
                >
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 0 }}>{t("picture")}</div>
                  </div>
                </Upload>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row justify="center" align="bottom" className="eq-cg-btn">
        <Col className="eq-cg-buttons">
          <EqButton
            type="button"
            bordered="true"
            classNames="btn-light-gray parent-btn"
            onClick={cancelFunc}
          >
            {t("cancel")}
          </EqButton>
        </Col>

        {!isEdit ? (
          <Col className="eq-cg-buttons">
            <EqButton
              type="button"
              classNames="btn-background-orange parent-btn"
              onClick={CreateFunc}
            >
              {t("create")}
            </EqButton>
          </Col>
        ) : (
          <Col className="eq-cg-buttons">
            <EqButton
              type="button"
              classNames="btn-background-orange parent-btn"
              onClick={EditFunc}
            >
              {t("saveChanges")}
            </EqButton>
          </Col>
        )}
      </Row>
      </Spin>
    </div>
  );
};

export default EditorCreateProduct;
