import { Col, Radio, Row } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../shared/Api';
import { ApiEndpoints } from '../../shared/Common';
import { EqHorseTrainingType, EqTaskTypes } from '../../shared/enums';
import { ISelectProps } from '../../shared/Interfaces';
import EqButton from '../eqButton/EqButton';
import EqDatePicker, { IDatePickerOptions } from '../eqDatePicker/EqDatePicker';
import { EqInput } from '../eqInput/EqInput';
import EqSelect from '../eqSelect/EqSelect';
import EqTimePicker, { ITimePickerOptions } from '../eqTimePicker/EqTimePicker';
import "./HorseActivityForm.scss";

export interface EditOrCreateHorseActivityProps {
    horsesList: any;
    cancelFunc?: (item: any, isAdd: boolean) => void;
    createResultFunc?: (isSuccess: boolean, param: any) => void;
    formValues?: {
        horseId?: string;
        taskDate?: any;
        taskTime?: any;
        timeTo?: any;
        notificationTime?: any;
        taskType?: EqTaskTypes;
        trainingType?: EqHorseTrainingType;
        duration?: number;
        description?: string;
        isEditing?: boolean;
    }
}

export const HorseActivityForm = (props: EditOrCreateHorseActivityProps) => {

    //hooks
    //#region hooks
    const { t, i18n } = useTranslation();

    const [alertError, setAlertError] = React.useState({ isError: false, description: "" });
    const [alertSuccess, setAlertSuccess] = React.useState({ isSuccess: false, description: "" });
    const [selectedOption, setSelectedOption] = useState(1);
    const [formData, setFormData] = React.useState({} as EditOrCreateHorseActivityProps);
    const [addTask, setAddTask] = useState(false);
    //#endregion hooks

    React.useEffect(() => {
        if (props && props.formValues) {
            setFormData({ ...props.formValues } as any);
        }
    }, [props]);

    //props
    //#region Props    
    const horseListProps: ISelectProps = {
        label: `${t('horse')}`,
        extraHeight: "40px",
        options: props?.horsesList,
        defaultSelectedValue: formData?.formValues?.horseId ? formData?.formValues?.horseId : [],
        classNames: "extra-height",
        changeFunc: (value: any) => {
            console.log("selected horse: ", value);
            //let type: number = +value;
            setFormData({ ...formData, formValues: { ...formData.formValues, horseId: value } });
        },
    };

    const horseSelectProps: ISelectProps = {
        label: `${t('horse')}`,
        extraHeight: "40px",
        options: props?.horsesList,
        defaultSelectedValue: formData?.formValues?.horseId ? formData?.formValues?.horseId : [],
        classNames: "extra-height",
        changeFunc: (value: any) => {
            console.log("selected horse: ", value);
            //let type: number = +value;
            setFormData({ ...formData, formValues: { ...formData.formValues, horseId: value } });
        },
    };

    const taskTypeProps: ISelectProps = {
        classNames: "extra-height",
        placeholder: `${t('selectTaskType')}`,
        isTag: false,
        label: `${t('taskType')}`,
        defaultSelectedValue: formData?.formValues?.taskType ? formData?.formValues?.taskType : [],
        options: Object.entries(EqTaskTypes).filter((e: any) => !isNaN(e[0] as any)).map((e: any) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
        changeFunc: (value: any) => {
            setFormData({ ...formData, formValues: { ...formData.formValues, taskType: value } });
        }
    };

    const traningSelectProps: ISelectProps = {
        classNames: "extra-height",
        placeholder: `${t('trainingType')}`,
        isTag: false,
        label: `${t('trainingType')}`,
        defaultSelectedValue: formData?.formValues?.trainingType ? formData?.formValues?.trainingType : [],
        options: Object.entries(EqHorseTrainingType).filter((e: any) => !isNaN(e[0] as any)).map((e: any) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
        changeFunc: (value: any) => {
            setFormData({ ...formData, formValues: { ...formData.formValues, trainingType: value } });
        },
    };

    const startOnProps: IDatePickerOptions = {
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: formData?.formValues?.taskDate?.valueOf(),
        placeHolder: `${t('date')}`,
        classNames: "eq-event",
        onChangeFunc: (date: any) => {
            setFormData({ ...formData, formValues: { ...formData.formValues, taskDate: moment(date) } });
        },
    };

    const timeToProps: ITimePickerOptions = {
        isSuffixIcon: false,
        isBordered: true,
        classNames: "eq-event small-helight",
        defaultValue: formData?.formValues?.taskTime,
        placeHolder: `${t('selectDuration')}`,
        onChangeFunc: (value: any) => {
            debugger;
            const formattedTime = value.format("HH:mm");


            setFormData({ ...formData, formValues: { ...formData.formValues, taskTime: formattedTime} })

        },
    };

    const taskDescription: any = {
        label: `${t('description')}`,
        value: formData?.formValues?.description,
        onChange: (event: any) => {
            setFormData({ ...formData, formValues: { ...formData.formValues, description: event?.target?.value } });
        }
    };

    const taskNotification: ISelectProps = {
        classNames: "extra-height",
        placeholder: `${t('notificationTime')}`,
        isTag: false,
        label: `${t('notificationTime')}: ${t('notificationText')}`,
        defaultSelectedValue: formData?.formValues?.notificationTime ? formData?.formValues?.notificationTime : [],
        changeFunc: (value: any) => {
            setFormData({ ...formData, formValues: { ...formData.formValues, notificationTime: value } });
        },
        options: [
            {
                key: 1,
                value: `6 ${t('weeks')}`
            },
            {
                key: 2,
                value: `8 ${t('weeks')}`
            },
            {
                key: 3,
                value: `3 ${t('months')}`
            },
            {
                key: 4,
                value: `6 ${t('months')}`
            },
            {
                key: 5,
                value: `1 ${t('year')}`
            },
        ]
    };

    const durationSelectProps: ISelectProps = {
        classNames: "extra-height",
        placeholder: `${t('selectDuration')}`,
        isTag: false,
        label: `${t('duration')}`,
        defaultSelectedValue: formData?.formValues?.duration ? formData?.formValues?.duration : [],
        changeFunc: (value: any) => {
            setFormData({ ...formData, formValues: { ...formData.formValues, duration: value } });
        },
        options: [
            {
                key: 1,
                value: `20 ${t('minutes')}`
            },
            {
                key: 2,
                value: `30 ${t('minutes')}`
            },
            {
                key: 3,
                value: `1 ${t('hour')}`
            },
            {
                key: 4,
                value: `2 ${t('hours')}`
            }
        ]
    };

    //#endregion Props

    //binings
    //#region bindings

    const handleRadioChange = (e: any) => {
        setSelectedOption(e.target.value);
    };

    const setEventDefaults = () => {
        setFormData({} as EditOrCreateHorseActivityProps);
    };

    const handleSaveTask = () => {
        debugger;
        var tim =  moment(formData?.formValues?.taskTime ?? moment());
        var dat = moment(formData?.formValues?.taskDate ?? moment());
        dat.set("hour", tim.get('hour'));
        dat.set("minute", tim.get('minute'));
        console.log(formData);
        var data: any = formData;
        var time = moment(data.formValues.taskTime);
        var dataToSend: any = {
            horseId: data.formValues.horseId,
            time: moment(dat).valueOf(),
            date: moment(dat).valueOf(),
            notificationTime: data.formValues.notificationTime!=null?CalcNotificationTime(data.formValues.notificationTime):data.formValues.notificationTime,
            description: data?.formValues?.description
        }

        if (selectedOption == 1) {
            //task
            console.log(ApiEndpoints.horse.task.add);
            dataToSend.taskType = data.formValues.taskType;
            Api.post(ApiEndpoints.horse.task.add, dataToSend).then((result:any) => {
                setEventDefaults();
                setAlertSuccess({ isSuccess: true, description: t('horseTrainingAddTxt') })
                setAlertError({ isError: false, description: "" })
                props?.createResultFunc && props.createResultFunc(true, result.data);
            }, error => {
                console.log(error);
                props?.createResultFunc && props.createResultFunc(false, data);
                //throw error;
            })
        } else {
            //training
            dataToSend.trainingType = data.formValues.trainingType;
            dataToSend.durationInMinutes = CalcDurationInMinutes(data.formValues.duration);
            Api.post(ApiEndpoints.horse.training.add, dataToSend).then(result => {

                setEventDefaults();
                props?.createResultFunc && props.createResultFunc(true, data);
            }, error => {
                console.log(error);
                props?.createResultFunc && props.createResultFunc(false, data);
                //throw error;
            })
        }

        console.log(dataToSend);
    }

    const CalcDurationInMinutes = (param: any): any => {
        switch (param) {
            case 1: // 20 min
                return 20;
            case 2: // 30 min
                return 30;
            case 3: // 1 hour
                return 60;
            case 4: // 2 hours
                return 120;
            default: return 30;
        }
    }

    const CalcNotificationTime = (param: any) => {
        switch (param) {
            case 1: // 6 weeks
                var nfcTime = moment().add(6, 'w').valueOf();
                return nfcTime;
            case 2: // 8 weeks
                return moment().add(8, 'w').valueOf();
            case 3: // 3 months
                return moment().add(3, 'M').valueOf();
            case 4: // 6 months
                return moment().add(8, 'M').valueOf();
            case 5: // 1 year
                return moment().add(1, 'y').valueOf();

            default:
                var nfcTime = moment().add(8, 'w').valueOf();
                return nfcTime;
        }
    }

    //#endregion bindings


    return (
        <div className='task-details'>
            <div className='task-options'>
                <span className='options-heading'>
                    {t('activityType')}
                </span>

                <Col className='options'>
                    <Radio.Group
                        onChange={handleRadioChange}
                        value={selectedOption}

                    >
                        <Radio className='options_1 option' value={1}>{t('task')}</Radio>
                        <Radio className='options_2 option' value={2}>{t('training')}</Radio>
                    </Radio.Group>
                </Col>
            </div>

            {selectedOption === 1 ? (
                <Row>
                    <Row gutter={[16, 24]} className='horse-taskList'>
                        <Col md={12} xs={24} sm={24} >
                            <EqSelect
                                {...horseSelectProps}
                            >
                            </EqSelect>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <EqSelect
                                {...taskTypeProps}
                            >
                            </EqSelect>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <label>{t('date')}</label>
                            <EqDatePicker
                                {...startOnProps}
                            >
                            </EqDatePicker>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <label>{t('time')}</label>
                            <EqTimePicker
                                {...timeToProps}
                            ></EqTimePicker>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <EqInput
                                {...taskDescription}
                            >
                            </EqInput>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <EqSelect
                                {...taskNotification}
                            >
                            </EqSelect>
                        </Col>
                    </Row>
                </Row>

            ) : (
                <Row>
                    <Row gutter={[16, 24]} className='horse-taskList'>
                        <Col md={12} xs={24} sm={24} >
                            <EqSelect
                                {...horseListProps}
                            >
                            </EqSelect>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <EqSelect
                                {...traningSelectProps}
                            >
                            </EqSelect>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <label>{t('date')}</label>
                            <EqDatePicker
                                {...startOnProps}
                            >
                            </EqDatePicker>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <label>{t('time')}</label>
                            <EqTimePicker
                                {...timeToProps}
                            ></EqTimePicker>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <EqSelect
                                {...durationSelectProps}
                            >
                            </EqSelect>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <EqInput
                                {...taskDescription}
                            >
                            </EqInput>
                        </Col>
                        <Col md={12} xs={24} sm={24}>
                            <EqSelect
                                {...taskNotification}
                            >
                            </EqSelect>
                        </Col>
                    </Row>
                </Row>
            )}

            <div className="eq-add-horse-btn">
                <EqButton
                    type="button"
                    onClick={handleSaveTask}
                    classNames="btn-background-orange"
                >
                    {t("create")}
                </EqButton>
                <EqButton
                    type="button"
                    classNames="btn-background-white"
                    onClick={() => props?.cancelFunc && props?.cancelFunc(props.formValues, !addTask)}
                >
                    {t("cancel")}

                </EqButton>
            </div>
        </div>
    );
}
