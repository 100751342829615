import { DeleteFilled, DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Empty, Image, Row, Upload, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import "./EqHorseBoard.scss"
import { EqInput } from '../eqInput/EqInput'
import EqDatePicker, { IDatePickerOptions } from '../eqDatePicker/EqDatePicker'
import EqToggle, { IEqToggle } from '../eqToggle/EqToggle'
import TextArea from 'antd/lib/input/TextArea'
import EqButton from '../eqButton/EqButton'
import HorsesList, { IEqHorseList } from '../../eqTrainer/HorsesList/HorsesList'
import Api from '../../shared/AApi'
import { ApiEndpoints, GetEventMessage, dateFormat, openNotificationWithIcon } from '../../shared/Common'
import {
    eqNotificationTypes,
    genderType
} from '../../shared/enums'
import { useTranslation, Trans } from "react-i18next";
import moment from 'moment'
import { dE } from '@fullcalendar/core/internal-common'
import Compressor from 'compressorjs'
import { AxiosResponse } from 'axios'
import { Popconfirm } from 'antd-v5'
import AnimationDiv from '../animationDiv/AnimationDiv'
import Reveal from '../Reveal/Reveal'


const EqHorseBoard = () => {
    const { t, i18n } = useTranslation();
    const [isCheckAll, setIsCheckAll] = useState(false);

    const [addHorse, setAddHorse] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [ImageUrl, setImageUrl] = React.useState("") as any;
    const [toggleFilters, setToggleFilters] = React.useState([] as any);
    const [selectedToggle, setSelectedToggle] = React.useState() as any;
    const [isError, setIsError] = React.useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([] as any);
    const horseInitial = {
        name: "",
        about: "",
        dateOfBirth: 0,
        imageUrl: "",
        type: 1,
        // userId:0
    };

    const [popconfirmProps, setPopconfirmProps] = useState({
        title: t('deleteHorses'),
        description: t('areYouSureToDeleteAllSelectedHorses'),
        onConfirm: (val: any) => removeMultipleHorse(val),
        onCancel: (val: any) => Oncancel(),
        okText: t("yes"),
        cancelText: t("no")
    } as any)
    const [popconfirmMultipleProps, setPopconfirmMultipleProps] = useState({
        title: t('deleteHorses'),
        description: t('areYouSureToDeleteAllSelectedHorses'),
        onConfirm: (val: any) => removeMultipleHorse(val),
        onCancel: (val: any) => Oncancel(),
        okText: t("yes"),
        cancelText: t("no")
    } as any)

    const [horseFormData, setHorseFormData] = React.useState({
        ...horseInitial,
    } as any);
    const [horsesList, setHorsesList] = React.useState([] as any);

    const [editingHorse, setEditingHorse] = useState(null);

    const checkAll = () => {
        setIsCheckAll(!isCheckAll);
        const updatedHorsesList = horsesList.map((horse: any) => ({
            ...horse,
            selected: !isCheckAll,
        }));
        setHorsesList(updatedHorsesList);
        const updatedSelectedCheckboxes = updatedHorsesList.filter((x: any) => x.selected);
        setSelectedCheckboxes(updatedSelectedCheckboxes);
    };


    const [isCollapseAll, setIsCollapseAll] = useState(false);
    const handleIsCollapseAll = (props: boolean) => {
        setIsCollapseAll(props);
    };
    const collapseAll = () => {
        handleIsCollapseAll(!isCollapseAll);
        horsesList.map((f: IEqHorseList) => {
            f.display = !isCollapseAll;
        });
    };

    const handleOnCheck = (horse: any) => {
        const list = [...horsesList]
        var foundIndex = list.findIndex((x) => x.id == horse.id);
        var prevhorse = list.find((x) => x.id === horse.id);
        prevhorse.selected = !prevhorse.selected
        list[foundIndex] = prevhorse;
        setHorsesList(list);

        const updatedSelectedCheckboxes = list.filter((x) => x.selected);
        setSelectedCheckboxes(updatedSelectedCheckboxes);

    };

    const handleEditHorse = (horseData: any) => {
debugger;
        const list = [...horsesList]
        var foundIndex = list.findIndex((x) => x.id == horseData.id);
        list[foundIndex] = horseData;

        setEditingHorse(horseData);
        setHorsesList(list);


        getHorseList();
    };

    const handleChangeHorseUpload = (info: any) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.originFileObj) {
            getBase64Horse(info.file.originFileObj);
        } else {
            getBase64Horse(info.file);
        }
    };
    const getBase64Horse = (img: any) => {
        setLoading(true);
        new Compressor(img, {
            quality: 0.7,
            success: (compressedResult) => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    var srcStr = reader.result as any;
                    var apiImgSrc = srcStr.substr(srcStr.indexOf(",") + 1);

                    var form = new FormData();
                    form.append("imageFile", img);
                    form.append("imageSrc", apiImgSrc);
                    form.append("imageName", img.name);

                    setIsError(false);

                    Api.post("image/UploadImage", form).then(
                        (response) => {
                            setImageUrl(response?.data?.url);
                            setHorseFormData({
                                ...horseFormData,
                                imageUrl: response?.data?.url,
                            });
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            setIsError(true);
                        }
                    );

                    //updateImage(dispatch, reader.result);
                });
                reader.readAsDataURL(compressedResult);
            },
        });
    };


    const createToggleFilters = () => {
        var filters = [];
        for (var x in genderType) {
            if (isNaN(Number(x)) == false) {
                filters.push({ key: Number(x), value: genderType[x] });
            }
        }
        setToggleFilters(filters);
        setSelectedToggle(filters[0]);
    };

    const togglerProps: IEqToggle = {
        items: toggleFilters.map((filter: any) => ({
            key: t(filter.key),
            value: t(filter.value), // Translate the filter label
        })),
        defaultSelectedValue: selectedToggle,
        classNames: "eq-toggle-orange-border eq-toggle-create-event",
        changeFunc: (value: string) => {
            // console.log(filteredTrainingsList);
            let filter: number = +value;
            setSelectedToggle(filter);

            if (filter === genderType.Stallion) {
                setHorseFormData({ ...horseFormData, type: filter });
                // setSelectedTrainingsList(filteredTrainingsList.available);
            } else if (filter === genderType.Gelding) {
                setHorseFormData({ ...horseFormData, type: filter });

                // setSelectedTrainingsList(filteredTrainingsList.joined);
            } else if (filter === genderType.Mare) {
                setHorseFormData({ ...horseFormData, type: filter });

                // setSelectedTrainingsList(filteredTrainingsList.previous);
            }
        },
    };

    const openNotificationWithIcon = (
        msg: any,
        desc: any,
        type = eqNotificationTypes.error
    ) => {
        if (type == eqNotificationTypes.success) {
            notification["success"]({
                message: msg,
                description: desc,
            });
        } else if (type == eqNotificationTypes.info) {
            notification["info"]({
                message: msg,
                description: desc,
            });
        } else {
            notification["error"]({
                message: msg,
                description: desc,
            });
        }
    };
    const validateData = (data: any): boolean => {
        let isComplete = true;
        const msg = t("incompleteData");
        const desc = t("isEmpty");
        if (data.name == "") {
            openNotificationWithIcon(msg, t("name") + desc);
            isComplete = false;
        } else if (!data.dateOfBirth) {
            openNotificationWithIcon(msg, t("dateOfBirth") + desc);
            isComplete = false;
        } else if (ImageUrl == "") {
            openNotificationWithIcon(msg, t("image") + desc);
            isComplete = false;
            // } else if (uiImage == null || Object.keys(uiImage).length === 0) {
            //   openNotificationWithIcon(msg, "Image" + desc);
            //   isComplete = false;
        }

        return isComplete;
    };

    const handleSaveHorse = () => {
        let validate = validateData(horseFormData);
        if (validate == true) {
            const formData = {
                ...horseFormData,
                dateOfBirth: moment(horseFormData.dateOfBirth, dateFormat)
                    .utc()
                    .valueOf(),
            };
            console.log(formData);

            if (formData.dateOfBirth) {
                Api.post(ApiEndpoints.CreateHorse, formData).then(
                    (response) => {
                        if (response && response.status == 200) {
                            openNotificationWithIcon(
                                t('created'),
                                t('horseAdded'),
                                eqNotificationTypes.success
                            );
                            setIsError(false);

                            setHorseFormData({ ...horseInitial });

                            const list = [...horsesList];
                            list.push(response.data);

                            setHorsesList(list);
                            setImageUrl("");
                        } else {
                            openNotificationWithIcon(
                                t('serverError'),
                                t('generalServerError')
                            );
                            setIsError(true);
                        }
                    },
                    (error) => {
                        if (error?.response?.status === 403) {
                            openNotificationWithIcon(
                                t('serverError'),
                                t('notAuthorizedError')
                            );
                            setIsError(true);
                        } else {
                            openNotificationWithIcon(
                                t('serverError'),
                                t('generalServerError')
                            );
                            setIsError(true);
                        }
                    }
                );
            } else {
                setIsError(true);
            }
        }
    };

    const removeHorse = (data: any) => {

        Api.delete(ApiEndpoints.DeleteHorse + `/${data.id}`).then(
            (response) => {
                if (response && response.status == 200) {
                    removeItem(data);
                    Onconfirm(data)
                } else {
                    setIsError(true);
                    // setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                // setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };



    const removeItem = (data: any) => {
        if (horsesList != undefined) {
            var updatedList =
                horsesList != undefined
                    ? horsesList.filter((item: any) => item.id !== data.id)
                    : new Array<any>();
            setHorsesList(updatedList);

            horsesList.map((item: any, index: number) => {
                item.display = index == 0 ? true : false;
            });
        }
    };


    const removeMultipleHorse = (data: any) => {

        const selectedHorseIds = selectedCheckboxes.map((horse: any) => horse.id);
        Api.post(ApiEndpoints.DeleteMultiHorse, selectedHorseIds).then(
            (response) => {
                if (response && response.status == 200) {
                    Onconfirm(data)
                } else {
                    setIsError(true);
                    // setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                // setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };
    const Onconfirm = (data: any) => {
        if (selectedCheckboxes.length > 0) {
            const selectedHorseIds = selectedCheckboxes.map((horse: any) => horse.id);
            const updatedList = horsesList.filter((horse: any) => !selectedHorseIds.includes(horse.id));
            setHorsesList(updatedList);
            updatedList.forEach((item: any, index: number) => {
                item.display = index === 0 ? true : false;
            });
        }
    };




    const Oncancel = () => {
    };

    const horseDobProps: IDatePickerOptions = {
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: horseFormData?.dateOfBirth,
        placeHolder: t("dateofBirth"),
        classNames: "eq-event",
        onChangeFunc: (date: any, dateString: string) => {
            setHorseFormData({ ...horseFormData, dateOfBirth: moment(date) });
        },
    };
    const getHorseList = () => {
        Api.get(ApiEndpoints.GetAllHorses).then(
            (response) => {
                if (response && response.status === 200) {
                    setIsError(false);
                    setMessage("");
                    let data = response.data;
                    if (data) {
                        data.forEach((obj: any) => {
                            obj.selected = false;
                        });

                        setHorsesList(data);

                    }


                } else {
                    setIsError(true);
                    setMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setMessage(GetEventMessage(error.response.status));
            }
        );
    };

    

    React.useEffect(() => {
        createToggleFilters();
        getHorseList()
        // processProfile(Api.get("Profile/GetProfile"));

    }, []);
    return (
        <>
            <AnimationDiv>

                <Col className="eq-HorseBoard-container">
                    <Row className="add-horse">
                        <Col>
                            <div className="add-horse-div">
                                <EqButton
                                    type="primary"
                                    classNames="create-Profile"
                                    onClick={() => setAddHorse(!addHorse)}
                                >
                                    + {t("createProfile")}
                                </EqButton>
                            </div>
                        </Col>
                    </Row>
                    {addHorse && (
                        <div className="horse-details">
                            <Row>
                                <Col span={24}>
                                    <div className="horse-section">
                                        <div className="label">{t("selectImage")}</div>
                                        {/* <h3 className="section-heading my-details-heading">
                              Horse
                            </h3> */}
                                        <Row>
                                            <Col>
                                                {ImageUrl != "" && (
                                                    <Image
                                                        className="eq-image"
                                                        preview={true}
                                                        src={ImageUrl}
                                                    />
                                                )}
                                            </Col>
                                            <Col>
                                                <Upload
                                                    name="avatar"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    onChange={handleChangeHorseUpload}
                                                >
                                                    <div>
                                                        {loading ? (
                                                            <LoadingOutlined />
                                                        ) : (
                                                            <PlusOutlined />
                                                        )}
                                                        <div style={{ marginTop: 0 }}>{t("picture")}</div>
                                                    </div>
                                                </Upload>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row align="middle" className="input-row">
                                <Col md={12} xs={24} sm={24} className="col-left mb-16">
                                    <EqInput
                                        label={t("name")}
                                        className="profile-input"
                                        placeholder=""
                                        value={horseFormData.name}
                                        onChange={(event: any) =>
                                            setHorseFormData({
                                                ...horseFormData,
                                                name: event?.target?.value,
                                            })
                                        }
                                    ></EqInput>
                                </Col>
                                <Col md={12} xs={24} sm={24} className="mb-16"></Col>

                                <Col md={12} xs={24} sm={24} className="col-left mb-16">
                                    <label className='horse-info-label'>{t("dateofBirth")}</label>
                                    <EqDatePicker {...horseDobProps}></EqDatePicker>

                                    {/* <EqInput
                            label="Date of Birth"
                            className="profile-input"
                            type="date"
                            placeholder=""
                          ></EqInput> */}
                                </Col>

                                <Col md={12} xs={24} sm={24} className="mb-16"></Col>

                                {/*Gender Type*/}
                                <Col span={24} className="">
                                    <div className="horse-info-label">{t("gender")}</div>
                                </Col>
                                <Col span={24}>
                                    <EqToggle {...togglerProps} />
                                </Col>
                                <Col span={24} className="">
                                    <div className="horse-info-label">{t("about")}</div>
                                </Col>
                                <Col span={24}>
                                    <TextArea
                                        rows={4}
                                        onChange={(e: any) =>
                                            setHorseFormData({
                                                ...horseFormData,
                                                about: e.target.value,
                                            })
                                        }
                                        value={horseFormData.about}
                                        className="profile-input text-area"
                                    ></TextArea>
                                </Col>

                            </Row>
                            <div className="eq-add-horse-btn">
                                <EqButton
                                    type="button"
                                    onClick={handleSaveHorse}
                                    classNames="btn-background-orange"
                                >
                                    {t("save")}
                                </EqButton>
                                <EqButton
                                    type="button"
                                    classNames="btn-background-white"
                                    onClick={() => setAddHorse(!addHorse)}
                                >
                                    {t("cancel")}

                                </EqButton>
                            </div>
                        </div>

                    )}
                    <Row justify="end" className='action-delete-all hidden-sm'>
                        {selectedCheckboxes.length > 1 && (
                            <Popconfirm {...popconfirmProps} >
                        <EqButton>
                            <DeleteFilled className="" />
                            {t("clear")}
                        </EqButton>

                    </Popconfirm>
                        )}
                </Row>
                <Col span={24}>
                    <Row className="eq-fl-row hidden-sm">
                        <Col className="eq-fl-th " span={1}>
                            <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                        </Col>
                        <Col className="eq-fl-th" span={9}>
                            {t("name")}
                        </Col>
                        <Col className="eq-fl-th" span={6}>
                            {t("dateofBirth")}
                        </Col>
                        <Col className="eq-fl-th" span={6}>
                            {t("gender")}
                        </Col>
                        <Col className="eq-fl-th" span={2}>
                            {t("actions")}

                        </Col>
                    </Row>
                    <div className="hidden-md">
                        <Row justify="start" align="middle" className="eq-list-checkAll">
                            <Col span={2}>
                                <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                            </Col>
                            <Col flex="auto">{t("checkAll")}

                            </Col>
                            <Col className='action-delete-all'>
                                    {selectedCheckboxes.length > 1 && (
                                        <Popconfirm {...popconfirmMultipleProps}>
                                <EqButton>
                                    <DeleteFilled className="" />
                                    {t("clear")}
                                </EqButton>

                            </Popconfirm>
                                    )}
                        </Col>
                        {/* <Col>
                                <EqButton
                                    type="button"
                                    classNames="btn-background-orange"
                                    onClick={collapseAll}
                                >
                                    {isCollapseAll ? "Collapse All" : "Expand All"}
                                </EqButton>
                            </Col> */}
                    </Row>
                </div>

                {/* <AddNewHorses groupMembersList={[]} /> */}
                {horsesList && horsesList.length ? (
                    <Reveal>
                        <HorsesList
                            horseList={horsesList}
                            delFunc={removeHorse}
                            display={true}
                            onEdit={handleEditHorse}
                            onCheck={handleOnCheck}
                            horse={horsesList}

                        />
                    </Reveal>
                ) : (


                                <Empty description={t("noHorseAvailable")}></Empty>
                )}
            </Col>

        </Col >
            </AnimationDiv >

        </>
    )
}

export default EqHorseBoard