import React, { useEffect, useState } from "react";
import "./EqCourseCard.scss";
import { Card, Col, Row, Skeleton, Tag, Modal } from "antd-v5";
import moment from "moment";
import Meta from "antd/lib/card/Meta";
import { useTranslation } from "react-i18next";
import AsyncImage from "../../../custom/asyncImage/AsyncImage";
import { EqDisciplineType, EqOrderStatus, EqRefundType } from "../../../../components/shared/enums";
import Title from "antd-v5/es/typography/Title";
import EqButton from "../../../../components/custom/eqButton/EqButton";
import EqTags from "../../../custom/eqTags/EqTags";
import { getDefaultImage, imageType } from "../../../shared/Common";
import { CalendarOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { CourseStatus } from "../../../shared/interfaces/ridingCourse/IRidingCourse";

export interface IEqCourseCreate {
    courseData: any;
    onCardClick?: () => void;
    onEdit?: () => void;
    onDelete?: () => void; // Callback for delete button
    onDuplicate?: () => void; // Callback for delete button,
    isStudent?: boolean;
    isOwner?: boolean;
    onBuy?: () => void;
    onPayNow?: (orderId:string) => void;
}

const EqCourseCard = ({
    courseData,
    onCardClick,
    onEdit,
    onDuplicate,
    onDelete,
    onBuy,
    onPayNow,
    isStudent,
    isOwner,
}: IEqCourseCreate) => {
    //console.log('Course Data:', courseData); // Log the entire courseData
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const options = { year: "numeric", month: "long", day: "2-digit" };
    const [owner, setOwner] = React.useState() as any;

    const [isLatePaymentAllowed, setIsLatePaymentAllowed] = React.useState(false);
     const [canCancelEvent, setCanCancelEvent] = React.useState(false);
     const [confirmModal, contextHolderConfirm] = Modal.useModal();

    useEffect(() => {
        if(isStudent && courseData){
            setupCourse(courseData);
        }
        // Set loading to false after 1 second
        setTimeout(() => {
            setLoading(false);
        }, 500);
        
    }, [courseData]);

   const setupCourse = (course: any) => {
        if (!course) return;
        let order =  courseData.courseLessonOrders[0]?.orderStatus;
        let startTime =  courseData.startDate;
        // Set organizers
        if (course.createdBy) {
            setOwner(course.createdBy);
        }


        // check if organizer has Allowed Late Payment To the Student
        if (course.createdBy.allowLatePayments) {
            setIsLatePaymentAllowed(true);
        }

        // check if student can cancel or not
        // Calculate time difference
        const currentDate = new Date();
        const timeDifferenceInMS = startTime - currentDate.getTime();

        // if date has no passed yet student can cancel
        if (startTime > currentDate.getTime()) {
            setCanCancelEvent(true);
        }

        // if cancellation deadline

        if (course.createdBy?.cancellationDeadline) {
            const cancellationDeadlineMS =
                course.createdBy.cancellationDeadline * 60 * 60 * 1000; // hours in milliseconds

            if (timeDifferenceInMS > cancellationDeadlineMS) {
                // The event can be canceled
                setCanCancelEvent(true);
                console.log("You can cancel the event.");
            } else {
                // Cancellation is not allowed at this time
                console.log("Cancellation is not allowed at this time.");
                setCanCancelEvent(false);
            }
        }

       
    
    };


    // Placeholder for event image alt text and source
    // const imgSrc = courseData?.imageId;

    //console.log("The Recieveing Image is this " , imgSrc);
    const alt = courseData?.title || "Event Image";

    // Calculate days difference between event start date and current date
    const calculateDaysDifference = () => {
        const today = new Date();
        const eventStartDate = new Date(courseData?.startDate);
        // Calculate the time difference in milliseconds
        const timeDiff = eventStartDate.getTime() - today.getTime();
        // Convert milliseconds to days
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysDiff > 0 ? ` ${t("in")} ${daysDiff} ${t("days")} ` : t("Event started");
    };



  const checkCancellationAndRefundForProductTraining = () => {
       
        if (owner.allowProductTrainingCancel) {
            const currentDate = new Date();
            const cancellationDeadlineMS =
            owner.cancellationDeadline * 60 * 60 * 1000; // hours in milliseconds
            const startTime = courseData.startDate;
            const deadlineDate = startTime - cancellationDeadlineMS;
            if (currentDate.getTime() < deadlineDate) {
                switch (owner.productRefundType) {
                    case EqRefundType.Refund:
                        return "You are eligible for a refund.";
                    case EqRefundType.Credit:
                        return "You are eligible for a credit.";
                    default:
                        return "No refund or Credit available.";
                }
            } else {
                // Deadline has passed
                return "Cancellation deadline has passed. No refund or Credit available.";
            }
        } else {
            return "No refund or Credit available.";
        }
    };
    const removeAttendee = (e: any) => {
        e.stopPropagation();
        
        let isPaidByUser=courseData?.courseLessonOrders[0]?.orderStatus === EqOrderStatus.Paid

        //check Lesson is from Product Training or Normal Training
      
            if (isPaidByUser) {
                const message =
                    checkCancellationAndRefundForProductTraining() + " Would you like to Cancel";
                confirmRemove(message);
            } else {
                // removeAttendeeFunc(courseData);
                onDelete &&  onDelete();
            }
        
    };
    const confirmRemove = (message: any) => {
        confirmModal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: message,
            okText: "Yes",
            cancelText: "NO",
            // onOk: () => removeAttendeeFunc(courseData),
            onOk: () => onDelete &&  onDelete(),
            //   onCancel: () => AcceptProductOrderInvite(productOrder),
        });
    };
    return (
        <div className="eq-course-card">
            {/* <Badge.Ribbon   text="is">
              </Badge.Ribbon> */}
            <Card onClick={onCardClick} hoverable style={{ width: "100%" }}>
                <Meta
                    description={
                        loading ? (
                            <>
                                <div>
                                    <Skeleton.Image
                                        active
                                        style={{
                                            maxWidth: "100%",
                                            width: "400px",
                                            height: "250px",
                                        }}
                                    />
                                </div>
                                <div className="skeleton-type">
                                    <Skeleton.Button
                                        active
                                        size="small"
                                        shape="round"
                                        style={{ maxWidth: "94px", height: "20px" }}
                                    />
                                    <Skeleton.Button
                                        active
                                        size="small"
                                        shape="round"
                                        style={{ maxWidth: "94px", height: "20px" }}
                                    />
                                </div>
                                <Skeleton active style={{ maxWidth: "100%", marginTop: "5px" }} />
                            </>
                        ) : (
                            <>
                                <Row>
                                    <Col>
                                        {/* <AsyncImage className="event-img" alt={alt} src={imgSrc} /> */}
                                        <AsyncImage
                                            className="event-img"
                                            alt={alt}
                                            src={
                                                courseData.image?.url ||
                                                getDefaultImage(imageType.partner)
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Row className="event-detail">
                                    <Col className="event-type">
                                        {t(EqDisciplineType[courseData?.disciplineType])}
                                    </Col>
                                    <Col>
                                        {courseData.status=== CourseStatus.Drafted ? <EqTags text={t("draft")} /> : ""}
                                    </Col>
                                </Row>

                                <Row>
                                    <Title level={3} className="eq-create-event-title">
                                        {courseData?.title}
                                    </Title>
                                </Row>
                                <Row className="event-description">
                                    <Col>
                                        <div
                                            className="description-text"
                                            dangerouslySetInnerHTML={{
                                                __html: courseData?.description || "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                    <Row className="event-time">

                                        <Tag icon={<CalendarOutlined style={{ color: "#FF8838", verticalAlign:"center" }} />} color="default">
                                            {new Date(courseData?.startDate).toLocaleDateString(
                                                t("dateLang"),
                                                options as any
                                            )}
                                        </Tag>

                                        <Tag icon={<ClockCircleOutlined style={{ color: "#FF8838", verticalAlign: "center" }} />} color="default">
                                            {moment(courseData?.startDate).format("hh:mm A")}
                                        </Tag>
                                </Row>
                                {/* <Row className="in-days">
                                    {calculateDaysDifference()}{" "}
                                    <FontAwesomeIcon className="icon" icon={faArrowRightLong} />
                                </Row> */}
                            </>
                        )
                    }
                />
                {/* Edit and Delete Buttons */}
                <Row justify="end" style={{ marginTop: "10px" }}>
                    {onDuplicate && (
                        <Col>
                            <EqButton
                                type="primary"
                                onClick={onDuplicate}
                                style={{ marginRight: "8px" }}
                            >
                                {t("duplicate")}
                            </EqButton>
                        </Col>
                    )}
                    {onEdit && (
                        <Col>
                            <EqButton
                                bordered
                                type="primary"
                                onClick={onEdit}
                                style={{ marginRight: "8px" }}
                            >
                                {isStudent ? t("view") : t("edit")}
                            </EqButton>
                        </Col>
                    )}
                  
                    {onBuy && (
                        <Col>
                       

                            <EqButton
                                // onClick={onBuy}
                                onClick={() => {
                                    if (
                                        courseData?.courseLessonOrders?.length > 0 && 
                                        courseData.courseLessonOrders[0]?.orderStatus === EqOrderStatus.Accepted
                                    ) {
                                        onPayNow?.(courseData.courseLessonOrders[0]?.id); // Call onPayNow with orderId
                                    } else {
                                        onBuy?.(); // Call onBuy for other cases
                                    }
                                }}
                                disabled={
                                    courseData?.courseLessonOrders?.length > 0 && // Only disable if there are courseLessonOrders
                                    courseData.courseLessonOrders[0]?.orderStatus !== EqOrderStatus.Accepted // Disable if orderStatus is not Accepted
                                }
                            >
                                {courseData?.courseLessonOrders?.length
                                    ? courseData.courseLessonOrders[0]?.orderStatus === EqOrderStatus.Accepted
                                        ? t("payNow")
                                        : t(EqOrderStatus[courseData.courseLessonOrders[0]?.orderStatus])
                                    : t("request")}
                            </EqButton>
                        </Col>
                    )}
                      {onDelete && (
                        <>
                        {isStudent?(
                            canCancelEvent && courseData.courseLessonOrders[0]?.orderStatus === EqOrderStatus.Paid &&
                            <Col>
                            <span style={{ margin: "3px" }}>
                                <img alt="" src="/trash-alt-red.svg" onClick={removeAttendee} />
                            </span>
                        </Col>
                        ):
                        <Col>
                            <span style={{ margin: "3px" }}>
                                <img alt="" src="/trash-alt-red.svg" onClick={onDelete} />
                            </span>
                        </Col>  
                        }
                        </>
                      
                    )}
                </Row>
            </Card>
            {contextHolderConfirm}
        </div>
    );
};

export default EqCourseCard;
