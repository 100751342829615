import { Row, Col, Skeleton, Avatar } from "antd-v5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AnimationDiv from "../../../custom/animationDiv/AnimationDiv";
import EqButton from "../../../custom/eqButton/EqButton";
import Api from "../../../shared/Api";
import {
    ApiEndpoints,
    FrontEndPoints,
    getDefaultImage,
    GetEventMessage,
    getUserIdFromToken,
    imageType,
    openNotificationWithIcon,
    ValidateIsSchool,
    ValidateIsStudent,
    ValidateIsUser,
} from "../../../shared/Common";
import "./ProfileCourseSection.scss";
import TrainingCourseDrawer from "../trainingCourseDrawer";
import EqCourseCard from "../EqCourseCard/EqCourseCard";
import { useAuthState } from "../../../context/Context";
import {
    CourseUI,
    IEqTrainingCourseBoard,
    ISchoolStudents,
    EqImageInfoDto,
    ITrainerOption,
} from "../../../shared/interfaces/ridingCourse/IRidingCourse";
import { eqNotificationTypes } from "../../../shared/enums";
import { error } from "console";
import { useLocation, useNavigate } from "react-router-dom";

const ProfileCourseSection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const userDetails: any = useAuthState();
    const isLoggedIn: boolean = ValidateIsUser(userDetails);

    const isSchool: boolean = ValidateIsSchool(userDetails);
    const isStudent: boolean = ValidateIsStudent(userDetails);

    const loggedUserId = getUserIdFromToken();

    const [isError, setIsError] = useState(false);

    const [message, setMessage] = useState("");
    const [spinning, setSpinning] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [editCourse, setEditCourse] = useState<CourseUI | undefined>(undefined);

    const [imagesData, setImagesData] = React.useState<EqImageInfoDto[]>([]);
    const [schoolStudents, setSchoolStudents] = useState<ISchoolStudents[]>([]);
    const [studentHorsesList, setStudentHorsesList] = useState([]);

    const [courseList, setCourseList] = useState<any[]>([]);
    const [trainersOptions, setTrainersOptions] = useState<ITrainerOption[]>([]);
    const [isOwner, setIsOwner] = React.useState(false);

    useEffect(() => {
        console.log("--School-Profile-Course--");
        const params = new URLSearchParams(window.location.search);
        var key = params.get("key");
        var courseKey = params.get("courseKey");
        var userKey;
        if (key) {
            userKey = Number(key);
            fetchUserCourses(userKey);
        }
        if (isSchool && userKey === userDetails.key) {
            setIsOwner(true);
            fetchImages();
            fetchSchoolStudentsWithHorses();
            fetchTrainersOptions();
        } else {
            setIsOwner(false);
        }
        if (courseKey) {
            editClick(courseKey);
        }

        window.scrollTo(0, 0);
    }, []);

    const handleError = (error: any, customMessage?: string) => {
        const errorMessage = customMessage || GetEventMessage(error?.response?.status || 500);

        setIsError(true);
        setMessage(errorMessage);
        console.error(errorMessage, error);
    };
    const fetchUserCourses = async (userKey: number) => {
        const path = ApiEndpoints.Course.GetByUserKey + `/${userKey}`;
        try {
            const response = await Api.get(path);
            if (response.status === 200) {
                const sortedCourses = response.data.sort(
                    (a: any, b: any) => b.createdDate - a.createdDate
                );
                setCourseList(sortedCourses);
            }
        } catch (error) {
            handleError(error, t("fetchCoursesError"));
        }
    };

    const fetchImages = async () => {
        try {
            const response = await Api.get(ApiEndpoints.Image.GetUserImages);
            if (response.data) {
                setImagesData(response.data as EqImageInfoDto[]);
            }
        } catch (error) {
            handleError(error, t("fetchImagesError"));
        }
    };

    const fetchUserCourseDetailsById = async (courseKey: number) => {
        let path = ApiEndpoints.Course.GetById + "/" + courseKey;

        Api.get(path).then(
            (response) => {
                if (response && response.status === 200) {
                    var data: CourseUI = response.data;
                    console.log(
                        "The response that we get from the API is on the course board is ",
                        data
                    );
                    setEditCourse(data);
                    console.log("EventListObject", data);
                } else {
                    setIsError(true);
                    console.error("GetUserCourses", GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                console.error("GetUserCourses", GetEventMessage(error.response.status));
            }
        );
    };

    const fetchTrainersOptions = async () => {
        try {
            const response = await Api.get(ApiEndpoints.SchoolGetTrainers);
            if (response.status === 200) {
                const trainers = response.data;
                const trainerOptions = [
                    {
                        key: loggedUserId,
                        value: userDetails.name,
                        label: userDetails.name,
                        icon: (
                            <Avatar
                                size={30}
                                src={
                                    userDetails.userImage?.url || getDefaultImage(imageType.trainer)
                                }
                            />
                        ),
                    },
                    ...trainers.map((item: any) => ({
                        key: item.id,
                        value: `${item.firstName} ${item.lastName}`,
                        label: item.firstName,
                        icon: (
                            <Avatar
                                size={30}
                                src={item.userImage?.url || getDefaultImage(imageType.trainer)}
                            />
                        ),
                    })),
                ];
                setTrainersOptions(trainerOptions);
            }
        } catch (error: any) {
            setIsError(true);
            setMessage(GetEventMessage(error.response?.status || 500));
        }
    };
    //console.log("Fetched Data Type is", trainersOptions);

    const handleCreateClick = () => {
        setEditCourse(undefined); // Reset edit course data
        setIsDrawerVisible(true);
    };

    const handleCloseDrawer = () => {
        setEditCourse(undefined);
        setIsDrawerVisible(false);
    };

    const handleOnCreateCourse = async (data: any) => {
        try {
            setSpinning(true);
            const response = await Api.post(ApiEndpoints.Course.Create, data);
            if (response.status === 200) {
                const newCourse = response.data;
                const updatedCourses = [...courseList];
                updatedCourses.push(newCourse);
                setCourseList(updatedCourses);
                setIsDrawerVisible(false);
            } else {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
            }
        } catch (error) {
            openNotificationWithIcon(t("serverError"), t("generalServerError"));
            console.error("Error creating or updating course:", error);
        } finally {
            setSpinning(false);
        }
    };

    const handleOnUpdateCourseDetails = async (data: any) => {
        try {
            setSpinning(true);
            // Replace with your API endpoint and method
            const response = await Api.put(ApiEndpoints.Course.UpdateDetails, data);
            if (response && response.status === 200) {
                const updatedEvent = response.data;

                // Find the event in the list
                const courses = [...courseList];
                const foundIndex = courses.findIndex((x) => x.id === updatedEvent.id);

                if (foundIndex !== -1) {
                    const prevEvent = courses[foundIndex];

                    // Check draft status before and after update to show appropriate notification
                    openNotificationWithIcon(
                        t("updated"),
                        t("eventUpdated"),
                        eqNotificationTypes.success
                    );

                    // Update the event in the list
                    UpdateCourseList(prevEvent);
                }
            } else {
                openNotificationWithIcon(
                    t("serverError"),
                    t("generalServerError"),
                    eqNotificationTypes.error
                );
            }
        } catch (error) {
            console.error("Error creating or updating event:", error);
            openNotificationWithIcon(
                t("serverError"),
                t("generalServerError"),
                eqNotificationTypes.error
            );
        } finally {
            setSpinning(false);
        }
    };

    const UpdateCourseList = (updatedCourse: any) => {
        // Only update if eventsList is defined
        if (courseList) {
            const updatedCourses = courseList.map((course: any) =>
                course.id === updatedCourse.id ? updatedCourse : course
            );
            setCourseList(updatedCourses);
        }

        // If editing the event, update the edit state
        // if (editEvent?.id === updatedEvent.id) {
        //     setEditEvent(updatedEvent);
        // }
    };

    const handleOnUpdateCourseLesson = async (data: any) => {
        try {
            setSpinning(true);
            // Replace with your API endpoint and method
            const response = await Api.put(ApiEndpoints.Course.UpdateLesson, data);
            if (response && response.status === 200) {
                const updatedLesson = response.data;

                if (editCourse !== undefined) {
                    const prevEvent = editCourse;
                    // Find and update the specific lesson in the lessons array
                    const lessonIndex = prevEvent.courseLessons.findIndex(
                        (lesson: any) => lesson.id === updatedLesson.id
                    );
                    if (lessonIndex !== -1) {
                        prevEvent.courseLessons[lessonIndex] = {
                            ...prevEvent.courseLessons[lessonIndex],
                            ...updatedLesson,
                        };
                    }
                    setEditCourse(prevEvent);
                }

                // Check draft status before and after update to show appropriate notification
                openNotificationWithIcon(
                    t("updated"),
                    t("eventLUpdated"),
                    eqNotificationTypes.success
                );

                // find lesson in prev Event and update
            } else {
                openNotificationWithIcon(
                    t("serverError"),
                    t("generalServerError"),
                    eqNotificationTypes.error
                );
            }
        } catch (error) {
            console.error("Error creating or updating event:", error);
            openNotificationWithIcon(
                t("serverError"),
                t("generalServerError"),
                eqNotificationTypes.error
            );
        } finally {
            setSpinning(false);
        }
    };

    const handleOnRemoveLessonOrder = (
        lessonId:string,
        order: any,
        refund: boolean,
        refundAmount?: number
    ) => {
        setSpinning(true)
        Api.delete(
            `${ApiEndpoints.Course.removeFromLesson}/${order.id}?lessonId=${lessonId}&refund=${refund}&refundAmount=${refundAmount}`
        )
            .then((response) => {


                if (response && response.status === 200) {
                    openNotificationWithIcon(
                        t("removed"),
                        t("attendeeRemoved"),
                        eqNotificationTypes.success
                    );

                    UpdateCourseList(response.data);
                    setEditCourse(response.data);

                } else {
                    setIsError(true);
                    // Optionally handle error messages here
                    // setErrorMessage(GetEventMessage(response.status));
                }
                setSpinning(false)

            })
            .catch((error) => {
                setIsError(true);
                // Optionally handle error messages here
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                setSpinning(false)

            });
    };

    const fetchSchoolStudentsWithHorses = () => {
        Api.get(ApiEndpoints.SchoolStudents.GetSchoolStudentsGroupDto).then(
            (response) => {
                if (response && response.status === 200) {
                    setMessage("");
                    var schoolStudents = response.data;
                    // getting both student and School Horses and making list
                    setSchoolStudents(response.data);

                    // getting both student and School Horses and making list
                    // and adding school horse in each student horse list
                    var studentHorsesListOptions = [] as any;

                    [...schoolStudents].map((item: any) => {
                        let horseListObj = [] as any;

                        if (item.user.horses.length > 0) {
                            [...item.user.horses].map((h: any) => {
                                let horseObj = {
                                    key: h.id,
                                    value: h.name,
                                    label: h.name,
                                };
                                horseListObj.push(horseObj);
                            });
                        }
                        if (item.school.horses.length > 0) {
                            [...item.school.horses].map((h: any) => {
                                let horseObj = {
                                    key: h.id,
                                    value: h.name,
                                    label: h.name,
                                };
                                horseListObj.push(horseObj);
                            });
                        }

                        let studentHorsesObj = {
                            key: item.user.id,
                            horseList: horseListObj,
                        };

                        studentHorsesListOptions.push(studentHorsesObj);
                    });
                    setStudentHorsesList(studentHorsesListOptions);
                } else {
                    setIsError(true);
                    setMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setMessage(GetEventMessage(error.response.status));
            }
        );
    };

    const updateImageLibrary = (newImage: any) => {
        setImagesData((prevImages: any) => [...prevImages, newImage]);
    };

    const removeFromImageLibrary = (imageId: any) => {
        setImagesData((prevImages: any) => prevImages.filter((image: any) => image.id !== imageId));
    };

    const GetCourseOrderCheckoutSession = async (orderId: any) => {
        try {
            let path = "api/CoursePayment/GetCourseOrderCheckoutSession" + `/${orderId}`;
            // Replace with your API endpoint and method
            const response = await Api.get(path);
            if (response && response.status === 200) {
                window.location.href = response.headers.location;
            }
            // Optionally handle the result
        } catch (error: any) {
            if (error.response.status === 409) {
                // already exist
                openNotificationWithIcon("Error!", error.response.data, eqNotificationTypes.error);
                // setIsEventSubscribed(false);
            } else {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                console.error("Error Buy Course:", error);
            }
        }
    };

    const onClickBuy = (courseId: any) => {
        const path = ApiEndpoints.CourseJoin.Request;
        
        Api.post(path, courseId).then(
            (response) => {
                if (response && response.status === 200) {
                    const newLessonOrder = response.data; // Response data containing new lesson orders

                    // Update courseLessonOrders in the courseList state
                    setCourseList((prevCourses) =>
                        prevCourses.map((course) =>
                            course.id === courseId
                                ? {
                                      ...course,
                                      courseLessonOrders: [
                                          ...(course?.courseLessonOrders || []),
                                          newLessonOrder,
                                      ],
                                  }
                                : course
                        )
                    );
                    // Update the edit class state if it is open
                    setEditCourse((prevEdit: any) => {
                        if (prevEdit?.id === courseId) {
                            return {
                                ...prevEdit,
                                courseLessonOrders: [
                                    ...(prevEdit?.courseLessonOrders || []),
                                    newLessonOrder,
                                ],
                            };
                        }
                        return prevEdit;
                    });
                    openNotificationWithIcon(
                        "Requested!",
                        "Course Request is Sent",
                        eqNotificationTypes.success
                    );
                }
            },
            (error) => {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
            }
        );
    };

    const navigateToLogin = (courseId: any) => {
        // e.stopPropagation();
        
        navigate(FrontEndPoints.login, {
            state: {
                prevPath: `${location.pathname + location.search}&courseKey=${courseId}`,
            },
        });
    };

    const editClick = async (courseId: any): Promise<void> => {
        fetchUserCourseDetailsById(courseId);
        setIsDrawerVisible(true);
    };
    //courseList.map((course, index) => {
    //    console.log("The Course is", course);
    //})

    const DeleteCourse = (courseId: string) => {
        Api.delete(`${ApiEndpoints.Course.Delete}/${courseId}`).then(
            (response) => {
                if (response && response.status === 200) {
                    openNotificationWithIcon(
                        t("deleted"),
                        t("courseDeleted"),
                        eqNotificationTypes.success
                    );
                    removeItem(courseId);
                }
            },
            (error) => {
                if (error?.response?.status === 412) {
                    openNotificationWithIcon(
                        t("Conflict"),
                        t("Cannot delete the course as it has associated class orders.")
                    );
                } else {
                    openNotificationWithIcon(t("serverError"), t("generalServerError"));
                }
            }
        );
    };

    const removeItem = (courseId: any) => {
        if (courseList !== undefined) {
            var updatedList =
                courseList !== undefined
                    ? courseList.filter((item: any) => item.id !== courseId)
                    : new Array<any>();
            setCourseList(updatedList);
        }
    };

    const handleAcceptCourseOrderRequest = async (orderId: string, price: number | null) => {
        try {
            const response = await Api.put(`${ApiEndpoints.UserCourseRequest.Accept}/${orderId}`, {
                price,
            });
            if (response.status === 200) {
                const updatedLessonOrder = response.data;
                if (editCourse !== undefined) {
                    const updatedCourse = { ...editCourse };

                    const courseLessonOrderIndex = updatedCourse.courseLessonOrders?.findIndex(
                        (lessonOrder: any) => lessonOrder.id === updatedLessonOrder.id
                    );

                    if (courseLessonOrderIndex !== -1) {
                        const updatedLessonOrders = [...updatedCourse.courseLessonOrders];
                        updatedLessonOrders[courseLessonOrderIndex] = {
                            ...updatedLessonOrders[courseLessonOrderIndex],
                            ...updatedLessonOrder,
                        };
                        updatedCourse.courseLessonOrders = updatedLessonOrders;
                        // Update course object with modified lesson orders
                        setEditCourse(updatedCourse);
                        UpdateCourseList(updatedCourse);
                    }
                }
                openNotificationWithIcon(
                    "Accepted!",
                    "Successfully Accepted Request",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error: any) {
            if (error.response.status === 409) {
                openNotificationWithIcon(
                    "Error!",
                    `Unable to Accept Request for ${error.response.data.product.title}`,
                    eqNotificationTypes.error
                );
            } else {
                console.error("Error Accepting invite:", error);
                openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
            }
        }
    };

    const handleRejectCourseOrderRequest = async (requestId: string) => {
        try {
            const response = await Api.put(`${ApiEndpoints.UserCourseRequest.Reject}/${requestId}`);

            if (response.status === 200) {
                const updatedLessonOrder = response.data;
                if (editCourse !== undefined) {
                    const prevCourse = editCourse;
                    const lessonIndex = prevCourse.courseLessonOrders.findIndex(
                        (lessonOrder: any) => lessonOrder.id === updatedLessonOrder.id
                    );

                    if (lessonIndex !== -1) {
                        prevCourse.courseLessonOrders[lessonIndex] = {
                            ...prevCourse.courseLessonOrders[lessonIndex],
                            ...updatedLessonOrder,
                        };
                    }
                    setEditCourse(prevCourse);
                    UpdateCourseList(prevCourse);
                }
                openNotificationWithIcon(
                    "Rejected!",
                    "Successfully Rejected Request",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error Reject invite:", error);
            openNotificationWithIcon("Error!", "Unable to Reject", eqNotificationTypes.error);
        }
    };

    const handleDeleteCourseInvite = async (inviteId: string) => {
        try {
            const response = await Api.delete(
                `${ApiEndpoints.UserCourseInvite.DeleteInvite}/${inviteId}`
            );

            if (response.status === 200) {
                if (editCourse !== undefined) {
                    const prevCourse = editCourse;
                    const CourseOrderIndex = prevCourse.courseLessonOrders.findIndex(
                        (courseOrder: any) => courseOrder.id === inviteId
                    );

                    if (CourseOrderIndex !== -1) {
                        prevCourse.courseLessonOrders = {
                            ...prevCourse.courseLessonOrders.filter(
                                (item: any) => item.id !== inviteId
                            ),
                        };
                    }
                    setEditCourse(prevCourse);
                    UpdateCourseList(prevCourse);
                }
                openNotificationWithIcon(
                    "Deleted!",
                    "Successfully Removed Invite",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error deleting invite:", error);
            openNotificationWithIcon("Error!", "Unable to Delete", eqNotificationTypes.error);
        }
    };

    return (
        <div className="overall-container">
            <div className="training-course-board">
                <AnimationDiv>
                    <div className="eq-trainer-products-board">
                        <Row align="middle" justify="end" style={{ margin: "10px 0" }}>
                            {isOwner && !isDrawerVisible && (
                                <Col className="eq-event-buttons">
                                    <EqButton
                                        type="button"
                                        classNames="btn-background-orange"
                                        onClick={handleCreateClick}
                                    >
                                        {editCourse ? t("update") : t("createCourse")}
                                    </EqButton>
                                </Col>
                            )}
                        </Row>
                        <Row className="eq-event-heading-line"></Row>
                        <Row>
                            {[...Array(5)].map((_, index) => (
                                <Skeleton
                                    key={index}
                                    loading={!courseList}
                                    paragraph={{ rows: 2 }}
                                    active
                                />
                            ))}
                        </Row>
                        {courseList.length > 0 && (
                            <Row gutter={[16, 16]} className="event-card-list">
                                {courseList.map((course: any) => (
                                    <Col xs={24} sm={12} xl={8} xxl={6} key={course.id}>
                                        <EqCourseCard
                                            isOwner={isOwner}
                                            isStudent={isStudent}
                                            // onBuy={isStudent ? ()=>{ onClickBuy(course.id)}:undefined}
                                            onBuy={
                                                isLoggedIn
                                                    ? isStudent
                                                        ? () => onClickBuy(course.id)
                                                        : undefined
                                                    : () => navigateToLogin(course.id) // Replace with your actual navigation logic
                                            }
                                            onPayNow={GetCourseOrderCheckoutSession}
                                            courseData={course}
                                            onEdit={
                                                isLoggedIn ? () => editClick(course.id) : undefined
                                            }
                                            onDelete={
                                                isOwner ? () => DeleteCourse(course.id) : undefined
                                            }
                                        />
                                    </Col>
                                ))}
                            </Row>
                        )}

                        {isDrawerVisible && (
                            <TrainingCourseDrawer
                                onBuy={isLoggedIn
                                    ? isStudent
                                        ? () => editCourse && onClickBuy(editCourse.id)
                                        : undefined
                                    : () => editCourse && navigateToLogin(editCourse.id) // Replace with your actual navigation logic
                                }
                                onPayNow={GetCourseOrderCheckoutSession}
                                isStudent={isStudent}
                                onClose={handleCloseDrawer}
                                trainersOptions={trainersOptions}
                                schoolStudents={schoolStudents}
                                studentHorsesList={studentHorsesList}
                                imagesData={imagesData}
                                onImageUploadSuccess={updateImageLibrary}
                                onImageDeleteSuccess={removeFromImageLibrary}
                                onCreateCourse={handleOnCreateCourse}
                                onEditCourseDetails={handleOnUpdateCourseDetails}
                                onEditCourseLesson={handleOnUpdateCourseLesson}
                                editCourse={editCourse}
                                onRemoveLessonOrder={handleOnRemoveLessonOrder}
                                onAcceptCourseOrderRequest={(requestId: any) => {
                                    handleAcceptCourseOrderRequest(requestId, null);
                                } }
                                onRejectCourseOrderRequest={handleRejectCourseOrderRequest}
                                onDeleteCourseInvite={handleDeleteCourseInvite} onAcceptCourseLessonOrderRequest={function (requestId: string): void {
                                    throw new Error("Function not implemented.");
                                } } onRejectCourseLessonOrderRequest={function (requestId: string): void {
                                    throw new Error("Function not implemented.");
                                } }                            />
                        )}
                    </div>
                </AnimationDiv>
            </div>
        </div>
    );
};

export default ProfileCourseSection;
