import React, { useState, useEffect } from "react";
import EqFinancial, { IEqFinancialDetail } from "../../eqFinancial/EqFinancial";
import { Checkbox, Tabs, Tooltip } from "antd";
import { Row, Col } from "antd";
import EqButton from "../../../custom/eqButton/EqButton";
import "./EqProductFinancialsList.scss";
import { useTranslation, Trans } from "react-i18next";
import EqProductFinancial, { IEqProductFinancial } from "./eqProductFinancial/EqProductFinancial";
import { InfoCircleFilled } from "@ant-design/icons";

export interface IEqProductFinancialsList {    
     financialsList: Array<IEqFinancialDetail>;
}

const EqProductFinancialsList = ({ financialsList, entityName, isNotVAT }: any) => {
    const { t, i18n } = useTranslation();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const checkAll = () => {
        setIsCheckAll(!isCheckAll);

        financialsList.map((f: IEqProductFinancial) => {
            f.selected = !isCheckAll;
        });
    };
    const [isCollapseAll, setIsCollapseAll] = useState(false);
    const handleIsCollapseAll = (props: boolean) => {
        setIsCollapseAll(props);
    };
    const collapseAll = () => {
        handleIsCollapseAll(!isCollapseAll);
        financialsList.map((f: IEqProductFinancial) => {
            f.isDisplay = !isCollapseAll;
        });
    };

    useEffect(() => {
        console.log("financialsList", financialsList);
    }, [financialsList]);
    return (
        <div className="payment-board">
            <div className="all-payments payment-board">
                <Row className="eq-fl-row hidden-sm">
                    <Col className="eq-fl-th " span={1}>
                        <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                    </Col>
                    <Col className="eq-fl-th" span={3}>
                        {t("name")}
                    </Col>
                    <Col className="eq-fl-th" span={3}>
                        {entityName}
                    </Col>
                    <Col className="eq-fl-th" span={4}>
                        {t("paymentDate")}
                    </Col>
                    <Col className="eq-fl-th" span={3}>
                        {t("amount")}
                        <span className="eq-f-currency" style={{ marginLeft:"2px" }}>
                            <Tooltip
                                placement="topLeft"
                                title={t("vatFeeIncludedInSum")}
                            >
                                <InfoCircleFilled />
                            </Tooltip>
                        </span>
                    </Col>
                    {!isNotVAT && <Col className="eq-fl-th" span={3}>
                        {t("vat")}
                        <span className="eq-f-currency" style={{ marginLeft:"2px" }}>
                            <Tooltip
                                placement="topLeft"
                                title={t("vatInPercent")}
                            >
                                <InfoCircleFilled />
                            </Tooltip>
                        </span>
                    </Col>}
                    {/* <Col className="eq-fl-th" span={2}>
                        {t("fee")}
                    </Col> */}
                    <Col className="eq-fl-th" span={3}>
                        {t("status")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("action")}
                    </Col>
                </Row>
                <div className="hidden-md">
                    <Row justify="start" align="middle" className="eq-list-checkAll">
                        <Col span={2}>
                            <Checkbox onChange={checkAll} checked={isCheckAll}></Checkbox>
                        </Col>
                        <Col flex="auto">{t("checkAll")}</Col>
                        <Col>
                            <EqButton
                                type="button"
                                classNames="btn-background-orange"
                                onClick={collapseAll}
                            >
                                {isCollapseAll ? "Collapse All" : "Expand All"}
                            </EqButton>
                        </Col>
                    </Row>
                </div>
                {financialsList.length > 0 && (
                    <>
                        {
                            financialsList.map((item: IEqProductFinancial, index: number) => (
                                    <EqProductFinancial {...item}></EqProductFinancial>
                            ))
                        }
                    </>
                )}
            </div>
        </div>
    );
};
export default EqProductFinancialsList;
