import React, { useState, useEffect } from 'react';
import { Button, Modal, Upload, message, Row, Col } from 'antd-v5';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'; // Import DeleteOutlined icon
import Compressor from 'compressorjs';
import "./EqImageSelector.scss";
import { useTranslation } from 'react-i18next';
import EqButton from '../../components/custom/eqButton/EqButton';
import { ApiEndpoints } from '../../components/shared/Common';
import Api from '../../components/shared/Api';


interface EqImageInfoDto {
  id: string;
  key: number;
  url: string;
  uploadedDate: number;
  imageId: string;
  eqUser: any | null;
}

interface EqImageSelectorProps {
  imagesData: EqImageInfoDto[];
  onImageSelect: (image: EqImageInfoDto) => void;
  onImageUploadSuccess: (newImage: any) => void;
  onImageDeleteSuccess: (imageId: any) => void;
  defaultSelectedImage?: EqImageInfoDto| null; // Optional default image prop
  className?:string;
}

const EqImageSelector: React.FC<EqImageSelectorProps> = ({
  imagesData,
  onImageSelect,
  onImageUploadSuccess,
  onImageDeleteSuccess,
  defaultSelectedImage,
  className
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<EqImageInfoDto | null>(defaultSelectedImage || null);
  const [tempSelectedImage, setTempSelectedImage] = useState<EqImageInfoDto | null>(defaultSelectedImage || null); // Temporary state for image selection in the modal
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultSelectedImage) {
      setSelectedImage(defaultSelectedImage);
      setTempSelectedImage(defaultSelectedImage);
    }
  }, [defaultSelectedImage]);

  const handleImageClick = (image: EqImageInfoDto) => {
    setTempSelectedImage(image);
  };

  const handleImageUploadClick = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (tempSelectedImage) {
      setSelectedImage(tempSelectedImage);
      onImageSelect(tempSelectedImage); // Pass the confirmed image to the parent component
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setTempSelectedImage(defaultSelectedImage || null); // Clear the temp selection on cancel
    setIsModalOpen(false);
  };

  const handleUploadToLibrary = async (info: any) => {
    try {
      const file = info.file.originFileObj || info.file;
      await getBase64Product(file);
      info.onSuccess(null, file); // Notify Ant Design that the upload was successful
    } catch (error) {
      info.onError(error); // Notify Ant Design of the upload failure
    }
  };

  const getBase64Product = (img: any) => {
    return new Promise<void>((resolve, reject) => {
      new Compressor(img, {
        quality: 0.7,
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onload = async () => {
            try {
              const srcStr = reader.result as string;
              const apiImgSrc = srcStr.substr(srcStr.indexOf(',') + 1);

              const form = new FormData();
              form.append('imageFile', compressedResult);
              form.append('imageSrc', apiImgSrc);
              form.append('imageName', img.name);

              const response = await Api.post(ApiEndpoints.Image.uploadImage, form);
              onImageUploadSuccess(response.data); // Call the callback here
              message.success(t('imageUploaded'));
              resolve();
            } catch (error) {
              message.error(t('imageUploadFailed'));
              reject(error);
            }
          };
          reader.readAsDataURL(compressedResult);
        },
        error: (err) => {
          message.error(t('imageUploadFailed'));
          reject(err);
        },
      });
    });
  };

  const confirmDeleteImage = (imageId: string) => {
    Modal.confirm({
      title: t('Are you sure you want to delete this image?'),
        footer:(<Row justify={'end'}>
          <EqButton style={{marginRight:"2px"}} bordered onClick={() => Modal.destroyAll()}>
          {t("no")}
        </EqButton> 
        <EqButton onClick={ ()=>deleteImage(imageId)}>
        {t("yes")}
      </EqButton></Row>),
      
      // onOk: () => deleteImage(imageId),
    });
  };

  const deleteImage = async (imageId: string) => {
    try {
      await Api.delete(`${ApiEndpoints.Image.DeleteById}/${imageId}`); // Assuming API route is /image/:id
      Modal.destroyAll();
      onImageDeleteSuccess(imageId); // Call the callback here
      message.success(t('imageDeleted'));

    } catch (error) {
      message.error(t("generalServerError"));
    }
  };

  return (
    <div className='eq-image-selector'>
      <Row>
              <Col>
                  <Button onClick={handleImageUploadClick} className={className} icon={<span className="plus-icon"> + </span>}>
            <div>{t("uploadPicture")}</div>
          </Button>
        </Col>

        {selectedImage && (
          <img
            className='selected-image'
            src={selectedImage.url}
            alt="Selected"
          />
        )}
      </Row>

      <Modal
        // title={t("select")}
        
        open={isModalOpen}
        onCancel={handleCancel}
        width={800}
        className='eq-image-selector-modal'
        style={{ top: 20 }}
        bodyStyle={{ overflowY: 'auto', maxHeight: '60vh', margin:"10px" }}
        footer={[
          <EqButton key="ok" type="primary" onClick={handleOk}>
            {t("select")}
          </EqButton>
        ]}
      >
        <div className='upload-library' style={{ marginBottom: '10px' }}>
          <Upload
            name="image"
            showUploadList={false}
            customRequest={handleUploadToLibrary}
          >
            <EqButton
              icon={<UploadOutlined />}
              type="primary"
              className='upload-to-library-button'
            >
              {t("upload")}
            </EqButton>
          </Upload>
        </div>

        <div className="image-grid">
          {imagesData.map((image: EqImageInfoDto) => (
            <div
              key={image.id}
              className={`image-item ${tempSelectedImage?.id === image.id ? 'selected' : ''}`}
              onClick={() => handleImageClick(image)}
            >
              <img src={image.url} alt="Event" />
              <DeleteOutlined
                className="delete-icon"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent selecting the image when clicking delete
                  confirmDeleteImage(image.id);
                }}
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default EqImageSelector;
