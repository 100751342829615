import React, { useEffect } from "react";
import { Row, Col, Checkbox, Button, Space, Empty } from "antd-v5";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import EqCollapse from "../../../../custom/eqCollapse/EqCollapse";
import "./RequestListGeneric.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";


interface ListProps {
  title: string;
  data: any[];
  orderStatus: number;
  multipleSelect?: boolean;
  isCheckAll: boolean;
  onCheckAll: (checked: boolean) => void;
  selectedCheckboxes: any[];
  handleAccept: (id: any) => void;
  handleReject: (id: any) => void;
  handleCheckboxChange: (item: any) => void;
  coursePrice: number;
}

const RequestListGeneric: React.FC<ListProps> = ({
  title,
  data,
  orderStatus,
  multipleSelect = false,
  isCheckAll,
  onCheckAll,
  selectedCheckboxes,
  handleAccept,
  handleReject,
  handleCheckboxChange,
  coursePrice,
}) => {
  const { t, i18n } = useTranslation();
  const filteredData = data.filter((item) => item.orderStatus === orderStatus);

    
useEffect(() => {
  debugger;
 
}, [ filteredData]);


  
  return (
    <div className="req-list-generic">
      <h3>{title}</h3>
      {filteredData.length > 0 ? (
        <>
          {/* Desktop View */}
          <div className="hidden-sm">
            <Col span={24} className="Table-head-col">
              <Row className="eq-fl-row">
                {multipleSelect && (
                  <Col className="eq-fl-th" span={2}>
                    <Checkbox onChange={(e) => onCheckAll(e.target.checked)} checked={isCheckAll} />
                  </Col>
                )}
                <Col span={6}>  {t("date")}</Col>
                <Col className="eq-fl-th" span={6}>
                {t("student")}

                </Col>
                <Col className="eq-fl-th" span={6}>
                {t("amount")}
                </Col>
                <Col className="eq-fl-th" span={6}>
                {t("actions")}

                </Col>
              </Row>
              {filteredData.map((item, index) => (
                <Row key={item.id} className="eq-f-row">
                  {multipleSelect && (
                    <Col className="eq-fl-td" span={2}>
                      <Checkbox
                        onChange={() => handleCheckboxChange(item)}
                        checked={item.selected}
                      />
                    </Col>
                  )}
                  <Col span={6}>{moment(item.createDate).format("YYYY-MM-DD hh:mm A")} </Col>
                  <Col className="eq-fl-td" span={6}>
                    {`${item.student.firstName} ${item.student.lastName}`}
                  </Col>
                  <Col className="eq-fl-td" span={6}>
                    {item.lessonPrice? item.lessonPrice: coursePrice}
                  </Col>
                  <Col className="eq-fl-td product-action" span={6}>
                    <Space>
                      <Button
                        onClick={() => handleAccept(item.id)}
                        className="invite-accept-btn"
                        size="small"
                        shape="circle"
                        icon={<CheckOutlined />}
                      />
                      <Button
                        onClick={() => handleReject(item.id)}
                        className="invite-reject-btn"
                        size="small"
                        shape="circle"
                        icon={<CloseOutlined />}
                      />
                    </Space>
                  </Col>
                </Row>
              ))}
            </Col>
          </div>

          {/* Mobile View */}
          <div className="hidden-md">
            {filteredData.map((item, index) => (
              <EqCollapse
                key={item.id}
                header={
                  <Row justify="start" align="middle">
                    {multipleSelect && (
                      <Col span={2}>
                        <Checkbox
                          onChange={() => handleCheckboxChange(item)}
                          checked={item.selected}
                        />
                      </Col>
                    )}
                    <Col span={22}>
                      {`${item.student.firstName} ${item.student.lastName}`}
                    </Col>
                  </Row>
                }
              >
                <Row className="eq-card-row">
                  <Col className="eq-card-col-heading" span={24}>
                    Student
                  </Col>
                  <Col span={24}>
                    {`${item.student.firstName} ${item.student.lastName}`}
                  </Col>
                </Row>
                <Row>
                  <Col flex="auto" className="eq-card-divider"></Col>
                </Row>
                <Row className="eq-card-row">
                  <Col className="eq-card-col-heading" span={24}>
                    Amount
                  </Col>
                  <Col span={24}>
                  {item.lessonPrice? item.lessonPrice: coursePrice}
                  </Col>
                  
                </Row>
                <Row>
                  <Col flex="auto" className="eq-card-divider"></Col>
                </Row>
                <Row className="eq-card-row">
                  <Col className="eq-card-col-heading" span={24}>
                    Action
                  </Col>
                  <Col span={24} className="product-action">
                    <Space>
                      <Button
                        onClick={() => handleAccept(item.id)}
                        className="invite-accept-btn"
                        size="small"
                        shape="circle"
                        icon={<CheckOutlined />}
                      />
                      <Button
                        onClick={() => handleReject(item.id)}
                        className="invite-reject-btn"
                        size="small"
                        shape="circle"
                        icon={<CloseOutlined />}
                      />
                    </Space>
                  </Col>
                </Row>
              </EqCollapse>
            ))}
          </div>
        </>
      ) : (
        <Empty description={t("noDataAvailable")} />
      )}
    </div>
  );
};

export default RequestListGeneric;
