import { Col, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./CourseLessonCollapse.scss";
import moment from "moment";
import { EqIconCalendar } from "../../../../../custom/eqIcon/EqIcons";
import EqCollapse from "../../../../../custom/eqCollapse/EqCollapse";
import { CourseOrderType, EqDisciplineType, EqJoinPermissions } from "../../../../../shared/enums";
import { Input, message, Row } from "antd-v5";
import EqSelect from "../../../../../custom/eqSelect/EqSelect";
import { ISelectProps } from "../../../../../shared/Interfaces";
import {
    CourseUI,
    EqOrderStatus,
    ICourseLessonUI,
    ISchoolStudents,
    IStudentHorseList,
    ITrainerOption,
} from "../../../../../shared/interfaces/ridingCourse/IRidingCourse";
import EqDatePicker, { IDatePickerOptions } from "../../../../../custom/eqDatePicker/EqDatePicker";
import EqButton from "../../../../../custom/eqButton/EqButton";
import { useTranslation } from "react-i18next";
import { getUserIdFromToken } from "../../../../../shared/Common";
export interface ICourseLessonCollapseProps {
    data: any;
    onEditLesson?: (data: any) => void;
    onRemoveLessonOrder?: (lessonId:string,order: any, refund: boolean, refundAmount?: number) => void;
   
    trainings: Array<Object>;
    weekdays: Object;
    schoolStudentsData?: ISchoolStudents[];
    studentHorsesList?: IStudentHorseList[];

    trainersOptions: ITrainerOption;
    editCourse?: CourseUI | null;
    isStudent?: boolean;
    onCourseLessonJoinRequest?: (lessonId:string) => void;
    onPayNowLessonOrder?: (orderId:string) => void;

}
const CourseLessonCollapse = ({
    data,
    onEditLesson,
    onRemoveLessonOrder,
    trainings,
    schoolStudentsData,
    studentHorsesList,
    trainersOptions,
    editCourse,
    isStudent,
    onCourseLessonJoinRequest,
    onPayNowLessonOrder
}: any) => {
    const [formData, setFormData] = useState<any>({});
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const [orders, setOrders] = useState<any[]>([]);
    const [selectedHorses, setSelectedHorses] = useState<any[]>([]);
    const [refundAmount, setRefundAmount] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [order, setOrder] = useState<string>("");
    const { t } = useTranslation();
    const currentUserId = getUserIdFromToken();

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setRefundAmount("");
        setOrder("");
    };

    const handleRefund = () => {
        const amount = Number(refundAmount);
        if (!refundAmount || isNaN(amount) || amount <= 0) {
            message.error(t("Please enter a valid refund amount"));
            return;
        }
        if (onRemoveLessonOrder) onRemoveLessonOrder(order, true, amount);
        closeModal();
    };

    const handlePayLater = () => {
        if (onRemoveLessonOrder) onRemoveLessonOrder(order, false, 0);
        closeModal();
    };

    useEffect(() => {
        if (data != null && editCourse != null) {
            // setFormData(data);
            debugger;
            
            let courseOrders = editCourse.courseLessonOrders;
            let lessonOrders = data.courseLessonOrders;

            let orders = [...courseOrders, ...lessonOrders] as any;
            
            let filteredOrdersList = orders.filter((or:any)=>{return(or.orderStatus===EqOrderStatus.Accepted || or.orderStatus===EqOrderStatus.Paid)}) as any;

            let uniqueLessonOrders = orders.reduce((acc: any, curr: any) => {
                const existing = acc.find(
                    (item: any) =>
                        item.courseId === curr.courseId && item.studentId === curr.studentId
                );
                if (!existing) {
                    acc.push(curr); // If no object with the same courseId, add the current one
                } else if (curr.orderType === 1) {
                    // If the current one is 'lessonOnly', replace the existing one
                    const index = acc.indexOf(existing);
                    acc[index] = curr;
                }
                return acc;
            }, []);

            setOrders(uniqueLessonOrders);
            setFormData({
                ...data,
                // startTime: ticksToMilliSeconds(data.startTime),
         
            });
        }

        if (editCourse != null && schoolStudentsData != null) {
            const MatchingStudent = schoolStudentsData
                .filter((student: any) =>
                    editCourse.courseLessonOrders.some(
                        (order: any) => order.studentId === student.user.id
                    )
                )
                .map((student: any) => student.user.id);

            console.log("Matching Student IDs:", MatchingStudent);
            setSelectedStudents(MatchingStudent);
        }
    }, [editCourse, schoolStudentsData]);

    const HorseOptions = (key: any): any[] => {
        if (!studentHorsesList) return [];
        const matchedEntry = studentHorsesList.find((x: any) => x.key === key);
        if (!matchedEntry) return [];

        return matchedEntry.horseList.filter(
            (horse: any) =>
                !selectedHorses.some(
                    (selected) => selected.horseId === horse.key && selected.key !== key
                )
        );
    };

    const handleOnChangeHorse = (horseId: any, attendeeKey: any) => {
        // Update selected horses
        
        const updatedHorses = [...selectedHorses];
        const horseIndex = updatedHorses.findIndex((h: any) => h.key === attendeeKey);

        if (horseIndex !== -1) {
            // Update existing horse selection
            updatedHorses[horseIndex].horseId = horseId;
        } else {
            // Add new horse selection
            updatedHorses.push({ key: attendeeKey, horseId });
        }
        setSelectedHorses(updatedHorses);

        // Update orders with new horseId
        const updatedOrders = orders.map((order: any) => {
            if (order.student.id === attendeeKey) {
                return { ...order, horseId }; // Update horseId in the order
            }
            return order; // Keep other orders unchanged
        });

        setOrders(updatedOrders); // Update state for orders
    };

    const trainerSelectProps: ISelectProps = {
        isDisabled:isStudent,

        options: trainersOptions,
        label: t("trainer"),
        defaultSelectedValue: isStudent?(editCourse?.courseTrainer?.firstName +" "+ editCourse?.courseTrainer?.lastName) :formData?.trainerId,
        classNames: "extra-height",
        placeholder: t("selectTrainer"),

        showSearch: true,
        optionFilterProp: "label",
        filterOption: true,

        changeFunc: (value: string) => {
            setFormData({ ...formData, trainerId: value });
        },
    };

    const startOnProps: IDatePickerOptions = {
        label: `${t("startDate")} *`,
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: formData?.startTime,
        placeHolder: t("startDate"),
        classNames: "eq-event",
        isDisabled:isStudent,
        onChangeFunc: (date: any) => {
            
            setFormData({
                ...formData,
                startTime: moment(date).valueOf(),
                // startTime: moment(date),
                // endDate: moment(date),
                // registrationDeadline: moment(date),
            });
        },
    };

    const handleInputChange = (feild: string, value: any) => {
        setFormData({
            ...formData,
            [feild]: value,
        });
    };

    const handleOnclickRemoveAttendee = (order: any) => {
        
        if (order.id !== undefined) {
            if (order.orderStatus === EqOrderStatus.Paid) {
                setOrder(order.id);
                handleOpenModal();
            } else {
                onRemoveLessonOrder && onRemoveLessonOrder(data.id,order, false, 0);
            }

            // handle remove attendee
        }
        else{
            setOrders((prevOrders) =>
                prevOrders.filter((o: any) => o.studentId !== order.studentId)
            );
          
        }
        // if order has id
        // check if status is paid
        //show modal for for refund

        // order id is not available
        // remove order from list
    };

    const handlestudentselect = (selectedValue: string) => {
        

        // check if selected user is not available in orders
        let isStudentAvailable = orders.find((o: any) => o.studentId === selectedValue);

        if (isStudentAvailable) {
            return;
        } else {
            let prevOrders = [...orders];

            let student = schoolStudentsData?.find((ss: any) => ss.user.id === selectedValue);
            prevOrders.push({
                studentId: selectedValue,
                student: student?.user,
            });
            setOrders(prevOrders);
        }
    };

    //setSelectedStudents(editCourse.courseLessonOrders?.[0].price);
    const selectedStudentDetails = schoolStudentsData?.filter((student: ISchoolStudents) =>
        selectedStudents.includes(student.user.id)
    );

    const handleOnUpdateLesson = async () => {
        console.log(formData);

        // Prepare the data to be sent to the API
        const lessonData = {
            ...formData,
            courseLessonOrders: orders,
        };

        onEditLesson && onEditLesson(lessonData);
    };

 const handleJoinLessonRequest=()=>{

 }

 const handlePayLessonOrder=()=>{

 }

 const renderLessonJoinButton = () => {
    if (!isStudent) return null; // Render nothing if the current user is not a student

    const today = moment();
    const userOrder = orders.find(order => order.student.id === currentUserId);

    const renderOrderButton = () => {
        if (userOrder?.orderType === CourseOrderType.FullCourse) {
            // Full course order - show order status
            return (
                <EqButton disabled>
                    {t(`${EqOrderStatus[userOrder.orderStatus]}`)}
                </EqButton>
            );
        } else {
            // Lesson-specific order
            if (userOrder?.orderStatus === EqOrderStatus.Accepted) {
                // If the order is accepted, show "Pay Now" button
                return (
                    <EqButton onClick={()=>onPayNowLessonOrder(userOrder.id)}>
                        {t("payNow")}
                    </EqButton>
                    // <EqButton onClick={(handlePayLessonOrder)}>
                    //     {t("payNow")}
                    // </EqButton>
                );
            } else {
                // Otherwise, show the order status
                return (
                    <EqButton disabled>
                        {t(`${EqOrderStatus[userOrder?.orderStatus]}`)}
                    </EqButton>
                );
            }
        }
    };

    // Handle permission: AllowLessonJoin
    if (editCourse.joinPermission === EqJoinPermissions.AllowLessonJoin) {
        if (userOrder) {
            return renderOrderButton();
        }
        // If no order exists, show "Request to Join" button

        // Only the first available lesson after the current date can be joined by a student.
        var dateToday= new Date();
        dateToday.setHours(0, 0, 0, 0);

        const firstAvailableLesson = editCourse.courseLessons.sort((a: any, b: any) => a.startTime - b.startTime).find((lesson: ICourseLessonUI) => {
            const lessonDate = new Date(lesson.startTime);
            lessonDate.setHours(0, 0, 0, 0);
            return lessonDate > dateToday;
        });

        if(firstAvailableLesson===undefined){
            return;
        }
        if (firstAvailableLesson.id === data.id ) {
            return (
                <EqButton onClick={()=>onCourseLessonJoinRequest(firstAvailableLesson.id)}>
                    {t("request")}
                </EqButton>
            );
        }

        // return (
        //     <EqButton onClick={()=>onCourseLessonJoinRequest(data.id)}>
        //         {t("request")}
        //     </EqButton>
        // );
    }

    // Handle permission: AllowLessonJoinAfterCourseStart
    if (editCourse.joinPermission === EqJoinPermissions.AllowLessonJoinAfterCourseStart) {
        if (moment(data.startTime).isAfter(today)) {
            // Course has started
            if (userOrder) {
                return renderOrderButton();
            }

      // Only the first available lesson after the current date can be joined by a student.
      var dateToday= new Date();
      dateToday.setHours(0, 0, 0, 0);

      const firstAvailableLesson = editCourse.courseLessons.sort((a: any, b: any) => a.startTime - b.startTime).find((lesson: ICourseLessonUI) => {
          const lessonDate = new Date(lesson.startTime);
          lessonDate.setHours(0, 0, 0, 0);
          return lessonDate > dateToday;
      });

      if (firstAvailableLesson.id === data.id ) {
          return (
              <EqButton onClick={()=>onCourseLessonJoinRequest(firstAvailableLesson.id)}>
                  {t("request")}
              </EqButton>
          );
      }

            // // If no order exists, show "Request to Join" button
            // return (
            //     <EqButton onClick={()=>onCourseLessonJoinRequest(data.id)}>
            //         {t("request")}
            //     </EqButton>
            // );
        }
        // If the course hasn't started yet, render nothing
        return (
            <EqButton disabled>
                {t("Join Available After Course Starts")}
            </EqButton>
        );
    }

    if(editCourse.joinPermission === EqJoinPermissions.DontAllowLessonJoin){
debugger;
        if(userOrder && userOrder.orderType === CourseOrderType.LessonOnly && userOrder.courseId === null){
            return renderOrderButton();
        }
    }
    // Handle permission: DontAllowLessonJoin or other cases
    // return (
    //     <EqButton disabled>
    //         {t("Lesson Join Disabled")}
    //     </EqButton>
    // );

    return;
};

    

    return (
        <div className="eq-student-activity-collapse">
            <EqCollapse
                header={
                    <>
                        <div className="eq-activity-row">
                            <Row className="eq-status-row-border">
                                <Col className="content-col">
                                    <Row>
                                        <Col xs={24} md={4} className="Header-col">
                                            <div className="date-icon">
                                                <EqIconCalendar
                                                    eqWidth={34}
                                                    eqHeight={34}
                                                    eqClass="eq-cal-icon"
                                                    eqFill="#083b49"
                                                />
                                                <div className="day">
                                                    {moment(
                                                        data?.startTime
                                                    ).format("DD")}
                                                </div>
                                                <div className="month">
                                                    {moment(
                                                        data?.startTime
                                                    ).format("MMM")}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} md={10} className="Header-col">
                                            <span className="time">
                                                {moment(data.startTime).format(
                                                    "hh:mm A"
                                                )}{" "}
                                                -{" "}
                                                {trainings.disciplineType != EqDisciplineType.Open
                                                    ? moment(data.startTime)
                                                          .add(
                                                            data
                                                                  .durationMinutes,
                                                              "minutes"
                                                          )
                                                          .format("hh:mm A")
                                                    : moment(
                                                        data.endTime
                                                      ).format("hh:mm A")}
                                            </span>
                                        </Col>

                                        <Col xs={24} md={4} className="Header-col">
                                            <span className="Capacity">
                                                <>
                                                    {t("capacity")}:
                                                    {(orders?.filter(order=>(order.orderStatus === EqOrderStatus.Paid || order.orderStatus === EqOrderStatus.Accepted) )?.length)}/{editCourse?.capacity}
                                                </>
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} className="Header-col">
                                            <span className="Price">
                                                {t("price")}:
                                                {editCourse?.lessonPrice}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                             
                                {renderLessonJoinButton()}
                                </Col>
                            </Row>
                        </div>
                    </>
                }
            >
                <div style={{ border: "2px solid #F0F0F0", paddingBottom: "5px" }}>
                    <div className="Course-Lesson-Collapse">
                        <Row gutter={[16, 16]} align={"middle"}>
                            <Col xs={24} sm={24} md={12}>
                                <div className="eq-lesson-update-input">
                                    <EqSelect {...trainerSelectProps} />
                                </div>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                                <div className="eq-lesson-update-input">
                                    <EqDatePicker {...startOnProps}></EqDatePicker>
                                </div>
                            </Col>

                            {/* <Col xs={24} sm={24} md={12}>
                            <div className="eq-lesson-update-input">
                                <Row className="label-h2">
                                    <>{t("from")}</>
                                </Row>
                                <EqTimePicker {...timeFromProps}></EqTimePicker>
                            </div>
                        </Col> */}
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12}>
                                <div className="eq-lesson-update-input">
                                    <EqSelect
                                        label={t("duration")}
                                        classNames="extra-height"
                                        isDisabled={isStudent}
                                        defaultSelectedValue={formData?.durationMinutes}
                                        changeFunc={(value: string) =>
                                            handleInputChange("durationMinutes", value)
                                        }
                                        options={[
                                            {
                                                key: 30,
                                                value: 30,
                                                label: "30",
                                            },
                                            {
                                                key: 45,
                                                value: 45,
                                                label: "45",
                                            },
                                            { key: 60, value: 60, label: "60" },
                                            { key: 90, value: 90, label: "90" },
                                        ]}
                                    />
                                </div>
                            </Col>
                        </Row>

                        {/*<TrainingCourseCreate />*/}

                        <Col>
                            <label>{t("student")}</label>
                            <EqSelect
                            isDisabled={isStudent}
                                // mode="multiple"
                                showSearch
                                filterOption={true}
                                optionFilterProp="label"
                                // isTag={false}
                                tagClosable={false}
                                placeholder={t("selectStudents")}
                                classNames="eq-multiSelect eq-searchSelect extra-height"
                                defaultSelectedValue={[]}
                                changeFunc={handlestudentselect}
                                options={schoolStudentsData?.map((student: any) => ({
                                    key: student.user.id,
                                    value: student.user.firstName,
                                    label: `${student.user.firstName} ${student.user.lastName}`,
                                }))}
                            />
                        </Col>
                    </div>
                    {true && (
                    // {selectedStudentDetails && selectedStudentDetails.length > 0 && (
                        <div className="student-details-table">
                          {!isStudent &&   <h2>{t("selectedStudentDetails")}</h2>}
                            <Row className="table-header">
                                <Col md={5} className="user-details-row">
                                    <strong>{t("name")}</strong>
                                </Col>
                                <Col md={6} className="user-details-row">
                                    <strong>{t("contact")}</strong>
                                </Col>
                                <Col md={6} className="user-details-row">
                                    <strong>{t("horse")}</strong>
                                </Col>
                                {!isStudent &&  <Col md={4}>
                                    <strong>{t("status")}</strong>
                                </Col>}
                                {!isStudent &&   <Col md={3}>
                                    <strong>{t("action")}</strong>
                                </Col>}
                            </Row>
                            {orders.map((order: any, index: any) => (
                                <Row key={order.student.id} className="table-row">
                                    <Col md={5} className="user-details-row">
                                        <>
                                            {order.student.firstName} {order.student.lastName}
                                        </>
                                    </Col>
                                    <Col md={6} className="user-details-row">
                                        <>{order.student.email}</>
                                    </Col>
                                    <Col md={6} className="user-details-row">
                                        <EqSelect
                                            isDisabled={isStudent}
                                            showSearch
                                            placeholder="Select Horse"
                                            classNames="Horse-select"
                                            options={HorseOptions(order.student.id)}
                                            defaultSelectedValue={ isStudent? (order?.horse?.name):(order?.horseId)}
                                            changeFunc={(e: any) => {
                                                handleOnChangeHorse(e, order.student.id);
                                            }}
                                        />
                                    </Col>

                                    {!isStudent &&   <Col md={4} className="user-details-row">
                                        <>
                                            {EqOrderStatus[order.orderStatus]}
                                            {/* Paid */}
                                        </>
                                    </Col>}
                                    {!isStudent && (order.orderStatus === EqOrderStatus.Paid || order.orderStatus === EqOrderStatus.Accepted) && <Col md={3} className="Delete-Icon">
                                        <span>
                                            <img
                                                alt=""
                                                // src="/trash-alt.svg"
                                                src="/trash-alt-red.svg"
                                                onClick={() => handleOnclickRemoveAttendee(order)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </span>
                                    </Col>}
                                </Row>
                            ))}
                        </div>
                    )}
                    {!isStudent && 
                    <Row justify={"center"}>
                        <EqButton
                            type="button"
                            classNames="btn-background-orange parent-btn"
                            onClick={handleOnUpdateLesson}
                            >
                            {t("update")}
                        </EqButton>
                    </Row>
                        }
                </div>
            </EqCollapse>

            <Modal
                title={t("confirm")}
                className="refund-confirm-modal"
                open={isModalOpen}
                onCancel={closeModal}
                footer={null} // Custom footer
                closable={true}
                // closeIcon={<span style={{ fontSize: '16px', color: '#000' }}>X</span>}
            >
                <div>
                    <p>{t("refundAttendee")}</p>
                    <Input
                        type="number"
                        placeholder={t("amount")}
                        min={0}
                        value={refundAmount}
                        onChange={(e) => setRefundAmount(e.target.value)}
                        style={{ marginBottom: "10px" }}
                    />
                </div>

                <Row style={{ padding: "2px" }} justify={"end"}>
                    {/* Refund Button */}
                    <EqButton style={{ marginRight: "2px" }} bordered onClick={handleRefund}>
                        {t("refund")}
                    </EqButton>

                    {/* Pay Later Button */}
                    <EqButton onClick={handlePayLater}>{t("payLater")}</EqButton>
                </Row>
            </Modal>
        </div>
    );
};
export default CourseLessonCollapse;
