import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { EqInput } from '../eqInput/EqInput';
import './EqLocation.scss';
import Api from '../../shared/Api';
import { EqLocationType } from '../../shared/enums';
import EqGoogleMap from '../eqGoogleMap/eqGoogleMap';
import EqAutocomplete from '../eqAutocomplete/eqAutocomplete';
import { useLoadScript } from "@react-google-maps/api";
import { googleMap_API_Key } from '../../shared/Common';

export interface IEqLocationProps {
    visible: boolean;
    okClicked: (param: any, detail?:any) => void;
    cancelClicked: (param: any) => void;
    title: string;
    email: string;
}

export const EqLocation = (props: IEqLocationProps) => {
    const libraries = ["places"] as any;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMap_API_Key,
        libraries: libraries
    });
    const [selected, setSelected] = React.useState(null as any);

    const { visible, okClicked, cancelClicked, title, email } = props;

    const [isModalVisible, setIsModalVisible] = useState(visible);
    const [location, setLocation] = useState(null as any);
    const [errorMessage, setErrorMessage] = useState("");
    const errorText = "Could not add the location";

    function Locate() {
        navigator.geolocation.getCurrentPosition((position: any) =>
            setSelected({ lat: position.coords.latitude, lng: position.coords.longitude }),
            () => null)
    }

    // const  handleUploadImage = async (location: any) => {
    //     const form = new FormData();
    //     // form.append("imageFile", "");
    //     form.append("imageSrc", location.locationImageUrl);
    //     form.append("imageName", location.locationName);

    //    await Api.post("Image/uploadImage", form).then(response => {
    //        console.log(response)
    //        return response
    //     },error=>{
    //         console.log(error);
    //         console.log("Error Occurred while uploading image");

    //     });

    // }

    const handleOk = async () => {
        setErrorMessage("");
        
        console.log(location);
        if (location != null || undefined) {
        //    var image= await handleUploadImage(location);
            location.type = EqLocationType.Work;
            location.email = email;
            Api.post("Location/Create", location).then((response: any) => {
                console.log(response.data);
                const locDetail = response.data;
                const locSelect = {
                    key: response.data?.key?.toString(),
                    value: response.data?.city,
                    description: response.data?.street + ", " + response.data?.city + ", " + response.data?.zipCode
                };
                okClicked && okClicked(locSelect, locDetail);
                setIsModalVisible(false);
            }, error => {
                setErrorMessage(errorText);
            });
        }

    }

    const handleCancel = () => {
        cancelClicked && cancelClicked(location);
        setIsModalVisible(false);
    }

    useEffect(() => {
        
        Locate();
        setIsModalVisible(visible);
    }, [props]);

    return (
        <div className="ex-location">
            <Modal
            className='locationModal'
            title={title} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {errorText.length > 0 && <Row align="middle" className="input-row">
                    <Col flex="auto">
                        <div className="error-message">
                            {errorMessage}
                        </div>
                    </Col>
                </Row>}
                <Row>
                    <Col flex="auto">
                        {isLoaded &&
                        <>
                                <div className="places-container">

                                <EqAutocomplete setSelected={setSelected} setLocation={setLocation}></EqAutocomplete>
                        </div>
                            <EqGoogleMap selected={selected} containerStyle={{ height: '100vh', width: '100%' }}></EqGoogleMap>
                            </>
}
                        </Col>
                    </Row>
                {/*<Row align="middle" className="input-row">*/}
                {/*    <Col flex="auto">*/}
                {/*        <EqInput label="Location name" className="location-name-input" onChange={(e: any) => setLocation({ ...location, locationName: e.target.value })} value={location?.locationName} placeholder=""></EqInput>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row align="middle" className="input-row">*/}
                {/*    <Col flex="auto">*/}
                {/*        <EqInput label="Street address" className="street-input" onChange={(e: any) => setLocation({ ...location, street: e.target.value })} value={location?.street} placeholder=""></EqInput>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row align="middle" className="input-row">*/}
                {/*    <Col md={12} xs={24} sm={24} className="col-left">*/}
                {/*        <Row align="middle">*/}
                {/*            <Col flex="auto">*/}
                {/*                <EqInput label="City" className="profile-input" onChange={(e: any) => setLocation({ ...location, city: e.target.value })} value={location?.city} placeholder=""></EqInput>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*    <Col md={12} xs={24} sm={24} className="col-right">*/}
                {/*        <Row align="middle">*/}
                {/*            <Col flex="auto">*/}
                {/*                <EqInput label="Zip code" className="profile-input" onChange={(e: any) => setLocation({ ...location, zipCode: e.target.value })} value={location?.zipCode} placeholder=""></EqInput>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row align="middle" className="input-row">*/}
                {/*    <Col flex="auto">*/}
                {/*        <EqInput label="Municipality" className="street-input" onChange={(e: any) => setLocation({ ...location, municipality: e.target.value })} value={location?.municipality} placeholder=""></EqInput>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row align="middle" className="input-row">*/}
                {/*    <Col flex="auto">*/}
                {/*        <EqInput label="State" className="street-input" onChange={(e: any) => setLocation({ ...location, state: e.target.value })} value={location?.state} placeholder=""></EqInput>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row align="middle" className="input-row">*/}
                {/*    <Col flex="auto">*/}
                {/*        <EqInput label="Country name" className="street-input" onChange={(e: any) => setLocation({ ...location, countryName: e.target.value })} value={location?.countryName} placeholder=""></EqInput>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Modal>
        </div>
    );
}