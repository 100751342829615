import * as React from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import { useEffect } from "react";
import { EqCurrency, QuestionType } from "../../../components/shared/enums";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        paddingTop: 50,
        paddingBottom: 50,
        paddingHorizontal: 50,
    },
    section: {
        marginBottom: 20,
        textAlign: "left",
    },
    heading: {
        fontSize: 20,
        marginBottom: 5,
    },
    attendeeContainer: { border: "1px solid #FF8838", marginBottom: 10, padding: 10 },
    attendeeRow: {
        flexDirection: "row",
        // alignItems: "center",
        marginBottom: 5,
    },
    attendeeNumber: {
        marginRight: 5,
        fontWeight: 600,
    },
    attendeeDetails: {
        flexDirection: "column",
        flex: 1,
    },
    attendeeName: {
        fontSize: 16,
        fontWeight: 600,
    },
    attendeeEmail: {
        marginVertical: 1,
        fontSize: 10,
        color: "#666666",
    },
    divider: {
        borderBottom: "1px solid #d9d9d9",
        marginVertical: 5,
    },
    classOrder: { marginLeft: 10, marginBottom: 5 },
 
    classRow: {
        flexDirection: "row",
        // alignItems: "center",
        marginBottom: 5,
    },
    classNumber: {
        fontSize: 14,
        color: "#666666",
        marginRight: 5,
        fontWeight: 600,
    },
    classDetails: {
        flexDirection: "column",
        flex: 1,
    },
    classTitle: {

        fontSize: 14,
        color: "#666666",
        fontWeight: 600,
        // marginVertical: 5,
    },
    priceText: {
        fontSize: 10,
        color: "#666666",
        fontWeight: 600,
        marginVertical: 1,
    },
    listContainer: {
        marginBottom: 10,
    },
    questionContainer: {
        border: "1px solid #d9d9d9",
        padding: "10px",
        fontSize: 11,
        backgroundColor: "#f8f8f8", // Light background color
    },
    question: {
        fontSize: 12,
        fontWeight: "bold",
        color: "black",
        marginBottom: 7,
    },
    answer: {
        fontSize: 12,
        color: "#474e51",
    },

    questionText: {
        fontWeight: 600,
        marginBottom: 5,
    },
    answerText: {
        fontStyle: "italic",
        color: "#666666",
    },
});

const EqPublicEventPdfGenerateListView = ({ event }: any) => {

    const { t } = useTranslation();

    const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
    };
    useEffect(() => {
        console.log(event);
    }, [event]);

    // Function to gather all attendees and class orders from alternatives
    const getAttendeesWithOrders = (event: any) => {
        const attendeesMap = {} as any;

        event?.alternatives?.forEach((alternative: any) => {
            alternative.classList.forEach((classListItem: any) => {
                classListItem?.classOrders?.forEach((classOrder: any) => {
                    // const attendeeEmail = classOrder.user.email;
                    const attendUsingName = classOrder.attendUsingName;
                    // for startList Use AttendeeName as key

                    // If attendee is already in the map, just push the class order
                    if (!attendeesMap[attendUsingName]) {
                        attendeesMap[attendUsingName] = {
                            user: classOrder.user,
                            attendUsingName:classOrder.attendUsingName,
                            classOrders: [],
                        };
                    }
                    attendeesMap[attendUsingName].classOrders.push({
                        classTitle: classListItem.title,
                        price: classListItem.price,
                        answers: classOrder.answers,
                    });
                });
            });
        });

        return Object.values(attendeesMap);
    };

    const attendeesWithOrders = getAttendeesWithOrders(event);

    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>
                <Page style={styles.page}>
                <View style={styles.section}>
                                <Text style={styles.heading}>
                                    {event?.title}{" "}
                                    {"(" +
                                        new Date(event.startDate).toLocaleDateString(
                                            t("dateLang"),
                                            options as any
                                        ) +
                                        ")"}
                                </Text>
                            </View>
                    
                    {attendeesWithOrders?.map((attendeeData: any, attIdx: any) => (
                        <View key={attIdx} style={styles.attendeeContainer}>
                           <View style={styles.attendeeRow}>
                    <Text style={styles.attendeeNumber}>{attIdx + 1}.</Text>
                    <View style={styles.attendeeDetails}>
                        <Text style={styles.attendeeName}>
                        {/* {attendeeData.attendUsingName
                                                        ? attendeeData.attendUsingName
                                                        : (attendeeData?.user?.firstName+" "+attendeeData?.user?.lastName ) ||
                                                        attendeeData?.user?.userName} */}

                        {attendeeData.attendUsingName 
                            ? attendeeData.attendUsingName 
                            : (attendeeData?.user?.firstName || "") + " " + (attendeeData?.user?.lastName || "") || attendeeData?.user?.userName}

                            {/* {attendeeData.user?.firstName}{" "}{attendeeData.user.lastName && attendeeData.user.lastName } */}
                        </Text>
                        <Text style={styles.attendeeEmail}>{attendeeData.user?.email}</Text>
                        <Text style={styles.attendeeEmail}>{attendeeData.user?.phoneNumber}</Text>
                    </View>
                </View>

                <View style={styles.divider} />

                            {attendeeData.classOrders.map((classOrder: any, classIndex: any) => (
                                <View key={classIndex} style={styles.classOrder}>

<View style={styles.classRow}>
                            <Text style={styles.classNumber}>{classIndex + 1}.</Text>
                        

                        <View style={styles.classDetails}>
                            <Text style={styles.classTitle}>{classOrder.classTitle}</Text>
                            <Text style={styles.priceText}>Price: {classOrder.price +" " + EqCurrency[event.currency]}</Text>
                        </View>
                        </View>
                                  
                                    <View style={styles.listContainer}>
                                        {classOrder?.answers?.map((answer: any, qIdx: number) => (
                                            <View key={qIdx} style={styles.questionContainer}>
                                                <Text style={styles.questionText}>
                                                    {answer.question.questionText}
                                                </Text>
                                                <br />
                                                {answer.question.questionType ===
                                                QuestionType.Text ? (
                                                    <Text style={styles.answer}>
                                                        {answer.answerText}
                                                    </Text>
                                                ) : (
                                                    answer.selectedOptions.map(
                                                        (option: any, oIdx: number) => (
                                                            <Text style={styles.answerText} key={oIdx}>
                                                                {option.option.optionText}
                                                            </Text>
                                                        )
                                                    )
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            ))}
                        </View>
                    ))}
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default EqPublicEventPdfGenerateListView;
