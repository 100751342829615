import { Tabs, TabsProps } from "antd-v5";
import React from "react";
import TrainingCourseBoard from "../trainingCourse/trainingCourseBoard/TrainingCourseBoard";
import "./EqSchoolCourseBoard.scss";
import PreviousCourseBoard from "../trainingCourse/previousCourseBoard/PreviousCourseBoard";
import { useTranslation } from "react-i18next";

const EqSchoolCourseBoard = () => {
    const { t } = useTranslation();
   const [activeTab, setActiveTab] = React.useState("1");

    const [activeCourse, setActiveCourse] = React.useState(null) as any;

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: t("ridingCourse"),
            children: <TrainingCourseBoard 
            activeCourse={activeCourse}
            />,
        },
        {
            key: "2",
            label: t("previousRidingCourse"),
            children: <PreviousCourseBoard 
            onCreateDuplicateCourse={(course:any)=>{
                setActiveTab("1");
                setActiveCourse(course);
            }}
            />,
        },
    ];

    return (
        <div className="eq-school-course-board ">
            <Tabs
                // defaultActiveKey="1"
                activeKey={activeTab}
                items={items}
                onChange={(key) => {
                    setActiveTab(key);
                    // navigate(`?tab=${key}`);
                }}
            />
        </div>
    );
};

export default EqSchoolCourseBoard;
