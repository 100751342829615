import React from 'react';
import { TimePicker } from 'antd';
import './EqTimePicker.scss';
import moment from 'moment';


export interface ITimePickerOptions {
    isSuffixIcon?: boolean;
    isBordered?: boolean;
    isDisabled?: boolean;
    isReadOnly?:boolean
    defaultValue?: any;
    placeHolder?: string;
    classNames?: string;
    minuteInterval?: number;
    onChangeFunc: (time: any, timeString?: string) => void;

}


const EqTimePicker = ({minuteInterval,isSuffixIcon, isBordered, isDisabled, defaultValue, placeHolder, classNames,isReadOnly, onChangeFunc}: ITimePickerOptions) => {

    const timeFormat = 'HH:mm';
    const timeChanged = (time: any) => {
        var value = time;
        console.log(new Date(value).toTimeString())
        onChangeFunc(time);
    }
    const timeSelect = (time: any) => {
        var value = time;
        console.log(new Date(value).toTimeString())
        onChangeFunc(time);
    }
    return (
        <>
            <div className={`eq-pick ${classNames ? classNames : ""}`} >
                <TimePicker
                
                    inputReadOnly={true}
                    minuteStep={minuteInterval}
                    placeholder={placeHolder}
                    format={timeFormat}
                    value={defaultValue ? moment(defaultValue, timeFormat) : null}
                    disabled={isDisabled ? true:false}
                    onChange={timeChanged}
                    onSelect={timeSelect}
                    bordered={isBordered} 
                    allowClear={false} />
            </div>
        </>
    );
}
export default EqTimePicker;