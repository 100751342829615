import React from "react";
import { Document, Page, StyleSheet, Text, View, PDFViewer } from "@react-pdf/renderer";
import { IEqFinancialDetail } from "../../eqTrainer/eqFinancial/EqFinancial";
import { blackColor, lightBlackColor } from "../../shared/Common";
import moment from "moment";
import { EqDisciplineType, EqPaymentStatus, paymentStatusType } from "../../shared/enums";

const styles = StyleSheet.create({
    container: {
        marginBottom: 10,
        padding: 0,
        border: 1,
        borderBottom: 0,
        borderRadius: 6,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },

    header: {
        fontSize: 14,
        fontWeight: "bold",
        padding: 5,
        marginBottom: 0,
        borderBottom: 1,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },

    table: { fontSize: 8, marginBottom: 0, fontWeight: "bold" },

    tableRowHead: {
        fontSize: 10,
        fontWeight: "bold",
        flexDirection: "row",
        borderBottom: 1,
    },

    tableRow: { flexDirection: "row", borderBottom: 1 },

    tableLastRow: {
        flexDirection: "row",
        borderBottom: 1,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
    },

    tableCell: { padding: 4, paddingTop: 2, borderRight: 1 },

    tableCellLast: { padding: 3, paddingTop: 2 },
});

const EqFinancialPdfView = ({ financialsList, type }: any) => {
    return (
        <>
            <Document>
                <Page size="A4" style={styles.container}>
                    <Text style={styles.header}>Financial List</Text>
                    <br />
                    <View style={styles.table}>
                        <View style={styles.tableRowHead}>
                            <Text style={[styles.tableCell, { width: "14%" }]}>Name</Text>
                            <Text style={[styles.tableCell, { width: "14%" }]}> {type}</Text>

                            <Text style={[styles.tableCell, { width: "15%" }]}>Payment Date</Text>
                            {type === "Event" && (
                                <Text style={[styles.tableCell, { width: "14%" }]}>Duration</Text>
                            )}
                            <Text style={[styles.tableCell, { width: "14%" }]}>Amount</Text>
                            {/* <Text style={[styles.tableCell, { width: "14%" }]}>Fee</Text> */}
                            <Text style={[styles.tableCellLast, { width: "15%" }]}>Status</Text>
                        </View>

                        {financialsList.map((financial: any, index: number) => (
                            <View key={index} style={styles.tableRowHead}>
                                {type === "Product" || type === "Course" ? (
                                    <>
                                        <Text style={[styles.tableCell, { width: "14%" }]}>
                                            {financial.name}{" "}
                                        </Text>
                                        <Text style={[styles.tableCell, { width: "14%" }]}>
                                            {financial.title}
                                        </Text>

                                        <Text style={[styles.tableCell, { width: "15%" }]}>
                                            {moment(financial.date).format(
                                                "DD MMMM, YYYY HH:mm"
                                            )}
                                        </Text>

                                        <Text style={[styles.tableCell, { width: "14%" }]}>
                                            Kr. {financial.price}
                                        </Text>
                                        {/* <Text style={[styles.tableCell, { width: "14%" }]}>
                      Kr. {"1.23"}
                    </Text> */}
                                        <Text style={[styles.tableCellLast, { width: "15%" }]}>
                                            {EqPaymentStatus[financial.status]}
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Text style={[styles.tableCell, { width: "14%" }]}>
                                            {financial.user?.firstName} {financial.user?.lastName}
                                        </Text>
                                        <Text style={[styles.tableCell, { width: "14%" }]}>
                                            {financial.event.title}
                                        </Text>
                                        <Text style={[styles.tableCell, { width: "15%" }]}>
                                            {moment(financial.payment.paymentDate).format(
                                                "DD MMMM, YYYY HH:mm"
                                            )}
                                        </Text>
                                        {type === "Event" && (
                                            <Text style={[styles.tableCell, { width: "14%" }]}>
                                                {financial.event.duration} Minutes
                                            </Text>
                                        )}

                                        {type === "Event" ? (
                                            <Text style={[styles.tableCell, { width: "14%" }]}>
                                                Kr. {financial.eventClass.price}
                                            </Text>
                                        ) : (
                                            <Text style={[styles.tableCell, { width: "14%" }]}>
                                                Kr. {financial.event.price}
                                            </Text>
                                        )}

                                        {/* <Text style={[styles.tableCell, { width: "14%" }]}>
                      Kr. {"1.23"}
                    </Text> */}
                                        <Text style={[styles.tableCellLast, { width: "15%" }]}>
                                            {/* {paymentStatusType[financial.slotStatus]} */}
                                            {EqPaymentStatus[financial.payment.status]}
                                        </Text>
                                    </>
                                )}
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </>
    );
};

export default EqFinancialPdfView;
