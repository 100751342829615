import { Col, Row, FloatButton, Empty } from "antd-v5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ProductRequestNew.scss";
import { EqOrderStatus } from "../../../shared/enums";
import { CourseUI } from "../../../shared/interfaces/ridingCourse/IRidingCourse";
import RequestListGeneric from "./RequestListGeneric/RequestListGeneric";

interface ProductRequestProp {
    editCourse?: CourseUI | null;
    onAccept: (requestId: string) => void;
    OnReject: (requestId: string) => void;
    onAcceptCourseLessonOrderRequest: (requestId: string) => void;
    onRejectCourseLessonOrderRequest: (requestId: string) => void;
    multipleSelect?: boolean;
    OnRejectMultiple?: (requestIds: string[]) => void;
    OnAcceptMultiple?: (requestIds: string[]) => void;
    searchQuery: string;
}

const ProductRequestNew: React.FC<ProductRequestProp> = ({
    editCourse,
    onAccept,
    OnReject,
    multipleSelect,
    OnRejectMultiple,
    OnAcceptMultiple,
    onAcceptCourseLessonOrderRequest,
    onRejectCourseLessonOrderRequest,
    searchQuery,
}) => {
    const { t } = useTranslation();
    const [courseorderList, setCourseOrderList] = useState(editCourse?.courseLessonOrders || []);
    const [filteredProductRequests, setFilteredProductRequests] = useState([] as any);
    const [lessonRequests, setLessonRequests] = useState([] as any);
    const [lessonWaitingList, setLessonWaitingList] = useState([] as any);
    // Selection state
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<{ id: string; [key: string]: any }[]>([]);
    const [isCheckAll, setIsCheckAll] = useState(false);

    useEffect(() => {
        const updatedOrders = editCourse?.courseLessonOrders || [];
        setCourseOrderList(updatedOrders);
    debugger;
        if (searchQuery) {
            const filteredOrders = updatedOrders.filter((order) => {
                const fullName = `${order.student?.firstName || ""} ${order.student?.lastName || ""}`.toLowerCase();
                const email = (order.student?.email || "").toLowerCase();
                return (
                    fullName.includes(searchQuery.toLowerCase()) || email.includes(searchQuery.toLowerCase())
                );
            });
            setFilteredProductRequests(filteredOrders);
        } else {
            setFilteredProductRequests(updatedOrders);
        }

let allLessonOrders = [] as any;
editCourse?.courseLessons?.forEach((lesson: any) => {
    // Map through courseLessonOrders and add lesson.price to each order
    const ordersWithPrice = lesson.courseLessonOrders.map((order: any) => ({
        ...order,
        lessonPrice: lesson.price, // Add the lesson price to each order
    }));
    allLessonOrders = [...allLessonOrders, ...ordersWithPrice];
});
  // Separate lesson requests and waiting list based on status
  const requests = allLessonOrders.filter((order:any) => order.orderStatus === EqOrderStatus.Requested);
  const waitingList = allLessonOrders.filter((order:any) => order.orderStatus === EqOrderStatus.Waiting);

  setLessonRequests(requests);
  setLessonWaitingList(waitingList);
  
    }, [editCourse, searchQuery]);
    

    const checkAll = () => {
        const isChecked = !isCheckAll;
        setFilteredProductRequests((prevOrders: any) => {
            const updatedList = prevOrders.map((order: any) => ({
                ...order,
                selected: isChecked,
            }));

            setIsCheckAll(isChecked);
            setSelectedCheckboxes(updatedList.filter((x: any) => x.selected));
            return updatedList;
        });
    };

    const handleOnCheck = (order: any) => {
        setFilteredProductRequests((prevOrders: any) => {
            const updatedList = prevOrders.map((item: any) =>
                item.id === order.id ? { ...item, selected: !item.selected } : item
            );
            setSelectedCheckboxes(updatedList.filter((x: any) => x.selected));
            return updatedList;
        });
    };

    const handleMultipleReject = () => {
        const selectedInviteIds = selectedCheckboxes.map((invite: any) => invite.id);
        OnRejectMultiple && OnRejectMultiple(selectedInviteIds);
        setSelectedCheckboxes([]);
    };

    const handleMultipleAccept = () => {
        const selectedInviteIds = selectedCheckboxes.map((invite: any) => invite.id);
        OnAcceptMultiple && OnAcceptMultiple(selectedInviteIds);
        setSelectedCheckboxes([]);
    };



    // const renderRequestList = (
    //     title: string,
    //     data: any[],
    //     orderStatus: EqOrderStatus
    // ) => (
    //     <RequestListGeneric
    //         title={title}
    //         data={data}
    //         orderStatus={orderStatus}
    //         multipleSelect={false}
    //         isCheckAll={isCheckAll}
    //         onCheckAll={checkAll}
    //         selectedCheckboxes={selectedCheckboxes}
    //         handleAccept={onAccept}
    //         handleReject={OnReject}
    //         handleCheckboxChange={handleOnCheck}
    //         coursePrice={editCourse?.coursePrice}
    //     />
    // );
    

    return (
        <div className="eqProductRequest">
            {editCourse?.courseLessonOrders ? (
                <>
                    <div>
                        <Row>
                            <>
                                    <Col span={24}>
                                        <Row justify="end" className="action-all-btn hidden-sm">
                                            {selectedCheckboxes.length > 1 && (
                                                <div className="float-btn">
                                                    <FloatButton
                                                        className="accept-btn"
                                                        description={t("acceptAll")}
                                                        shape="square"
                                                        onClick={handleMultipleAccept}
                                                    />
                                                    <FloatButton
                                                        className="reject-btn"
                                                        description={t("rejectAll")}
                                                        shape="square"
                                                        onClick={handleMultipleReject}
                                                    />
                                                </div>
                                            )}
                                        </Row>
                                    </Col>

                                    <Col span={24}>
                                        <RequestListGeneric
                                            title={t("requests")}
                                            data={filteredProductRequests}
                                            orderStatus={EqOrderStatus.Requested}
                                            multipleSelect={false}
                                            isCheckAll={isCheckAll}
                                            onCheckAll={checkAll}
                                            selectedCheckboxes={selectedCheckboxes}
                                            handleAccept={onAccept}
                                            handleReject={OnReject}
                                            handleCheckboxChange={handleOnCheck}
                                            coursePrice={editCourse.coursePrice}
                                        />
                                    </Col>
                                </>
                         
                        </Row>
                    </div>

                    <div>
                        <Col span={24}>
                            <Row justify="end" className="action-all-btn hidden-sm">
                                {selectedCheckboxes.length > 1 && (
                                    <div className="float-btn">
                                        <FloatButton
                                            className="accept-btn"
                                            description={t("Accept All")}
                                            shape="square"
                                            onClick={handleMultipleAccept}
                                        />
                                        <FloatButton
                                            className="reject-btn"
                                            description={t("Reject All")}
                                            shape="square"
                                            onClick={handleMultipleReject}
                                        />
                                    </div>
                                )}
                            </Row>
                        </Col>

                        <Col span={24}>
                            <RequestListGeneric
                                title={t("waitingList")}
                                data={filteredProductRequests}
                                orderStatus={EqOrderStatus.Waiting}
                                multipleSelect={false}
                                isCheckAll={isCheckAll}
                                onCheckAll={checkAll}
                                selectedCheckboxes={selectedCheckboxes}
                                handleAccept={onAccept}
                                handleReject={OnReject}
                                handleCheckboxChange={handleOnCheck}
                                coursePrice={editCourse.coursePrice}
                            />
                        </Col>
                    </div>

                            <RequestListGeneric
                                            title={t("lessonRequests")}
                                            data={lessonRequests}
                                            orderStatus={EqOrderStatus.Requested}
                                            multipleSelect={false}
                                            isCheckAll={isCheckAll}
                                            onCheckAll={checkAll}
                                            selectedCheckboxes={selectedCheckboxes}
                                            handleAccept={onAcceptCourseLessonOrderRequest}
                                            handleReject={onRejectCourseLessonOrderRequest}
                                            handleCheckboxChange={handleOnCheck}
                                            coursePrice={editCourse.coursePrice}
                                        />
                        <RequestListGeneric
                                            title={t("lessonWaitingList")}
                                            data={lessonWaitingList}
                                            orderStatus={EqOrderStatus.Waiting}
                                            multipleSelect={false}
                                            isCheckAll={isCheckAll}
                                            onCheckAll={checkAll}
                                            selectedCheckboxes={selectedCheckboxes}
                                            handleAccept={onAcceptCourseLessonOrderRequest}
                                            handleReject={onRejectCourseLessonOrderRequest}
                                            handleCheckboxChange={handleOnCheck}
                                            coursePrice={editCourse.coursePrice}
                                        />

                </>
            ) : (
                <Empty description={t("noDataAvailable")} />
            )}
        </div>
    );
};

export default ProductRequestNew;
