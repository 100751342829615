import * as React from "react";
import { Empty } from "antd";
import { ValidateIsTrainer } from "../../../../shared/Common";
import { useAuthState } from "../../../../context/Context";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CourseLessonCollapse from "../CourseLessonList/CourseLessonCollapse/CourseLessonCollapse"
import { CourseUI, ISchoolStudents, IStudentHorseList, ITrainerOption } from "../../../../shared/interfaces/ridingCourse/IRidingCourse";



export interface ISchoolTrainingList {
    trainings: Array<Object>;
    weekdays: Object;
    studentHorsesList?: IStudentHorseList[];
    schoolStudentsData?: ISchoolStudents[];
    trainersOptions: ITrainerOption;
    editCourse?: CourseUI | null
    isStudent?: boolean;
    onEditLesson?: (data: any) => void;
    onRemoveLessonOrder?: (lessonId:string,order: any, refund: boolean, refundAmount?: number) => void;
    onCourseLessonJoinRequest?: (lessonId:string) => void;
    onPayNowLessonOrder?: (orderId:string) => void;


}
const SchoolTrainingList: React.FC<ISchoolTrainingList> = ({
    trainings,
    trainersOptions,
    weekdays,
    schoolStudentsData,
    studentHorsesList,
    editCourse,
    onEditLesson,
    onRemoveLessonOrder,
    isStudent,
    onCourseLessonJoinRequest,
    onPayNowLessonOrder

}: ISchoolTrainingList) => {
   
    const { t } = useTranslation();


    React.useEffect(() => {
        
    
    }, [trainings , editCourse]);

   

  
    return (
        <>
            
            {editCourse !=null ? (
                <div className="eq-el-container">
                    {editCourse?.courseLessons?.sort((a: any, b: any) => a.startTime - b.startTime)
                    .map((item: any, index: number) => (
                        <CourseLessonCollapse
                            key={index}
                            data={item}
                            onEditLesson={onEditLesson}
                            onRemoveLessonOrder={onRemoveLessonOrder}
                            trainings={item}
                            weekDays={weekdays}
                            trainersOptions={trainersOptions}
                            schoolStudentsData={schoolStudentsData}
                            studentHorsesList={studentHorsesList}
                            editCourse={editCourse}
                            isStudent={isStudent}
                            onCourseLessonJoinRequest={onCourseLessonJoinRequest}
                            onPayNowLessonOrder={onPayNowLessonOrder}

                        />
                    ))}
                </div>
            ) : (
                <div className="eq-el-container">
                    <Empty description={t("noTrainingsAvailable")} />
                </div>
            )}
        </>
    );
};

export default SchoolTrainingList;
