import { Button, Result } from "antd";
import * as React from "react";
import Api from "../../shared/Api";

import { Link, useNavigate } from "react-router-dom";
import EqButton from "../../custom/eqButton/EqButton";
import { ApiEndpoints, FrontEndPoints } from "../../shared/Common";

import "./checkout.scss"; 
const Checkout = (props: any) => {
  //   const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const [checkoutSuccess, setCheckoutSuccess] = React.useState() as any;

  React.useEffect(() => {
    ;
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setCheckoutSuccess(true);
      //   PaidSuccessFully(query.get("key"))
      //   setMessage("Order placed Successfully!");
    } else if (query.get("canceled")) {
      setCheckoutSuccess(false);

      //   setMessage(
      //     "Order canceled -- continue to shop around and checkout when you're ready."
      //   );
    } else {
        navigate(FrontEndPoints.all);
    }
      setTimeout(() => navigate(FrontEndPoints.student.dashboard), 10000);
  }, []);

  //   function PaidSuccessFully(e:any) {

  //     Api.post(ApiEndpoints.PaidSuccessFully, { key: e }).then(response => {
  //         
  //         if (response && response.status == 200) {
  //             // getPendingInvitations();
  //         } else {
  //             // setMessageString('');
  //         }
  //     }, err => {
  //         // setMessageString('');
  //     });
  // }
  return (
    <div className="student-checkout">
      <div className="overlay">
      {checkoutSuccess === true ? (
        <Result
          status="success"
          title="Successfully Purchased!"
          // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
          extra={[
            <Link to={FrontEndPoints.student.dashboard}>
              <EqButton
                type="button"
                bordered="true"
                classNames="btn-height btn-border-text-orange"
              >
                Go To Dashboard
              </EqButton>
            </Link>,
            // <Button type="primary" key="console" href="">
            //   Go To Dashboard
            // </Button>,
            //   <Button key="buy">Buy Again</Button>,
          ]}
        />
      ) : checkoutSuccess === false ? (
        <Result
          status="error"
          title="Submission Failed!"
          subTitle=" continue to shop around and checkout when you're ready."
          extra={[
            <Link to={FrontEndPoints.student.dashboard}>
              <EqButton
                type="button"
                bordered="true"
                //   block={true}
                classNames="btn-height btn-border-text-orange"
              >
                Go To Dashboard
              </EqButton>
            </Link>,
          ]}
        />
      ) : null}
    </div>
    </div>
  );
};

export default Checkout;
